import React, { FunctionComponent, useContext } from "react"
import { ProgressIndicator } from "./ProgressIndicator"
import { QueryParamContext } from "../context/queryParam/QueryParamContext"
import { View, ViewProps } from "react-native"

type OnboardingProgressBarProps = {
	position: number
	section?: number
} & ViewProps
export const OnboardingProgressBar: FunctionComponent<OnboardingProgressBarProps> = ({
	position,
	section = 0,
	style,
	...rest
}) => {
	const { clairEmbed } = useContext(QueryParamContext)
	return !clairEmbed ? (
		<View style={[{ marginBottom: 28, width: "100%" }, style]} {...rest}>
			<ProgressIndicator activeIndex={section} activeProgress={position} />
		</View>
	) : null
}
