import _ from "lodash"
import I18n from "i18n-js"
import { InputMaskTypeProp } from "./applyMask"
import { isAfter, isBefore, isFuture, isValid, parse, subYears } from "date-fns"
import { isEmailAddressValid } from "./isEmailValid"
import { isValidNumber } from "libphonenumber-js"
import { validateEmail } from "../api/validateEmail"
import { validatePasswordPattern } from "./validatePassword"

export const isValidPhoneNumber = (phoneNumber: string) => isValidNumber(`+1 ${phoneNumber}`)

type ValidationTypes =
	| "addressLine1"
	| "addressLine2"
	| "city"
	| "state"
	| "dateOfBirth"
	| "phone"
	| "confirmPassword"
	| "percentage"
	| InputMaskTypeProp
export function validateField(
	type: ValidationTypes,
	value: string,
	callback?: (hasError: boolean, errorMessage?: string) => void,
	comparator?: string
): { hasError: boolean; errorMessage?: string } {
	const checkHasError = () => {
		let errorMessage = ""
		switch (type) {
			case "phone":
				if (!isValidPhoneNumber(value)) {
					errorMessage = I18n.t("Form.IsInvalid", { label: type })
				}
				return {
					hasError: !!errorMessage,
					errorMessage,
				}
			case "city":
			case "state":
			case "addressLine1":
			case "name":
				return { hasError: value?.length < 2 }
			case "email":
				if (_.isEmpty(value)) {
					errorMessage = I18n.t("Form.EmptyField", {
						label: I18n.t("Email.EmailLabel"),
					})
				} else if (!isEmailAddressValid(value)) {
					errorMessage = I18n.t("Email.EmailError")
				}
				return {
					hasError: !!errorMessage,
					errorMessage,
				}
			case "dateOfBirth":
				errorMessage = ""
				const date = parse(value, "MM/dd/yyyy", new Date())
				if (_.isEmpty(value)) {
					errorMessage = I18n.t("Form.EmptyField", {
						label: I18n.t("Form.DateOfBirth"),
					})
				} else if (value?.length != 10) {
					errorMessage = I18n.t("Form.DateFormat", {
						label: I18n.t("Form.DateOfBirth"),
					})
				} else if (isBefore(date, subYears(new Date(), 150)) || isFuture(date) || !isValid(date)) {
					errorMessage = I18n.t("Form.DateOfBirthInvalid")
				} else if (isAfter(date, subYears(new Date(), 18))) {
					errorMessage = I18n.t("Form.MininumAge")
				}
				return {
					hasError: !!errorMessage,
					errorMessage,
				}
			case "postalCode":
				return { hasError: value?.length != 5 }

			case "password":
				errorMessage = ""
				if (_.isEmpty(value)) {
					errorMessage = I18n.t("Form.EmptyField", {
						label: I18n.t("PasswordSet.PasswordLabel"),
					})
				} else if (!validatePasswordPattern(value)) {
					errorMessage = I18n.t("PasswordSet.PasswordInvalid")
				}
				return {
					hasError: !!errorMessage,
					errorMessage,
				}
			case "confirmPassword":
				errorMessage = ""
				if (_.isEmpty(value)) {
					errorMessage = I18n.t("Form.EmptyField", {
						label: I18n.t("PasswordSet.ConfirmPasswordLabel"),
					})
				} else if (value !== comparator) {
					errorMessage = I18n.t("PasswordSet.PasswordNoMatch")
				}
				return {
					hasError: !!errorMessage,
					errorMessage,
				}
			case "numeric":
				errorMessage = ""
				if (value === "") {
					errorMessage = I18n.t("CalculatorAmount.InputError2")
				} else if (Number(value) <= 0) errorMessage = I18n.t("CalculatorAmount.InputError")
				return {
					hasError: !!errorMessage,
					errorMessage,
				}
			case "percentage":
				errorMessage = ""
				if (Number(value) > 100 || Number(value) <= 0) {
					errorMessage = I18n.t("CalculatorPercentage.InputError")
				}
				return {
					hasError: !!errorMessage,
					errorMessage,
				}
			default:
				return { hasError: true }
		}
	}
	const { hasError, errorMessage } = checkHasError()
	if (callback) callback(hasError, errorMessage)
	return hasError
}

export type ValidationArray = [
	ValidationTypes,
	string,
	(hasError: boolean, errorMessage?: string) => void,
	string?
][]
export function validateForm(form: ValidationArray) {
	let isValid = true
	_.forEach(form, (field) => {
		const hasError = validateField(field[0], field[1], field[2], field[3])
		if (hasError) isValid = false
	})
	return isValid
}

export const validateEmailRemote = async (
	value: string,
	callback: (hasError: boolean, errorMessage?: string) => void
): Promise<{ didYouMean: string; deliverable: boolean }> => {
	const { data } = await validateEmail(value)
	if (data.result === "undeliverable") {
		callback(true, I18n.t("EmailUndeliverable"))
	}
	return { didYouMean: data.didYouMean, deliverable: data.result !== "undeliverable" }
}
