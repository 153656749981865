import I18n from "i18n-js"
import { Suggestion } from "../components/AddressAutoComplete"

export const getAddressError = (recordType?: string | null) => {
	if (recordType == "P") {
		return I18n.t("AddressErrors.PostalBox")
	} else if (recordType == "R") {
		return I18n.t("AddressErrors.RuralRoute")
	} else {
		return I18n.t("AddressErrors.Generic")
	}
}

export const getAddressWarning = (
	recordType: string | null | undefined,
	dpvMatchCode: string | null | undefined,
	dpvVacantCode: string | null | undefined
) => {
	if (recordType === "G" || dpvMatchCode === null) {
		return I18n.t("AddressWarnings.Residential")
	} else if (dpvMatchCode === "D") {
		return I18n.t("AddressWarnings.MissingInfo")
	} else if (dpvMatchCode === "N" || dpvMatchCode === "S" || dpvVacantCode === "Y") {
		return I18n.t("AddressWarnings.NotVerified")
	}
}

export const formatAutocompleteSuggestion = (suggestion: Suggestion) => {
	const street = suggestion.streetLine ? `${suggestion.streetLine} ` : ""
	const secondary = suggestion?.secondary ? `${suggestion.secondary} ` : ""
	const entries = suggestion?.entries !== 0 ? `(${suggestion.entries}) ` : ""
	const city = suggestion?.city ? `${suggestion.city} ` : ""
	const state = suggestion?.state ? `${suggestion.state}, ` : ""
	const zip = suggestion?.zipcode ? `${suggestion.zipcode}` : ""
	return street + secondary + entries + city + state + zip
}
