import { cardAPI } from "."

export const getPINSetUrl = async () => {
	const { data } = await cardAPI.get("/set-pin")
	return data
}

export const getCardData = () => cardAPI.get("/").then((r) => r.data.data)
export const confirmPinSet = () => cardAPI.patch("/set-pin")
export const activateCard = () => cardAPI.patch("/activate")
