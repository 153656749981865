import I18n, { ToCurrencyOptions } from "i18n-js"

export const formatCurrency = (
	amount: number | string | null = 0,
	showDecimals = false,
	options?: ToCurrencyOptions
) =>
	I18n.toCurrency(Number(amount), {
		precision: showDecimals ? 2 : 0,
		...options,
	})
