import I18n from "i18n-js"
import React, { ReactElement, useContext, useEffect } from "react"
import { Button } from "../components/Button"
import { FeatureImage } from "../components/FeatureImage"
import { FlexView } from "../components/FlexView"
import { Headline, LegalText, MetroText } from "../components/StyledText"
import { INITIAL_ONBOARDING_STATE } from "../context/onboarding/OnboardingProvider"
import { OnboardingContext } from "../context/onboarding/OnboardingContext"
import { OnboardingStackParamList } from "../types"
import { ONE_SECOND } from "../constants/time"
import { ScreenWrapper } from "../components/ScreenWrapper"
import { SET_ONBOARDING_STATE } from "../context/actionTypes"
import { StackScreenProps } from "@react-navigation/stack"
import { urls } from "../constants/urls"
import { useOpenUrl } from "../hooks/useOpenUrl"
import { useQueryClient } from "react-query"
import { useStaticConfig } from "../hooks/useStaticConfig"

export default function MaintenanceMode({
	navigation,
}: StackScreenProps<OnboardingStackParamList, "MaintenanceMode">): ReactElement {
	const { openUrl } = useOpenUrl()
	const queryClient = useQueryClient()
	const { isSuccess, isFetching } = useStaticConfig(ONE_SECOND * 30)
	const { dispatch } = useContext(OnboardingContext)

	useEffect(() => {
		queryClient.invalidateQueries("staticConfig")
	}, [])

	useEffect(() => {
		if (isSuccess && !isFetching) {
			dispatch({ type: SET_ONBOARDING_STATE, payload: INITIAL_ONBOARDING_STATE })
			navigation.reset({
				routes: [{ name: "Splash" }],
			})
		}
	}, [isSuccess, isFetching])

	return (
		<ScreenWrapper center>
			<FlexView />
			<FeatureImage medium source={require("../assets/images/maintenance_ mode.png")} />
			<Headline center>{I18n.t("MaintenanceMode.Header")}</Headline>
			<MetroText center style={{ marginHorizontal: 26 }}>
				{I18n.t("MaintenanceMode.Body")}
			</MetroText>
			<FlexView />
			<Button title={I18n.t("MaintenanceMode.Button")} onPress={() => openUrl(urls.clairStatus)} />
			<LegalText>{I18n.t("BankingServicesFooter")}</LegalText>
		</ScreenWrapper>
	)
}
