import Colors from "../../constants/Colors"
import I18n from "i18n-js"
import React from "react"
import useAnalytics from "../../hooks/useAnalytics"
import useColorScheme from "../../hooks/useColorScheme"
import { AutoHeightImage } from "../../components/AutoHeightImage"
import { BalanceCards } from "./WageAdvance/BalanceCards"
import { Button, SecondaryButton } from "../../components/Button"
import { ClairCardWrapper } from "../../components/ClairCardWrapper"
import { LegalText, MetroText } from "../../components/StyledText"
import { OnboardingStackParamList } from "../../types"
import { Placeholder, PlaceholderLine, Shine } from "rn-placeholder"
import { ScreenWrapper } from "../../components/ScreenWrapper"
import { StackScreenProps } from "@react-navigation/stack"
import { useAppDownload } from "../../hooks/useAppDownload"
import { useCardData } from "../../hooks/useCardData"
import { useDeviceAndWalletType } from "../../hooks/useDeviceAndWalletType"
import { useOnboardingInit } from "../../hooks/useOnboardingInit"
import { View } from "../../components/Themed"
import { WalletButtonIcon } from "../../components/WalletButtonIcon"

export default function AccountInfoScreen({}: StackScreenProps<
	OnboardingStackParamList,
	"AccountInfo"
>) {
	const { url, receiveDate } = useCardData()
	const { sendDownloadLink } = useAppDownload()
	const { isMobileDevice, walletType, launchWallet } = useDeviceAndWalletType()
	const { data: onboardingData } = useOnboardingInit()
	const pdwDisabled = !onboardingData?.ewaEnabled
	const theme = useColorScheme()
	const { track } = useAnalytics()

	return (
		<ScreenWrapper
			center
			nonMobileContentStyle={{ paddingHorizontal: 0 }}
			contentContainerStyle={{ paddingHorizontal: 0 }}
		>
			<BalanceCards pdwDisabled={pdwDisabled} />
			<View style={{ paddingHorizontal: 24 }}>
				<ClairCardWrapper
					loading={!url}
					style={{
						alignSelf: "center",
						height: 176,
					}}
					loadingHeight={176}
					loadingWidth={280}
				>
					<div data-private>
						<AutoHeightImage
							style={{
								borderRadius: 10,
								marginVertical: 10,
							}}
							width={280}
							source={{ uri: url }}
						/>
					</div>
				</ClairCardWrapper>
				<LegalText style={{ marginBottom: 32, color: Colors[theme]["textLegalSecondary"] }}>
					{I18n.t("AccountInfo.MastercardLegalText")}
				</LegalText>
				{receiveDate ? (
					<MetroText h2 center style={{ marginBottom: 10 }}>
						{I18n.t("AccountInfo.Body1") + " " + receiveDate}
					</MetroText>
				) : (
					<Placeholder Animation={Shine}>
						<PlaceholderLine />
						<PlaceholderLine />
					</Placeholder>
				)}
				<MetroText center style={{ fontSize: 17, marginBottom: 28 }}>
					{I18n.t("AccountInfo.Body2")}
				</MetroText>
				<Button
					title={I18n.t("DownloadApp")}
					onPress={() => {
						track("tap_download_accountinfo")
						sendDownloadLink()
					}}
				/>
				{isMobileDevice && (
					<SecondaryButton
						buttonStyle={{ paddingVertical: walletType === "Samsung" ? 6 : 12 }}
						icon={<WalletButtonIcon walletType={walletType} />}
						iconPosition="right"
						title={I18n.t("AccountInfo.Button")}
						containerStyle={{ marginBottom: 26 }}
						onPress={launchWallet}
					/>
				)}
				<LegalText> {I18n.t("BankAndCardLegalText")}</LegalText>
			</View>
		</ScreenWrapper>
	)
}
