import enStatic from "../localization/en.json"
import I18n from "i18n-js"
import { getExperimentLanguage } from "./../api/getExperimentLanguage"
import { overrideObject } from "./overrideObject"

export const setExperimentLanguage = async (setLanguageReady: (languageReady: boolean) => void) => {
	const { lang } = await getExperimentLanguage()
	if (lang) {
		const en = overrideObject(enStatic, lang)
		I18n.translations = {
			en,
		}
	}
	setLanguageReady(true)
}
