import Colors from "../constants/Colors"
import React, { FunctionComponent } from "react"
import Skeleton from "./Skeleton"
import useColorScheme from "../hooks/useColorScheme"
import { View, ViewProps } from "react-native"

export interface ClairCardWrapperProps extends ViewProps {
	loading?: Boolean
	loadingHeight?: number | string
	loadingWidth?: number | string
}

export const ClairCardWrapper: FunctionComponent<ClairCardWrapperProps> = ({
	style,
	loading,
	loadingHeight,
	loadingWidth,
	...rest
}) => {
	const theme = useColorScheme()
	const backgroundColor = Colors[theme]["backgroundClairCard"]

	return loading ? (
		<View
			style={[
				{
					alignItems: "center",
					backgroundColor,
					justifyContent: "center",
					borderRadius: 10,
					overflow: "hidden",
					marginVertical: 10,
				},
				style,
			]}
		>
			<Skeleton
				color={backgroundColor}
				width={loadingWidth}
				height={loadingHeight}
				borderRadius={10}
			/>
		</View>
	) : (
		<View
			style={[
				{
					alignItems: "center",
					backgroundColor,
					justifyContent: "center",
					borderRadius: 10,
					overflow: "hidden",
					marginVertical: 10,
				},
				style,
			]}
			{...rest}
		/>
	)
}
