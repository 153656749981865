import { onboardingAPI } from "."
import { validatePasswordAPI } from "./index"

export const validatePassword = (payload: object) => {
	return validatePasswordAPI.post("/", payload).catch((error) => {
		throw error.response
	})
}

export const validateEmailPhone = (payload: object) => {
	return onboardingAPI.post(`/validate-email-phone`, payload).catch((error) => {
		throw error.response
	})
}
