import React, { RefObject } from "react"
import useAnalytics from "../../../hooks/useAnalytics"
import { FlatList } from "react-native"
import { Icon } from "react-native-elements"
import { Slide } from "./CarouselSlide"
import { useIsNonMobileScreen } from "../../../hooks/useIsNonMobileScreen"
import { View } from "../../../components/Themed"

export function DesktopChevrons({
	currentIndex,
	flatListRef,
	slides,
	index,
}: {
	currentIndex: number
	flatListRef: RefObject<FlatList<any>>
	slides: Slide[]
	index: number
}) {
	const isNonMobileScreen = useIsNonMobileScreen()
	const { track } = useAnalytics()
	return isNonMobileScreen ? (
		<>
			{currentIndex !== 0 && (
				<View style={{ position: "absolute", top: 300, left: 0, zIndex: 999 }}>
					<Icon
						name="left"
						type="antdesign"
						size={27}
						color={"#ACACAC"}
						onPress={() => {
							flatListRef?.current?.scrollToIndex({
								index: currentIndex - 1,
							})
							track(`tap_back_${currentIndex == 2 ? "three" : "two"}`)
						}}
					/>
				</View>
			)}

			{currentIndex !== slides.length - 1 && (
				<View style={{ position: "absolute", top: 300, right: 0, zIndex: 999 }}>
					<Icon
						name="right"
						type="antdesign"
						size={27}
						color={"#ACACAC"}
						onPress={() => {
							flatListRef?.current?.scrollToIndex({
								index: currentIndex + 1,
							})
							track(slides[index].trackingId)
						}}
					/>
				</View>
			)}
		</>
	) : null
}
