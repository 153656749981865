import Colors from "../../../constants/Colors"
import React, { FunctionComponent, useState } from "react"
import useColorScheme from "../../../hooks/useColorScheme"
import { MetroText } from "../../../components/StyledText"
import { StyleSheet, TouchableOpacity, View } from "react-native"
import { useInnerContentWidth } from "../../../hooks/useInnerContentWidth"

type DDOptionsButtonGroup = {
	buttonValues: string[]
	currentButton: string
	setCurrentButton: React.Dispatch<React.SetStateAction<string>>
	onPress?: Function
}

export const DDOptionsButtonGroup: FunctionComponent<DDOptionsButtonGroup> = ({
	buttonValues,
	currentButton,
	setCurrentButton,
	onPress,
}) => {
	const theme = useColorScheme()
	const titleColor = Colors[theme]["textHighlight"]
	const selectedColor = Colors[theme]["backgroundCard"]
	const [isPressed, setIsPressed] = useState(false)
	const innerContentWidth = useInnerContentWidth()
	return (
		<View
			style={{
				flexDirection: "row",
				justifyContent: "center",
				minWidth: innerContentWidth,
			}}
		>
			{buttonValues.map((button, index) => {
				return (
					<TouchableOpacity
						onPress={() => {
							setIsPressed(!isPressed)
							setCurrentButton(button)
							onPress && onPress()
						}}
						key={button}
						style={[
							styles.button,
							{
								borderColor: titleColor,
								flex: button === "Other" ? 2 : 1,
								backgroundColor: button === currentButton ? selectedColor : "transparent",
							},
						]}
						testID={`calculatorButton${index}`}
					>
						<MetroText style={[styles.buttonText, { color: titleColor }]}>{button}</MetroText>
					</TouchableOpacity>
				)
			})}
		</View>
	)
}

const styles = StyleSheet.create({
	buttonText: {
		fontFamily: "metropolis-semi",
		fontSize: 14,
		lineHeight: 24,
		textAlign: "center",
		padding: 12,
	},
	button: {
		flex: 1,
		borderWidth: 1,
		margin: 5,
		alignItems: "center",
	},
})
