import React, { FunctionComponent } from "react"
import { AutoHeightImage } from "./AutoHeightImage"
import { ImageProps, useWindowDimensions } from "react-native"
import { useIsThinScreen } from "../hooks/useIsThinScreen"

type FeatureImageProps = {
	medium?: boolean
	maxHeight?: number
} & ImageProps
export const FeatureImage: FunctionComponent<FeatureImageProps> = ({
	medium,
	maxHeight,
	style,
	...rest
}) => {
	const windowWidth = useWindowDimensions().width
	const isThinScreen = useIsThinScreen()
	const mediumWidth = isThinScreen ? windowWidth / 1.5 : 300
	const smallWidth = isThinScreen ? windowWidth - 48 : 332

	return (
		<AutoHeightImage
			width={medium ? mediumWidth : smallWidth}
			style={[{ marginBottom: medium ? 45 : 24, alignSelf: "center" }, style]}
			resizeMode="contain"
			maxHeight={maxHeight}
			{...rest}
		/>
	)
}
