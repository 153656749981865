import _ from "lodash"
import I18n from "i18n-js"
import PDWGeneralErrorImage from "../../assets/images/pdw_general_error.png"
import React from "react"
import { Button } from "../../components/Button"
import { FeatureImage } from "../../components/FeatureImage"
import { FlexView } from "../../components/FlexView"
import { Headline, LegalText, MetroText } from "../../components/StyledText"
import { OnboardingStackParamList } from "../../types"
import { ScreenWrapper } from "../../components/ScreenWrapper"
import { skipWageAdvanceStage } from "../../api/wageAdvance"
import { StackScreenProps } from "@react-navigation/stack"
import { useAuthenticatedUserQuery } from "../../hooks/useAuthenticatedUser"
import { useQueryClient } from "react-query"

export default function PDWGeneralError({
	navigation,
}: StackScreenProps<OnboardingStackParamList, "PDWGeneralError">) {
	const queryClient = useQueryClient()
	const { isFetching } = useAuthenticatedUserQuery()

	return (
		<ScreenWrapper center>
			<FeatureImage source={PDWGeneralErrorImage} />
			<Headline center>{I18n.t("PDWGeneralError.PageHeader")}</Headline>
			<MetroText h4 center>
				{I18n.t("PDWGeneralError.Body")}
			</MetroText>
			<FlexView />
			<Button
				loading={isFetching}
				title={I18n.t("PDWGeneralError.Button")}
				onPress={async () => {
					await skipWageAdvanceStage()
					await queryClient.refetchQueries("authUser")
					navigation.reset({ routes: [{ name: "CardActivationEntry" }] })
				}}
			/>
			<LegalText>{I18n.t("BankingServicesFooter")}</LegalText>
		</ScreenWrapper>
	)
}
