import _ from "lodash"
import I18n from "i18n-js"
import Loading from "../../components/Loading"
import React, { useEffect, useRef, useState } from "react"
import { activateCard, confirmPinSet } from "../../api/card"
import { FlexView } from "../../components/FlexView"
import { Headline, MetroText } from "../../components/StyledText"
import { OnboardingStackParamList } from "../../types"
import { ONE_SECOND } from "../../constants/time"
import { ScreenWrapper } from "../../components/ScreenWrapper"
import { StackScreenProps } from "@react-navigation/stack"
import { useCalculatedFrameDimensions } from "../../hooks/useCalculatedFrameDimensions"
import { useLinkTo } from "@react-navigation/native"
import { usePINSetUrl } from "../../hooks/usePinSetUrl"
import { useQueryClient } from "react-query"
import { View } from "react-native"
import { WebView } from "react-native-web-webview"

export default function SetPinScreen({
	navigation,
}: StackScreenProps<OnboardingStackParamList, "SetPin">) {
	const [isIframeLoaded, setIsIframeLoaded] = useState(false)
	const [loadingText, setLoadingText] = useState("Fetching...")
	const [loading, setLoading] = useState(false)
	const { width } = useCalculatedFrameDimensions()
	const [renderKey, setRenderKey] = useState(0)

	const linkTo = useLinkTo()
	const webViewRef = useRef()
	const { url, fetchUrl } = usePINSetUrl()
	const queryClient = useQueryClient()

	useEffect(() => {
		const iframe = document?.getElementById("iframe-container")?.firstChild
		// If there is data in the contentWindow, the iframe loaded successfully
		const iframeLoaded = Object.keys(iframe?.contentWindow).length > 0
		// Delay setting iframeLoaded for 1 second to ensure loading state is always seen
		const iframeTimer = setTimeout(() => {
			if (iframeLoaded) {
				setIsIframeLoaded(true)
			} else {
				setIsIframeLoaded(false)
			}
		}, ONE_SECOND)

		// Check that the iframe loaded, waiting 6 seconds for the content to load.
		const errorTimer = setTimeout(() => {
			if (!iframeLoaded) {
				handleErrorNavigation()
			}
		}, ONE_SECOND * 6)

		return () => {
			clearTimeout(iframeTimer)
			clearTimeout(errorTimer)
		}
	}, [])

	const handleErrorNavigation = () => {
		setRenderKey(renderKey + 1)
		fetchUrl()
		linkTo("/PinSettingError")
	}

	const handleMessage = async ({ nativeEvent }) => {
		// https://galileo-ft.readme.io/galileo-pro/docs/direct-render-pin-set-procedure
		const galileoOriginUrls = [
			"https://secure-agserv.ua.gpsrv.com", // Old staging
			"https://secure-agserv.pd.gpsrv.com", // Old prod
			"https://agserv-clair.cv.gpsrv.com", // New staging
			"https://agserv-clair.pd.gpsrv.com", // New prod
		]
		if (!galileoOriginUrls.includes(nativeEvent.origin)) return

		// If pin is successfully set, Galileo will emit an event with a status code
		// of 0 and the pin change can be commited and the user's card can be activated
		if (nativeEvent.data == "0") {
			setLoadingText("Setting your PIN...")
			setLoading(true)
			try {
				await confirmPinSet()
				await activateCard()
				// this will ensure the Add To Wallet screen will have the updated stage
				queryClient.invalidateQueries("authUser")
				navigation.reset({ index: 0, routes: [{ name: "AddToWallet" }] })
			} catch (err) {
				// If a pin is not set, a request is sent for a new pin set form URL,
				// so when a user returns to this screen, they can re-attempt to set a pin.
				handleErrorNavigation()
			}
			setLoading(false)
			setLoadingText("")
		} else {
			handleErrorNavigation()
		}
	}

	return (
		<ScreenWrapper noPadding>
			<View style={{ marginTop: 18, marginHorizontal: 24 }}>
				<Headline style={{ marginBottom: 30 }}>{I18n.t("SetPin.PageHeader")}</Headline>
				<MetroText h4>{I18n.t("SetPin.Body")}</MetroText>
			</View>
			<View
				style={{ alignItems: "center", justifyContent: "center", alignSelf: "center" }}
				nativeID="iframe-container"
			>
				<WebView
					key={renderKey}
					startInLoadingState={true}
					ref={webViewRef}
					source={{
						uri: url,
					}}
					style={{
						height: 600,
						width,
					}}
					javaScriptEnabled={true}
					scrollEnabled={false}
					onMessage={handleMessage}
				/>
				{(!isIframeLoaded || loading) && <Loading text={loadingText} />}
			</View>
			<FlexView />
		</ScreenWrapper>
	)
}
