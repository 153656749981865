import { AsYouType } from "libphonenumber-js/min"
import { negateSmartPunctuation } from "./negateSmartPunctuation"
import { stripInvalidCharacters } from "./stripInvalidCharacters"

export type InputMaskTypeProp =
	| "password"
	| "phone"
	| "postalCode"
	| "text"
	| "numeric"
	| "email"
	| "alphabetical"
	| "date"
	| "name"
export function applyMask(text: string, mask: InputMaskTypeProp) {
	let sanitized = negateSmartPunctuation(text) || ""
	switch (mask) {
		case "alphabetical": {
			sanitized = sanitized.replace(/[^a-zA-Z ]+/g, "")
			break
		}
		case "date": {
			sanitized = sanitized.replace(/\D/g, "")
			let dateCharArr = sanitized.split("")
			let month = dateCharArr.splice(0, 2)
			let day = dateCharArr.splice(0, 2)
			let year = dateCharArr.splice(0, 4)
			if (month?.length == 2 && day?.length > 0) {
				month.push("/")
			}
			if (day?.length == 2 && year?.length > 0) {
				day.push("/")
			}
			sanitized = month.join("") + day.join("") + year.join("")
			break
		}
		case "name": {
			sanitized = sanitized.replace(/[^A-Za-zÀ-ÖØ-öø-ÿ ]+/g, "")
			break
		}
		case "phone": {
			sanitized = sanitized.replace(/\D/g, "")
			if (sanitized.charAt(0) == "1") {
				sanitized = sanitized.substring(1)
			}
			if (sanitized?.length > 3) {
				if (sanitized?.length > 10) {
					sanitized = sanitized.substring(0, 10)
				}
				sanitized = new AsYouType("US").input(sanitized)
			}
			break
		}
		case "postalCode": {
			sanitized = sanitized.replace(/\D/g, "")
			if (sanitized?.length > 5) {
				sanitized = sanitized.substring(0, 5)
			}
			break
		}
		case "email": {
			// The emoji key is disabled on both android and iPhone keyboards, but this is used to catch the majority of emojis if the user copy and pastes it.
			sanitized = stripInvalidCharacters(sanitized)
			// Remove all whitespace within the field
			// This also works if the user pastes in an email with a space
			sanitized = sanitized.replace(/\s+/g, "")
			// Catches the majority of illegal characters
			sanitized = sanitized.replace("œ", "oe")
			sanitized = sanitized.replace(/[Ā-󟴗]/g, "")
			break
		}
		case "password": {
			// If the input is a single space, remove it
			if (text === " ") {
				sanitized = ""
				break
			}
			// Remove any whitespace at the beginning of the string
			// Whitespace present at the end of the string will be trimmed
			// once the string is submitted
			const startTrim = sanitized.charAt(0) === " " ? 1 : 0
			sanitized = sanitized.substring(startTrim)
			break
		}
		case "numeric": {
			sanitized = sanitized.replace(/[^0-9]/g, "")
			break
		}
		default: {
			break
		}
	}
	return sanitized
}
