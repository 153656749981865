import Colors from "../constants/Colors"
import I18n from "i18n-js"
import React, { FunctionComponent } from "react"
import useAnalytics from "../hooks/useAnalytics"
import useColorScheme from "../hooks/useColorScheme"
import { Avatar } from "react-native-elements"
import { buttonTextStyle, SecondaryListButton } from "../components/Button"
import { MetroText, P3 } from "./StyledText"
import { NavigationContainerRef } from "@react-navigation/native"
import { OnboardingStackParamList } from "../types"
import { Overlay, OverlayProps } from "./Overlay"
import { urls } from "../constants/urls"
import { useIsNonMobileScreen } from "../hooks/useIsNonMobileScreen"
import { useOpenInternalUrl } from "../hooks/useOpenInternalUrl"
import { useOpenUrl } from "../hooks/useOpenUrl"
import { useSendEmail } from "../hooks/useSendEmail"
import { useStaticConfig } from "../hooks/useStaticConfig"

type HelpOverlayProps = {
	navigationRef: NavigationContainerRef<OnboardingStackParamList> | null
} & OverlayProps

export const HelpOverlay: FunctionComponent<HelpOverlayProps> = ({
	navigationRef,
	isVisible,
	setShowOverlay,
}) => {
	const { data } = useStaticConfig()
	const {
		support_email,
		support_phone_number,
		friendly_support_sms_number,
		support_sms_enabled,
		support_sms_number,
		friendly_support_phone_number_numeric,
	} = data || {}
	const { sendEmail } = useSendEmail()
	const { openUrl } = useOpenUrl()
	const { openInternalUrl } = useOpenInternalUrl()
	const theme = useColorScheme()
	const isMobileScreen = !useIsNonMobileScreen()
	const { track } = useAnalytics()
	return isVisible ? (
		<Overlay
			isVisible={true}
			showClose
			setShowOverlay={(overlayVisible: boolean) => {
				if (!overlayVisible) track("tap_close_help", { on: navigationRef?.getCurrentRoute()?.name })
				setShowOverlay(overlayVisible)
			}}
			style={{ paddingHorizontal: 32 }}
		>
			<MetroText h1 style={{ alignSelf: "flex-start", marginBottom: 10 }}>
				{I18n.t("HelpOverlay.Header")}
			</MetroText>
			<P3 style={{ marginBottom: 24 }}>{I18n.t("HelpOverlay.Body")}</P3>
			<SecondaryListButton
				icon={
					<Avatar
						source={require("../assets/icons/question_book_solid_blue.png")}
						imageProps={{
							resizeMode: "contain",
						}}
						size={15}
						rounded={false}
					/>
				}
				onPress={async () => {
					await track("tap_FAQs", { on: navigationRef?.getCurrentRoute()?.name })
					openInternalUrl(urls.clairCareSupportHome, navigationRef)
					setShowOverlay(false)
				}}
				title={I18n.t("HelpOverlay.FAQ")}
				style={{ width: 264 }}
			/>
			<SecondaryListButton
				icon={
					<Avatar
						source={require("../assets/icons/envelope_solid_blue.png")}
						imageProps={{
							resizeMode: "contain",
						}}
						size={15}
						rounded={false}
					/>
				}
				onPress={async () => {
					await track("tap_email", { on: navigationRef?.getCurrentRoute()?.name })
					sendEmail({ recipients: [support_email] })
				}}
				title={I18n.t("HelpOverlay.Email")}
				style={{ width: 264 }}
			/>
			{support_sms_enabled && (
				<SecondaryListButton
					icon={
						<Avatar
							source={require("../assets/icons/text_solid_blue.png")}
							imageProps={{
								resizeMode: "contain",
							}}
							size={15}
							rounded={false}
						/>
					}
					onPress={async () => {
						await track("tap_text", { on: navigationRef?.getCurrentRoute()?.name })
						openUrl("sms:" + support_sms_number)
					}}
					title={
						<MetroText
							style={[{ paddingLeft: 19, color: Colors[theme]["textHighlight"] }, buttonTextStyle]}
						>
							{I18n.t("HelpOverlay.Text")}
							{!isMobileScreen && <P3> {"\n" + friendly_support_sms_number}</P3>}
						</MetroText>
					}
					style={{ width: 264 }}
				/>
			)}
			<SecondaryListButton
				icon={
					<Avatar
						source={require("../assets/icons/phone_blue.png")}
						imageProps={{
							resizeMode: "contain",
						}}
						size={15}
						rounded={false}
					/>
				}
				onPress={async () => {
					await track("tap_call_care", { on: navigationRef?.getCurrentRoute()?.name })
					openUrl("tel:" + support_phone_number)
				}}
				title={
					<MetroText
						style={[{ paddingLeft: 19, color: Colors[theme]["textHighlight"] }, buttonTextStyle]}
					>
						{I18n.t("HelpOverlay.Call")}
						{!isMobileScreen && <P3> {"\n" + friendly_support_phone_number_numeric}</P3>}
					</MetroText>
				}
				style={{ width: 264 }}
			/>
			<P3 style={{ alignSelf: "flex-start", marginTop: 10 }}>{I18n.t("HelpOverlay.HoursText")}</P3>
		</Overlay>
	) : null
}
