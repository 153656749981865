import Colors from "../../../constants/Colors"
import I18n from "i18n-js"
import React, { useCallback, useEffect, useState } from "react"
import useColorScheme from "../../../hooks/useColorScheme"
import { Avatar } from "react-native-elements"
import { Button, SecondaryListButton } from "../../../components/Button"
import { FlexView } from "../../../components/FlexView"
import { Headline, MetroText } from "../../../components/StyledText"
import { OnboardingProgressBar } from "../../../components/OnboardingProgressBar"
import { OnboardingStackParamList } from "../../../types"
import { ScreenWrapper } from "../../../components/ScreenWrapper"
import { StackScreenProps } from "@react-navigation/stack"
import { useContactSupport } from "../../../hooks/useContactSupport"
import { useDirectDepositFinalStatus } from "../../../hooks/useDirectDepositStatus"
import { useFocusEffect, useLinkTo, useNavigation } from "@react-navigation/native"
import { useRefetchInterval } from "../../../hooks/useRefetchInterval"

export default function FormActionRequired({}: StackScreenProps<
	OnboardingStackParamList,
	"FormActionRequired"
>) {
	const theme = useColorScheme()
	const { callSupport, emailSupport } = useContactSupport()
	const linkTo = useLinkTo()
	const navigation = useNavigation()
	const [refetchInterval, setRefetchInterval] = useState<false | number>(false)
	const { status, isFetching } = useDirectDepositFinalStatus(refetchInterval)
	const updatedInterval = useRefetchInterval(isFetching)

	useEffect(() => {
		setRefetchInterval(updatedInterval)
	}, [updatedInterval])

	useFocusEffect(
		useCallback(() => {
			if (status == "COMPLETED")
				navigation.reset({
					routes: [{ name: "DDSuccess" }],
				})
		}, [status])
	)

	const restartSetup = () => {
		linkTo("/CalculatorEntry")
	}

	return (
		<ScreenWrapper>
			<OnboardingProgressBar section={1} position={12} />
			<Headline>{I18n.t("FormActionRequired.PageHeader")}</Headline>
			<MetroText style={{ marginBottom: 24 }}>{I18n.t("FormActionRequired.Body")}</MetroText>
			<MetroText h3 style={{ marginBottom: 16 }}>
				{I18n.t("FormActionRequired.Subheader")}
			</MetroText>
			<SecondaryListButton
				title={I18n.t("FormActionRequired.Action1")}
				icon={
					<Avatar
						source={require("../../../assets/images/envelope_blue.png")}
						imageProps={{
							resizeMode: "contain",
						}}
						size={32}
						rounded={false}
						overlayContainerStyle={{ backgroundColor: Colors[theme]["background"] }}
						placeholderStyle={{ backgroundColor: Colors[theme]["background"] }}
					/>
				}
				onPress={() => emailSupport("tap_email_formactionrequired")}
			/>
			<SecondaryListButton
				title={I18n.t("FormActionRequired.Action2")}
				icon={
					<Avatar
						source={require("../../../assets/images/phone_with_blue_background.png")}
						imageProps={{
							resizeMode: "contain",
						}}
						size={32}
						rounded={false}
						overlayContainerStyle={{ backgroundColor: Colors[theme]["background"] }}
						placeholderStyle={{ backgroundColor: Colors[theme]["background"] }}
					/>
				}
				onPress={() => callSupport("tap_call_formactionrequired")}
			/>
			<FlexView />
			<Button title={I18n.t("FormActionRequired.ButtonText")} onPress={restartSetup} />
		</ScreenWrapper>
	)
}
