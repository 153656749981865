import _ from "lodash"
import I18n from "i18n-js"
import Loading from "../../../components/Loading"
import React, { useContext, useEffect, useState } from "react"
import useAnalytics from "../../../hooks/useAnalytics"
import { Button, SecondaryButton } from "../../../components/Button"
import { FAQsLink } from "./DirectDepositFAQLink"
import { FeatureImageProto } from "../../../components/FeatureImageProto"
import { FlexView } from "../../../components/FlexView"
import { Headline, LegalText, MetroText } from "../../../components/StyledText"
import { OnboardingProgressBar } from "../../../components/OnboardingProgressBar"
import { OnboardingStackParamList } from "../../../types"
import { QueryParamContext } from "../../../context/queryParam/QueryParamContext"
import { ScreenWrapper } from "../../../components/ScreenWrapper"
import { SHOW_DOWNLOAD_APP_TRY_AGAIN } from "../../../constants/featureFlags"
import { StackScreenProps } from "@react-navigation/stack"
import { urls } from "../../../constants/urls"
import { useAppDownload } from "../../../hooks/useAppDownload"
import { useCallback } from "react"
import { useDirectDepositFinalStatus } from "../../../hooks/useDirectDepositStatus"
import { useFocusEffect, useLinkTo } from "@react-navigation/native"
import { useRefetchInterval } from "../../../hooks/useRefetchInterval"
import { useTreatments } from "@splitsoftware/splitio-react"

export default function TryAgainScreen({
	navigation,
}: StackScreenProps<OnboardingStackParamList, "TryAgain">) {
	const { clairEmbed } = useContext(QueryParamContext)
	const [refetchInterval, setRefetchInterval] = useState<false | number>(false)
	const { forceManual, isFetching, isLoading, status, failReason, failReason2, isAtomic } =
		useDirectDepositFinalStatus(refetchInterval)
	const updatedInterval = useRefetchInterval(isFetching)
	const linkTo = useLinkTo()
	const { track } = useAnalytics()
	const { sendDownloadLink } = useAppDownload()
	const treatments = useTreatments([SHOW_DOWNLOAD_APP_TRY_AGAIN])

	useFocusEffect(
		useCallback(() => {
			if (status == "COMPLETED") {
				navigation.reset({
					routes: [{ name: "DDSuccess" }],
				})
			} else if (forceManual) {
				navigation.reset({
					routes: [{ name: "TryAnotherWay" }],
				})
			}
		}, [status, forceManual])
	)

	useEffect(() => {
		setRefetchInterval(updatedInterval)
	}, [updatedInterval])

	return isLoading ? (
		<Loading />
	) : (
		<ScreenWrapper center>
			<OnboardingProgressBar section={1} position={4} />
			<FeatureImageProto
				source={require("../../../assets/images/question_over_clair_app.png")}
				style={{ marginTop: 36 }}
			/>
			<Headline smallText center testID="tryAgainHeadline">
				{I18n.t("SomethingWentWrong")}
			</Headline>
			<MetroText center style={{ marginBottom: 22 }} testID="tryAgainBody1">
				{failReason}
			</MetroText>
			<MetroText center style={{ marginBottom: 32 }} testID="tryAgainBody2">
				{failReason2}
			</MetroText>
			<FlexView />

			{treatments[SHOW_DOWNLOAD_APP_TRY_AGAIN].treatment === "on" ? (
				<>
					{!clairEmbed && (
						<Button
							title={I18n.t("DownloadApp")}
							onPress={() => {
								track("tap_download_TryAgain")
								sendDownloadLink()
							}}
						/>
					)}
					<SecondaryButton
						title={I18n.t("TryAgain.Button1")}
						onPress={() => {
							linkTo(isAtomic ? "/AtomicWelcome" : "/CalculatorEntry")
						}}
						testID="tryAgainButton"
					/>
				</>
			) : (
				<Button
					title={I18n.t("TryAgain.Button1")}
					onPress={() => {
						linkTo(isAtomic ? "/AtomicWelcome" : "/CalculatorEntry")
					}}
					testID="tryAgainButton"
				/>
			)}
			<FlexView />
			<FAQsLink url={urls.howToSwitchToClair} eventName="tap_FAQs_tryagain" />
			<FlexView />
			<LegalText>{I18n.t("BankingServicesFooter")}</LegalText>
		</ScreenWrapper>
	)
}
