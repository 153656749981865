import _ from "lodash"
import { getDirectDepositStatus } from "../api/directDeposit"
import { ONE_MINUTE } from "./../constants/time"
import { useEffect, useState } from "react"
import { useQuery } from "react-query"
import { useUserContext } from "./../context/user/UserContext"

export type DirectDepositStatus = "NOT_STARTED" | "ACTION_REQUIRED" | "COMPLETED" | "IN_PROGRESS"

export type DirectDepositQuery = {
	type: "ATOMIC" | "ATOMIC_CLAIR_CARE" | "WORKLIO" | string
	token?: string
	companyId?: string
	connectorId?: string
	status: DirectDepositStatus
	failReason: string | null
	failReason2: string | null
	forceManual?: boolean
	exceededFailedTaskLimit: boolean
	requestType: "HUMAN_RESOURCES" | "SELF_SEND" | "DIRECT_DEPOSIT_INBOX" | null
	directDepositInboxAddress?: string
	metadata?: {
		tna: string
	}
	allowPartialDirectDepositChange?: boolean
	clairCare?: {
		directDepositInboxAddress: string
		status: DirectDepositStatus
		isLatest: boolean
		requestType: "HUMAN_RESOURCES" | "SELF_SEND" | null
	}
	payrollProvider: {
		name: string | null
		logo: string | null
	}
}

export default function useDirectDepositStatus(
	refetchInterval?: number | false | (() => number | false)
) {
	const { accessToken } = useUserContext()
	return useQuery<DirectDepositQuery>("directDeposit", () => getDirectDepositStatus(accessToken), {
		staleTime: ONE_MINUTE,
		enabled: !!accessToken,
		refetchInterval,
	})
}

/* Used to determine a user's direct deposit status based on their latest switch attempt.
Currently used to determine where to navigate returning users in the direct deposit stage */
export const useDirectDepositFinalStatus = (
	refetchInterval?: number | false | (() => number | false)
) => {
	const { data, isFetching, isLoading } = useDirectDepositStatus(refetchInterval)
	const automatedDepositHandlers = ["ATOMIC", "ATOMIC_CLAIR_CARE"]
	const [isManualSwitch, setIsManualSwitch] = useState(
		Boolean(!_.includes(automatedDepositHandlers, data?.type) || data?.clairCare?.isLatest)
	)
	const deriveManualSwitchStatus = () =>
		data?.status == "COMPLETED" ? "COMPLETED" : data?.clairCare?.status || data?.status
	const [status, setStatus] = useState(isManualSwitch ? deriveManualSwitchStatus() : data?.status)

	useEffect(() => {
		setIsManualSwitch(
			Boolean(!_.includes(automatedDepositHandlers, data?.type) || data?.clairCare?.isLatest)
		)
	}, [data?.clairCare?.isLatest, data?.type])

	useEffect(() => {
		setStatus(isManualSwitch ? deriveManualSwitchStatus() : data?.status)
	}, [data?.status, data?.clairCare?.status, isManualSwitch])

	return {
		isManualSwitch,
		status,
		isAtomic: data?.type?.includes("ATOMIC"),
		forceManual: data?.forceManual,
		failReason: data?.failReason,
		failReason2: data?.failReason2,
		allowPartialDirectDepositChange: data?.allowPartialDirectDepositChange,
		isFetching,
		isLoading,
		requestType: data?.requestType,
	}
}
/* Used to determine the different direct deposit switch options (i.e. Atomic, manual form, etc.) 
available to user if they do not have a status of complete. */
export const useDirectDepositOptions = () => {
	const { data } = useDirectDepositStatus()
	const automatedDepositHandlers = ["ATOMIC", "ATOMIC_CLAIR_CARE"]
	const hasManualHandler = Boolean(!_.includes(automatedDepositHandlers, data?.type))
	const [showManualOptions, setShowManualOptions] = useState(hasManualHandler || data?.forceManual)

	useEffect(() => {
		setShowManualOptions(hasManualHandler || data?.forceManual)
	}, [data?.forceManual])

	return {
		showManualOptions,
		allowPartialDirectDepositChange: data?.allowPartialDirectDepositChange,
	}
}
