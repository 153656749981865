import * as Device from "expo-device"
import * as MailComposer from "expo-mail-composer"
import { QueryParamContext } from "../context/queryParam/QueryParamContext"
import { useContext } from "react"
import { useOpenUrl } from "./useOpenUrl"

export const useSendEmail = () => {
	const { inSdk } = useContext(QueryParamContext)
	const { openUrl } = useOpenUrl()

	const sendEmail = async ({
		recipients,
		subject = "",
		body = "",
	}: {
		recipients?: string[]
		subject?: string
		body?: string
	}) =>
		!inSdk && Device.osName !== "iOS" && (await MailComposer.isAvailableAsync())
			? MailComposer.composeAsync({
					recipients,
					subject,
					body,
			  })
			: openUrl(
					`mailto:${recipients && encodeURIComponent(recipients.join(","))}?subject=${
						subject && encodeURIComponent(subject)
					}&body=${body && encodeURIComponent(body)}`
			  )

	return { sendEmail }
}
