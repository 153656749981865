import React, { useState } from "react"
import { Icon } from "react-native-elements"
import { Pressable, PressableProps } from "react-native"

type MuteButtonProps = PressableProps & {
	onMutePress: (shouldMute: boolean) => void
}
export const MuteButton = ({ onMutePress, ...rest }: MuteButtonProps) => {
	const [muted, setMuted] = useState(true)
	const opacity = 0.76

	return (
		<Pressable
			style={{
				position: "absolute",
				top: 80,
				right: 24,
				zIndex: 1,
				backgroundColor: "white",
				opacity,
				width: 40,
				height: 40,
				borderRadius: 40 / 2,
				alignItems: "center",
				justifyContent: "center",
			}}
			onPress={() => {
				onMutePress(!muted)
				setMuted(!muted)
			}}
			{...rest}
		>
			<Icon
				name={muted ? "volume-mute-outline" : "volume-high-outline"}
				type="ionicon"
				color="black"
				size={24}
				style={{
					opacity,
				}}
			/>
		</Pressable>
	)
}
