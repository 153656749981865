import I18n from "i18n-js"
import React, { ReactElement } from "react"
import useAnalytics from "../hooks/useAnalytics"
import { MetroText, MetroTextProps } from "./StyledText"
import { useNavigation } from "@react-navigation/native"
import { useOpenInternalUrl } from "../hooks/useOpenInternalUrl"
import { useOpenUrl } from "../hooks/useOpenUrl"

interface TextUrlProps extends MetroTextProps {
	url: string
	textPath: string
	highlight?: boolean
	eventName?: string
	internalUrl?: boolean
}

export function TextUrl({
	url,
	textPath,
	highlight,
	eventName,
	internalUrl,
	...rest
}: TextUrlProps): ReactElement {
	const { openUrl } = useOpenUrl()
	const { openInternalUrl } = useOpenInternalUrl()
	const { track } = useAnalytics()
	const navigation = useNavigation()
	return (
		<MetroText
			highlight={highlight}
			onPress={() => {
				if (eventName) track(eventName)
				internalUrl ? openInternalUrl(url, navigation) : openUrl(url)
			}}
			{...rest}
		>
			{I18n.t(textPath)}
		</MetroText>
	)
}
