import AsyncStorage from "@react-native-async-storage/async-storage"

export const hydrateState = async (
	context: string,
	initialState: object,
	stateSetter: React.Dispatch<React.SetStateAction<any>>,
	overrideState = {}
) => {
	try {
		// Get the async values for all of the keys for the @context
		const asyncState = await AsyncStorage.getItem(context)
		if (asyncState) {
			const asyncStateObject = JSON.parse(asyncState)
			// Only set the state of non-default state that is not overwritten by null Async State values
			// and apply optional override state, ie url params that cannot async state cannot be relied on for
			stateSetter({ ...initialState, ...asyncStateObject, ...overrideState })
		} else if (Object.keys(overrideState).length) {
			stateSetter({ ...initialState, ...overrideState })
		}
	} catch (error) {
		console.error(error, `Failed to hydrate state for context ${context}`)
	}
}
