import * as React from "react"
import Colors from "../constants/Colors"
import useColorScheme from "../hooks/useColorScheme"
import { forwardRef } from "react"
import { ScrollView as DefaultScrollView, TextProps, View as DefaultView } from "react-native"
import { Text as DefaultText, TextProps as RNETextProps } from "react-native-elements"
/**
 * Learn more about Light and Dark modes:
 * https://docs.expo.io/guides/color-schemes/
 */

export function useThemeColor(
	props: { light?: string; dark?: string },
	colorName: keyof typeof Colors.light & keyof typeof Colors.dark
) {
	const theme = useColorScheme()
	const colorFromProps = props[theme]

	if (colorFromProps) {
		return colorFromProps
	} else {
		return Colors[theme][colorName]
	}
}

type ThemeProps = {
	lightColor?: string
	darkColor?: string
	highlight?: boolean
	error?: boolean
}

export type DefaultTextProps = ThemeProps & RNETextProps & TextProps
export type ViewProps = ThemeProps & DefaultView["props"]
export type ScrollViewProps = ThemeProps & DefaultScrollView["props"]

export function Text({
	style,
	lightColor,
	darkColor,
	highlight,
	error,
	...rest
}: DefaultTextProps) {
	const color = useThemeColor(
		{ light: lightColor, dark: darkColor },
		highlight ? "textHighlight" : error ? "textError" : "text"
	)

	return (
		<DefaultText
			style={[{ color, fontFamily: "metropolis-regular", fontSize: 14, lineHeight: 20 }, style]}
			{...rest}
		/>
	)
}

export function View({ style, lightColor, darkColor, ...rest }: ViewProps) {
	const backgroundColor = useThemeColor({ light: lightColor, dark: darkColor }, "background")

	return <DefaultView style={[{ backgroundColor }, style]} {...rest} />
}

export const ScrollView = forwardRef(
	({ contentContainerStyle, lightColor, darkColor, ...rest }: ScrollViewProps, ref) => {
		const backgroundColor = useThemeColor({ light: lightColor, dark: darkColor }, "background")

		return (
			<DefaultScrollView
				ref={ref}
				contentContainerStyle={[{ backgroundColor }, contentContainerStyle]}
				showsVerticalScrollIndicator={false}
				keyboardShouldPersistTaps="handled"
				showsHorizontalScrollIndicator={false}
				{...rest}
			/>
		)
	}
)
