import { height } from "../App"
import { SMALL_PHONE_HEIGHT } from "../constants/Layout"
/**
 * @description Hook that checks if the visible, non obstructed (ie by a keyboard) screen hight is
 * shorter medium sized screen lower breakpoint. Usage should generally be limited to screen with
 * no expected obstructions.
 *
 * @returns boolean, true if the visible, non obstructed (ie by a keyboard) height is shorter than
 * medium sized screen lower breakpoint.
 */
export const useIsVisibleScreenShort = (): boolean => {
	return height <= SMALL_PHONE_HEIGHT
}
