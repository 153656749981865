import Constants from "expo-constants"
import { Platform } from "react-native"

export const devUrl = "https://clairpay.ninja/"
export const stagingUrl = "https://clairpay.ninja/"
export const sandboxUrl = "https://getclair.dev/"
export const prodUrl = "https://clairpay.io/"

const ENV = {
	dev: {
		environment: "dev",
		apiUrl: devUrl,
		clientId: "12",
		clientSecret: "TNULq9hZsanscn8qufMVDjpu5U2Z8ydI8PjHtqu0",
		pusherKey: "76ce38f9248957272e2d",
		splitKey: "p09c3pr77ddbq61bg3120k1tl1fhu788386n",
	},
	staging: {
		environment: "staging",
		apiUrl: stagingUrl,
		clientId: "12",
		clientSecret: "TNULq9hZsanscn8qufMVDjpu5U2Z8ydI8PjHtqu0",
		pusherKey: "76ce38f9248957272e2d",
		splitKey: "p09c3pr77ddbq61bg3120k1tl1fhu788386n",
	},
	sandbox: {
		environment: "sandbox",
		apiUrl: sandboxUrl,
		clientId: "17",
		clientSecret: "16HolKbmTsLehPVQaWXHgRT5SwueDmR8aYBY0ZsY",
		pusherKey: "b13769034e1f2502494e",
		splitKey: "d52pcu7c4o433pkda8nd25og6rv6c67i8300",
	},
	production: {
		environment: "production",
		apiUrl: prodUrl,
		clientId: "12",
		clientSecret: "SCESk2XpcODMIHrwblv7hyu4srl81ZEFEU4qzXDL",
		pusherKey: "2942a708a4e53876b8b8",
		splitKey: "b3341rrggmn06ndn09i7nkah56cu4t21la31",
	},
}

function getEnvVars(env = "") {
	if (Platform.OS == "web") {
		if (window.location.origin.indexOf("clairpay.io") !== -1) return ENV.production
		if (window.location.origin.indexOf("getclair.dev") !== -1) return ENV.sandbox
		if (window.location.origin.indexOf("clairpay.ninja") !== -1) return ENV.staging
		return ENV.dev
	}
	if (!env) return ENV.dev
	if (env.indexOf("staging") !== -1) return ENV.staging
	if (env.indexOf("sandbox") !== -1) return ENV.sandbox
	if (env.indexOf("production") !== -1) return ENV.production
	return ENV.dev
}

const releaseChannel =
	(Constants && Constants.manifest && Constants.manifest.releaseChannel) || undefined
export default getEnvVars(releaseChannel)
