import Colors from "../constants/Colors"
import I18n from "i18n-js"
import Loading from "../components/Loading"
import React, { useContext, useLayoutEffect } from "react"
import useAnalytics from "../hooks/useAnalytics"
import useColorScheme from "../hooks/useColorScheme"
import useDefaultHeaderStyle from "../hooks/useDefaultHeaderStyle"
import { Button, SecondaryButton } from "../components/Button"
import { FeatureImage } from "../components/FeatureImage"
import { FlexView } from "../components/FlexView"
import { Headline, MetroText } from "../components/StyledText"
import { INITIAL_USER_STATE } from "../context/user/UserProvider"
import { OnboardingStackParamList } from "../types"
import { Pressable, StyleSheet } from "react-native"
import { QueryParamContext } from "../context/queryParam/QueryParamContext"
import { ScreenWrapper } from "../components/ScreenWrapper"
import { SET_USER_STATE } from "../context/actionTypes"
import { StackScreenProps } from "@react-navigation/stack"
import { useBroadcastEvent } from "../hooks/useBroadcastEvent"
import { useLogout } from "../hooks/useLogout"
import { useOnboardingInit } from "../hooks/useOnboardingInit"
import { useUserContext } from "../context/user/UserContext"

export default function AreYouSure({
	navigation,
}: StackScreenProps<OnboardingStackParamList, "AreYouSure">) {
	const theme = useColorScheme()
	const broadcastEvent = useBroadcastEvent()
	const { accessToken, rememberUser, dispatch } = useUserContext()
	const { inSdk } = useContext(QueryParamContext)
	const { data } = useOnboardingInit()
	const { defaultHeaderStyle } = useDefaultHeaderStyle()
	const { track } = useAnalytics()
	const logout = useLogout()
	useLayoutEffect(() => {
		if (data?.status == "Complete") {
			navigation.setOptions({
				headerStyle: {
					...defaultHeaderStyle,
					backgroundColor: Colors[theme]["backgroundSuccess"],
				},
			})
		} else {
			navigation.setOptions({
				headerStyle: { ...defaultHeaderStyle, backgroundColor: Colors[theme]["background"] },
			})
		}
	}, [data])

	const renderPostKYC = () => (
		<ScreenWrapper
			center
			contentContainerStyle={{ backgroundColor: Colors[theme]["backgroundSuccess"] }}
			nonMobileContentStyle={{ backgroundColor: Colors[theme]["backgroundSuccess"] }}
		>
			<FeatureImage medium source={require("../assets/images/wallet_to_card.png")} />
			<Headline center smallText>
				{I18n.t("AreYouSure.PostKYC.PageHeader")}
			</Headline>
			<MetroText h4 center>
				{I18n.t("AreYouSure.PostKYC.LoggedInBody")}
			</MetroText>
			<FlexView />
			<Button
				title={I18n.t("AreYouSure.PostKYC.Button1")}
				onPress={() => {
					logout(navigation)
				}}
			/>
			<SecondaryButton
				title={I18n.t("AreYouSure.PostKYC.Button2")}
				onPress={() => {
					navigation.goBack()
				}}
			/>
		</ScreenWrapper>
	)

	const renderPostKYCEmbedded = () => (
		<ScreenWrapper
			center
			contentContainerStyle={{ backgroundColor: Colors[theme]["backgroundSuccess"] }}
			nonMobileContentStyle={{ backgroundColor: Colors[theme]["backgroundSuccess"] }}
		>
			<FeatureImage medium source={require("../assets/images/wallet_to_card.png")} />
			<Headline center>{I18n.t("AreYouSure.PostKYCEmbedded.PageHeader")}</Headline>
			{accessToken && rememberUser ? (
				<MetroText h4 center style={styles.bodyText}>
					{I18n.t("AreYouSure.PostKYCEmbedded.LoggedInBody")}{" "}
					<Pressable
						onPress={async () => {
							await logout(navigation)
							broadcastEvent({ event: "clair-onboarding-close", payload: { reason: "unknown" } })
						}}
					>
						<MetroText h4 highlight>
							{I18n.t("AreYouSure.PostKYCEmbedded.LoggedInLogOutText")}
						</MetroText>
					</Pressable>
				</MetroText>
			) : (
				<MetroText center style={styles.bodyText}>
					{I18n.t("AreYouSure.PostKYCEmbedded.Body")}
				</MetroText>
			)}

			<FlexView />
			<Button
				title={I18n.t("AreYouSure.PostKYCEmbedded.Button1")}
				onPress={() => {
					navigation.goBack()
				}}
			/>

			{accessToken && rememberUser ? (
				<SecondaryButton
					title={I18n.t("AreYouSure.PostKYCEmbedded.LoggedInButton2")}
					onPress={() => {
						track("exit_widget")
						broadcastEvent({ event: "clair-onboarding-close", payload: { reason: "unknown" } })
					}}
				/>
			) : (
				<SecondaryButton
					title={I18n.t("AreYouSure.PostKYCEmbedded.Button2")}
					onPress={async () => {
						await logout(navigation)
						dispatch({ type: SET_USER_STATE, payload: INITIAL_USER_STATE })
						broadcastEvent({ event: "clair-onboarding-close", payload: { reason: "unknown" } })
					}}
				/>
			)}
		</ScreenWrapper>
	)
	const renderPreKYCEmbedded = () => (
		<ScreenWrapper center>
			<FeatureImage medium source={require("../assets/images/phone_question.png")} />
			<Headline center>{I18n.t("AreYouSure.PreKYCEmbedded.PageHeader")}</Headline>
			<MetroText h4 center style={styles.bodyText}>
				{I18n.t("AreYouSure.PreKYCEmbedded.Body")}
			</MetroText>
			<FlexView />
			<Button
				title={I18n.t("AreYouSure.PreKYCEmbedded.Button1")}
				onPress={() => {
					navigation.goBack()
				}}
			/>
			<SecondaryButton
				title={I18n.t("AreYouSure.PreKYCEmbedded.Button2")}
				onPress={() => {
					track("exit_widget")
					broadcastEvent({ event: "clair-onboarding-close", payload: { reason: "unknown" } })
				}}
			/>
		</ScreenWrapper>
	)
	if (data?.status) {
		return data?.status == "Complete"
			? inSdk
				? renderPostKYCEmbedded()
				: renderPostKYC()
			: renderPreKYCEmbedded()
	}
	return <Loading />
}

const styles = StyleSheet.create({
	bodyText: { marginLeft: 30, marginRight: 30, marginBottom: 29 },
})
