export function negateSmartPunctuation(text = "") {
  let sanitized
  // Replaces curly apostrophes with the straight apostrophe.
  sanitized = text && text.replace(/[‘’]/g, "'")
  // Replaces curly quotation marks with the straight quotation mark.
  sanitized = sanitized && sanitized.replace(/[“”]/g, '"')
  // Replaces emdash with two dashes.
  // Currently replacing any set of more than one - in a row with just a single -.
  // sanitized = sanitized.replace(/[—]/g, '--');
  return sanitized
}
