import { getPINSetUrl } from "../api/card"
import { useEffect, useState } from "react"

export const usePINSetUrl = () => {
	const [url, setUrl] = useState("")
	const [error, setError] = useState(null)

	const fetchUrl = async () => {
		try {
			const { data } = await getPINSetUrl()
			setUrl(data.iframeUrl)
		} catch (err) {
			console.error(err)
			setError(err)
		}
	}

	useEffect(() => {
		fetchUrl()
	}, [])

	return { url, error, fetchUrl }
}
