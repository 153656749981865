import I18n from "i18n-js"
import React from "react"
import { Button } from "../../components/Button"
import { FeatureImage } from "../../components/FeatureImage"
import { FlexView } from "../../components/FlexView"
import { Headline, MetroText } from "../../components/StyledText"
import { OnboardingStackParamList } from "../../types"
import { ScreenWrapper } from "../../components/ScreenWrapper"
import { StackScreenProps } from "@react-navigation/stack"

export default function PinSettingErrorScreen({
	navigation,
}: StackScreenProps<OnboardingStackParamList, "PinSettingError">) {
	return (
		<ScreenWrapper center>
			<FeatureImage medium source={require("../../assets/images/pin_set_error.png")} />
			<Headline center style={{ marginBottom: 30 }}>
				{I18n.t("PinSettingError.PageHeader")}
			</Headline>
			<MetroText h4 center style={{ marginBottom: 59 }}>
				{I18n.t("PinSettingError.Body")}
			</MetroText>
			<FlexView />
			<Button
				title={I18n.t("Return")}
				onPress={() => {
					navigation.reset({
						routes: [{ name: "SetPin" }],
					})
				}}
			/>
		</ScreenWrapper>
	)
}
