import _ from "lodash"
import I18n from "i18n-js"
import React from "react"
import { Button } from "../../../components/Button"
import { FeatureImageProto } from "../../../components/FeatureImageProto"
import { FlexView } from "../../../components/FlexView"
import { LegalText, MetroText } from "../../../components/StyledText"
import { OnboardingProgressBar } from "../../../components/OnboardingProgressBar"
import { OnboardingStackParamList } from "../../../types"
import { ScreenWrapper } from "../../../components/ScreenWrapper"
import { SKIP_CALCULATOR_ENTRY_FIRST_ATTEMPT } from "../../../constants/featureFlags"
import { StackScreenProps } from "@react-navigation/stack"
import { useDirectDepositOptions } from "../../../hooks/useDirectDepositStatus"
import { useLinkTo } from "@react-navigation/native"
import { useTreatments } from "@splitsoftware/splitio-react"

export default function DirectDepositEntryB({}: StackScreenProps<
	OnboardingStackParamList,
	"DirectDepositEntryB"
>) {
	const linkTo = useLinkTo()
	const treatments = useTreatments([SKIP_CALCULATOR_ENTRY_FIRST_ATTEMPT])
	const { showManualOptions } = useDirectDepositOptions()

	return (
		<ScreenWrapper center>
			<OnboardingProgressBar section={1} position={3} />
			<FeatureImageProto source={require("../../../assets/images/check_over_clair_app.png")} />
			<MetroText h1 center testID="ddEntryBHeadline">
				{I18n.t("DirectDepositEntryB.PageHeader")}
			</MetroText>
			<FlexView />
			<MetroText center>{I18n.t("DirectDepositEntryB.Body")}</MetroText>
			<FlexView />
			<Button
				title={I18n.t("Continue")}
				onPress={() =>
					linkTo(
						treatments[SKIP_CALCULATOR_ENTRY_FIRST_ATTEMPT].treatment === "on"
							? `/Options?showManual=${showManualOptions}`
							: "/CalculatorEntry"
					)
				}
				testID="ddEntryContinue1"
			/>
			<LegalText>{I18n.t("IssuerStatement")}</LegalText>
		</ScreenWrapper>
	)
}
