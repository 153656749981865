import _ from "lodash"
import Colors from "../../../constants/Colors"
import I18n from "i18n-js"
import Loading from "../../../components/Loading"
import React from "react"
import useColorScheme from "../../../hooks/useColorScheme"
import useDirectDepositStatus from "../../../hooks/useDirectDepositStatus"
import { Button, SecondaryButton } from "../../../components/Button"
import { FAQsLink } from "./DirectDepositFAQLink"
import { FlexView } from "../../../components/FlexView"
import { Headline, LegalText, MetroText, Bold } from "../../../components/StyledText"
import { OnboardingProgressBar } from "../../../components/OnboardingProgressBar"
import { OnboardingStackParamList } from "../../../types"
import { ScreenWrapper } from "../../../components/ScreenWrapper"
import { StackScreenProps } from "@react-navigation/stack"
import { StyleSheet, View } from "react-native"
import { urls } from "../../../constants/urls"
import { useAuthenticatedUserQuery } from "../../../hooks/useAuthenticatedUser"
import { useLinkTo } from "@react-navigation/native"

export default function Options({ route }: StackScreenProps<OnboardingStackParamList, "Options">) {
	const { data: userData } = useAuthenticatedUserQuery()
	const { businesses } = userData?.userData || {}
	const employerName = businesses[0].name || I18n.t("Options.Employer")
	const { data: directDepositData, isFetching } = useDirectDepositStatus()
	const { payrollProvider } = directDepositData || {}
	const { showManual } = route?.params || {}
	// when using params with linkTo the type is string
	const showManualOptions =
		directDepositData?.forceManual || showManual === "true" || showManual === true
	const theme = useColorScheme()
	const linkTo = useLinkTo()

	if (isFetching) return <Loading />

	return (
		<ScreenWrapper>
			<OnboardingProgressBar section={1} position={6} />
			<Headline smallText testID="optionsHeadline">
				{I18n.t("Options.PageHeader")}
			</Headline>
			<MetroText style={{ marginBottom: 18 }} testID="optionsBody">
				{I18n.t("Options.Body1")}
				<Bold>{employerName}</Bold>
				{I18n.t("Options.Body2")}
			</MetroText>
			{showManualOptions ? (
				<View
					style={[
						styles.ddOptionCard,
						{
							borderColor: Colors[theme]["borderDefault"],
						},
					]}
				>
					<MetroText h3 style={{ marginBottom: 8 }} testID="optionsManualSubheader">
						{I18n.t("Options.ManualDIYSubheader")}
					</MetroText>
					<MetroText style={{ marginBottom: 10 }} testID="optionsManualDIYBody">
						{I18n.t("Options.ManualDIYBody")}
						{payrollProvider?.name ? (
							<Bold>{payrollProvider?.name}.</Bold>
						) : (
							I18n.t("Options.PayrollProviderUnknown")
						)}
					</MetroText>
					<Button
						title={I18n.t("Options.ManualDIYButton")}
						onPress={() => linkTo("/ManualSwitchHome")}
						containerStyle={{ width: "100%" }}
						buttonStyle={{ minWidth: "100%" }}
						testID="optionsManualDIYButton"
					/>
				</View>
			) : (
				<View
					style={[
						styles.ddOptionCard,
						{
							borderColor: Colors[theme]["borderDefault"],
						},
					]}
				>
					<MetroText h3 style={{ marginBottom: 8 }} testID="optionsAtomicSubheader">
						{payrollProvider?.name
							? I18n.t("Options.AtomicSubheaderKnown", { payrollProvider: payrollProvider.name })
							: I18n.t("Options.AtomicSubheaderUnknown")}
					</MetroText>
					<MetroText style={{ marginBottom: 10 }} testID="optionsAtomicBody">
						{I18n.t("Options.AtomicBody")}
					</MetroText>
					<Button
						title={I18n.t("Options.AtomicButton")}
						onPress={() => linkTo("/AtomicWelcome")}
						containerStyle={{ width: "100%" }}
						buttonStyle={{ minWidth: "100%" }}
						testID="optionsAtomicButton"
					/>
				</View>
			)}
			<View
				style={[
					styles.ddOptionCard,
					{
						borderColor: Colors[theme]["borderDefault"],
					},
				]}
			>
				<MetroText h3 style={{ marginBottom: 8 }}>
					{I18n.t("Options.ManualFormSubheader")}
				</MetroText>
				<MetroText style={{ marginBottom: 10 }}>{I18n.t("Options.ManualFormBody")}</MetroText>
				<SecondaryButton
					title={I18n.t("Options.ManualFormButton")}
					onPress={() => linkTo("/VerifyFormInfo")}
					containerStyle={{ width: "100%" }}
					buttonStyle={{ minWidth: "100%" }}
					testID="optionsManualFormButton"
				/>
			</View>
			<FlexView />
			<FAQsLink
				style={{ alignSelf: "center" }}
				url={urls.howToSwitchToClair}
				eventName={showManualOptions ? "tap_FAQs_OptionsManual" : "tap_FAQs_OptionsAtomic"}
			/>
			<LegalText>{I18n.t("BankingServicesFooter")}</LegalText>
		</ScreenWrapper>
	)
}

const styles = StyleSheet.create({
	ddOptionCard: {
		borderWidth: 1,
		borderRadius: 6,
		padding: 20,
		marginBottom: 24,
	},
})
