import { cardAPI } from "../api"
import { getCardData } from "../api/card"
import { ONE_MINUTE } from "../constants/time"
import { useQuery } from "react-query"
type CardData = {
	confirmedReceived: boolean
	createdAt: string
	hasActivatedMobileWallet: boolean
	id: number
	imageUrl: string
	imageUrlExpiration: string
	isFrozen: boolean
	isInitiallyActivated: boolean
	replacementCost: number
	shouldReceiveDate: string
}
export const useCard = () => {
	return useQuery<CardData>("card", () => getCardData(), {
		staleTime: ONE_MINUTE * 4,
		retry: true,
		enabled: !!cardAPI.defaults.headers["Authorization"],
	})
}
