import Colors from "../../../constants/Colors"
import I18n from "i18n-js"
import NumberOne from "../../../assets/images/numbers_1_blue.png"
import NumberThree from "../../../assets/images/numbers_3_blue.png"
import NumberTwo from "../../../assets/images/numbers_2_blue.png"
import PhoneRocket from "../../../assets/images/phone_rocket.png"
import React from "react"
import useAnalytics from "../../../hooks/useAnalytics"
import useColorScheme from "../../../hooks/useColorScheme"
import { Button, SecondaryButton } from "../../../components/Button"
import { FeatureImage } from "../../../components/FeatureImage"
import { Headline, LegalText, MetroText } from "../../../components/StyledText"
import { ImageBullet } from "../../../components/ImageBullet"
import { OnboardingProgressBar } from "../../../components/OnboardingProgressBar"
import { OnboardingStackParamList } from "../../../types"
import { ScreenWrapper } from "../../../components/ScreenWrapper"
import { StackScreenProps } from "@react-navigation/stack"
import { useContactSupport } from "../../../hooks/useContactSupport"
import { View } from "react-native"

export default function ManualSwitchConfirm({}: StackScreenProps<
	OnboardingStackParamList,
	"ManualSwitchConfirm"
>) {
	const theme = useColorScheme()
	const { callSupport, emailSupport } = useContactSupport()
	const { track } = useAnalytics()

	return (
		<ScreenWrapper center>
			<OnboardingProgressBar section={1} position={4} />
			<FeatureImage medium source={PhoneRocket} />
			<Headline>{I18n.t("ManualSwitchConfirm.PageHeader")}</Headline>
			<MetroText style={{ marginBottom: 24 }}>{I18n.t("ManualSwitchConfirm.Body")}</MetroText>
			<View
				style={{
					borderRadius: 6,
					padding: 21,
					backgroundColor: Colors[theme]["primaryGray"],
					marginBottom: 24,
				}}
			>
				<View
					style={{
						marginBottom: 22,
						alignItems: "center",
					}}
				>
					<MetroText h3 style={{ marginBottom: 8 }}>
						{I18n.t("ManualSwitchConfirm.InstructionsTitle")}
					</MetroText>
					<MetroText center>{I18n.t("ManualSwitchConfirm.InstructionsSubtitle")}</MetroText>
				</View>
				<ImageBullet
					image={NumberOne}
					imageSize={28}
					title={I18n.t(`ManualSwitchConfirm.Instruction1Title`)}
					titleStyle={{ color: Colors[theme]["text"] }}
					style={{ marginBottom: 8 }}
				/>
				<MetroText style={{ marginBottom: 24 }}>
					{I18n.t(`ManualSwitchConfirm.Instruction1Body`)}
				</MetroText>
				<ImageBullet
					image={NumberTwo}
					imageSize={28}
					title={I18n.t(`ManualSwitchConfirm.Instruction2Title`)}
					titleStyle={{ color: Colors[theme]["text"] }}
					style={{ marginBottom: 8 }}
				/>
				<MetroText style={{ marginBottom: 24 }}>
					{I18n.t(`ManualSwitchConfirm.Instruction2Body`)}
				</MetroText>
				<ImageBullet
					image={NumberThree}
					imageSize={28}
					title={I18n.t("ManualSwitchConfirm.Instruction3Title")}
					titleStyle={{ color: Colors[theme]["text"] }}
					style={{ marginBottom: 8 }}
				/>
				<MetroText>{I18n.t("ManualSwitchConfirm.Instruction3Body")}</MetroText>
			</View>
			<Button
				title={I18n.t("ManualSwitchConfirm.ButtonText")}
				onPress={() => {
					track("tap_send_confirmation")
					emailSupport()
				}}
			/>
			<SecondaryButton
				title={I18n.t("ManualSwitchConfirm.SecondaryButtonText")}
				onPress={() => {
					track("tap_call_clair_care_confirm")
					callSupport()
				}}
			/>
			<LegalText center>{I18n.t("BankingServicesFooter")}</LegalText>
		</ScreenWrapper>
	)
}
