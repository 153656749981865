import I18n from "i18n-js"
import React, { useContext } from "react"
import { Button } from "../../../components/Button"
import { FlexView } from "../../../components/FlexView"
import { Headline, MetroText } from "../../../components/StyledText"
import { KeyboardAvoidingView, Platform } from "react-native"
import { OnboardingContext } from "../../../context/onboarding/OnboardingContext"
import { OnboardingProgressBar } from "../../../components/OnboardingProgressBar"
import { OnboardingStackParamList } from "../../../types"
import { patchState } from "../../../api/onboarding"
import { Picker } from "../../../components/Picker"
import { ScreenWrapper } from "../../../components/ScreenWrapper"
import { SET_ONBOARDING_STATE } from "../../../context/actionTypes"
import { StackScreenProps } from "@react-navigation/stack"
import { useEffect, useState } from "react"
import { useLinkTo } from "@react-navigation/native"
import { useMappedAnnualSalaryRanges } from "../../../hooks/useMappedAnnualSalaryRanges"
import { useMappedIndustryOptions } from "../../../hooks/useMappedIndustryOptions"

export default function EmploymentInfoScreen({
	navigation,
}: StackScreenProps<OnboardingStackParamList, "EmploymentInfo">) {
	const { industry, annualSalaryRange, dispatch } = useContext(OnboardingContext)
	const [hasIndustryError, setHasIndustryError] = useState(false)
	const [hasAnnualSalaryRangeError, setHasAnnualSalaryRangeError] = useState(false)
	let industries = useMappedIndustryOptions()
	let annualSalaryRanges = useMappedAnnualSalaryRanges()
	const linkTo = useLinkTo()
	const onSubmit = () => {
		if (industry && annualSalaryRange) {
			patchState({
				annualSalaryRange,
				industry,
			})
			linkTo("/SSN")
		} else {
			if (!industry) setHasIndustryError(true)
			if (!annualSalaryRange) setHasAnnualSalaryRangeError(true)
		}
	}

	useEffect(() => {
		if (industries.length == 1) {
			dispatch({
				type: SET_ONBOARDING_STATE,
				payload: { industry: industries[0].value },
			})
			setHasIndustryError(false)
		}
	}, [industries])

	if (!industries) return null
	return (
		<ScreenWrapper>
			<KeyboardAvoidingView
				behavior={Platform.OS == "ios" ? "padding" : "height"}
				keyboardVerticalOffset={120}
				style={{ flex: 1 }}
			>
				<OnboardingProgressBar position={8} />
				<Headline smallText testID="employmentInfoHeadline">
					{I18n.t("EmploymentInfo.PageHeader")}
				</Headline>
				<MetroText>{I18n.t("EmploymentInfo.Body")}</MetroText>
				{industries.length > 1 && (
					<Picker
						pickerDefault={"EmploymentInfo.IndustryDefault"}
						label={I18n.t("EmploymentInfo.IndustryLabel")}
						labelStyle={{
							fontSize: 16,
							fontFamily: "metropolis-semi",
						}}
						items={industries}
						selectedValue={industry}
						onValueChange={(value: string) => {
							dispatch({
								type: SET_ONBOARDING_STATE,
								payload: { industry: value },
							})
							setHasIndustryError(false)
						}}
						errorMessage={I18n.t("Form.IsRequired", {
							label: I18n.t("EmploymentInfo.Industry"),
						})}
						hasError={hasIndustryError}
						testID="employmentInfoIndustryInput"
					/>
				)}
				<Picker
					pickerDefault={"EmploymentInfo.IncomeDefault"}
					label={I18n.t("EmploymentInfo.IncomeLabel")}
					labelStyle={{
						fontSize: 16,
						fontFamily: "metropolis-semi",
					}}
					items={annualSalaryRanges}
					selectedValue={annualSalaryRange}
					onValueChange={(value: string) => {
						dispatch({
							type: SET_ONBOARDING_STATE,
							payload: { annualSalaryRange: value },
						})
						setHasAnnualSalaryRangeError(false)
					}}
					errorMessage={I18n.t("Form.IsRequired", {
						label: I18n.t("EmploymentInfo.AnnualSalaryRange"),
					})}
					hasError={hasAnnualSalaryRangeError}
					testID="employmentInfoSalaryInput"
				/>

				<FlexView />
				<Button title={I18n.t("Next")} onPress={onSubmit} testID="employmentInfoNext" />
			</KeyboardAvoidingView>
		</ScreenWrapper>
	)
}
