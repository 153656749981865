import { sendAppDownloadNotification } from "../api/onboarding"
import { useDeviceAndWalletType } from "./useDeviceAndWalletType"
import { useLinkTo } from "@react-navigation/native"
import { useOpenUrl } from "./useOpenUrl"

export const useAppDownload = () => {
	const linkTo = useLinkTo()
	const { openUrl } = useOpenUrl()
	const { isMobileDevice } = useDeviceAndWalletType()
	const sendDownloadLink = async () => {
		await sendAppDownloadNotification()
		if (isMobileDevice) {
			openUrl("https://clairpay.io/app-download")
		} else linkTo("/TextMeALink")
	}

	return { sendDownloadLink }
}
