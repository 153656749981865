import Colors from "../constants/Colors"
import React, { ReactNode } from "react"
import useColorScheme from "../hooks/useColorScheme"
import { CheckBox, CheckBoxProps } from "react-native-elements"
import { Pressable, StyleSheet } from "react-native"

export const Checkbox = ({
	checked,
	text,
	style,
	size = 18,
	onPress,
	...rest
}: CheckBoxProps & { text: ReactNode }) => {
	const theme = useColorScheme()

	return (
		<Pressable style={[{ flexDirection: "row", marginBottom: 13 }, style]} onPress={onPress}>
			<CheckBox
				iconType="ionicon"
				checkedIcon="ios-checkbox"
				checkedColor={Colors[theme]["checkboxChecked"]}
				uncheckedColor={Colors[theme]["checkboxChecked"]}
				uncheckedIcon="ios-square-outline"
				size={size}
				checked={checked}
				containerStyle={styles.CheckboxContainer}
				onPress={onPress}
				{...rest}
			/>
			{text}
		</Pressable>
	)
}

const styles = StyleSheet.create({
	CheckboxContainer: {
		padding: 0,
		borderWidth: 0,
		marginLeft: 2,
		backgroundColor: "transparent",
	},
})
