import Colors from "../constants/Colors"
import React, { useEffect } from "react"
import useColorScheme from "../hooks/useColorScheme"
import { adjust } from "../utils/adjust"
import { Animated, Easing, StyleSheet, View } from "react-native"
import { LinearGradient } from "expo-linear-gradient"
import { useWindowDimensions } from "react-native"

const AnimatedLG = Animated.createAnimatedComponent(LinearGradient)
const theme = useColorScheme()

type SkeletonComponentType = {
	color?: string
	highlightColor?: string
	height?: number | string
	width?: number | string
	style?: {}
	brightenColor?: boolean
	borderRadius?: number
}
export default function Skeleton({
	color = Colors[theme]["skeleton"],
	highlightColor = Colors[theme]["background"],
	height = 24,
	width = 100,
	style = {},
	brightenColor = false,
	borderRadius = 0,
	...rest
}: SkeletonComponentType) {
	const windowWidth = useWindowDimensions().width

	const animatedValue = new Animated.Value(0)
	const highlight = brightenColor ? adjust(color, 10) : highlightColor
	const outputWidth = typeof width == "number" ? width : windowWidth

	useEffect(() => {
		Animated.loop(
			Animated.timing(animatedValue, {
				useNativeDriver: false,
				toValue: 1,
				duration: 1000,
				easing: Easing.inOut(Easing.linear),
			})
		).start()
	})

	const translateX = animatedValue.interpolate({
		inputRange: [0, 1],
		outputRange: [-outputWidth, outputWidth * 2],
	})

	return (
		<View
			style={{
				backgroundColor: color,
				borderColor: color,
				height,
				width,
				overflow: "hidden",
				borderRadius,
				...style,
			}}
			{...rest}
		>
			<AnimatedLG
				colors={[color, highlight, highlight, color]}
				start={{ x: 0, y: 0 }}
				end={{ x: 1, y: 0 }}
				style={{
					...StyleSheet.absoluteFillObject,
					transform: [{ translateX: translateX }],
				}}
			/>
		</View>
	)
}
