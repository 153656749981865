import _ from "lodash"
import Colors from "../../constants/Colors"
import I18n from "i18n-js"
import React, { useState } from "react"
import useColorScheme from "../../hooks/useColorScheme"
import { AxiosError } from "axios"
import { Button, TertiaryButton } from "../../components/Button"
import { FlexView } from "../../components/FlexView"
import { Headline, MetroText, P3 } from "../../components/StyledText"
import { Input } from "../../components/Input"
import { KeyboardAvoidingView, Platform } from "react-native"
import { OnboardingStackParamList } from "../../types"
import { passwordCheckAPI } from "../../api"
import { ScreenWrapper } from "../../components/ScreenWrapper"
import { StackScreenProps } from "@react-navigation/stack"
import { useIsThinScreen } from "../../hooks/useIsThinScreen"
import { useLinkTo } from "@react-navigation/native"
import { useMutation } from "react-query"
import { validateField } from "../../utils/validate"

export function PasswordCheck({}: StackScreenProps<OnboardingStackParamList, "PasswordCheck">) {
	const [loading, setLoading] = useState(false)
	const [password, setPassword] = useState("")
	const [authError, setAuthError] = useState("")
	const [showPassword, setShowPassword] = useState(false)
	const [passwordInvalid, setPasswordInvalid] = useState(false)

	const theme = useColorScheme()
	const isThinScreen = useIsThinScreen()
	const linkTo = useLinkTo()

	const passwordCheckMutation = useMutation(() => passwordCheckAPI.post("/", { password }), {
		onMutate: () => setLoading(true),
		onSuccess: () => linkTo("/SetPin"),
		onError: (err: AxiosError) => setAuthError(I18n.t("PasswordCheck.Error")),
		onSettled: () => setLoading(false),
	})

	return (
		<ScreenWrapper>
			<KeyboardAvoidingView
				behavior={Platform.OS == "ios" ? "padding" : "height"}
				style={{ flex: 1 }}
			>
				<Headline smallText style={{ marginVertical: 20 }}>
					{I18n.t("PasswordCheck.PageHeader")}
				</Headline>
				<MetroText>{I18n.t("PasswordCheck.Body")}</MetroText>
				<Input
					label={<P3>{I18n.t("PasswordCheck.InputLabel")}</P3>}
					value={password}
					onChangeText={(value: string) => {
						if (authError) setAuthError("")
						setPassword(value)
					}}
					onBlur={() => validateField("password", password, setPasswordInvalid)}
					mask="password"
					secureTextEntry={!showPassword}
					autoCapitalize="none"
					hasError={passwordInvalid || Boolean(authError)}
					errorMessage={
						_.isEmpty(password)
							? I18n.t("Form.EmptyField", { label: I18n.t("PasswordCheck.InputLabel") })
							: authError
					}
					rightIcon={{
						type: "font-awesome-5",
						name: !showPassword ? "eye" : "eye-slash",
						color: Colors[theme]["textHighlight"],
						size: 14,
						hitSlop: { top: 20, bottom: 20, left: 20, right: 20 },
						onPress: () => setShowPassword(!showPassword),
						containerStyle: { marginLeft: isThinScreen ? 10 : 20 },
					}}
					containerStyle={{ marginBottom: 15 }}
				/>
				<TertiaryButton
					title={I18n.t("PasswordCheck.ForgotPassword")}
					onPress={() => linkTo("/ResetPassword")}
					buttonStyle={{ justifyContent: "flex-start", minWidth: "100%", paddingLeft: 0 }}
					containerStyle={{ width: "100%" }}
					titleStyle={{ textDecorationLine: "underline" }}
				/>
				<FlexView />
				<Button
					title={I18n.t("PasswordCheck.Button")}
					onPress={() => passwordCheckMutation.mutate()}
					loading={loading}
				/>
			</KeyboardAvoidingView>
		</ScreenWrapper>
	)
}
