import { HALF_HOUR } from "../constants/time"
import { InitResponse, onboardingInit } from "../api/onboarding"
import { OnboardingContext } from "../context/onboarding/OnboardingContext"
import { useContext } from "react"
import { useQuery } from "react-query"

export function useOnboardingInit(refetchInterval?: number | false | (() => number | false)) {
	const { baseUrl } = useContext(OnboardingContext)

	return useQuery<InitResponse>("onboardingInit", () => onboardingInit(), {
		staleTime: HALF_HOUR,
		enabled: Boolean(baseUrl?.includes("widget")),
		retry: 2,
		refetchInterval,
	})
}
