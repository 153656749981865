import { ONE_MINUTE, ONE_SECOND } from "../constants/time"
import { useEffect, useState } from "react"

export const useRefetchInterval = (isFetching: boolean) => {
	const refetchIntervals = [
		0,
		ONE_SECOND * 10,
		ONE_SECOND * 20,
		ONE_SECOND * 30,
		ONE_MINUTE * 2,
		ONE_MINUTE * 2,
		ONE_MINUTE * 5,
		ONE_MINUTE * 5,
	]
	const [currentInterval, setCurrentInterval] = useState(0)

	useEffect(() => {
		if (isFetching === false && currentInterval < refetchIntervals.length - 1) {
			setCurrentInterval(currentInterval + 1)
		}
	}, [isFetching])

	return refetchIntervals[currentInterval]
}
