import _ from "lodash"
import Colors from "../constants/Colors"
import I18n from "i18n-js"
import React, { useState } from "react"
import useAnalytics from "../hooks/useAnalytics"
import useColorScheme from "../hooks/useColorScheme"
import { AxiosError } from "axios"
import { Bold, ErrorText, Headline, MetroText } from "../components/StyledText"
import { CodeInput } from "../components/CodeInput"
import { Icon } from "react-native-elements"
import { Keyboard, View } from "react-native"
import { OnboardingStackParamList } from "../types"
import { otpLoginAPI } from "../api"
import { ScreenWrapper } from "../components/ScreenWrapper"
import { SET_USER_STATE } from "../context/actionTypes"
import { StackScreenProps } from "@react-navigation/stack"
import { useEffect } from "react"
import { useMutation } from "react-query"
import { useOnboardingInit } from "../hooks/useOnboardingInit"
import { useUserContext } from "../context/user/UserContext"

export default function LoginVerifyOTP({
	navigation,
	route,
}: StackScreenProps<OnboardingStackParamList, "LoginVerifyOTP">) {
	const { method } = route.params || {}
	const [code, setCode] = useState("")
	const [hasError, setHasError] = useState(false)
	const [loading, setLoading] = useState(false)
	const [resendSent, setResendSent] = useState(false)
	const [codeSendError, setCodeSendError] = useState("")
	const { login, dispatch } = useUserContext()
	const theme = useColorScheme()
	const { track } = useAnalytics()
	const { data } = useOnboardingInit()
	const { username, maskedEmail, phoneNumberLastFour } = data || {
		username: "",
		isOtpAuthEligible: false,
		maskedEmail: "",
	}

	useEffect(() => {
		if (code?.length === 6 && navigation.isFocused()) {
			checkInvitationCode()
		} else setHasError(false)
	}, [code])

	const resendCodeMutation = useMutation(() => otpLoginAPI.post(`/${method}`), {
		onMutate: () => {
			track("tap_resend_otp_login")
			setCodeSendError("")
		},
		onSuccess: () => setResendSent(true),
		onError: (err: AxiosError) => {
			setResendSent(false)
			setCodeSendError(I18n.t("LoginOTPVerify.ResendError"))
		},
	})

	const checkInvitationCode = async () => {
		setLoading(true)
		Keyboard.dismiss()
		try {
			await login(username, code)
			dispatch({
				type: SET_USER_STATE,
				payload: { usedOTPLogin: true },
			})
			setHasError(false)
			navigation.reset({ routes: [{ name: "Splash" }] })
		} catch (error) {
			setHasError(true)
		}
		setLoading(false)
	}

	return (
		<ScreenWrapper>
			<Headline
				smallText
				style={{ marginTop: 48, marginBottom: 25 }}
				testID="loginVerifyOTPHeadline"
			>
				{I18n.t("LoginVerifyOTP.PageHeader")}
			</Headline>
			<MetroText>
				{I18n.t(`LoginVerifyOTP.Body${method[0].toUpperCase() + method.substring(1)}`) + " "}
				<Bold>
					{I18n.t("LoginVerifyOTP.MaskedValue", {
						maskedValue: method === "sms" ? phoneNumberLastFour : maskedEmail,
					})}
				</Bold>
				<MetroText>
					{". "}
					{I18n.t("LoginVerifyOTP.Body2")}
				</MetroText>
			</MetroText>
			<CodeInput hasError={hasError && code.length == 6 && !loading} setValue={setCode} />
			{codeSendError ? (
				<ErrorText style={{ marginTop: 24 }}>{I18n.t("LoginVerifyOTP.ResendError")}</ErrorText>
			) : resendSent ? (
				<View
					style={{
						flexDirection: "row",
						width: "100%",
						justifyContent: "space-between",
						marginTop: 24,
						borderBottomWidth: 1,
						borderBottomColor: Colors[theme]["successGreen"],
					}}
				>
					<MetroText h4 style={{ color: Colors[theme]["successGreen"] }}>
						{I18n.t("LoginVerifyOTP.Sent")}
					</MetroText>
					<Icon
						name="checkmark-sharp"
						type="ionicon"
						size={16}
						color={Colors[theme]["successGreen"]}
					/>
				</View>
			) : (
				<MetroText
					h3
					highlight
					onPress={() => resendCodeMutation.mutate()}
					style={{ marginTop: 24 }}
					testID="loginVerifyOTPResendCodeLink"
				>
					{I18n.t("LoginVerifyOTP.ResendCode")}
				</MetroText>
			)}
		</ScreenWrapper>
	)
}
