import Colors from "../../../constants/Colors"
import I18n from "i18n-js"
import React, { useCallback, useEffect, useState } from "react"
import useColorScheme from "../../../hooks/useColorScheme"
import useDirectDepositStatus, { DirectDepositQuery } from "../../../hooks/useDirectDepositStatus"
import { DD_MESSAGE_PROCESSED } from "../../../constants/pusherChannels"
import { echo } from "../../../navigation"
import { FeatureImage } from "../../../components/FeatureImage"
import { FIVE_MINUTES } from "../../../constants/time"
import { FlexView } from "../../../components/FlexView"
import { Headline, LegalText, MetroText } from "../../../components/StyledText"
import { OnboardingProgressBar } from "../../../components/OnboardingProgressBar"
import { OnboardingStackParamList } from "../../../types"
import { ScreenWrapper } from "../../../components/ScreenWrapper"
import { StackScreenProps } from "@react-navigation/stack"
import { useAuthenticatedUserQuery } from "../../../hooks/useAuthenticatedUser"
import { useFocusEffect } from "@react-navigation/native"
import { useQueryClient } from "react-query"
import { useSendEmail } from "../../../hooks/useSendEmail"

export default function DIYLoading({ navigation }: StackScreenProps<OnboardingStackParamList>) {
	const theme = useColorScheme()
	const backgroundColor = Colors[theme]["backgroundSuccess"]
	const { sendEmail } = useSendEmail()
	const [hasOpenedEmailClient, setHasOpenedEmailClient] = useState(false)
	const { data: userData } = useAuthenticatedUserQuery()
	const { firstName, lastName, businesses, id } = userData?.userData || {}
	const currentBusiness = businesses[0] || {}
	const { data } = useDirectDepositStatus()
	const { directDepositInboxAddress = "" } = data || {}
	const queryClient = useQueryClient()

	useFocusEffect(
		useCallback(() => {
			const emailTimer = setTimeout(() => {
				navigation.reset({
					routes: [{ name: "DIYError" }],
				})
			}, FIVE_MINUTES)
			if (data?.requestType === "DIRECT_DEPOSIT_INBOX" && hasOpenedEmailClient) {
				// in case the pusher event is missed while backgrounded,
				// check requestType on focus
				handleNext()
			}
			return () => clearTimeout(emailTimer)
		}, [])
	)

	useEffect(() => {
		const channel = `employee.${id}.${currentBusiness.id}`
		echo?.private(channel)?.listen(DD_MESSAGE_PROCESSED, () => {
			// channel received an event
			handleNext()
		})
		sendEmail({
			recipients: [directDepositInboxAddress],
			subject: "Proof of direct deposit setup to Clair",
			body: `Note from Clair: Make sure to attach a photo of your updated payroll settings containing Clair or Metabank to this email
			\n
			Hi Clair Care Support,
			\n
			I’ve set up my direct deposit to send my paycheck from ${
				currentBusiness.name || "my employer"
			} to my Clair Spending Account. You can see my payroll settings reflecting this change in the attached photo.
			\n
			Best,
			\n
			${firstName} ${lastName}
			`,
		})
		setHasOpenedEmailClient(true)
		return () => {
			echo?.leaveChannel(channel)
		}
	}, [])

	const handleNext = () => {
		queryClient.setQueryData<DirectDepositQuery>("directDeposit", (oldData: any) => ({
			...oldData,
			clairCare: {
				...oldData.clairCare,
				status: "IN_PROGRESS",
				isLatest: true,
			},
			requestType: "DIRECT_DEPOSIT_INBOX",
		}))
		navigation.reset({
			routes: [{ name: "DIYPending" }],
		})
	}

	return (
		<ScreenWrapper
			center
			contentContainerStyle={{ backgroundColor }}
			nonMobileContentStyle={{ backgroundColor }}
		>
			<OnboardingProgressBar section={1} position={12} />
			<FeatureImage
				medium
				source={require("../../../assets/images/kyc_loading.gif")}
				style={{ marginTop: 24 }}
			/>
			<Headline center style={{ marginBottom: 30 }} testID="DIYLoading.PageHeader">
				{I18n.t("DIYLoading.PageHeader")}
			</Headline>
			<MetroText h4 center style={{ marginBottom: 29 }} testID="DIYLoading.Body">
				{I18n.t("DIYLoading.Body")}
			</MetroText>
			<FlexView />
			<LegalText>{I18n.t("BankingServicesFooter")}</LegalText>
		</ScreenWrapper>
	)
}
