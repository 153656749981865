import Colors from "../constants/Colors"
import I18n from "i18n-js"
import React, { createRef, FunctionComponent, useState } from "react"
import useColorScheme from "../hooks/useColorScheme"
import { applyMask, InputMaskTypeProp } from "../utils/applyMask"
import { Input as RNEInput } from "react-native-elements"
import { InputProps as RNEInputProps } from "react-native-elements"
import { Platform } from "react-native"

type InputProps = {
	hasError?: boolean
	mask?: InputMaskTypeProp
	refInput?: any
} & RNEInputProps
export const Input: FunctionComponent<InputProps> = ({
	labelStyle,
	inputStyle,
	containerStyle,
	hasError,
	errorMessage,
	errorStyle,
	errorProps,
	mask,
	onChangeText,
	refInput = createRef(),
	testID = "input",
	...rest
}) => {
	const theme = useColorScheme()
	const [hasNameError, setHasNameError] = useState(false)
	return (
		<RNEInput
			ref={refInput}
			selectionColor={Colors[theme]["text"]}
			labelStyle={[
				{
					color: Colors[theme]["textInputLabel"],
					fontFamily: "metropolis-regular",
					fontSize: 13,
					fontWeight: "normal",
				},
				labelStyle,
			]}
			inputStyle={[
				{
					color: Colors[theme]["text"],
					fontFamily: "metropolis-regular",
					fontSize: 16,
					...Platform.select({ web: { outlineStyle: "none" } }),
				},
				inputStyle,
			]}
			containerStyle={[{ paddingHorizontal: 0, marginTop: 28 }, containerStyle]}
			errorProps={{
				...errorProps,
				testID: `${testID}-error`,
			}}
			errorMessage={hasError ? errorMessage : hasNameError ? I18n.t("Form.NameError") : ""}
			errorStyle={[
				{
					marginTop: 10,
					fontFamily: "metropolis-regular",
					marginHorizontal: 0,
					color: Colors[theme]["errorRed"],
				},
				errorStyle,
			]}
			onChangeText={(text: string) => {
				if (onChangeText) {
					if (mask) {
						let maskedText = applyMask(text, mask)
						if (mask === "name" && maskedText !== text) {
							setHasNameError(true)
						} else setHasNameError(false)
						onChangeText(maskedText)
					} else onChangeText(text)
				}
			}}
			disabledInputStyle={{
				color: Colors[theme]["text"],
				opacity: 1,
			}}
			testID={testID}
			{...rest}
		/>
	)
}
