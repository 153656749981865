import I18n from "i18n-js"
import React, { useCallback, useEffect, useState } from "react"
import { Bold, Headline, LegalText, MetroText } from "../../../components/StyledText"
import { Button, SecondaryButton } from "../../../components/Button"
import { directDepositEmail } from "../../../constants/emails"
import { FeatureImageProto } from "../../../components/FeatureImageProto"
import { FlexView } from "../../../components/FlexView"
import { OnboardingProgressBar } from "../../../components/OnboardingProgressBar"
import { OnboardingStackParamList } from "../../../types"
import { ScreenWrapper } from "../../../components/ScreenWrapper"
import { StackScreenProps } from "@react-navigation/stack"
import { useDirectDepositFinalStatus } from "../../../hooks/useDirectDepositStatus"
import { useFocusEffect, useLinkTo, useNavigation } from "@react-navigation/native"
import { useRefetchInterval } from "../../../hooks/useRefetchInterval"
import { useSendEmail } from "../../../hooks/useSendEmail"

export default function DIYError({}: StackScreenProps<OnboardingStackParamList>) {
	const { sendEmail } = useSendEmail()
	const linkTo = useLinkTo()
	const navigation = useNavigation()
	const [refetchInterval, setRefetchInterval] = useState<false | number>(false)
	const { status, isFetching } = useDirectDepositFinalStatus(refetchInterval)
	const updatedInterval = useRefetchInterval(isFetching)

	useEffect(() => {
		setRefetchInterval(updatedInterval)
	}, [updatedInterval])

	useFocusEffect(
		useCallback(() => {
			if (status == "COMPLETED")
				navigation.reset({
					routes: [{ name: "DDSuccess" }],
				})
		}, [status])
	)

	return (
		<ScreenWrapper center>
			<OnboardingProgressBar section={1} position={12} />
			<FeatureImageProto source={require("../../../assets/images/email_important.png")} />
			<Headline center style={{ marginBottom: 30 }} testID="DIYError.PageHeader">
				{I18n.t("DIYError.PageHeader")}
			</Headline>
			<MetroText h4 center style={{ marginBottom: 32, fontSize: 14 }} testID="DIYError.Body">
				{I18n.t("DIYError.Body1")}{" "}
				<Bold highlight onPress={() => sendEmail({ recipients: [directDepositEmail] })}>
					{directDepositEmail}
				</Bold>{" "}
				{I18n.t("DIYError.Body2")}
			</MetroText>
			<FlexView />
			<Button
				title={I18n.t("DIYError.Button1")}
				onPress={() => {
					linkTo("/ManualSwitchHome")
				}}
				testID="DIYError.Button"
			/>
			<SecondaryButton
				title={I18n.t("DIYError.Button2")}
				onPress={() => linkTo("/Options?showManual=true")}
			/>
			<LegalText>{I18n.t("BankingServicesFooter")}</LegalText>
		</ScreenWrapper>
	)
}
