import { authAPI } from "../api"
import { getAuthenticatedUser } from "../api/getAuthenticatedUser"
import { HALF_HOUR, ONE_SECOND } from "./../constants/time"
import { QueryParamContext } from "../context/queryParam/QueryParamContext"
import { useContext } from "react"
import { useQuery } from "react-query"
import { useUserContext } from "./../context/user/UserContext"

export function useAuthenticatedUserQuery() {
	const { accessToken } = useUserContext()
	const { businessId, employeeId, src, widgetId } = useContext(QueryParamContext)

	return useQuery<any>("authUser", () => getAuthenticatedUser(), {
		enabled:
			Boolean(accessToken && businessId && employeeId && src && widgetId) &&
			Boolean(authAPI.defaults.headers["Authorization"]),
		staleTime: HALF_HOUR,
		retry: 2,
		retryDelay: ONE_SECOND / 10,
	})
}
