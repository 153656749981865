import _ from "lodash"
import Colors from "../../../constants/Colors"
import I18n from "i18n-js"
import React, { useContext } from "react"
import useColorScheme from "../../../hooks/useColorScheme"
import { Headline, LegalText, MetroText } from "../../../components/StyledText"
import { ImageBullet } from "../../../components/ImageBullet"
import { OnboardingContext } from "../../../context/onboarding/OnboardingContext"
import { OnboardingProgressBar } from "../../../components/OnboardingProgressBar"
import { OnboardingStackParamList } from "../../../types"
import { patchState } from "../../../api/onboarding"
import { ScreenWrapper } from "../../../components/ScreenWrapper"
import { SET_ONBOARDING_STATE } from "../../../context/actionTypes"
import { StackScreenProps } from "@react-navigation/stack"
import { StyleSheet, View } from "react-native"
import { TouchableFrame } from "../../../components/TouchableFrame"
import { useSignupNavigation } from "../../../hooks/useSignupNavigation"

export default function MailingAddressOneScreen({}: StackScreenProps<
	OnboardingStackParamList,
	"MailingAddressOne"
>) {
	const theme = useColorScheme()
	const { navigateToNextSignupScreen } = useSignupNavigation()

	const {
		addressLine1,
		addressLine2,
		city,
		state,
		postalCode,
		shippingAddressLine1,
		shippingAddressLine2,
		shippingCity,
		shippingState,
		shippingPostalCode,
		dispatch,
	} = useContext(OnboardingContext)

	const continueWithPermanentAddress = () => {
		const address = {
			shippingAddressLine1: addressLine1,
			shippingAddressLine2: addressLine2,
			shippingCity: city,
			shippingState: state,
			shippingPostalCode: postalCode,
		}
		dispatch({
			type: SET_ONBOARDING_STATE,
			payload: address,
		})
		patchState(address)
		navigateToNextSignupScreen(true)
	}

	const onSetDifferentMailing = () => {
		const permAddress = {
			addressLine1,
			addressLine2,
			city,
			state,
			postalCode,
		}

		const shippingAddress = {
			shippingAddressLine1,
			shippingAddressLine2,
			shippingCity,
			shippingState,
			shippingPostalCode,
		}

		if (_.isEqual(Object.values(permAddress), Object.values(shippingAddress))) {
			const address = {
				shippingAddressLine1: null,
				shippingAddressLine2: null,
				shippingCity: null,
				shippingState: null,
				shippingPostalCode: null,
			}
			dispatch({
				type: SET_ONBOARDING_STATE,
				payload: address,
			})
			patchState(address)
		}
		navigateToNextSignupScreen()
	}

	return (
		<ScreenWrapper>
			<OnboardingProgressBar position={7} />
			<Headline smallText testID="mailingAddressOneHeadline">
				{I18n.t("MailingAddressOne.PageHeader")}
			</Headline>
			<ImageBullet
				body
				text={I18n.t("MailingAddressOne.BulletText")}
				image={require("../../../assets/images/home.png")}
				imageSize={24}
				style={{ marginBottom: 0 }}
			/>
			<TouchableFrame
				onPress={continueWithPermanentAddress}
				style={{ marginVertical: 32, alignSelf: "stretch" }}
				testID="mailingAddressOneSame"
			>
				<MetroText h4 style={{ lineHeight: 24 }}>
					{I18n.t("MailingAddressOne.UsePermanent")}
				</MetroText>
				<MetroText
					style={[
						styles.AddressText,
						{
							color: Colors[theme]["textTouchableFrameSecondary"],
						},
					]}
				>
					{addressLine1},{" "}
					{addressLine2 && (
						<MetroText
							style={[
								styles.AddressText,
								{
									color: Colors[theme]["textTouchableFrameSecondary"],
								},
							]}
						>
							{addressLine2},{" "}
						</MetroText>
					)}
					{city}, {state} {postalCode}
				</MetroText>
			</TouchableFrame>
			<TouchableFrame
				onPress={onSetDifferentMailing}
				style={{ alignSelf: "stretch", minHeight: 72 }}
				testID="mailingAddressOneDifferent"
			>
				<MetroText h4 highlight style={{ lineHeight: 24 }}>
					{I18n.t("MailingAddressOne.EnterMailingAddress")}
				</MetroText>
			</TouchableFrame>
			<View style={{ flex: 1 }} />
			<LegalText>{I18n.t("BankingServicesFooter")}</LegalText>
		</ScreenWrapper>
	)
}

const styles = StyleSheet.create({
	AddressText: {
		fontSize: 12,
		lineHeight: 12,
	},
})
