import _ from "lodash"
import AccountInfoScreen from "../screens/Onboarding/AccountInfo"
import AddToWalletScreen from "../screens/Onboarding/AddToWallet"
import AlreadyStartedScreen from "../screens/Onboarding/SignUp/AlreadyStarted"
import AreYouSure from "../screens/AreYouSure"
import AtomicWebView from "../screens/Onboarding/DirectDeposit/AtomicWebView"
import AtomicWelcome from "../screens/Onboarding/DirectDeposit/AtomicWelcome"
import CalculatorAmount from "../screens/Onboarding/DirectDeposit/CalculatorAmount"
import CalculatorEntry from "../screens/Onboarding/DirectDeposit/CalculatorEntry"
import CalculatorPercentage from "../screens/Onboarding/DirectDeposit/CalculatorPercentage"
import CardActivationEntry from "../screens/Onboarding/CardActivationEntry"
import Colors from "../constants/Colors"
import DDFormPreview from "../screens/Onboarding/DirectDeposit/DDFormPreview"
import DDSuccessScreen from "../screens/Onboarding/DirectDeposit/DDSuccess"
import DirectDepositEntry from "../screens/Onboarding/DirectDeposit/DirectDepositEntry"
import DirectDepositEntryB from "../screens/Onboarding/DirectDeposit/DirectDepositEntryB"
import DIYError from "../screens/Onboarding/DirectDeposit/DIYError"
import DIYLoading from "../screens/Onboarding/DirectDeposit/DIYLoading"
import DIYPending from "../screens/Onboarding/DirectDeposit/DIYPending"
import EmailScreen from "../screens/Onboarding/SignUp/Email"
import EmploymentInfoScreen from "../screens/Onboarding/SignUp/EmploymentInfo"
import ExitSurvey from "../screens/ExitSurvey"
import FormActionRequired from "../screens/Onboarding/DirectDeposit/FormActionRequired"
import FormPending from "../screens/Onboarding/DirectDeposit/FormPending"
import GeneralError from "../screens/GeneralError"
import KYCErrorScreen from "../screens/Onboarding/KYCError"
import KYCFailScreen from "../screens/Onboarding/KYCFail"
import KYCLoading from "../screens/Onboarding/KYCLoading"
import KYCPassScreen from "../screens/Onboarding/KYCPass"
import KYCReferScreen from "../screens/Onboarding/KYCRefer"
import LoginVerifyOTP from "../screens/LoginVerifyOTP"
import MailingAddressOneScreen from "../screens/Onboarding/SignUp/MailingAddressOne"
import MailingAddressTwoScreen from "../screens/Onboarding/SignUp/MailingAddressTwo"
import MaintenanceMode from "../screens/MaintenanceMode"
import ManualSwitchConfirm from "../screens/Onboarding/DirectDeposit/ManualSwitchConfirm"
import ManualSwitchHome from "../screens/Onboarding/DirectDeposit/ManualSwitchHome"
import MobileNumberScreen from "../screens/Onboarding/SignUp/MobileNumber"
import OnboardingCompleteScreen from "../screens/Onboarding/OnboardingComplete"
import OnboardingCompleteVariant from "../screens/Onboarding/OnboardingCompleteVariant"
import Options from "../screens/Onboarding/DirectDeposit/Options"
import PasswordSetScreen from "../screens/Onboarding/SignUp/PasswordSet"
import PaydayWalletDisabledAfterKYCPass from "../screens/Onboarding/PaydayWalletDisabledAfterKYCPass"
import PaydayWalletDisabledBeforeKYCPass from "../screens/Onboarding/PaydayWalletDisabledBeforeKYCPass"
import PDWActionRequired from "../screens/Onboarding/PDWActionRequired"
import PDWGeneralError from "../screens/Onboarding/PDWGeneralError"
import PendingTextScreen from "../screens/Onboarding/DirectDeposit/PendingText"
import PermanentAddressScreen from "../screens/Onboarding/SignUp/PermanentAddress"
import PersonalInfoScreen from "../screens/Onboarding/SignUp/PersonalInfo"
import PinSettingErrorScreen from "../screens/Onboarding/PinSettingError"
import React, { useState } from "react"
import ScreenshotEducation from "../screens/Onboarding/DirectDeposit/ScreenshotEducation"
import SendMyself from "../screens/Onboarding/DirectDeposit/SendMyself"
import SendWithClair from "../screens/Onboarding/DirectDeposit/SendWithClair"
import SetPinScreen from "../screens/Onboarding/SetPin"
import SSNScreen from "../screens/Onboarding/SignUp/SSN"
import TextMeALinkScreen from "../screens/Onboarding/TextMeALink"
import TryAgainScreen from "../screens/Onboarding/DirectDeposit/TryAgain"
import TryAnotherWayScreen from "../screens/Onboarding/DirectDeposit/TryAnotherWay"
import useAnalytics from "../hooks/useAnalytics"
import useColorScheme from "../hooks/useColorScheme"
import useDefaultHeaderStyle from "../hooks/useDefaultHeaderStyle"
import VerifyFormInfo from "../screens/Onboarding/DirectDeposit/VerifyFormInfo"
import VerifyNumberScreen from "../screens/Onboarding/SignUp/VerifyNumber"
import WageAdvance from "../screens/Onboarding/WageAdvance/WageAdvance"
import WelcomeCarousel from "../screens/Onboarding/WelcomeCarousel/WelcomeCarousel"
import { AutoHeightImage } from "../components/AutoHeightImage"
import { createStackNavigator } from "@react-navigation/stack"
import { DocumentViewer } from "../screens/Onboarding/DocumentViewer"
import { EWASuccess } from "../screens/Onboarding/EWASuccess"
import { HeaderRight, HeaderRightProps } from "../components/HeaderRight"
import { HelpOverlay } from "../components/HelpOverlay"
import { Image, Pressable } from "react-native"
import { ISplitTreatmentsChildProps } from "@splitsoftware/splitio-react/types/types"
import { Login } from "../screens/Login"
import { NavigationContainerRefWithCurrent } from "@react-navigation/native"
import { OnboardingStackParamList } from "../types"
import { P3 } from "../components/StyledText"
import { PasswordCheck } from "../screens/Onboarding/PasswordCheck"
import { ResetPassword } from "../screens/ResetPassword"
import { SHOW_EXPERIMENTAL_WELCOME_SCREEN } from "../constants/featureFlags"
import { Splash } from "../screens/Splash"
import { useBroadcastEvent } from "../hooks/useBroadcastEvent"
import { useIsNonMobileScreen } from "../hooks/useIsNonMobileScreen"
import { useLogout } from "../hooks/useLogout"
import { withSplitTreatments } from "@splitsoftware/splitio-react"
import VideoCarouselTabs, {
	VideoCarouselHeaderOptions,
} from "../screens/Onboarding/VideoCarousel/tabNavigator"

type OnboardingNavigatorProps = {
	navigationRef: NavigationContainerRefWithCurrent<OnboardingStackParamList> | null
} & ISplitTreatmentsChildProps

const Onboarding = createStackNavigator<OnboardingStackParamList>()
const OnboardingNavigator = withSplitTreatments([SHOW_EXPERIMENTAL_WELCOME_SCREEN])(
	({ treatments, isReady, navigationRef }: OnboardingNavigatorProps) => {
		const theme = useColorScheme()
		const isNonMobileScreen = useIsNonMobileScreen()
		const { defaultHeaderStyle, stageTitle } = useDefaultHeaderStyle()
		const logout = useLogout()
		const { track } = useAnalytics()
		const welcomeScreenTreatment = treatments[SHOW_EXPERIMENTAL_WELCOME_SCREEN].treatment
		const broadcastEvent = useBroadcastEvent()
		const [helpOverlayVisible, setHelpOverlayVisible] = useState(false)
		let headerStyle = defaultHeaderStyle
		if (isNonMobileScreen) headerStyle = { ...headerStyle, height: 70 }
		const showVideoCarousel = welcomeScreenTreatment === "video_carousel"

		const OnboardingHeaderRight = (props: Partial<HeaderRightProps>) => {
			if (!navigationRef?.isReady()) return null
			const routeName = navigationRef?.getCurrentRoute()?.name as keyof OnboardingStackParamList
			return (
				<HeaderRight
					routeName={routeName}
					setShowOverlay={(shouldShow: boolean) => {
						track("tap_help", { on: routeName })
						setHelpOverlayVisible(shouldShow)
					}}
					{...props}
				/>
			)
		}

		return (
			<>
				<HelpOverlay
					navigationRef={navigationRef}
					isVisible={helpOverlayVisible}
					setShowOverlay={() => setHelpOverlayVisible(false)}
				/>
				<Onboarding.Navigator
					screenOptions={{
						headerBackTitleVisible: false,
						headerLeftContainerStyle: { marginLeft: 24 },
						headerTitle: () => <P3 style={{ color: Colors[theme]["text"] }}>{stageTitle}</P3>,
						headerTitleAlign: "center",
						headerRight: () => <OnboardingHeaderRight />,
						headerLeft: ({ canGoBack }) => {
							if (canGoBack)
								return (
									<Pressable onPress={() => navigationRef.current.goBack()}>
										<Image
											source={require("../assets/icons/back_button.png")}
											style={{ width: 17, height: 14, marginLeft: 25 }}
										/>
									</Pressable>
								)
						},
						headerTintColor: "#fff",
						headerRightContainerStyle: {
							marginRight: isNonMobileScreen ? 25 : 5,
							width: "30%",
						},
						headerStyle,
					}}
				>
					<Onboarding.Screen name="Splash" component={Splash} options={{ headerShown: false }} />
					<Onboarding.Screen
						name="WelcomeCarousel"
						options={
							showVideoCarousel && !isNonMobileScreen
								? {
										headerRight: () => <OnboardingHeaderRight textColor="white" />,
										...VideoCarouselHeaderOptions,
								  }
								: {
										headerTitle: () => (
											<AutoHeightImage source={require("../assets/images/logo.png")} width={60} />
										),
								  }
						}
						component={isReady ? (showVideoCarousel ? VideoCarouselTabs : WelcomeCarousel) : Splash}
						initialParams={{ treatment: treatments[SHOW_EXPERIMENTAL_WELCOME_SCREEN].treatment }}
					/>
					<Onboarding.Screen name="MobileNumber" component={MobileNumberScreen} />
					<Onboarding.Screen name="VerifyNumber" component={VerifyNumberScreen} />
					<Onboarding.Screen name="Email" component={EmailScreen} />
					<Onboarding.Screen name="PasswordSet" component={PasswordSetScreen} />
					<Onboarding.Screen name="AlreadyStarted" component={AlreadyStartedScreen} />
					<Onboarding.Screen name="PersonalInfo" component={PersonalInfoScreen} />
					<Onboarding.Screen name="PermanentAddress" component={PermanentAddressScreen} />
					<Onboarding.Screen name="MailingAddressOne" component={MailingAddressOneScreen} />
					<Onboarding.Screen name="MailingAddressTwo" component={MailingAddressTwoScreen} />
					<Onboarding.Screen name="EmploymentInfo" component={EmploymentInfoScreen} />
					<Onboarding.Screen name="SSN" component={SSNScreen} />
					<Onboarding.Screen
						name="KYCLoading"
						component={KYCLoading}
						options={{
							headerTitle: () => <P3 style={{ color: Colors[theme]["text"] }}>Account creation</P3>,
						}}
					/>
					<Onboarding.Screen
						name="KYCPass"
						component={KYCPassScreen}
						options={({ navigation }) => ({
							headerTitle: () => <P3 style={{ color: Colors[theme]["text"] }}>Account creation</P3>,
							headerRight: () => (
								<OnboardingHeaderRight onPress={() => navigation.navigate("AreYouSure")} />
							),
						})}
					/>
					<Onboarding.Screen
						name="TextMeALink"
						component={TextMeALinkScreen}
						options={({ navigation }) => ({
							headerRight: () => (
								<OnboardingHeaderRight
									onPress={() => {
										broadcastEvent({
											event: "clair-onboarding-complete",
											payload: { status: "Pass" },
										})
										logout(navigation)
									}}
								/>
							),
						})}
					/>
					<Onboarding.Screen
						name="KYCError"
						component={KYCErrorScreen}
						options={{
							headerRight: () => (
								<OnboardingHeaderRight
									onPress={() =>
										broadcastEvent({
											event: "clair-onboarding-complete",
											payload: { status: "Pending" },
										})
									}
								/>
							),
						}}
					/>
					<Onboarding.Screen
						name="KYCFail"
						component={KYCFailScreen}
						options={{
							headerRight: () => (
								<OnboardingHeaderRight
									onPress={() =>
										broadcastEvent({
											event: "clair-onboarding-complete",
											payload: { status: "Fail" },
										})
									}
								/>
							),
						}}
					/>
					<Onboarding.Screen
						name="KYCRefer"
						component={KYCReferScreen}
						options={{
							headerRight: () => (
								<OnboardingHeaderRight
									onPress={() =>
										broadcastEvent({
											event: "clair-onboarding-complete",
											payload: { status: "Refer" },
										})
									}
								/>
							),
						}}
					/>
					<Onboarding.Screen
						name="AreYouSure"
						component={AreYouSure}
						options={{
							headerStyle: {
								...defaultHeaderStyle,
								backgroundColor: Colors[theme]["backgroundSuccess"],
							},
							headerLeft: () => null,
							headerRight: () => null,
						}}
					/>
					<Onboarding.Screen
						name="ExitSurvey"
						component={ExitSurvey}
						options={{
							headerLeft: () => null,
						}}
					/>
					<Onboarding.Screen
						name="Login"
						component={Login}
						options={{
							headerLeft: () => null,
							headerTitle: "",
							headerRight: () => (
								<OnboardingHeaderRight
									onPress={() => {
										track("exit_widget")
										broadcastEvent({
											event: "clair-onboarding-close",
											payload: { reason: "unknown" },
										})
									}}
								/>
							),
						}}
					/>
					<Onboarding.Screen
						name="LoginVerifyOTP"
						component={LoginVerifyOTP}
						options={{
							headerTitle: "",
							headerRight: () => (
								<OnboardingHeaderRight
									onPress={() => {
										track("exit_widget")
										broadcastEvent({
											event: "clair-onboarding-close",
											payload: { reason: "login verify OTP screen exit" },
										})
									}}
								/>
							),
						}}
					/>
					<Onboarding.Screen
						name="ResetPassword"
						component={ResetPassword}
						options={{
							headerTitle: "",
							headerRight: () => (
								<OnboardingHeaderRight
									onPress={() => {
										track("exit_widget")
										broadcastEvent({
											event: "clair-onboarding-close",
											payload: { reason: "reset password screen exit" },
										})
									}}
								/>
							),
						}}
					/>
					<Onboarding.Screen
						name="GeneralError"
						component={GeneralError}
						options={({ navigation }) => ({
							headerRight: () => (
								<OnboardingHeaderRight
									onPress={() => {
										broadcastEvent({
											event: "clair-onboarding-close",
											payload: { reason: "error" },
										})
										logout(navigation)
									}}
								/>
							),
							headerLeft: () => null,
						})}
					/>
					<Onboarding.Screen
						name="MaintenanceMode"
						component={MaintenanceMode}
						options={() => ({
							headerTitle: "",
							headerRight: () => (
								<OnboardingHeaderRight
									onPress={() =>
										broadcastEvent({
											event: "clair-onboarding-close",
											payload: { reason: "error" },
										})
									}
								/>
							),
							headerLeft: () => null,
						})}
					/>
					<Onboarding.Screen name="DirectDepositEntry" component={DirectDepositEntry} />
					<Onboarding.Screen name="DirectDepositEntryB" component={DirectDepositEntryB} />
					<Onboarding.Screen name="AtomicWelcome" component={AtomicWelcome} />
					<Onboarding.Screen
						name="PaydayWalletDisabledAfterKYCPass"
						component={PaydayWalletDisabledAfterKYCPass}
						options={{
							headerRight: () => null,
							headerLeft: () => null,
						}}
					/>
					<Onboarding.Screen
						name="PaydayWalletDisabledBeforeKYCPass"
						component={PaydayWalletDisabledBeforeKYCPass}
						options={{
							headerRight: () => (
								<OnboardingHeaderRight
									onPress={() =>
										broadcastEvent({
											event: "clair-onboarding-close",
											payload: { reason: "Payday wallet disabled - pre kyc pass" },
										})
									}
								/>
							),
						}}
					/>
					<Onboarding.Screen name="WageAdvance" component={WageAdvance} />
					<Onboarding.Screen name="PDWActionRequired" component={PDWActionRequired} />
					<Onboarding.Screen
						name="EWASuccess"
						component={EWASuccess}
						options={{
							headerTitle: () => <P3 style={{ color: Colors[theme]["text"] }}>On-demand pay</P3>,
						}}
					/>
					<Onboarding.Screen name="PDWGeneralError" component={PDWGeneralError} />
					<Onboarding.Screen
						name="AtomicWebView"
						component={AtomicWebView}
						options={{
							headerShown: false,
							headerLeft: () => null,
						}}
					/>
					<Onboarding.Screen name="PendingText" component={PendingTextScreen} />
					<Onboarding.Screen name="CardActivationEntry" component={CardActivationEntry} />
					<Onboarding.Screen name="PasswordCheck" component={PasswordCheck} />
					<Onboarding.Screen name="TryAgain" component={TryAgainScreen} />
					<Onboarding.Screen name="TryAnotherWay" component={TryAnotherWayScreen} />
					<Onboarding.Screen name="DDSuccess" component={DDSuccessScreen} />
					<Onboarding.Screen name="DDFormPreview" component={DDFormPreview} />
					<Onboarding.Screen name="SendWithClair" component={SendWithClair} />
					<Onboarding.Screen name="SendMyself" component={SendMyself} />
					<Onboarding.Screen name="ManualSwitchHome" component={ManualSwitchHome} />
					<Onboarding.Screen name="ScreenshotEducation" component={ScreenshotEducation} />
					<Onboarding.Screen name="ManualSwitchConfirm" component={ManualSwitchConfirm} />
					<Onboarding.Screen name="DIYLoading" component={DIYLoading} />
					<Onboarding.Screen name="DIYPending" component={DIYPending} />
					<Onboarding.Screen name="DIYError" component={DIYError} />
					<Onboarding.Screen name="CalculatorEntry" component={CalculatorEntry} />
					<Onboarding.Screen name="CalculatorAmount" component={CalculatorAmount} />
					<Onboarding.Screen name="CalculatorPercentage" component={CalculatorPercentage} />
					<Onboarding.Screen name="VerifyFormInfo" component={VerifyFormInfo} />
					<Onboarding.Screen name="FormActionRequired" component={FormActionRequired} />
					<Onboarding.Screen name="FormPending" component={FormPending} />
					<Onboarding.Screen name="Options" component={Options} />
					<Onboarding.Screen
						name="OnboardingCompleteVariant"
						component={OnboardingCompleteVariant}
						options={{ headerTitle: "" }}
					/>
					<Onboarding.Screen
						name="OnboardingComplete"
						component={OnboardingCompleteScreen}
						options={({ navigation }) => ({
							headerTitle: "",
							headerLeft: () => null,
							headerRight: () => <OnboardingHeaderRight onPress={() => logout(navigation)} />,
						})}
					/>
					<Onboarding.Screen
						name="AddToWallet"
						component={AddToWalletScreen}
						options={{
							headerTitle: () => <P3 style={{ color: Colors[theme]["text"] }}>Card activation</P3>,
						}}
					/>
					<Onboarding.Screen name="PinSettingError" component={PinSettingErrorScreen} />
					<Onboarding.Screen
						name="AccountInfo"
						component={AccountInfoScreen}
						options={({ navigation }) => ({
							headerRight: () => <OnboardingHeaderRight onPress={() => logout(navigation)} />,
						})}
					/>
					<Onboarding.Screen name="SetPin" component={SetPinScreen} />
					<Onboarding.Screen
						name="DocumentViewer"
						component={DocumentViewer}
						options={{
							headerTitle: "",
						}}
					/>
				</Onboarding.Navigator>
			</>
		)
	}
)

export default OnboardingNavigator
