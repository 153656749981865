import I18n from "i18n-js"
import React, { FunctionComponent, useState } from "react"
import useSpendingAccount from "../hooks/useSpendingAccount"
import { SingleItemDataCard } from "./SingleItemDataCard"
import { View, ViewProps } from "react-native"

type AccountInfoCardProps = {
	onAccountCopy?: () => {}
	onRoutingCopy?: () => {}
} & ViewProps

export const AccountInfoCard: FunctionComponent<AccountInfoCardProps> = ({
	onAccountCopy = () => {},
	onRoutingCopy = () => {},
	...rest
}) => {
	const [visible, setVisible] = useState(false)
	const { data } = useSpendingAccount()
	const { accountNumber = "", routingNumber = "" } = data || {}
	const concealBankingDetailsRegex = /.(?=.{5,}$)/g
	const accountNo = visible
		? accountNumber
		: accountNumber?.toString().replace(concealBankingDetailsRegex, "•")

	const routingNo = visible
		? routingNumber
		: routingNumber?.toString().replace(concealBankingDetailsRegex, "•")

	return (
		<View {...rest}>
			<SingleItemDataCard
				title={I18n.t("AccountInfoCard.AccountNumber")}
				value={accountNo}
				secretValue={accountNumber}
				setVisibility={() => setVisible(!visible)}
				visible={visible}
				onCopy={onAccountCopy}
			/>
			<SingleItemDataCard
				title={I18n.t("AccountInfoCard.RoutingNumber")}
				value={routingNo}
				secretValue={routingNumber}
				setVisibility={() => setVisible(!visible)}
				visible={visible}
				onCopy={onRoutingCopy}
			/>
		</View>
	)
}
