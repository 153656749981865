import axios from "axios"

export const getAddressByPostalCode = async (code: string) => {
	const API_URL = `https://api.zippopotam.us/us/${code}`
	try {
		const { data } = await axios.get(API_URL)
		const { places } = data
		const transformed = {
			city: places[0]["place name"],
			state: places[0]["state abbreviation"],
		}
		return transformed
	} catch (_err) {
		return false
	}
}
