import _ from "lodash"
import Colors from "../constants/Colors"
import React, { FunctionComponent, useState } from "react"
import useColorScheme from "../hooks/useColorScheme"
import { Bold } from "./StyledText"
import { Icon } from "react-native-elements"
import { Platform, StyleSheet, TextInput, TextInputKeyPressEventData, View } from "react-native"
import { useEffect } from "react"
import { useIsThinScreen } from "../hooks/useIsThinScreen"
import { useRef } from "react"

const DashSeparator = () => (
	<View style={styles.LineSeparator}>
		<Bold h3 style={{ paddingTop: 5 }}>
			-
		</Bold>
	</View>
)

type SSNInputValue = "0" | "1" | "2" | "3" | "4" | "5" | "6" | "7" | "8" | "9" | ""
type SSNInputProps = {
	onEndReached?: () => void
	focused?: boolean
	hasError?: boolean
	iconTestID?: string
	inputName?: string
	setValue(value: string): void
}
export const SSNInput: FunctionComponent<SSNInputProps> = ({
	onEndReached = () => {},
	focused,
	hasError,
	setValue,
	iconTestID,
	inputName,
}) => {
	const theme = useColorScheme()
	const isThinScreen = useIsThinScreen()
	const backgroundColor = Colors[theme]["backgroundOTPInput"]
	const borderColor = Colors[theme][hasError ? "textError" : "borderCodeInput"]
	const [valuesArray, setValuesArray] = useState(["", "", "", "", "", "", "", "", ""])
	const [lastChangedIndex, setLastChangedIndex] = useState(0)
	const allowedValues = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"]
	const input1 = useRef()
	const input2 = useRef()
	const input3 = useRef()
	const input4 = useRef()
	const input5 = useRef()
	const input6 = useRef()
	const input7 = useRef()
	const input8 = useRef()
	const input9 = useRef()
	const [isConcealed, setIsConcealed] = useState(true)
	const CombinedSSNInputStyle = [
		styles.SSNInputBase,
		{ backgroundColor, borderColor, outlineColor: borderColor },
		isThinScreen ? styles.SSNInputSmall : styles.SSNInputLarge,
	]

	const refMap = {
		0: input1,
		1: input2,
		2: input3,
		3: input4,
		4: input5,
		5: input6,
		6: input7,
		7: input8,
		8: input9,
	}

	useEffect(() => {
		if (focused) {
			input1.current.focus()
		}
	}, [focused])

	const onChangeText = (newValue = "", index = 0) => {
		const newValuesArray = _.clone(valuesArray)
		if (_.isEmpty(newValue)) {
			newValuesArray[index] = ""
		} else if (allowedValues.includes(newValue[newValue.length - 1])) {
			newValuesArray[index] = newValue[newValue.length - 1] as SSNInputValue
		}
		if (newValuesArray[index] && newValuesArray[index] !== valuesArray[index]) {
			if (index === 8) {
				onEndReached()
			} else {
				refMap[index + 1].current.focus()
			}
		}
		setValuesArray(newValuesArray)
		setLastChangedIndex(index)
		setValue(newValuesArray.join(""))
	}

	const onKeyPress = (nativeEvent: TextInputKeyPressEventData, index = 1) => {
		const { key } = nativeEvent
		if (key === "Backspace") {
			nativeEvent.preventDefault()
			if (valuesArray[index] == "") {
				refMap[index - 1]?.current.focus()
			} else {
				const newValuesArray = _.clone(valuesArray)
				newValuesArray[index] = ""
				setValuesArray(newValuesArray)
				setValue(newValuesArray.join(""))
			}
		}
	}

	return (
		<View style={{ flexDirection: "row", marginTop: 20, alignItems: "center" }}>
			<TextInput
				ref={input1}
				autoFocus={true}
				style={CombinedSSNInputStyle}
				value={isConcealed && lastChangedIndex !== 0 && valuesArray[0] ? "•" : valuesArray[0]}
				caretHidden={true}
				onChangeText={(newValue: string) => onChangeText(newValue, 0)}
				onKeyPress={({ nativeEvent }) => onKeyPress(nativeEvent, 0)}
				keyboardType="number-pad"
				testID={`${inputName}Input1`}
			/>
			<TextInput
				ref={input2}
				style={CombinedSSNInputStyle}
				value={isConcealed && lastChangedIndex !== 1 && valuesArray[1] ? "•" : valuesArray[1]}
				caretHidden={true}
				onChangeText={(newValue: string) => onChangeText(newValue, 1)}
				onKeyPress={({ nativeEvent }) => onKeyPress(nativeEvent, 1)}
				keyboardType="number-pad"
				testID={`${inputName}Input2`}
			/>
			<TextInput
				ref={input3}
				style={CombinedSSNInputStyle}
				value={isConcealed && lastChangedIndex !== 2 && valuesArray[2] ? "•" : valuesArray[2]}
				caretHidden={true}
				onChangeText={(newValue: string) => onChangeText(newValue, 2)}
				onKeyPress={({ nativeEvent }) => onKeyPress(nativeEvent, 2)}
				keyboardType="number-pad"
				testID={`${inputName}Input3`}
			/>
			<DashSeparator />
			<TextInput
				ref={input4}
				style={CombinedSSNInputStyle}
				value={isConcealed && lastChangedIndex !== 3 && valuesArray[3] ? "•" : valuesArray[3]}
				caretHidden={true}
				onChangeText={(newValue: string) => onChangeText(newValue, 3)}
				onKeyPress={({ nativeEvent }) => onKeyPress(nativeEvent, 3)}
				keyboardType="number-pad"
				testID={`${inputName}Input4`}
			/>
			<TextInput
				ref={input5}
				style={CombinedSSNInputStyle}
				value={isConcealed && lastChangedIndex !== 4 && valuesArray[4] ? "•" : valuesArray[4]}
				caretHidden={true}
				onChangeText={(newValue: string) => onChangeText(newValue, 4)}
				onKeyPress={({ nativeEvent }) => onKeyPress(nativeEvent, 4)}
				keyboardType="number-pad"
				testID={`${inputName}Input5`}
			/>
			<DashSeparator />
			<TextInput
				ref={input6}
				style={CombinedSSNInputStyle}
				value={valuesArray[5]}
				caretHidden={true}
				onChangeText={(newValue: string) => onChangeText(newValue, 5)}
				onKeyPress={({ nativeEvent }) => onKeyPress(nativeEvent, 5)}
				keyboardType="number-pad"
				testID={`${inputName}Input6`}
			/>
			<TextInput
				ref={input7}
				style={CombinedSSNInputStyle}
				value={valuesArray[6]}
				caretHidden={true}
				onChangeText={(newValue: string) => onChangeText(newValue, 6)}
				onKeyPress={({ nativeEvent }) => onKeyPress(nativeEvent, 6)}
				keyboardType="number-pad"
				testID={`${inputName}Input7`}
			/>
			<TextInput
				ref={input8}
				style={CombinedSSNInputStyle}
				value={valuesArray[7]}
				caretHidden={true}
				onChangeText={(newValue: string) => onChangeText(newValue, 7)}
				onKeyPress={({ nativeEvent }) => onKeyPress(nativeEvent, 7)}
				keyboardType="number-pad"
				testID={`${inputName}Input8`}
			/>
			<TextInput
				ref={input9}
				style={CombinedSSNInputStyle}
				value={valuesArray[8]}
				caretHidden={true}
				onChangeText={(newValue: string) => onChangeText(newValue, 8)}
				onKeyPress={({ nativeEvent }) => onKeyPress(nativeEvent, 8)}
				keyboardType="number-pad"
				testID={`${inputName}Input9`}
			/>
			<Icon
				name={isConcealed ? "eye" : "eye-slash"}
				type="font-awesome-5"
				color={Colors[theme]["textHighlight"]}
				size={16}
				hitSlop={{ top: 20, bottom: 20, left: 20, right: 20 }}
				onPress={() => setIsConcealed(!isConcealed)}
				containerStyle={{ marginLeft: isThinScreen ? 10 : 20 }}
				testID={iconTestID}
			/>
		</View>
	)
}

const styles = StyleSheet.create({
	SSNInputBase: {
		borderWidth: 0.66,
		borderRadius: 2,
		textAlign: "center",
		fontFamily: "metropolis-bold",
		...Platform.select({ web: { outlineStyle: "none" } }),
	},
	SSNInputSmall: {
		width: 19,
		height: 25,
		marginRight: 2,
		fontSize: 14,
	},
	SSNInputLarge: {
		width: 26,
		height: 34,
		marginRight: 4,
		fontSize: 17,
	},
	LineSeparator: {
		width: 9,
		marginRight: 4,
		height: 34,
		alignItems: "center",
	},
})
