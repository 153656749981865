import React, { FunctionComponent, useReducer } from "react"
import variables from "../../utils/variables"
import { GrantType } from "../../api/config"
import { loginAPI } from "../../api"
import { SET_USER_STATE } from "../actionTypes"
import { useAsyncStateEffect } from "../useAsyncStateEffect"
import { UserContext } from "./UserContext"
import { userReducer, UserState } from "./userReducer"

export const INITIAL_USER_STATE: UserState = {
	accessToken: null,
	email: "",
	firstName: "",
	lastName: "",
	stage: "",
	rememberUser: false,
	seenWageAdvanceTour: false,
	showWageAdvanceTour: true,
	distributionType: "PERCENTAGE",
	distributionValue: "100",
	usedOTPLogin: false,
}
interface UserProviderProps extends FunctionComponent {
	asyncState: object
}
export const UserProvider: FunctionComponent<UserProviderProps> = ({ children, asyncState }) => {
	const [state, dispatch] = useReducer(userReducer, INITIAL_USER_STATE)

	useAsyncStateEffect(dispatch, { type: SET_USER_STATE, payload: asyncState })

	/* tslint:disable:no-unused-variable */
	async function login(emailOrUserName: string, password: string) {
		try {
			const postData = {
				grant_type: GrantType.password,
				client_id: variables.clientId,
				client_secret: variables.clientSecret,
				username: emailOrUserName,
				password,
			}
			const formData = new FormData()
			for (let [key, value] of Object.entries(postData)) {
				formData.append(key, value)
			}

			const response = await loginAPI.post("", formData)
			dispatch({
				type: SET_USER_STATE,
				payload: { accessToken: response?.data?.access_token },
			})
			return response.data
		} catch (error) {
			throw error.response
		}
	}
	return (
		<UserContext.Provider value={{ ...state, dispatch, login }}>{children}</UserContext.Provider>
	)
}
