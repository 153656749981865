export const POST_KYC_PRE_ONBOARDING_COMPLETE_STAGES: Stage[] = [
	"DIRECT_DEPOSIT",
	"WAGE_ADVANCE",
	"CARD_ACTIVATION",
]
export const ONBOARDING_COMPLETE_STAGES: Stage[] = ["READY_TO_GET_PAID_EARLY", "DOWNLOAD_APP"]

export const STAGES: Stage[] = [
	...POST_KYC_PRE_ONBOARDING_COMPLETE_STAGES,
	...ONBOARDING_COMPLETE_STAGES,
]

export type Stage =
	| "DIRECT_DEPOSIT"
	| "WAGE_ADVANCE"
	| "CARD_ACTIVATION"
	| "READY_TO_GET_PAID_EARLY"
	| "DOWNLOAD_APP"
