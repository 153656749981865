import useAnalytics from "./useAnalytics"
import { supportEmail } from "./../constants/emails"
import { useOpenUrl } from "./useOpenUrl"
import { useSendEmail } from "./useSendEmail"

export const useContactSupport = () => {
	const { openUrl } = useOpenUrl()
	const { sendEmail } = useSendEmail()
	const { track } = useAnalytics()

	const emailSupport = (eventName?: string) => {
		if (eventName) track(eventName)
		return sendEmail({ recipients: [supportEmail] })
	}

	const callSupport = (eventName?: string) => {
		if (eventName) track(eventName)
		return openUrl("tel:+1(888)442-5247")
	}

	return {
		emailSupport,
		callSupport,
	}
}
