import I18n from "i18n-js"
import React, { useContext, useEffect, useState } from "react"
import { Button } from "../../components/Button"
import { cardAPI } from "../../api"
import { FeatureImage } from "../../components/FeatureImage"
import { FlexView } from "../../components/FlexView"
import { Headline, MetroText } from "../../components/StyledText"
import { OnboardingContext } from "../../context/onboarding/OnboardingContext"
import { OnboardingProgressBar } from "../../components/OnboardingProgressBar"
import { OnboardingStackParamList } from "../../types"
import { ScreenWrapper } from "../../components/ScreenWrapper"
import { StackScreenProps } from "@react-navigation/stack"
import { useOnboardingInit } from "../../hooks/useOnboardingInit"
import { useOpenUrl } from "../../hooks/useOpenUrl"
import { useRefetchInterval } from "../../hooks/useRefetchInterval"
import { useUserContext } from "../../context/user/UserContext"

export default function KYCReferScreen({
	navigation,
}: StackScreenProps<OnboardingStackParamList, "KYCRefer">) {
	const { openUrl } = useOpenUrl()
	const [refetchInterval, setRefetchInterval] = useState<false | number>(false)
	const { data: onboardingData, isFetching } = useOnboardingInit(refetchInterval)
	const { idologyIdScanUrl = "", status } = onboardingData || {}
	const updatedInterval = useRefetchInterval(isFetching)
	const { email, password } = useContext(OnboardingContext)
	const { login } = useUserContext()

	useEffect(() => {
		setRefetchInterval(updatedInterval)
	}, [updatedInterval])

	useEffect(() => {
		const checkStatus = async () => {
			if (status === "Complete") {
				if (password) {
					const { access_token } = await login(email, password)
					cardAPI.defaults.headers["Authorization"] = `Bearer ${access_token}`
				}
				navigation.reset({
					routes: [{ name: "KYCPass" }],
				})
			} else if (status === "KYC Fail") {
				navigation.reset({
					routes: [{ name: "KYCFail" }],
				})
			}
		}
		checkStatus()
	}, [status])

	return (
		<ScreenWrapper center>
			<OnboardingProgressBar position={10} style={{ marginBottom: 30 }} />
			<FeatureImage medium source={require("../../assets/images/kyc_refer.png")} />
			<Headline center testID="kycReferHeadline">
				{I18n.t("KYCRefer.PageHeader")}
			</Headline>
			<MetroText center style={{ marginBottom: 25 }}>
				{I18n.t("KYCRefer.Body1")}
			</MetroText>
			<MetroText center style={{ marginBottom: 29 }}>
				{I18n.t("KYCRefer.Body2")}
			</MetroText>
			<FlexView />
			<Button
				title={I18n.t("KYCRefer.VerifyYourIdentity")}
				onPress={() => openUrl(idologyIdScanUrl)}
				testID="kycReferVerify"
			/>
		</ScreenWrapper>
	)
}
