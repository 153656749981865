/**
 * @description Method that receives two objects and returns a new object with the properties of the
 * second object overriding the properties of the first object.
 * @returns object, the new object with the properties of the second object overriding the properties
 * of the first object.
 */
export const overrideObject = (oldObject: {}, newObject: {}) => {
	const result = { ...oldObject }
	for (const key in newObject) {
		if (typeof newObject[key] === "object") {
			result[key] = overrideObject(result[key], newObject[key])
		} else {
			result[key] = newObject[key]
		}
	}
	return result
}
