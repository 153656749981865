import * as Font from "expo-font"
import * as SplashScreen from "expo-splash-screen"
import { Asset } from "expo-asset"
import { Image } from "react-native"
import { Ionicons } from "@expo/vector-icons"
import { useEffect, useState } from "react"

function cacheImages(images: any[]) {
	return images.map((image) => {
		if (typeof image === "string") {
			return Image.prefetch(image)
		} else {
			return Asset.fromModule(image).downloadAsync()
		}
	})
}

export default function useCachedResources() {
	const [isLoadingComplete, setLoadingComplete] = useState(false)

	// Load any resources or data that we need prior to rendering the app
	useEffect(() => {
		async function loadResourcesAndDataAsync() {
			try {
				SplashScreen.preventAutoHideAsync()

				await Font.loadAsync({
					...Ionicons.font,
					"metropolis-regular": require("../assets/fonts/Metropolis-Regular.otf"),
					"metropolis-medium": require("../assets/fonts/Metropolis-Medium.otf"),
					"metropolis-medium-italic": require("../assets/fonts/Metropolis-Medium-Italic.otf"),
					"metropolis-semi": require("../assets/fonts/Metropolis-SemiBold.otf"),
					"metropolis-bold": require("../assets/fonts/Metropolis-Bold.otf"),
					"pacifico-regular": require("../assets/fonts/Pacifico-Regular.ttf"),
				})
				cacheImages([
					require("../assets/images/bank_magnifier.png"),
					require("../assets/images/Clair_7shifts_cloud.png"),
					require("../assets/images/cloud_card_savings.png"),
					require("../assets/images/cloud.png"),
					require("../assets/images/gross_wages_earned.png"),
					require("../assets/images/icon.png"),
					require("../assets/images/Illustration_verified.png"),
					require("../assets/images/info.png"),
					require("../assets/images/info.png"),
					require("../assets/images/logo_white.png"),
					require("../assets/images/logo.png"),
					require("../assets/images/no_fees.png"),
					require("../assets/images/numbers_1.png"),
					require("../assets/images/numbers_2.png"),
					require("../assets/images/numbers_3.png"),
					require("../assets/images/personal_info.png"),
					require("../assets/images/Phone_blue.png"),
					require("../assets/images/splash.png"),
					require("../assets/images/Star.png"),
				])
			} catch (e) {
				// We might want to provide this error information to an error reporting service
				console.warn(e)
			} finally {
				setLoadingComplete(true)
				SplashScreen.hideAsync()
			}
		}
		loadResourcesAndDataAsync()
	}, [])

	return isLoadingComplete
}
