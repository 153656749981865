import _ from "lodash"
import Colors from "../constants/Colors"
import React, { FunctionComponent } from "react"
import useColorScheme from "../hooks/useColorScheme"
import { MetroText } from "./StyledText"
import { StyleSheet, TouchableOpacity, View, ViewStyle } from "react-native"

type Button = {
	label: string
	onPress?: Function
	flex?: number
}

type ButtonGroupProps = {
	buttons: Button[]
	containerStyle?: ViewStyle
}

export const ButtonGroup: FunctionComponent<ButtonGroupProps> = ({ buttons, containerStyle }) => {
	const theme = useColorScheme()
	const titleColor = Colors[theme]["textHighlight"]

	return (
		<View
			style={[
				{
					flexDirection: "row",
					justifyContent: "center",
					width: "100%",
				},
				containerStyle,
			]}
		>
			{buttons.map((button, index) => {
				return (
					<TouchableOpacity
						onPress={() => {
							button.onPress && button.onPress()
						}}
						key={button.label}
						style={[
							styles.button,
							{
								marginRight: index < buttons.length - 1 ? 8 : 0,
								borderColor: titleColor,
							},
						]}
						testID={_.camelCase(button.label) + "Button"}
					>
						<MetroText style={[styles.buttonText, { color: titleColor }]}>{button.label}</MetroText>
					</TouchableOpacity>
				)
			})}
		</View>
	)
}

const styles = StyleSheet.create({
	buttonText: {
		fontFamily: "metropolis-semi",
		fontSize: 14,
		lineHeight: 24,
		textAlign: "center",
		padding: 12,
	},
	button: {
		flex: 1,
		borderWidth: 1,
		alignItems: "center",
	},
})
