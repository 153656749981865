import * as Linking from "expo-linking"
import * as Localization from "expo-localization"
import * as Sentry from "sentry-expo"
import _ from "lodash"
import analytics from "./analytics"
import AsyncStorage from "@react-native-async-storage/async-storage"
import en from "./localization/en.json"
import i18n from "i18n-js"
import LogRocket from "logrocket"
import LogrocketFuzzySanitizer from "logrocket-fuzzy-search-sanitizer"
import Navigation from "./navigation"
import Pusher from "pusher-js"
import React, { useEffect, useState } from "react"
import setupLogRocketReact from "logrocket-react"
import useCachedResources from "./hooks/useCachedResources"
import useColorScheme from "./hooks/useColorScheme"
import variables from "./utils/variables"
import { Dimensions, Platform } from "react-native"
import { GestureHandlerRootView } from "react-native-gesture-handler"
import { hydrateState } from "./context/stateHydration"
import { INITIAL_USER_STATE, UserProvider } from "./context/user/UserProvider"
import { OnboardingProvider } from "./context/onboarding/OnboardingProvider"
import { QueryClient, QueryClientProvider } from "react-query"
import { QueryParamState } from "./context/queryParam/queryParamReducer"
import { SafeAreaProvider } from "react-native-safe-area-context"
import { StatusBar } from "expo-status-bar"

import {
	INITIAL_QUERY_PARAM_STATE,
	QueryParamProvider,
} from "./context/queryParam/QueryParamProvider"

declare global {
	interface Window {
		Pusher: any
	}
}
window.Pusher = Pusher

export const height = Platform.OS === "web" ? window.innerHeight : Dimensions.get("screen").height

export const queryClient = new QueryClient({
	defaultOptions: {
		queries: {
			cacheTime: 1000 * 60 * 60 * 24, // 24 hours
		},
	},
})

Sentry.init({
	dsn: "https://04fd352af9d34da6a8c31ae40f0de1c7@o378781.ingest.sentry.io/6044705",
	enableInExpoDevelopment: true,
	debug: variables.environment != "production",
	environment: variables.environment,
})

if (variables.environment == "production") {
	const privateFieldNames = [
		"ssn",
		"ssnVerify",
		"password",
		"confirmPassword",
		"access_token",
		"refresh_token",
		"accountNumber",
		"token",
	]
	const { requestSanitizer, responseSanitizer } = LogrocketFuzzySanitizer.setup([
		...privateFieldNames,
	])
	LogRocket.init("1xtzqk/clair-embedded", {
		dom: {
			inputSanitizer: true,
		},
		network: {
			requestSanitizer: (request) => {
				if (request.headers["Authorization"]) request.headers["Authorization"] = "******"
				// @ts-ignore
				return requestSanitizer(request)
			},
			// @ts-ignore
			responseSanitizer,
		},
	})

	setupLogRocketReact(LogRocket)

	LogRocket.getSessionURL((sessionURL) => {
		Sentry.Browser.configureScope((scope) => {
			scope.setExtra("sessionURL", sessionURL)
		})
		analytics.track("LogRocket", {
			sessionURL,
		})
	})
}

// Set the key-value pairs for the different languages you want to support.
i18n.translations = {
	en,
}
i18n.defaultLocale = "en-US"
// Set the locale once at the beginning of your app.
i18n.locale = Localization.locale
// When a value is missing from a language it'll fallback to another language with the key present.
i18n.fallbacks = true

export default function App() {
	const colorScheme = useColorScheme()
	const cachedResourcesLoaded = useCachedResources()
	const [queryParams, setQueryParams] = useState<any>("")
	const [asyncStateReady, setAsyncStateReady] = useState(false)
	const [userAsyncState, setUserAsyncState] = useState(null)
	const [appAccessToken, setAppAccessToken] = useState(null)
	const [queryParamAsyncState, setQueryParamAsyncState] = useState<QueryParamState | null>(null)

	const currentUrl = Linking.useURL()

	// Hook into the initial url query params and store the query params
	async function getInitUrl() {
		const url = await Linking.getInitialURL()
		let { queryParams } = Linking.parse(url || "")
		if (queryParams) {
			queryParams = _.pickBy(queryParams, (_, key) =>
				Object.keys(INITIAL_QUERY_PARAM_STATE).includes(key)
			)
			if (Object.keys(queryParams).includes("inSdk"))
				queryParams.inSdk = queryParams?.inSdk?.toLowerCase() == "true"

			if ("appAccessToken" in queryParams && queryParams.appAccessToken?.length) {
				setAppAccessToken(queryParams.appAccessToken)
				setQueryParams({ ...queryParams, clairEmbed: true })
			} else {
				setQueryParams(queryParams)
			}
		}
	}

	useEffect(() => {
		async function checkIfPageRefresh() {
			let lastUrl = await AsyncStorage.getItem("previousUrl")
			// Reset onboarding flow to start if page is reloaded. This is done to avoid
			// a user refreshing the current page they are on, and loosing their
			// app context for the previous state. If they already started, splash should
			// init "already started flow" which should hydrate the onboarding context
			// so that the user does not need to refill out all the fields.
			if (lastUrl && lastUrl == currentUrl && !currentUrl.includes("Splash")) {
				window.location.href = "/Splash"
			}
		}
		checkIfPageRefresh()
		if (currentUrl) AsyncStorage.setItem("previousUrl", currentUrl)
	}, [currentUrl])

	useEffect(() => {
		getInitUrl()
	}, [])

	useEffect(() => {
		const restoreAsyncState = async () => {
			try {
				// Hydrate all Contexts' state
				await Promise.all([
					hydrateState(
						"@queryParams",
						INITIAL_QUERY_PARAM_STATE,
						setQueryParamAsyncState,
						queryParams
					),
				])
				if (appAccessToken) {
					await hydrateState("@user", INITIAL_USER_STATE, setUserAsyncState, {
						accessToken: appAccessToken,
						rememberUser: true,
					})
				} else {
					await hydrateState("@user", INITIAL_USER_STATE, setUserAsyncState)
				}
			} finally {
				setAsyncStateReady(true)
			}
		}

		if (!asyncStateReady && queryParams) {
			restoreAsyncState()
		}
	}, [asyncStateReady, queryParams])

	if (!cachedResourcesLoaded) return null
	else {
		return (
			<SafeAreaProvider>
				<GestureHandlerRootView style={{ flex: 1 }}>
					<QueryClientProvider client={queryClient}>
						<QueryParamProvider asyncState={queryParamAsyncState}>
							<UserProvider asyncState={userAsyncState}>
								<OnboardingProvider>
									<StatusBar />
									<Navigation colorScheme={colorScheme} />
								</OnboardingProvider>
							</UserProvider>
						</QueryParamProvider>
					</QueryClientProvider>
				</GestureHandlerRootView>
			</SafeAreaProvider>
		)
	}
}
