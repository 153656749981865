import Colors from "../constants/Colors"
import I18n from "i18n-js"
import React, { FunctionComponent, useState } from "react"
import useAnalytics from "../hooks/useAnalytics"
import useColorScheme from "../hooks/useColorScheme"
import { Bold, Headline, P3 } from "./StyledText"
import { Button } from "./Button"
import { Overlay } from "./Overlay"
import { View } from "react-native"

type InfoTextProps = {
	headline: string
	body: string
	screen?: string
}

export const InfoText: FunctionComponent<InfoTextProps> = ({ headline, body, screen }) => {
	const [showInfoOverlay, setShowInfoOverlay] = useState(false)
	const theme = useColorScheme()
	const { track } = useAnalytics()

	return (
		<View style={{ marginVertical: 10 }}>
			<Bold
				style={{ color: Colors[theme]["textHighlight"] }}
				onPress={() => {
					if (screen) track(`tap_popupinfo_${screen}`)
					setShowInfoOverlay(!showInfoOverlay)
				}}
				testID="infoText"
			>
				{headline}
			</Bold>
			<Overlay
				overlayName="infoText"
				style={{ paddingHorizontal: 30, paddingVertical: 60 }}
				setShowOverlay={() => setShowInfoOverlay(!showInfoOverlay)}
				showClose
				isVisible={showInfoOverlay}
			>
				<Headline smallText testID="infoTextHeadline">
					{headline}
				</Headline>
				<P3 style={{ marginBottom: 35, color: Colors[theme]["text"] }} testID="infoTextBody">
					{body}
				</P3>
				<Button
					title={I18n.t("Close")}
					onPress={() => setShowInfoOverlay(!setShowInfoOverlay)}
					testID="infoTextButton"
				/>
			</Overlay>
		</View>
	)
}
