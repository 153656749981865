import * as Device from "expo-device"
import Carousel1 from "./Carousel1"
import Carousel2 from "./Carousel2"
import Carousel3 from "./Carousel3"
import React from "react"
import { createMaterialTopTabNavigator } from "@react-navigation/material-top-tabs"
import { Image } from "react-native"
import { StackNavigationOptions } from "@react-navigation/stack"

const Tab = createMaterialTopTabNavigator()

export default function VideoCarouselTabs() {
	return (
		<Tab.Navigator
			screenOptions={{
				tabBarStyle: {
					display: "none",
				},
			}}
		>
			<Tab.Screen name="Carousel1" component={Carousel1} />
			<Tab.Screen name="Carousel2" component={Carousel2} />
			<Tab.Screen name="Carousel3" component={Carousel3} />
		</Tab.Navigator>
	)
}

export const VideoCarouselHeaderOptions: StackNavigationOptions = {
	headerTransparent: true,
	headerBackTitleVisible: false,
	headerTitle: () => (
		<Image
			source={require("../../../assets/images/logo_white.png")}
			style={{
				height: 26,
				width: 60,
				padding: 5,
				marginBottom: 16,
			}}
			resizeMode="contain"
		/>
	),
	headerTitleAlign: "center",
	headerTitleContainerStyle: {
		justifyContent: "flex-end",
		paddingVertical: Device.osName === "Android" ? 20 : 0,
	},
}
