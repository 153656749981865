import _ from "lodash"
import I18n from "i18n-js"
import React, { useContext, useEffect, useRef, useState } from "react"
import { AxiosError } from "axios"
import { Button } from "../../../components/Button"
import { FlexView } from "../../../components/FlexView"
import { Headline, LegalText, MetroText } from "../../../components/StyledText"
import { Icon } from "react-native-elements"
import { InfoText } from "../../../components/InfoText"
import { Input } from "../../../components/Input"
import { OnboardingContext } from "../../../context/onboarding/OnboardingContext"
import { OnboardingProgressBar } from "../../../components/OnboardingProgressBar"
import { OnboardingStackParamList } from "../../../types"
import { patchState, sendInvitationCode } from "../../../api/onboarding"
import { ScreenWrapper } from "../../../components/ScreenWrapper"
import { SET_ONBOARDING_STATE } from "../../../context/actionTypes"
import { StackScreenProps } from "@react-navigation/stack"
import { useLinkTo } from "@react-navigation/native"
import { useMutation } from "react-query"
import { useOnboardingInit } from "../../../hooks/useOnboardingInit"
import { useSignupNavigation } from "../../../hooks/useSignupNavigation"
import { validateField } from "../../../utils/validate"

export default function MobileNumberScreen({
	navigation,
}: StackScreenProps<OnboardingStackParamList, "MobileNumber">) {
	const { phoneNumber, dispatch } = useContext(OnboardingContext)
	const { data } = useOnboardingInit()
	const { allowPhoneEdit, phoneNumberLastFour } = data || {}
	const [disabled, setDisabled] = useState(!!phoneNumberLastFour && !phoneNumber)
	const numberInput = useRef()
	const [hasError, setHasError] = useState(false)
	const [errorMessage, setErrorMessage] = useState(I18n.t("MobileNumber.Input1ErrorMessage"))
	const linkTo = useLinkTo()
	const { navigateToNextSignupScreen } = useSignupNavigation()

	const sendCodeMutation = useMutation(() => sendInvitationCode({ phoneNumber }), {
		onMutate: () => {
			navigateToNextSignupScreen()
		},
		onError: (err: AxiosError) => {
			if (err.response?.status === 503) {
				linkTo("/MaintenanceMode")
			} else {
				navigation.pop()
				const message = err.response?.data?.message
				if (message) setErrorMessage(message)
				setHasError(true)
			}
		},
		onSuccess: () => {
			if (!disabled) patchState({ phoneNumber })
		},
	})

	useEffect(() => {
		// @ts-ignore
		if (!disabled) numberInput.current?.focus()
	}, [disabled])

	const handleNext = () => {
		const hasNewError = !disabled && validateField("phone", phoneNumber, setHasError).hasError
		if (!hasNewError) sendCodeMutation.mutate()
	}

	const enableInput = () => {
		dispatch({ type: SET_ONBOARDING_STATE, payload: { phoneNumber: "" } })
		setDisabled(false)
	}

	return (
		<ScreenWrapper>
			<OnboardingProgressBar position={1} />
			<Headline smallText testID="mobileNumberHeadline">
				{I18n.t("MobileNumber.PageHeader")}
			</Headline>
			<MetroText>
				{I18n.t(`MobileNumber.Body${disabled && !allowPhoneEdit ? "Locked" : "Unlocked"}`)}
			</MetroText>
			<Input
				ref={numberInput}
				label={I18n.t("MobileNumber.Input1Label")}
				value={disabled ? `(•••) ••• - ${phoneNumberLastFour}` : phoneNumber}
				onChangeText={(value: string) => {
					dispatch({ type: SET_ONBOARDING_STATE, payload: { phoneNumber: value } })
					if (hasError) {
						const stillHasError = validateField("phone", value, setHasError)
						if (!stillHasError) setErrorMessage(I18n.t("MobileNumber.Input1ErrorMessage"))
					}
				}}
				keyboardType="numeric"
				mask="phone"
				errorMessage={errorMessage}
				hasError={hasError}
				containerStyle={{ marginBottom: 24 }}
				testID="mobileNumberInput"
				editable={!disabled}
				inputContainerStyle={{
					borderBottomWidth: disabled ? 0 : 1,
				}}
				rightIcon={
					disabled &&
					allowPhoneEdit && (
						<Icon
							name="pencil-alt"
							type="font-awesome-5"
							size={14}
							onPress={enableInput}
							hitSlop={{ top: 10, bottom: 10, left: 10, right: 10 }}
						/>
					)
				}
			/>
			<InfoText
				headline={I18n.t("MobileNumber.InfoText")}
				body={I18n.t("MobileNumber.OverlayBody")}
				screen="MobileNumber"
			/>
			<FlexView />
			<LegalText>{I18n.t("MobileNumber.LegalText")}</LegalText>
			<Button
				title={I18n.t("MobileNumber.ButtonText")}
				onPress={handleNext}
				testID="mobileNumberLetsGo"
			/>
		</ScreenWrapper>
	)
}
