import { getSpendingAccount } from "./../api/getSpendingAccount"
import { useQuery } from "react-query"
import { useUserContext } from "./../context/user/UserContext"

export type DirectDepositStatus = "NOT_STARTED" | "ACTION_REQUIRED" | "COMPLETED" | "IN_PROGRESS"

type SpendingAccountQuery = {
	accountNumber: string
	routingNumber: string
}

export default function useSpendingAccount(ready = true) {
	const { stage } = useUserContext()
	return useQuery<SpendingAccountQuery>("spendingAccount", getSpendingAccount, {
		enabled: ready && stage == "DIRECT_DEPOSIT",
	})
}
