import Colors from "../../constants/Colors"
import I18n from "i18n-js"
import React from "react"
import useColorScheme from "../../hooks/useColorScheme"
import { Address, MailingAddress } from "../../models/Address"
import { Headline, MetroText, SecondaryText } from "../../components/StyledText"
import { Overlay } from "../../components/Overlay"
import { SecondaryButton, TertiaryButton } from "../../components/Button"
import { StyleSheet, View } from "react-native"

type AddressWarningOverlayProps = {
	warning: string | null | undefined
	setWarning: React.Dispatch<React.SetStateAction<string | null | undefined>>
	address: Address
	addressType: "permament" | "mailing"
	onContinue: (address: Address | MailingAddress, type: "suggestion" | "warning") => void
}

export const AddressWarningOverlay = ({
	warning,
	setWarning,
	address,
	onContinue,
	addressType,
}: AddressWarningOverlayProps) => {
	const { addressLine1, addressLine2, city, state, postalCode } = address
	const theme = useColorScheme()

	return (
		<Overlay
			showClose
			isVisible={!!warning}
			setShowOverlay={() => setWarning(null)}
			overlayName="addressWarning"
			style={{ paddingHorizontal: 26 }}
		>
			<Headline smallText testID="addressWarningHeadline">
				{I18n.t("PermanentAddress.WarningHeader")}
			</Headline>
			<MetroText>{warning}</MetroText>
			<View style={[styles.warningAddressContainer, { borderColor: Colors[theme]["borderModal"] }]}>
				<MetroText style={{ fontSize: 14, lineHeight: 24 }}>
					{addressLine1}
					{addressLine2 && <MetroText>, {addressLine2}</MetroText>}
				</MetroText>
				<SecondaryText>
					{city}, {state} {postalCode}
				</SecondaryText>
			</View>
			<SecondaryButton
				title={I18n.t("PermanentAddress.WarningButton1")}
				onPress={() => setWarning(null)}
				testID="editAddress"
			/>
			<TertiaryButton
				title={I18n.t("PermanentAddress.WarningButton2")}
				onPress={() => {
					const payload =
						addressType === "permament"
							? address
							: {
									shippingAddressLine1: addressLine1,
									shippingAddressLine2: addressLine2,
									shippingCity: city,
									shippingState: state,
									shippingPostalCode: postalCode,
							  }
					onContinue(payload, "warning")
				}}
				testID="editAddressContinue"
			/>
		</Overlay>
	)
}

const styles = StyleSheet.create({
	warningAddressContainer: {
		borderWidth: 1,
		margin: 20,
		borderRadius: 6,
		paddingHorizontal: 15,
		paddingVertical: 15,
		width: "100%",
	},
})
