import I18n from "i18n-js"
import React, { useCallback, useRef, useState } from "react"
import { Animated, useWindowDimensions } from "react-native"
import { AutoHeightImage } from "../../../components/AutoHeightImage"
import { CarouselButton } from "./components/CarouselButton"
import { CarouselIndicator } from "./components/CarouselIndicator"
import { CustomColors } from "./utils/index"
import { FlexView } from "../../../components/FlexView"
import { LegalText } from "../../../components/StyledText"
import { ScreenWrapper } from "../../../components/ScreenWrapper"
import { useFocusEffect, useLinkTo } from "@react-navigation/native"
import { useIsNonMobileScreen } from "../../../hooks/useIsNonMobileScreen"
import { Video } from "expo-av"
import { wait } from "../../../utils/wait"

export default function Carousel3() {
	const video = useRef<Video>(null)
	const fadeAnim = useRef(new Animated.Value(0)).current
	const linkTo = useLinkTo()
	const { width } = useWindowDimensions()
	const [hasPlayed, setHasPlayed] = useState(false)
	const isNonMobile = useIsNonMobileScreen()
	const nonMobileWidth = 400

	useFocusEffect(
		useCallback(() => {
			if (!hasPlayed) {
				video.current?.playAsync()
			}
			fadeIn()
			return () => setHasPlayed(true)
		}, [hasPlayed])
	)

	const fadeIn = async () => {
		await wait(1000)
		// Will change fadeAnim value to 1 in 5 seconds
		Animated.timing(fadeAnim, {
			toValue: 1,
			duration: 2000,
			useNativeDriver: false,
		}).start()
	}

	return (
		<ScreenWrapper
			noPadding
			contentContainerStyle={{
				backgroundColor: CustomColors.PRUSSIAN_BLUE,
			}}
			nonMobileContentStyle={{
				backgroundColor: CustomColors.PRUSSIAN_BLUE,
				overflow: "hidden",
				maxWidth: nonMobileWidth,
				minWidth: nonMobileWidth,
				minHeight: 900,
			}}
		>
			<AutoHeightImage
				source={require("../../../assets/images/carousel_3.gif")}
				width={isNonMobile ? nonMobileWidth : Math.min(width, 600)}
				maxHeight={isNonMobile ? 600 : 986}
				style={{
					marginBottom: 16,
				}}
			/>
			<FlexView />
			<Animated.View
				style={{
					// Bind opacity to animated value
					opacity: fadeAnim,
				}}
			>
				<CarouselIndicator active={2} />
				<LegalText style={{ color: "white", marginBottom: 16, marginHorizontal: 24 }}>
					{I18n.t("BankingServicesFooter")}
				</LegalText>
				<CarouselButton
					title="Continue"
					onPress={() => linkTo("/MobileNumber")}
					style={{ marginBottom: 16 }}
				/>
			</Animated.View>
		</ScreenWrapper>
	)
}
