/* 
This function brightens an input color by the input amount
by updating the color's hex code. 
*/

export function adjust(color: string, amount: number) {
	return (
		"#" +
		color
			.replace(/^#/, "")
			.replace(/../g, (color) =>
				("0" + Math.min(255, Math.max(0, parseInt(color, 16) + amount)).toString(16)).substr(-2)
			)
	)
}
