import _ from "lodash"
import Colors from "../../constants/Colors"
import I18n from "i18n-js"
import React from "react"
import useAnalytics from "../../hooks/useAnalytics"
import useColorScheme from "../../hooks/useColorScheme"
import { Button, SecondaryButton } from "../../components/Button"
import { FeatureImage } from "../../components/FeatureImage"
import { FlexView } from "../../components/FlexView"
import { Headline, LegalText, MetroText } from "../../components/StyledText"
import { OnboardingStackParamList } from "../../types"
import { ScreenWrapper } from "../../components/ScreenWrapper"
import { StackScreenProps } from "@react-navigation/stack"
import { useAppDownload } from "../../hooks/useAppDownload"
import { useLinkTo } from "@react-navigation/native"

export default function OnboardingCompleteScreen({}: StackScreenProps<
	OnboardingStackParamList,
	"OnboardingComplete"
>) {
	const theme = useColorScheme()
	const linkTo = useLinkTo()
	const { track } = useAnalytics()
	const { sendDownloadLink } = useAppDownload()

	const backgroundColor = Colors[theme]["backgroundSuccess"]

	return (
		<ScreenWrapper
			center
			contentContainerStyle={{ backgroundColor }}
			nonMobileContentStyle={{ backgroundColor }}
		>
			<FeatureImage
				medium
				style={{ marginBottom: 32 }}
				source={require("../../assets/images/onboarding_complete.gif")}
			/>
			<Headline center style={{ marginBottom: 24 }}>
				{I18n.t("OnboardingComplete.PageHeader")}
			</Headline>
			<MetroText h4 center>
				{I18n.t("OnboardingComplete.Body")}
			</MetroText>
			<FlexView />
			<Button
				title={I18n.t("DownloadApp")}
				onPress={() => {
					track("complete_download")
					sendDownloadLink()
				}}
			/>
			<SecondaryButton
				buttonStyle={{ backgroundColor }}
				title={I18n.t("OnboardingComplete.Button")}
				onPress={() => {
					linkTo("/AccountInfo")
				}}
			/>
			<LegalText>{I18n.t("BankingServicesFooter")}</LegalText>
		</ScreenWrapper>
	)
}
