declare global {
	interface Window {
		analytics: any
	}
}

const analytics = {
	track: (event: string, properties: {}) => {
		window.analytics.track(event, properties)
	},
	page: (pageName: string, properties: {}) => {
		window.analytics.page(pageName, properties)
	},
	identify: (userId: string, traits: { [key: string]: any }) => {
		window.analytics.identify(userId, traits)
	},
}

export default analytics
