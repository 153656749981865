import _ from "lodash"
import I18n from "i18n-js"
import React from "react"
import useAnalytics from "../../../hooks/useAnalytics"
import useSpendingAccount from "../../../hooks/useSpendingAccount"
import { Button } from "../../../components/Button"
import { FlexView } from "../../../components/FlexView"
import { Headline, LegalText, MetroText } from "../../../components/StyledText"
import { LabeledText } from "../../../components/LabeledText"
import { OnboardingProgressBar } from "../../../components/OnboardingProgressBar"
import { OnboardingStackParamList } from "../../../types"
import { ScreenWrapper } from "../../../components/ScreenWrapper"
import { StackScreenProps } from "@react-navigation/stack"
import { useAuthenticatedUserQuery } from "../../../hooks/useAuthenticatedUser"
import { useLinkTo } from "@react-navigation/native"
import { useUserContext } from "../../../context/user/UserContext"

export default function VerifyFormInfo({
	navigation,
}: StackScreenProps<OnboardingStackParamList, "VerifyFormInfo">) {
	const { data: userData } = useAuthenticatedUserQuery()
	const { data: accountData } = useSpendingAccount()
	const { firstName, lastName, businesses } = userData?.userData || {}
	const { accountNumber = "", routingNumber = "" } = accountData || {}
	const employerName = businesses[0].name
	const { distributionType, distributionValue = "100" } = useUserContext()
	const { track } = useAnalytics()
	const linkTo = useLinkTo()

	return (
		<ScreenWrapper>
			<OnboardingProgressBar section={1} position={7} />
			<Headline testID="verifyFormInfoHeadline">{I18n.t("VerifyFormInfo.PageHeader")}</Headline>
			<MetroText>{I18n.t("VerifyFormInfo.Body")}</MetroText>
			<LabeledText labelText={I18n.t("VerifyFormInfo.EmployerLabel")} valueText={employerName} />
			<LabeledText
				labelText={I18n.t("VerifyFormInfo.NameLabel")}
				valueText={`${firstName} ${lastName}`}
			/>
			<LabeledText
				bankingDetails
				labelText={I18n.t("VerifyFormInfo.AccountNumberLabel")}
				valueText={accountNumber}
			/>

			<LabeledText
				bankingDetails
				labelText={I18n.t("VerifyFormInfo.RoutingNumberLabel")}
				valueText={routingNumber}
			/>
			<LabeledText
				labelText={I18n.t(
					distributionType === "FIXED"
						? "VerifyFormInfo.AmountLabel"
						: "VerifyFormInfo.PercentageLabel"
				)}
				rightLabel="Edit"
				onRightLabelPress={() => {
					navigation.reset({ routes: [{ name: "CalculatorEntry", params: { isFormEdit: true } }] })
				}}
				valueText={distributionType === "FIXED" ? `$${distributionValue}` : `${distributionValue}%`}
			/>
			<LabeledText
				signature
				labelText={I18n.t("VerifyFormInfo.SignatureLabel")}
				valueText={`${firstName} ${lastName}`}
			/>
			<FlexView />
			<Button
				title={I18n.t("VerifyFormInfo.Button")}
				onPress={() => {
					track("tap_view_DDform", { distributionType, distributionValue })
					linkTo("/DDFormPreview")
				}}
			/>
			<LegalText>{I18n.t("BankingServicesFooter")}</LegalText>
		</ScreenWrapper>
	)
}
