import React, { FunctionComponent } from "react"
import { AutoHeightImage } from "./AutoHeightImage"
import { ImageProps } from "react-native"
import { useInnerContentWidth } from "../hooks/useInnerContentWidth"
import { useIsVisibleScreenShort } from "../hooks/useIsVisibleScreenShort"

type FeatureImageProtoProps = {
	medium?: boolean
	maxHeight?: number
} & ImageProps
// Prototype FeatureImage replacement we're trying out in collaboration with Design
export const FeatureImageProto: FunctionComponent<FeatureImageProtoProps> = ({
	medium,
	maxHeight = 200,
	style,
	...rest
}) => {
	const innerContentWidth = useInnerContentWidth()
	const isShortScreen = useIsVisibleScreenShort()
	return (
		<AutoHeightImage
			width={innerContentWidth}
			style={[
				{
					marginBottom: 24,
					alignSelf: "center",
					flex: 1,
				},
				style,
			]}
			resizeMode="contain"
			maxHeight={isShortScreen ? 100 : 200}
			{...rest}
		/>
	)
}
