import _ from "lodash"
import Colors from "../constants/Colors"
import React, { FunctionComponent, useEffect, useRef, useState } from "react"
import useColorScheme from "../hooks/useColorScheme"
import { Platform, StyleSheet, TextInput, View } from "react-native"
import { useIsThinScreen } from "../hooks/useIsThinScreen"

type CodeInputValue = "0" | "1" | "2" | "3" | "4" | "5" | "6" | "7" | "8" | "9" | ""
type CodeInputProps = {
	focused?: boolean
	hasError?: boolean
	setValue(value: string): void
}
export const CodeInput: FunctionComponent<CodeInputProps> = ({ focused, hasError, setValue }) => {
	const theme = useColorScheme()
	const isThinScreen = useIsThinScreen()
	const backgroundColor = Colors[theme]["backgroundOTPInput"]
	const borderColor = Colors[theme][hasError ? "textError" : "borderCodeInput"]
	const [valuesArray, setValuesArray] = useState(["", "", "", "", "", ""])
	const allowedValues = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"]
	const input1 = useRef()
	const input2 = useRef()
	const input3 = useRef()
	const input4 = useRef()
	const input5 = useRef()
	const input6 = useRef()
	const CodeInputCombinedStyle = [
		styles.CodeInputBase,
		{ backgroundColor, borderColor, outlineColor: borderColor },
		isThinScreen ? styles.CodeInputSmall : styles.CodeInputLarge,
	]

	const refMap = {
		0: input1,
		1: input2,
		2: input3,
		3: input4,
		4: input5,
		5: input6,
	}

	useEffect(() => {
		if (focused) {
			input1.current.focus()
		}
	}, [focused])

	const onChangeText = (newValue = "", index = 0) => {
		if (newValue.length === 6) {
			setValue(newValue)
			setValuesArray(newValue.split(""))
		} else {
			const newValuesArray = _.clone(valuesArray)
			if (_.isEmpty(newValue)) {
				newValuesArray[index] = ""
			} else if (allowedValues.includes(newValue[newValue.length - 1])) {
				newValuesArray[index] = newValue[newValue.length - 1] as CodeInputValue
			}
			if (newValuesArray[index] && newValuesArray[index] !== valuesArray[index]) {
				if (index < 5) {
					refMap[index + 1].current.focus()
				}
			}
			setValuesArray(newValuesArray)
			setValue(newValuesArray.join(""))
		}
	}

	const onKeyPress = (nativeEvent: any, index = 1) => {
		const { key } = nativeEvent
		if (key === "Backspace") {
			nativeEvent.preventDefault()
			if (valuesArray[index] == "") {
				refMap[index - 1]?.current.focus()
			} else {
				const newValuesArray = _.clone(valuesArray)
				newValuesArray[index] = ""
				setValuesArray(newValuesArray)
				setValue(newValuesArray.join(""))
			}
		}
	}

	return (
		<View style={styles.ContainerStyle}>
			{valuesArray.map((value, index) => {
				return (
					<TextInput
						key={index}
						ref={refMap[index]}
						autoFocus={index === 0 && Platform.OS === "ios" ? true : false}
						style={CodeInputCombinedStyle}
						value={value}
						caretHidden={true}
						onChangeText={(newValue: string) => onChangeText(newValue, index)}
						onKeyPress={({ nativeEvent }) => onKeyPress(nativeEvent, index)}
						keyboardType="number-pad"
						testID={`verifyCodeInput${index}`}
					/>
				)
			})}
		</View>
	)
}

const styles = StyleSheet.create({
	ContainerStyle: {
		flexDirection: "row",
		marginTop: 20,
		alignItems: "center",
		alignSelf: "center",
	},
	CodeInputBase: {
		borderWidth: 0.66,
		borderRadius: 3,
		textAlign: "center",
		fontFamily: "metropolis-bold",
		...Platform.select({ web: { outlineStyle: "none" } }),
	},
	CodeInputSmall: {
		width: 34,
		height: 48,
		marginRight: 4,
		fontSize: 17,
	},
	CodeInputLarge: {
		width: 48,
		height: 64,
		marginRight: 8,
		fontSize: 24,
	},
})
