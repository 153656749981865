import I18n from "i18n-js"
import React, { useCallback, useContext, useEffect, useState } from "react"
import useAnalytics from "../../../hooks/useAnalytics"
import { Button, SecondaryButton } from "../../../components/Button"
import { FeatureImage } from "../../../components/FeatureImage"
import { FlexView } from "../../../components/FlexView"
import { formatCurrency } from "../../../utils/formatCurrency"
import { Headline, LegalText, MetroText } from "../../../components/StyledText"
import { InfoBox } from "../../../components/InfoBox"
import { OnboardingProgressBar } from "../../../components/OnboardingProgressBar"
import { OnboardingStackParamList } from "../../../types"
import { QueryParamContext } from "../../../context/queryParam/QueryParamContext"
import { ScreenWrapper } from "../../../components/ScreenWrapper"
import { StackScreenProps } from "@react-navigation/stack"
import { useAppDownload } from "../../../hooks/useAppDownload"
import { useDirectDepositFinalStatus } from "../../../hooks/useDirectDepositStatus"
import { useFocusEffect, useLinkTo, useNavigation } from "@react-navigation/native"
import { useOnboardingInit } from "../../../hooks/useOnboardingInit"
import { useRefetchInterval } from "../../../hooks/useRefetchInterval"

export default function DIYPending({}: StackScreenProps<OnboardingStackParamList>) {
	const { clairEmbed } = useContext(QueryParamContext)
	const { track } = useAnalytics()
	const { sendDownloadLink } = useAppDownload()
	const navigation = useNavigation()
	const linkTo = useLinkTo()
	const { data } = useOnboardingInit()
	const { gracePeriodMaxCreditLine } = data || {}
	const [refetchInterval, setRefetchInterval] = useState<false | number>(false)
	const { status, isFetching } = useDirectDepositFinalStatus(refetchInterval)
	const updatedInterval = useRefetchInterval(isFetching)

	useEffect(() => {
		setRefetchInterval(updatedInterval)
	}, [updatedInterval])

	useFocusEffect(
		useCallback(() => {
			if (status == "COMPLETED")
				navigation.reset({
					routes: [{ name: "DDSuccess" }],
				})
			else if (status == "ACTION_REQUIRED")
				navigation.reset({
					routes: [{ name: "DIYError" }],
				})
		}, [status])
	)

	return (
		<ScreenWrapper center>
			<OnboardingProgressBar section={1} position={12} />
			<FeatureImage source={require("../../../assets/images/envelope_clair.png")} width={100} />
			<Headline center style={{ marginBottom: 30 }} testID="DIYPending.PageHeader">
				{I18n.t("DIYPending.PageHeader")}
			</Headline>
			<MetroText h4 center style={{ marginBottom: 32, fontSize: 14 }} testID="DIYPending.Body">
				{I18n.t("DIYPending.Body", {
					gracePeriodMaxCreditLine: formatCurrency(gracePeriodMaxCreditLine),
				})}
			</MetroText>
			<FlexView />
			<InfoBox title={I18n.t("DIYPending.InfoBoxTitle")} body={I18n.t("DIYPending.InfoBoxBody")} />
			{!clairEmbed && (
				<Button
					title={I18n.t("DownloadApp")}
					onPress={() => {
						track("tap_download_DIYPending")
						sendDownloadLink()
					}}
					testID="DIYPending.DownloadApp"
				/>
			)}
			<SecondaryButton
				title={I18n.t("DIYPending.Button2")}
				onPress={() => {
					track("tap_redo_DIYPending")
					linkTo("/CalculatorEntry")
				}}
				testID="DIYPending.Button2"
			/>
			<LegalText>{I18n.t("BankingServicesFooter")}</LegalText>
		</ScreenWrapper>
	)
}
