import Colors from "../constants/Colors"
import React, { FunctionComponent } from "react"
import useColorScheme from "../hooks/useColorScheme"
import { FlexView } from "./FlexView"
import { Placeholder, PlaceholderLine, PlaceholderMedia, Shine } from "rn-placeholder"
import { View, ViewProps } from "react-native"

export interface CardProps extends ViewProps {
	loading?: Boolean
}

export const Card: FunctionComponent<CardProps> = ({ style, loading, ...rest }) => {
	const theme = useColorScheme()
	const backgroundColor = Colors[theme]["backgroundCard"]

	return loading ? (
		<View style={[{ backgroundColor, borderRadius: 6, paddingHorizontal: 24 }, style]}>
			<FlexView />
			<Placeholder Left={PlaceholderMedia} Animation={Shine} style={{ height: "90%" }}>
				<PlaceholderLine width={0} />
				<PlaceholderLine width={0} />
				<PlaceholderLine width={55} />
				<PlaceholderLine width={70} />
			</Placeholder>
			<FlexView />
		</View>
	) : (
		<View style={[{ backgroundColor, borderRadius: 6, paddingHorizontal: 24 }, style]} {...rest} />
	)
}
