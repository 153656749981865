import { useEffect, useState } from "react"
import { useLinkTo } from "@react-navigation/native"
import { useUserContext } from "../context/user/UserContext"

export const useResetLinkingEffect = () => {
	const { stage } = useUserContext()
	const linkTo = useLinkTo()
	const [prevStage, setPreviousStage] = useState(stage)

	useEffect(() => {
		if (prevStage && prevStage !== stage) {
			setPreviousStage(stage)
			// Linking config will handle unexpected routes by routing to the screen handled by "*" (the first screen for a given stage)
                        const nonExistentRoute = "/nonExistentRoute"
                        linkTo(nonExistentRoute)
		}
	}, [stage])
}
