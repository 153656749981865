import AsyncStorage from "@react-native-async-storage/async-storage"
import { SET_QUERY_PARAMS_STATE } from "../actionTypes"

export type QueryParamState = {
	src?: string
	businessId?: string
	employeeId?: string
	widgetId?: string
	inSdk?: boolean
	appAccessToken?: string
	clairEmbed: boolean
}
export type QueryParamAction = {
	type: typeof SET_QUERY_PARAMS_STATE
	payload: any
}
export const queryParamReducer = (state: QueryParamState, action: QueryParamAction) => {
	let newState = state
	switch (action.type) {
		case SET_QUERY_PARAMS_STATE:
			newState = { ...state, ...action.payload }
			break
		default:
			break
	}

	// Don't persist the appAccessToken
	const { appAccessToken, ...persistPayload } = newState
	AsyncStorage.setItem("@queryParams", JSON.stringify(persistPayload))
	return newState
}
