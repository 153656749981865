import Colors from "../constants/Colors"
import React, { ReactNode } from "react"
import useColorScheme from "../hooks/useColorScheme"
import { AutoHeightImage } from "./AutoHeightImage"
import { FlexView } from "./FlexView"
import { MetroText } from "./StyledText"
import { StyleProp, StyleSheet, TextStyle, View, ViewProps } from "react-native"

type ImageBulletTipProps = {
	tipText: string
}
export const ImageBulletTip = ({ tipText }: ImageBulletTipProps) => {
	return (
		<ImageBullet
			image={require("../assets/images/question.png")}
			imageSize={14}
			text={tipText}
			textStyle={{ fontSize: 12, lineHeight: 16 }}
			style={{ marginTop: 8, marginBottom: 0 }}
		/>
	)
}

type ImageBulletProps = {
	title?: string
	titleStyle?: StyleProp<TextStyle>
	text?: string | ReactNode
	textStyle?: StyleProp<TextStyle>
	image: number | { uri: string }
	imageSize?: number
	topAlign?: boolean
	tipText?: string
	body?: boolean
} & ViewProps
export const ImageBullet = ({
	image,
	imageSize = 32,
	title,
	titleStyle,
	text,
	textStyle,
	tipText,
	topAlign,
	body,
	style,
	...rest
}: ImageBulletProps) => {
	const theme = useColorScheme()
	const titleColor = Colors[theme]["textHighlight"]

	return (
		<View
			style={[styles.BulletPoint, { alignItems: topAlign ? "flex-start" : "center" }, style]}
			{...rest}
		>
			<View style={styles.BulletImage}>
				<AutoHeightImage source={image} width={imageSize} />
			</View>
			<FlexView>
				{title && (
					<MetroText
						style={[
							{
								color: titleColor,
								fontFamily: "metropolis-medium",
								marginBottom: 6,
								marginTop: 5,
							},
							titleStyle,
						]}
					>
						{title}
					</MetroText>
				)}
				<MetroText h4={!body} style={textStyle}>
					{text}
				</MetroText>
				{tipText && <ImageBulletTip tipText={tipText} />}
			</FlexView>
		</View>
	)
}

const styles = StyleSheet.create({
	BulletPoint: {
		flexDirection: "row",
		marginBottom: 25,
	},
	BulletImage: {
		padding: 2,
		marginRight: 10,
	},
})
