import * as Device from "expo-device"
import _ from "lodash"
import analytics from "../analytics"
import Echo from "laravel-echo"
import Loading from "../components/Loading"
import OnboardingNavigator from "./OnboardingNavigator"
import React, { useContext, useEffect, useRef, useState } from "react"
import useAnalytics from "../hooks/useAnalytics"
import useSpendingAccount from "../hooks/useSpendingAccount"
import variables from "../utils/variables"
import { ColorSchemeName, PanResponder, View } from "react-native"
import { getLinkingConfig } from "./LinkingConfiguration"
import { HALF_HOUR } from "../constants/time"
import { INITIAL_ONBOARDING_STATE } from "../context/onboarding/OnboardingProvider"
import { INITIAL_USER_STATE } from "../context/user/UserProvider"
import { LinkingOptions } from "@react-navigation/native/lib/typescript/src/types"
import { logout as logoutRequest } from "../api/logout"
import { OnboardingContext } from "../context/onboarding/OnboardingContext"
import { OnboardingStackParamList } from "../types"
import { QueryParamContext } from "../context/queryParam/QueryParamContext"
import { SET_ONBOARDING_STATE, SET_USER_STATE } from "../context/actionTypes"
import { setExperimentLanguage } from "../utils/setExperimentLanguage"
import { SplitFactory } from "@splitsoftware/splitio-react"
import { useAuthenticatedUserQuery } from "../hooks/useAuthenticatedUser"
import { useLogout } from "../hooks/useLogout"
import { useOnboardingInit } from "../hooks/useOnboardingInit"
import { useSurveyQuery } from "../hooks/useSurveyQuery"
import { useUserContext } from "../context/user/UserContext"
import {
	DefaultTheme,
	NavigationContainer,
	useNavigationContainerRef,
} from "@react-navigation/native"
import {
	addressDeliverabilityAPI,
	annualSalaryRangesAPI,
	authAPI,
	cardAPI,
	directDepositAPI,
	experimentAPI,
	industryOptionsAPI,
	loginAPI,
	onboardingAPI,
	otpLoginAPI,
	passwordCheckAPI,
	staticConfigAPI,
	surveyAPI,
	userAPI,
	validateEmailAPI,
	validatePasswordAPI,
	wageAdvanceAPI,
} from "../api"

export let echo: Echo | null = null

export default function Navigation({ colorScheme }: { colorScheme: ColorSchemeName }) {
	const navigationRef = useNavigationContainerRef<OnboardingStackParamList>()
	const routeNameRef = useRef<keyof OnboardingStackParamList | undefined>()
	const [apiReady, setApiReady] = useState(false)
	const [languageReady, setLanguageReady] = useState(false)
	const [linkingConfig, setLinkingConfig] = useState<LinkingOptions<{}> | null>(null)
	const {
		data: authData,
		isStale: isAuthStale,
		isLoading: isAuthLoading,
		isIdle: isAuthIdle,
		isError: isAuthError,
	} = useAuthenticatedUserQuery()
	const { userData, metaData } = authData || {}
	const timerRef = useRef<number>()
	const { dispatch: onboardingDispatch, isPhoneVerified } = useContext(OnboardingContext)
	const { accessToken, rememberUser, dispatch: userDispatch } = useUserContext()
	const { businessId, src, employeeId, widgetId, inSdk, clairEmbed } = useContext(QueryParamContext)
	const { source } = useAnalytics()
	const logout = useLogout()

	// initialize queries to improve perceived performance on screen load
	useSpendingAccount(apiReady)
	useSurveyQuery(apiReady)

	const {
		data: onboardingData,
		isFetched: onboardingFetched,
		isError: isOnboardingError,
		isFetching: isOnboardingFetching,
	} = useOnboardingInit()

	const CustomTheme = {
		dark: false,
		colors: {
			...DefaultTheme.colors,
			background: "transparent",
		},
	}
	// Add callback that clears the inactivity timeout on each touch
	const panResponder = PanResponder.create({
		onStartShouldSetPanResponderCapture: () => {
			handleActivity()
			return false // Don't claim the touch/clicks from children to this view. If we did, we'd loose all button presses
		},
	})
	const panResponderRef = panResponder

	// Every time there's an activity, clear the timeout and set a new one
	function handleActivity() {
		clearTimeout(timerRef.current)
		timerRef.current = window.setTimeout(async () => {
			// Reset a user's state and navigation for non-logged in users or non remember me, logged in users.
			if (!accessToken || (accessToken && !rememberUser)) {
				if (accessToken) {
					if (navigationRef) {
						await logout(navigationRef)
					} else {
						await logoutRequest()
						window.location.href = "/Login"
					}
				} else {
					Boolean(navigationRef)
						? navigationRef?.reset({ routes: [{ name: "Splash" }] })
						: (window.location.href = "/Splash")
				}
				// Ensure that both context's are reset, regardless of navigation or logged in status
				userDispatch({ type: SET_USER_STATE, payload: INITIAL_USER_STATE })
				onboardingDispatch({ type: SET_ONBOARDING_STATE, payload: INITIAL_ONBOARDING_STATE })
			}
		}, HALF_HOUR)
	}

	useEffect(() => {
		if (source && !__DEV__) {
			// Shows browser warning before refreshing https://developer.mozilla.org/en-US/docs/Web/API/WindowEventHandlers/onbeforeunload#example
			window.addEventListener("beforeunload", function (e) {
				// Cancel the event
				e.preventDefault() // If you prevent default behavior in Mozilla Firefox prompt will always be shown
				// Chrome requires returnValue to be set
				e.returnValue = ""
				analytics.track("exit_widget", { web_or_app: source })
			})
		}
	}, [source])

	useEffect(() => {
		handleActivity()
	}, [accessToken, rememberUser])

	useEffect(() => {
		if (accessToken)
			echo = new Echo({
				broadcaster: "pusher",
				key: variables.pusherKey,
				cluster: "mt1",
				authEndpoint: `${variables.apiUrl}broadcasting/auth`,
				auth: {
					headers: {
						Authorization: `Bearer ${accessToken}`,
						Accept: "application/json",
					},
				},
			})
	}, [accessToken])

	useEffect(() => {
		if (apiReady) setExperimentLanguage(setLanguageReady)
	}, [apiReady])

	useEffect(() => {
		if (navigationRef.isReady()) {
			const prePhoneVerifyRoutes: Array<keyof OnboardingStackParamList> = [
				"WelcomeCarousel",
				"MobileNumber",
				"VerifyNumber",
			]

			const currentRoute = navigationRef?.getCurrentRoute()?.name as keyof OnboardingStackParamList

			if (
				navigationRef &&
				!isPhoneVerified &&
				!prePhoneVerifyRoutes.includes(currentRoute) &&
				onboardingData?.status !== "Complete"
			) {
				navigationRef.reset({ routes: [{ name: "Splash" }] })
			}
		}
	}, [navigationRef, isPhoneVerified, onboardingData?.status])
	useEffect(() => {
		if (source) {
			const APIS = [
				{ instance: loginAPI },
				{ instance: authAPI },
				{ instance: userAPI },
				{ instance: onboardingAPI },
				{ instance: directDepositAPI },
				{ instance: cardAPI },
				{ instance: surveyAPI },
				{ instance: experimentAPI },
				{ instance: wageAdvanceAPI, errorRoute: "PDWGeneralError" },
				{ instance: addressDeliverabilityAPI },
				{ instance: annualSalaryRangesAPI },
				{ instance: industryOptionsAPI },
				{ instance: validatePasswordAPI },
				{ instance: validateEmailAPI },
				{ instance: staticConfigAPI },
				{ instance: otpLoginAPI },
				{ instance: passwordCheckAPI },
			]

			APIS.forEach((api) => {
				api.instance.defaults.headers["X-CLAIR-WIDGET-SRC"] = src
				api.instance.defaults.headers["X-CLAIR-WIDGET-ID"] = widgetId
				api.instance.defaults.headers["X-CLAIR-SEGMENT-SOURCE"] = source
				api.instance.interceptors.response.use(
					(response) => response,
					(error) => {
						const isMaintenanceMode = error?.response?.status === 503
						const isServerError = error?.response?.status >= 500 && error?.response?.status !== 503
						const route = isMaintenanceMode ? "MaintenanceMode" : api?.errorRoute || "GeneralError"
						const currentRouteName = navigationRef?.getCurrentRoute()?.name
						if ((isServerError || isMaintenanceMode) && route !== currentRouteName) {
							navigationRef?.reset({
								routes: [{ name: route }],
							})
						}
						throw error
					}
				)
			})

			onboardingAPI.defaults.baseURL = `${variables.apiUrl}widget/registration/v1/business/${businessId}/employee/${employeeId}`
			directDepositAPI.defaults.baseURL = `${variables.apiUrl}widget/direct-deposit/v1/business/${businessId}/employee/${employeeId}`
			wageAdvanceAPI.defaults.baseURL = `${variables.apiUrl}widget/wage-advance/v1/business/${businessId}/employee/${employeeId}`
			cardAPI.defaults.baseURL = `${variables.apiUrl}widget/card/v1/business/${businessId}/employee/${employeeId}`
			userAPI.defaults.baseURL = `${variables.apiUrl}widget/auth/v1/business/${businessId}/employee/${employeeId}/user`
			surveyAPI.defaults.baseURL = `${variables.apiUrl}widget/survey/v1/business/${businessId}/employee/${employeeId}`
			experimentAPI.defaults.baseURL = `${variables.apiUrl}widget/experiment/v1/business/${businessId}/employee/${employeeId}`
			otpLoginAPI.defaults.baseURL = `${variables.apiUrl}widget/auth/v1/business/${businessId}/employee/${employeeId}/otp-login`
			passwordCheckAPI.defaults.baseURL = `${variables.apiUrl}widget/auth/v1/business/${businessId}/employee/${employeeId}/password-check`

			onboardingDispatch({
				type: SET_ONBOARDING_STATE,
				payload: {
					baseUrl: onboardingAPI.defaults.baseURL,
				},
			})
			const AUTH_APIS = [
				authAPI,
				userAPI,
				directDepositAPI,
				cardAPI,
				wageAdvanceAPI,
				passwordCheckAPI,
			]

			if (accessToken) {
				AUTH_APIS.forEach((api) => {
					api.defaults.headers["Authorization"] = `Bearer ${accessToken}`
				})
			} else {
				AUTH_APIS.forEach((api) => delete api.defaults.headers.common["Authorization"])
			}

			setApiReady(true)
		}
	}, [src, businessId, employeeId, accessToken, widgetId, source])
	// Dynamically set the linking config based off of user state, stage, and authentication.
	useEffect(() => {
		if (onboardingFetched) {
			if (accessToken && !isAuthStale && !isAuthIdle && metaData?.stage) {
				userDispatch({
					type: SET_USER_STATE,
					payload: _.pick({ ...userData, ...metaData }, Object.keys(INITIAL_USER_STATE)),
				})
				setLinkingConfig(
					getLinkingConfig(
						metaData?.stage,
						undefined,
						true,
						// @ts-ignore
						onboardingData?.ewaEnabled,
						onboardingData?.hideCarousel
					)
				)
			} else if (isAuthError || (!isAuthLoading && !accessToken)) {
				setLinkingConfig(
					getLinkingConfig(
						null,
						onboardingData?.status,
						false,
						// @ts-ignore
						onboardingData?.ewaEnabled,
						onboardingData?.hideCarousel
					)
				)
			}
		}

		if (isOnboardingError && !isOnboardingFetching && !onboardingFetched)
			setLinkingConfig(getLinkingConfig()) // Returns error state linking config
	}, [
		metaData?.stage,
		onboardingData?.ewaEnabled,
		onboardingData?.status,
		onboardingData?.hideCarousel,
		isOnboardingError,
		isOnboardingFetching,
		accessToken,
		isAuthStale,
		isAuthIdle,
		isAuthError,
		isAuthLoading,
	])

	return apiReady && languageReady && linkingConfig && panResponderRef.panHandlers ? (
		<View style={{ flex: 1, paddingTop: 1 }} {...panResponderRef.panHandlers}>
			<SplitFactory
				config={{
					core: {
						authorizationKey: variables.splitKey,
						key: onboardingData?.userId,
					},
				}}
			>
				<NavigationContainer
					ref={navigationRef}
					onReady={() => {
						routeNameRef.current = navigationRef?.getCurrentRoute()
							?.name as keyof OnboardingStackParamList
					}}
					linking={linkingConfig}
					theme={colorScheme === "dark" ? CustomTheme : CustomTheme} // no specs from product on dark mode yet
					onStateChange={async (state) => {
						const previousRouteName = routeNameRef.current || "N/A"
						// @ts-ignore
						const currentRouteName = navigationRef?.getCurrentRoute()
							.name as keyof OnboardingStackParamList
						const currentParams = navigationRef?.getCurrentRoute()?.params
						routeNameRef.current = currentRouteName
						if (previousRouteName !== currentRouteName) {
							let widgetSource
							if (inSdk) {
								widgetSource = clairEmbed ? "self_embedded" : "embedded"
							} else {
								widgetSource =
									Device.osName == "iOS" || Device.osName == "Android"
										? "mobile_browser"
										: "desktop_browser"
							}
							analytics.page(currentRouteName, {
								previousPage: previousRouteName,
								web_or_app: widgetSource,
								params: currentParams,
							})
						}
						routeNameRef.current = currentRouteName
					}}
				>
					<OnboardingNavigator navigationRef={navigationRef} />
				</NavigationContainer>
			</SplitFactory>
		</View>
	) : (
		<Loading showLogo />
	)
}
