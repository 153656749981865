import axios from "axios"
import Constants from "expo-constants"
import variables from "../utils/variables"
import { deviceYearClass } from "expo-device"
import { nativeApplicationVersion, nativeBuildVersion } from "expo-application"
const headers = {
	common: {
		Authorization: null,
		"x-device-name": Constants.deviceName,
		"x-device-year-class": deviceYearClass,
		"x-native-app-version": nativeApplicationVersion,
		"x-native-build-version": nativeBuildVersion,
	},
}

export const directDepositAPI = axios.create({
	headers,
})

export const loginAPI = axios.create({
	baseURL: `${variables.apiUrl}oauth/token`,
})

export const authAPI = axios.create({
	baseURL: `${variables.apiUrl}widget/auth/v1/`,
	headers,
})

export const addressDeliverabilityAPI = axios.create({
	baseURL: `${variables.apiUrl}api/v2/address-verification`,
})
export const annualSalaryRangesAPI = axios.create({
	baseURL: `${variables.apiUrl}api/v2/option/annual-salary-range`,
})
export const industryOptionsAPI = axios.create({
	baseURL: `${variables.apiUrl}api/v2/option/industry`,
})
export const validatePasswordAPI = axios.create({
	baseURL: `${variables.apiUrl}api/v2/password-eligibility`,
})
export const validateEmailAPI = axios.create({
	baseURL: `${variables.apiUrl}mailgun/validate`,
})

export const staticConfigAPI = axios.create({
	baseURL: `${variables.apiUrl}api/v2/app-config`,
})

export const userAPI = axios.create()
export const onboardingAPI = axios.create()
export const wageAdvanceAPI = axios.create()
export const cardAPI = axios.create()
export const surveyAPI = axios.create()
export const experimentAPI = axios.create()
export const otpLoginAPI = axios.create()
export const passwordCheckAPI = axios.create()
