import _ from "lodash"
import I18n from "i18n-js"
import React, { useContext } from "react"
import { Button, SecondaryButton } from "../../components/Button"
import { FeatureImage } from "../../components/FeatureImage"
import { FlexView } from "../../components/FlexView"
import { Headline, MetroText } from "../../components/StyledText"
import { OnboardingStackParamList } from "../../types"
import { QueryParamContext } from "../../context/queryParam/QueryParamContext"
import { ScreenWrapper } from "../../components/ScreenWrapper"
import { StackScreenProps } from "@react-navigation/stack"
import { useBroadcastEvent } from "../../hooks/useBroadcastEvent"

export default function PaydayWalletDisabledBeforeKYCPass({
	navigation,
}: StackScreenProps<OnboardingStackParamList, "PaydayWalletDisabledAfterKYCPass">) {
	const { inSdk } = useContext(QueryParamContext)
	const broadcastEvent = useBroadcastEvent()
	return (
		<ScreenWrapper center>
			<FeatureImage
				medium
				style={{ width: 200 }}
				source={require("../../assets/images/kyc_disabled.png")}
			/>
			<Headline center style={{ marginBottom: 28 }}>
				{I18n.t("PDWDisabledAfterKYCPass.PageHeader")}
			</Headline>
			<MetroText center style={{ marginBottom: 29 }}>
				{I18n.t("PDWDisabledAfterKYCPass.Body")}
			</MetroText>
			<FlexView />
			<Button
				title={I18n.t("PDWDisabledAfterKYCPass.Button1")}
				onPress={() => navigation.goBack()}
			/>
			{inSdk && (
				<SecondaryButton
					title={I18n.t("PDWDisabledAfterKYCPass.Button2")}
					onPress={() =>
						broadcastEvent({
							event: "clair-onboarding-close",
							payload: { reason: "Payday Wallet Disabled - after KYC" },
						})
					}
				/>
			)}
		</ScreenWrapper>
	)
}
