import { getStaticConfig } from "./../api/getStaticConfig"
import { useQuery } from "react-query"

type PDFConfig = {
	title: string
	url: string
}

type StaticConfigQuery = {
	support_email: string
	support_phone_number: string
	support_sms_number: string
	support_sms_enabled: boolean
	friendly_support_phone_number: string
	friendly_support_phone_number_numeric: string
	friendly_support_sms_number: string
	screen_time_value: number
	pdf: {
		e_sign: PDFConfig
		meta_bank_deposit_agreement: PDFConfig
		mobile_terms_of_use: PDFConfig
		privacy_notice: PDFConfig
		privacy_policy: PDFConfig
		savings_agreement: PDFConfig
		terms_of_use: PDFConfig
	}
	segment: {
		ios_write_key: string
		android_write_key: string
	}
	smarty_streets: {
		embedded_key: string
	}
}

export const useStaticConfig = (refetchInterval?: number | false | (() => number | false)) => {
	return useQuery<StaticConfigQuery>("staticConfig", () => getStaticConfig(), {
		refetchInterval,
	})
}
