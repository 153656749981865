import { height } from "../App"
import { useEffect, useState } from "react"
import { useIs19by9 } from "./useIs19by9"
import { useIsNonMobileScreen } from "./useIsNonMobileScreen"
import { useWindowDimensions } from "react-native"

export const useCalculatedFrameDimensions = () => {
	const isNonMobile = useIsNonMobileScreen()
	const windowHeight = useWindowDimensions().height
	const is19by9 = useIs19by9()
	const flippedAspectRatio = is19by9 ? 9 / 19 : 9 / 16
	const calculateDesktopHeight = () => windowHeight / 1.2
	const calculateHeight = () => (isNonMobile ? calculateDesktopHeight() : height)
	const calculateWidth = () => calculateHeight() * flippedAspectRatio
	const [calculatedFrameDimensions, setCalculatedFrameDimensions] = useState({
		height: calculateHeight(),
		width: calculateWidth(),
		maxWidth: 500,
	})

	useEffect(() => {
		if (isNonMobile) {
			const width = calculateWidth()
			setCalculatedFrameDimensions({
				height: calculateHeight(),
				width,
				maxWidth: 500,
			})
		}
	}, [windowHeight])

	return calculatedFrameDimensions
}
