import Colors from "../../../constants/Colors"
import GestureRecognizer from "react-native-swipe-gestures"
import I18n from "i18n-js"
import Loading from "../../../components/Loading"
import React, { ReactElement, useEffect, useLayoutEffect, useRef, useState } from "react"
import useAnalytics from "../../../hooks/useAnalytics"
import useColorScheme from "../../../hooks/useColorScheme"
import { Button, LegalText, MetroText, SecondaryButton } from "@getclair/react-native-storybook"
import { DesktopChevrons } from "./DesktopChevrons"
import { FeatureImageProto } from "../../../components/FeatureImageProto"
import { FlatList, ImageSourcePropType, TouchableOpacity, View, ViewToken } from "react-native"
import { HeaderBackButton, useHeaderHeight } from "@react-navigation/elements"
import { isNumber } from "lodash"
import { OnboardingStackParamList } from "../../../types"
import { ScreenWrapper } from "../../../components/ScreenWrapper"
import { StackScreenProps } from "@react-navigation/stack"
import { urls } from "../../../constants/urls"
import { useCalculatedFrameDimensions } from "../../../hooks/useCalculatedFrameDimensions"
import { useInnerContentWidth } from "../../../hooks/useInnerContentWidth"
import { useIsNonMobileScreen } from "../../../hooks/useIsNonMobileScreen"
import { useIsVisibleScreenShort } from "../../../hooks/useIsVisibleScreenShort"
import { useOpenInternalUrl } from "../../../hooks/useOpenInternalUrl"
import { useSignupNavigation } from "../../../hooks/useSignupNavigation"

export type Slide = {
	key: number
	image: ImageSourcePropType
	title: string
	description?: string
	legalText: string
	button1Text: string
	button2Text?: string
	button2Link?: string
	button2TestId?: string
	button2TackingId?: string
	backgroundColor: string
	trackingId: string
}

export default function WelcomeCarousel({
	navigation,
}: StackScreenProps<OnboardingStackParamList, "WelcomeCarousel">): ReactElement {
	const { navigateToNextSignupScreen } = useSignupNavigation()
	const { track } = useAnalytics()
	const flatListRef = useRef<FlatList>(null)
	const isShortScreen = useIsVisibleScreenShort()
	const [slides, setSlides] = useState<Slide[]>([])
	const isNonMobileScreen = useIsNonMobileScreen()
	const [currentIndex, setCurrentIndex] = useState(0)
	const theme = useColorScheme()
	const headerHeight = useHeaderHeight()
	const { width, height, maxWidth } = useCalculatedFrameDimensions()
	const innerContentWidth = useInnerContentWidth()
	const { openInternalUrl } = useOpenInternalUrl()
	// Must create a ref to handle FlatList's onViewableItemsChanged to avoid changing on the fly  (is not supported)
	const onViewableItemsChangedRef = useRef(
		({ viewableItems }: { viewableItems: ViewToken[] }) =>
			viewableItems.length &&
			isNumber(viewableItems[0]?.index) &&
			setCurrentIndex(viewableItems[0]?.index)
	)

	useEffect(() => {
		setSlides(getSlides())
	}, [])

	useLayoutEffect(() => {
		if (currentIndex && !isNonMobileScreen) {
			navigation.setOptions({
				headerLeft: () => (
					<HeaderBackButton
						onPress={() => {
							flatListRef?.current?.scrollToIndex({
								index: currentIndex - 1,
							})
							track(`tap_back_${currentIndex == 2 ? "three" : "two"}`)
						}}
					/>
				),
			})
		} else {
			navigation.setOptions({
				headerLeft: () => null,
			})
		}
	}, [currentIndex])

	const _renderItem = ({ item, index }: { item: Slide; index: number }) => {
		const slideHeight = window.innerHeight - headerHeight
		return (
			<View
				style={[
					{
						flex: 1,
						alignItems: "center",
						minHeight: !isNonMobileScreen ? slideHeight : height - 10,
						minWidth: !isNonMobileScreen ? window.innerWidth : maxWidth,
					},
				]}
			>
				<View style={{ flex: 0.18 }} />
				<FeatureImageProto
					source={item.image}
					style={{
						flex: isShortScreen ? 0.4 : 0.5,
						marginTop: isShortScreen ? 45 : 35,
						maxHeight: 200,
					}}
				/>
				<DesktopChevrons
					currentIndex={currentIndex}
					flatListRef={flatListRef}
					slides={slides}
					index={index}
				/>
				<MetroText
					h2
					center
					style={{
						maxWidth: innerContentWidth,
						marginBottom: 24,
					}}
					testID={`welcomeHeadline${item.key}`}
				>
					{item.title}
				</MetroText>

				<View
					style={{
						flex: 1,
						position: "absolute",
						bottom: 70,
						justifyContent: "center",
						alignItems: "center",
						width: maxWidth,
					}}
				>
					<View
						style={{
							height: 16,
							marginBottom: 15,
							flexDirection: "row",
							justifyContent: "center",
							alignItems: "center",
						}}
					>
						{slides.length > 1 &&
							slides.map((_, i) => (
								<TouchableOpacity
									key={i}
									style={[
										{
											backgroundColor: Colors[theme]["backgroundButtonDefault"],
											width: 8,
											height: 8,
											borderRadius: 5,
											marginHorizontal: 4,
											justifyContent: "center",
											alignItems: "center",
										},
										i === currentIndex
											? {
													width: 11,
													height: 11,
													borderRadius: 50,
											  }
											: null,
									]}
									onPress={() => {
										if (currentIndex > i) track(`tap_back_${currentIndex == 2 ? "three" : "two"}`)
										else if (currentIndex !== slides.length - 1)
											track(slides[i ? i - 1 : 0].trackingId)
										flatListRef?.current?.scrollToIndex({
											index: i,
										})
									}}
								>
									{i === currentIndex && (
										<View
											style={{
												justifyContent: "center",
												alignItems: "center",
												alignSelf: "center",
												backgroundColor: Colors[theme]["background"],
												width: 5,
												height: 5,
												borderRadius: 50,
											}}
										/>
									)}
								</TouchableOpacity>
							))}
					</View>

					{item.button2Text ? (
						<View style={{ flexDirection: "row", justifyContent: "center" }}>
							<SecondaryButton
								title={item.button1Text}
								testID={item.button2TestId}
								buttonStyle={{ margin: 0 }}
								containerStyle={{
									marginTop: 0,
									marginVertical: 0,
									marginRight: 8,
									width: "auto",
								}}
								titleStyle={{ textAlign: "left" }}
								onPress={async () => {
									if (item.button2TackingId) await track(item.button2TackingId)
									if (item.button2Link) openInternalUrl(item.button2Link, navigation)
								}}
							/>
							<Button
								title={item.button2Text}
								buttonStyle={{
									maxWidth: innerContentWidth / 2,

									borderWidth: 1,
									borderColor: Colors[theme]["backgroundButtonDefault"],
								}}
								containerStyle={{ width: "auto" }}
								testID={`welcomeButton${currentIndex}`}
								onPress={() => {
									if (currentIndex === slides.length - 1) {
										track(slides[slides.length - 1].trackingId)
										navigateToNextSignupScreen()
									} else {
										track(slides[index].trackingId)
										flatListRef?.current?.scrollToIndex({
											index: currentIndex + 1,
										})
										setCurrentIndex(currentIndex + 1)
									}
								}}
							/>
						</View>
					) : (
						<Button
							title={item.button1Text}
							testID={`welcomeButton${currentIndex}`}
							buttonStyle={{ minWidth: innerContentWidth, alignSelf: "center" }}
							onPress={() => {
								if (currentIndex === slides.length - 1) {
									track(slides[slides.length - 1].trackingId)
									navigateToNextSignupScreen()
								} else {
									track(slides[index].trackingId)
									flatListRef?.current?.scrollToIndex({
										index: currentIndex + 1,
									})
									setCurrentIndex(currentIndex + 1)
								}
							}}
						/>
					)}
					<View
						style={{
							flex: 1,
							position: "absolute",
							top: 98,
							justifyContent: "center",
							alignItems: "center",
							width: width,
						}}
					>
						<LegalText style={{ marginHorizontal: 10 }}>{item.legalText}</LegalText>
					</View>
				</View>
			</View>
		)
	}

	return slides ? (
		<ScreenWrapper
			center
			nonMobileContentStyle={{
				alignItems: "stretch",
				paddingTop: 0,
				paddingBottom: 0,
				paddingHorizontal: 0,
				paddingVertical: 0,
				maxHeight: !isShortScreen ? height : "none",
			}}
			contentContainerStyle={{
				alignItems: "stretch",
				paddingTop: 0,
				paddingBottom: 0,
				paddingHorizontal: 0,
				paddingVertical: 0,
			}}
		>
			<GestureRecognizer
				onSwipeRight={() => {
					if (currentIndex !== 0) {
						flatListRef?.current?.scrollToIndex({
							index: currentIndex - 1,
						})
						track(`tap_back_${currentIndex == 2 ? "three" : "two"}`)
						setCurrentIndex(currentIndex - 1)
					}
				}}
				onSwipeLeft={() => {
					if (currentIndex !== slides.length - 1) {
						flatListRef?.current?.scrollToIndex({
							index: currentIndex + 1,
						})
						track(slides[currentIndex].trackingId)
						setCurrentIndex(currentIndex + 1)
					}
				}}
			>
				<View
					style={{
						width: !isNonMobileScreen ? window.innerWidth : maxWidth,
						overflow: "hidden",
					}}
				>
					<FlatList
						horizontal
						pagingEnabled
						data={slides}
						ref={flatListRef}
						renderItem={_renderItem}
						keyExtractor={(_, index) => index.toString()}
						scrollEnabled={false}
						showsHorizontalScrollIndicator={false}
						viewabilityConfig={{
							itemVisiblePercentThreshold: 50,
						}}
						onViewableItemsChanged={onViewableItemsChangedRef.current}
						getItemLayout={(_, index) => ({
							length: !isNonMobileScreen ? window.innerWidth : maxWidth,
							offset: (!isNonMobileScreen ? window.innerWidth : maxWidth) * index,
							index,
						})}
					/>
				</View>
			</GestureRecognizer>
		</ScreenWrapper>
	) : (
		<Loading />
	)
}

const getSlides = (): Slide[] => [
	{
		key: 0,
		title: I18n.t("WelcomeCarousel.Slide1.Title"),
		description: I18n.t("WelcomeCarousel.Slide1.Description"),
		image: require("../../../assets/images/money_float_clock.png"),
		button1Text: I18n.t("WelcomeCarousel.Slide1.Button1Text"),
		legalText: I18n.t("IssuerStatement"),
		trackingId: "tap_next_carouselone",
		backgroundColor: "#fff",
	},
	{
		key: 1,
		title: I18n.t("WelcomeCarousel.Slide2.Title"),
		description: I18n.t("WelcomeCarousel.Slide2.Description"),
		image: require("../../../assets/images/clair_phone_with_cash.png"),
		button1Text: I18n.t("WelcomeCarousel.Slide2.Button1Text"),
		legalText: I18n.t("BankingServicesFooter"),
		trackingId: "tap_next_carouseltwo",
		backgroundColor: "#fff",
	},
	{
		key: 2,
		title: I18n.t("WelcomeCarousel.Slide3.Title"),
		description: I18n.t("WelcomeCarousel.Slide3.Description"),
		image: require("../../../assets/images/clair_card_in_hand.png"),
		button1Text: I18n.t("WelcomeCarousel.Slide3.Button1Text"),
		button2Text: I18n.t("WelcomeCarousel.Slide3.Button2Text"),
		button2Link: urls.howClairWorks,
		button2TestId: "welcome.learnMore",
		button2TackingId: "tap_learnmore_carousel",
		backgroundColor: "#fff",
		trackingId: "start_funnel",
		legalText: I18n.t("BankingServicesFooter"),
	},
]
