import _ from "lodash"
import Base64 from "Base64"
import Colors from "../../../constants/Colors"
import I18n from "i18n-js"
import React from "react"
import useColorScheme from "../../../hooks/useColorScheme"
import useDirectDepositStatus from "../../../hooks/useDirectDepositStatus"
import variables from "../../../utils/variables"
import { AtomicPayload, OnboardingStackParamList } from "../../../types"
import { AutoHeightImage } from "../../../components/AutoHeightImage"
import { Bold, Headline, LegalText, MetroText, P3 } from "../../../components/StyledText"
import { Button } from "../../../components/Button"
import { FAQsLink } from "./DirectDepositFAQLink"
import { FlexView } from "../../../components/FlexView"
import { ImageBullet } from "../../../components/ImageBullet"
import { OnboardingProgressBar } from "../../../components/OnboardingProgressBar"
import { ScreenWrapper } from "../../../components/ScreenWrapper"
import { StackScreenProps } from "@react-navigation/stack"
import { urls } from "../../../constants/urls"
import { useAuthenticatedUserQuery } from "../../../hooks/useAuthenticatedUser"
import { useUserContext } from "../../../context/user/UserContext"
import { View } from "react-native"

export default function AtomicWelcome({
	navigation,
}: StackScreenProps<OnboardingStackParamList, "AtomicWelcome">) {
	const { data } = useDirectDepositStatus()
	const { companyId, connectorId, token = "", metadata, payrollProvider } = data || {}
	const { business, distributionType, distributionValue } = useUserContext()
	const { data: userData } = useAuthenticatedUserQuery()
	const { businesses } = userData?.userData || {}
	const employer = businesses[0].name || I18n.t("AtomicWelcome.Employer")
	const payrollProviderName = payrollProvider?.name || I18n.t("AtomicWelcome.PayrollProvider")
	const theme = useColorScheme()

	// id can come in as the string null
	const hasKnownPayrollProvider = companyId && companyId !== "null"

	const triggerTransact = () => {
		const payload: AtomicPayload = {
			publicToken: token,
			inSdk: true,
			handoff: ["exit-prompt", "authentication-success", "high-latency"],
			theme: { brandColor: Colors[theme]["primary"] },
			metadata,
			tasks: [
				{
					product: "deposit",
					distribution: {
						type: distributionValue
							? distributionType === "PERCENTAGE"
								? "percent"
								: "fixed"
							: "total",
						amount: Number(distributionValue),
						canUpdate: true,
					},
				},
			],
		}
		if (connectorId && connectorId !== "null") {
			// id can come in as the string null
			_.assign(payload, {
				deeplink: {
					step: "login-payroll",
					connectorId,
					companyName: business?.name || "unavailable",
				},
			})
		} else if (hasKnownPayrollProvider) {
			_.assign(payload, {
				deeplink: {
					step: "login-company",
					companyId,
				},
			})
		} else {
			_.assign(payload, {
				deeplink: {
					step: "search-company",
				},
				search: {
					tags: ["payroll-provider"],
				},
			})
		}
		const encodedPayload = Base64.btoa(JSON.stringify(payload))
		navigation.navigate({
			name: "AtomicWebView",
			params: {
				uri: `https://${
					variables.environment == "production" ? "transact" : "transact-sandbox"
				}.atomicfi.com/initialize/${encodedPayload}`,
			},
		})
	}

	return (
		<ScreenWrapper center noPadding>
			<View style={{ paddingHorizontal: 24, paddingTop: 18 }}>
				<OnboardingProgressBar section={1} position={2} />
				<Headline smallText testID="atomicWelcomeHeadline">
					{I18n.t("AtomicWelcome.PageHeader", { payrollProviderName })}
				</Headline>
				<MetroText h4 style={{ fontSize: 14, marginBottom: 24 }}>
					{I18n.t("AtomicWelcome.Body1")}
					<MetroText
						style={{
							fontSize: 14,
							fontFamily: payrollProvider?.name ? "metropolis-semi" : "metropolis-regular",
						}}
					>
						{I18n.t("AtomicWelcome.Body2", { payrollProviderName })}
					</MetroText>
					{payrollProvider?.name && I18n.t("AtomicWelcome.Body3")}
					{I18n.t(
						payrollProvider?.name ? "AtomicWelcome.Body4" : "AtomicWelcome.Body4UnknownProvider",
						{
							employer,
						}
					)}
				</MetroText>
			</View>
			{payrollProvider?.name ? (
				<>
					<View
						style={{
							borderBottomColor: Colors[theme]["borderDDBox"],
							borderBottomWidth: 3,
							width: "100%",
						}}
					/>
					<View
						style={{
							flexDirection: "row",
							alignItems: "center",
							paddingVertical: 42,
						}}
					>
						<View style={{ padding: 10 }}>
							<AutoHeightImage source={require("../../../assets/images/logo.png")} width={85} />
						</View>
						<AutoHeightImage
							source={require("../../../assets/images/arrows.png")}
							width={45}
							style={{ marginHorizontal: 20, marginVertical: 5 }}
						/>
						{payrollProvider && payrollProvider.logo ? (
							<AutoHeightImage source={payrollProvider.logo} width={110} />
						) : (
							<MetroText center style={{ fontSize: 20, minWidth: 85, maxWidth: 115 }}>
								{payrollProvider?.name}
							</MetroText>
						)}
					</View>
					<View
						style={{
							borderBottomColor: Colors[theme]["borderDDBox"],
							borderBottomWidth: 3,
							width: "100%",
						}}
					/>
				</>
			) : (
				<View style={{ paddingHorizontal: 24 }}>
					<View
						style={{
							backgroundColor: Colors[theme]["backgroundSecondary"],
							padding: 20,
							borderRadius: 8,
							marginBottom: 25,
						}}
					>
						<P3 style={{ color: Colors[theme]["text"] }}>
							<Bold style={{ fontSize: 13, lineHeight: 16 }}>
								{I18n.t("AtomicWelcome.BodyUnknownProvider1")}
							</Bold>
							{I18n.t("AtomicWelcome.BodyUnknownProvider2")}
						</P3>
					</View>
					<AutoHeightImage
						style={{ alignSelf: "center" }}
						width={279}
						source={require("../../../assets/images/payroll_icons.png")}
					/>
				</View>
			)}
			<FlexView />
			<View
				style={{
					paddingHorizontal: 24,
					paddingBottom: 45,
					alignItems: "center",
				}}
			>
				<ImageBullet
					text={I18n.t("AtomicWelcome.Footer")}
					image={require(`../../../assets/images/shield_icon.png`)}
					imageSize={35}
					style={{ marginBottom: 18 }}
					textStyle={{ fontSize: 14, fontFamily: "metropolis-semi" }}
				/>
				<Button
					title={I18n.t("AtomicWelcome.Button")}
					onPress={triggerTransact}
					testID="atomicWelcomeLogIn"
				/>
				{!payrollProvider?.name && (
					<FAQsLink
						url={urls.atomicWelcomeFAQUrl}
						eventName="tap_FAQs_AtomicWelcome"
						style={{ marginVertical: 15 }}
					/>
				)}

				<LegalText>{I18n.t("BankingServicesFooter")}</LegalText>
			</View>
		</ScreenWrapper>
	)
}
