import Colors from "../../../constants/Colors"
import I18n from "i18n-js"
import React, { useEffect, useState } from "react"
import useAnalytics from "../../../hooks/useAnalytics"
import useColorScheme from "../../../hooks/useColorScheme"
import useDirectDepositStatus from "../../../hooks/useDirectDepositStatus"
import {
	Button,
	MetroText,
	P3,
	TertiaryButton
	} from "@getclair/react-native-storybook"
import { OnboardingStackParamList } from "../../../types"
import { Overlay } from "../../../components/Overlay"
import { ScreenWrapper } from "../../../components/ScreenWrapper"
import { StackScreenProps } from "@react-navigation/stack"
import { useCalculatedFrameDimensions } from "../../../hooks/useCalculatedFrameDimensions"
import { useOpenUrl } from "../../../hooks/useOpenUrl"
import { useQueryClient } from "react-query"
import { View } from "react-native"
import { WebView } from "react-native-web-webview"

export default function AtomicWebView({
	navigation,
	route,
}: StackScreenProps<OnboardingStackParamList, "AtomicWebView">) {
	const { uri } = route.params
	const [showExit, setShowExit] = useState(false)
	const queryClient = useQueryClient()
	const { openUrl } = useOpenUrl()
	const { data: ddStatusData } = useDirectDepositStatus()
	const { track } = useAnalytics()
	const theme = useColorScheme()
	const { height } = useCalculatedFrameDimensions()

	useEffect(() => {
		return () => {
			queryClient.invalidateQueries("directDeposit")
		}
	}, [])

	const receiveEvent = (originalEvent) => {
		console.log("original event", originalEvent)

		// Deposit will broadcast three types of events:
		// 1) 'atomic-transact-close' : Broadcast when exiting the app
		// 2) 'atomic-transact-finish' : Broadcast when the user finished the transaction
		// 3) 'atomic-transact-open-url' : Broadcast when an external url is being triggered
		const data = originalEvent.data
		const { event: name, payload } = data
		switch (name) {
			case "atomic-transact-close":
				if (payload.handoff == "high-latency") {
					queryClient.setQueryData("directDeposit", {
						...ddStatusData,
						status: "IN_PROGRESS",
					})
					navigation.reset({
						routes: [{ name: "PendingText" }],
					})
				} else {
					track("open_atomicexit")
					setShowExit(true)
				}
				break
			case "atomic-transact-finish":
				queryClient.setQueryData("directDeposit", {
					...ddStatusData,
					status: "IN_PROGRESS",
				})
				navigation.reset({
					routes: [{ name: "PendingText" }],
				})
				break
			case "atomic-transact-open-url":
				if (payload?.url) openUrl(payload?.url)
				break
			case "atomic-transact-interaction":
				track("interact_within_sdk", { name: payload?.name || "N/A" })
				break
			default:
			// TODO: handle other broadcast events
		}
		originalEvent.stopPropagation()
	}

	return (
		<ScreenWrapper noPadding nonMobileContentStyle={{ paddingRight: 24 }} center>
			<WebView
				source={{ uri }}
				startInLoadingState
				originWhitelist={["http://*", "https://*", "intent://*"]}
				onMessage={({ nativeEvent }: any) => !showExit && receiveEvent(nativeEvent)}
				style={{ height, maxWidth: 380 }}
			/>
			<Overlay
				showClose
				isVisible={showExit}
				setShowOverlay={() => {
					track("tap_close_atomicexit")
					setShowExit(false)
				}}
				style={{ paddingBottom: 10, paddingTop: 64, paddingHorizontal: 30 }}
				overlayName="atomicExit"
			>
				<View style={{ marginBottom: 30 }}>
					<MetroText h2 style={{ marginBottom: 24 }} testID="atomicExitHeadline">
						{I18n.t("AtomicWebView.OverlayTitle")}
					</MetroText>
					<P3 style={{ color: Colors[theme]["text"] }}>{I18n.t("AtomicWebView.OverlayBody")}</P3>
				</View>
				<Button
					title={I18n.t("AtomicWebView.OverlayPrimary")}
					onPress={() => {
						track("tap_anotherway_atomicexit")
						setShowExit(false)
						navigation.reset({
							routes: [{ name: "Options", params: { showManual: "true" } }],
						})
					}}
					testID="atomicExitButton1"
				/>
				<TertiaryButton
					title={I18n.t("AtomicWebView.OverlayTertiary")}
					onPress={() => {
						track("tap_later_atomicexit")
						setShowExit(false)
						queryClient.invalidateQueries("directDeposit")
						navigation.reset({
							routes: [{ name: "Options", params: { showManual: "false" } }],
						})
					}}
					testID="atomicExitButton2"
				/>
			</Overlay>
		</ScreenWrapper>
	)
}
