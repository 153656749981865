import _ from "lodash"
import Colors from "../../../constants/Colors"
import I18n from "i18n-js"
import React, { useContext, useEffect, useState } from "react"
import useAnalytics from "../../../hooks/useAnalytics"
import useColorScheme from "../../../hooks/useColorScheme"
import { AxiosError } from "axios"
import { Bold, Headline, MetroText } from "../../../components/StyledText"
import { CodeInput } from "../../../components/CodeInput"
import { FlexView } from "../../../components/FlexView"
import { format, parseISO } from "date-fns"
import { Icon } from "react-native-elements"
import { ImageBullet } from "../../../components/ImageBullet"
import { INITIAL_ONBOARDING_STATE } from "../../../context/onboarding/OnboardingProvider"
import { IUser } from "../../../models/User"
import { Keyboard, View } from "react-native"
import { OnboardingContext } from "../../../context/onboarding/OnboardingContext"
import { OnboardingProgressBar } from "../../../components/OnboardingProgressBar"
import { OnboardingStackParamList } from "../../../types"
import { ScreenWrapper } from "../../../components/ScreenWrapper"
import { sendInvitationCode, verifyInvitationCode } from "../../../api/onboarding"
import { SET_ONBOARDING_STATE } from "../../../context/actionTypes"
import { StackScreenProps } from "@react-navigation/stack"
import { useContactSupport } from "../../../hooks/useContactSupport"
import { useMutation, useQueryClient } from "react-query"
import { useOnboardingInit } from "../../../hooks/useOnboardingInit"
import { useSignupNavigation } from "../../../hooks/useSignupNavigation"

export default function VerifyNumberScreen({
	navigation,
}: StackScreenProps<OnboardingStackParamList, "VerifyNumber">) {
	const theme = useColorScheme()
	const [code, setCode] = useState("")
	const { data } = useOnboardingInit()
	const { phoneNumberLastFour, hasState } = data || {}
	const { dispatch, phoneNumber } = useContext(OnboardingContext)
	const [hasError, setHasError] = useState(false)
	const [resendSent, setResendSent] = useState(false)
	const [loading, setLoading] = useState(false)
	const queryClient = useQueryClient()
	const { track } = useAnalytics()
	const { emailSupport } = useContactSupport()
	const { navigateToNextSignupScreen } = useSignupNavigation()
	const resendCodeMutation = useMutation(() => sendInvitationCode({ phoneNumber }), {
		onMutate: async () => {
			await queryClient.cancelQueries("onboardingUser")
			setResendSent(true)
			track("tap_resend_otp")
		},
		onError: (err: AxiosError) => {
			setResendSent(false)
		},
	})

	useEffect(() => {
		if (hasState) {
			sendInvitationCode({ phoneNumber })
		}
	}, [])

	useEffect(() => {
		if (code?.length == 6 && navigation.isFocused()) {
			checkInvitationCode()
		} else {
			setHasError(false)
		}
	}, [code])

	const checkInvitationCode = async () => {
		setLoading(true)
		Keyboard.dismiss()
		try {
			const { cachedUser, providedUser } = await verifyInvitationCode(code)
			track("verify_invite_code")
			const dateOfBirth = providedUser?.dateOfBirth
				? format(parseISO(providedUser?.dateOfBirth), "MM/dd/yyyy")
				: ""
			let payload: Partial<IUser> = {
				id: providedUser.id,
				dateOfBirth,
				invitationCode: code,
				phoneNumber,
				email: providedUser.email,
			}
			if (cachedUser?.email) {
				const targetKeys = Object.keys(INITIAL_ONBOARDING_STATE)
				const wantedPairs = _.pick(cachedUser, targetKeys)
				payload = {
					...payload,
					...wantedPairs,
				}
			}
			dispatch({
				type: SET_ONBOARDING_STATE,
				payload: { ...payload, isPhoneVerified: true },
			})
			setHasError(false)
			navigateToNextSignupScreen()
		} catch (error) {
			setHasError(true)
		}
		setLoading(false)
	}

	return (
		<ScreenWrapper>
			<OnboardingProgressBar position={2} />
			<Headline smallText testID="verifyNumberHeadline">
				{I18n.t("VerifyNumber.PageHeader")}
			</Headline>
			<MetroText>
				{I18n.t("VerifyNumber.Body")}{" "}
				<Bold>{phoneNumber ? phoneNumber.substr(-4) : phoneNumberLastFour}</Bold>
			</MetroText>
			<CodeInput hasError={hasError && code.length == 6 && !loading} setValue={setCode} />
			{resendSent ? (
				<View
					style={{
						flexDirection: "row",
						width: "100%",
						justifyContent: "space-between",
						marginTop: 24,
						borderBottomWidth: 1,
						borderBottomColor: Colors[theme]["successGreen"],
					}}
				>
					<MetroText h4 style={{ color: Colors[theme]["successGreen"] }}>
						{I18n.t("VerifyNumber.Sent")}
					</MetroText>
					<Icon
						name="checkmark-sharp"
						type="ionicon"
						size={16}
						color={Colors[theme]["successGreen"]}
					/>
				</View>
			) : (
				<MetroText
					h4
					onPress={() => resendCodeMutation.mutate()}
					highlight
					style={{ textDecorationLine: "underline", marginTop: 24 }}
					testID="verifyNumberResendCodeLink"
				>
					{I18n.t("VerifyNumber.ResendCode")}
				</MetroText>
			)}
			<FlexView />
			<ImageBullet
				text={
					<MetroText>
						{I18n.t("VerifyNumber.ImageBulletTitle")}
						{"\n"}
						{I18n.t("VerifyNumber.ImageBulletText1")}{" "}
						<MetroText highlight onPress={emailSupport} testID="verifyNumberEmailSupportLink">
							support@getclair.com
						</MetroText>
					</MetroText>
				}
				image={require("../../../assets/images/info.png")}
				imageSize={22}
				topAlign
			/>
		</ScreenWrapper>
	)
}
