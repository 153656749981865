import Colors from "../constants/Colors"
import I18n from "i18n-js"
import React, { useContext, useState } from "react"
import useAnalytics from "../hooks/useAnalytics"
import useColorScheme from "../hooks/useColorScheme"
import { Button, SecondaryButton, TertiaryButton } from "../components/Button"
import { Headline, MetroText } from "../components/StyledText"
import { INITIAL_USER_STATE } from "../context/user/UserProvider"
import { logout } from "../api/logout"
import { OnboardingStackParamList } from "../types"
import { QueryParamContext } from "../context/queryParam/QueryParamContext"
import { RadioListItem } from "../components/RadioListItem"
import { ScreenWrapper } from "../components/ScreenWrapper"
import { SET_USER_STATE } from "../context/actionTypes"
import { StackScreenProps } from "@react-navigation/stack"
import { submitSurveyResponse } from "../api/surveys"
import { SurveyRadioData, SurveyResponseData } from "../models/Survey"
import { TextInput, View } from "react-native"
import { useBroadcastEvent } from "../hooks/useBroadcastEvent"
import { useMutation, useQueryClient } from "react-query"
import { useUserContext } from "../context/user/UserContext"

export default function ExitSurvey({
	navigation,
	route,
}: StackScreenProps<OnboardingStackParamList, "ExitSurvey">) {
	const { survey } = route.params
	const theme = useColorScheme()
	const broadcastEvent = useBroadcastEvent()
	const { track } = useAnalytics()
	const queryClient = useQueryClient()
	const { inSdk } = useContext(QueryParamContext)
	const { accessToken, dispatch, rememberUser } = useUserContext()
	const [loading, setLoading] = useState(false)
	const [loadingSecondary, setLoadingSecondary] = useState(false)
	const [error, setError] = useState(false)
	const [selection, setSelection] = useState<Partial<SurveyResponseData>>(null)

	const handleExit = () => {
		queryClient.invalidateQueries("surveys")
		setLoading(false)
		if (accessToken && (!inSdk || !rememberUser)) {
			dispatch({ type: SET_USER_STATE, payload: INITIAL_USER_STATE })
			navigation.reset({ routes: [{ name: "Login" }] })
			logout()
		}
		broadcastEvent({ event: "clair-onboarding-close", payload: { reason: "unknown" } })
	}

	const submitSurveyMutation = useMutation(
		(data: SurveyResponseData) =>
			submitSurveyResponse(survey.id, {
				questionId: survey.nextQuestion?.id,
				data,
			}),
		{
			onMutate: (data: SurveyResponseData) => {
				data ? setLoading(true) : setLoadingSecondary(true)
			},
			onSettled: handleExit,
		}
	)

	const renderRadioOption = (option: SurveyRadioData, index: number) => {
		const selected = option?.id === selection?.option
		return (
			<View
				style={{
					borderColor: Colors[theme]["borderLight"],
					borderWidth: 1,
					borderRadius: 6,
					marginBottom: 16,
					...(selected && {
						shadowColor: Colors[theme]["borderLight"],
						shadowOffset: { height: 4, width: 0 },
						shadowOpacity: 0.6,
						shadowRadius: 3,
					}),
				}}
			>
				<RadioListItem
					key={option?.id}
					label={option?.label}
					onPress={() => {
						setSelection({ option: option?.id, index })
						setError(false)
					}}
					selected={selected}
				/>
				{selected && option.comments && (
					<TextInput
						placeholder={option.commentsPlaceholder || "What stopped you?"}
						value={selection?.details || ""}
						onChangeText={(text) => setSelection({ ...selection, details: text })}
						onFocus={() => {
							setSelection({ option: option?.id, index })
							setError(false)
						}}
						style={{
							fontFamily: "metropolis-regular",
							color: Colors[theme]["text"],
							fontSize: 13,
							lineHeight: 16,
							borderWidth: 1,
							borderColor: Colors[theme]["borderDefault"],
							borderRadius: 4,
							marginHorizontal: 20,
							marginBottom: 20,
							padding: 16,
							height: 96,
							backgroundColor: "transparent",
						}}
						multiline={true}
					/>
				)}
			</View>
		)
	}

	return (
		<ScreenWrapper>
			<Headline smallText>{survey.nextQuestion?.label}</Headline>
			<MetroText h4 style={{ marginBottom: 22 }}>
				{survey.nextQuestion?.subText}
			</MetroText>
			{survey.nextQuestion?.data.map(renderRadioOption)}
			{error && (
				<MetroText style={{ color: Colors[theme]["textError"], marginBottom: 16 }}>
					{I18n.t("ExitSurvey.Error")}
				</MetroText>
			)}
			<Button
				title={I18n.t("ExitSurvey.ButtonPrimary")}
				onPress={() => {
					track("tap_submit_survey", {
						survey_name: survey.id,
					})
					!selection
						? setError(true)
						: submitSurveyMutation.mutate({
								option: selection?.option || "",
								index: selection?.index || 0,
								details: selection?.details || undefined,
						  })
				}}
				loading={loading}
				testID="ExitSurvey.ButtonPrimary"
			/>
			<SecondaryButton
				title={I18n.t("ExitSurvey.ButtonSecondary")}
				onPress={() => {
					track("tap_skip_survey", {
						survey_name: survey.id,
					})
					submitSurveyMutation.mutate(null)
				}}
				loading={loadingSecondary}
				testID="ExitSurvey.ButtonSecondary"
			/>
			<TertiaryButton
				title={I18n.t("ExitSurvey.ButtonTertiary")}
				onPress={() => navigation.goBack()}
				testID="ExitSurvey.ButtonTertiary"
			/>
		</ScreenWrapper>
	)
}
