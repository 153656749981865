import I18n from "i18n-js"
import React, { useContext } from "react"
import { Button } from "../../components/Button"
import { FeatureImage } from "../../components/FeatureImage"
import { FlexView } from "../../components/FlexView"
import { Headline, MetroText } from "../../components/StyledText"
import { OnboardingStackParamList } from "../../types"
import { QueryParamContext } from "../../context/queryParam/QueryParamContext"
import { ScreenWrapper } from "../../components/ScreenWrapper"
import { StackScreenProps } from "@react-navigation/stack"

export default function TextMeALinkScreen({
	navigation,
}: StackScreenProps<OnboardingStackParamList, "TextMeALink">) {
	const { inSdk = false } = useContext(QueryParamContext)

	const handlePrimaryAction = () => navigation.pop()

	return (
		<ScreenWrapper center>
			<FeatureImage source={require("../../assets/images/phone_rocket.png")} />
			<Headline center>{I18n.t("TextMeALink.PageHeader")}</Headline>
			<MetroText center style={{ marginBottom: 29 }}>
				{I18n.t("TextMeALink.Body")}
			</MetroText>
			<FlexView />
			{inSdk && <Button title={I18n.t("Return")} onPress={handlePrimaryAction} />}
		</ScreenWrapper>
	)
}
