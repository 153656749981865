import _ from "lodash"
import Colors from "../../../constants/Colors"
import I18n from "i18n-js"
import React, { useContext, useEffect, useState } from "react"
import useColorScheme from "../../../hooks/useColorScheme"
import { Button } from "../../../components/Button"
import { confirmDirectDepositComplete } from "../../../api/confirmDirectDepositComplete"
import { FeatureImage } from "../../../components/FeatureImage"
import { FlexView } from "../../../components/FlexView"
import { Headline, LegalText, MetroText } from "../../../components/StyledText"
import { OnboardingProgressBar } from "../../../components/OnboardingProgressBar"
import { OnboardingStackParamList } from "../../../types"
import { QueryParamContext } from "../../../context/queryParam/QueryParamContext"
import { ScreenWrapper } from "../../../components/ScreenWrapper"
import { StackScreenProps } from "@react-navigation/stack"
import { useAuthenticatedUserQuery } from "../../../hooks/useAuthenticatedUser"
import { useBroadcastEvent } from "../../../hooks/useBroadcastEvent"
import { useResetLinkingEffect } from "../../../hooks/useResetLinkingEffect"

export default function DDSuccessScreen({}: StackScreenProps<
	OnboardingStackParamList,
	"DDSuccess"
>) {
	useResetLinkingEffect()
	const [loading, setLoading] = useState(false)
	const theme = useColorScheme()
	const backgroundColor = Colors[theme]["backgroundSuccess"]
	const { refetch } = useAuthenticatedUserQuery()
	const { clairEmbed } = useContext(QueryParamContext)
	useEffect(() => {
		return () => setLoading(false)
	}, [])

	const broadcastEvent = useBroadcastEvent()

	const handlePrimaryAction = async () => {
		setLoading(true)
		await confirmDirectDepositComplete()
		if (clairEmbed) {
			broadcastEvent({
				event: "clair-onboarding-complete",
				payload: { type: "Embedded" },
			})
		}
		refetch()
	}

	return (
		<ScreenWrapper
			center
			contentContainerStyle={{ backgroundColor }}
			nonMobileContentStyle={{ backgroundColor }}
		>
			<OnboardingProgressBar section={1} position={13} style={{ marginBottom: 55 }} />
			<FeatureImage medium source={require("../../../assets/images/payday.gif")} />
			<Headline center>{I18n.t("DDSuccess.PageHeader")}</Headline>
			<MetroText h4 center style={{ marginBottom: 32, paddingHorizontal: 10 }}>
				{I18n.t("DDSuccess.Body")}
			</MetroText>
			<FlexView />
			<Button
				title={I18n.t("DDSuccess.ButtonText")}
				onPress={handlePrimaryAction}
				loading={loading}
			/>
			<LegalText>{I18n.t("BankingServicesFooter")}</LegalText>
		</ScreenWrapper>
	)
}
