import Colors from "../constants/Colors"
import React from "react"
import useColorScheme from "../hooks/useColorScheme"
import { Button } from "./Button"
import { Icon } from "react-native-elements"

type RadioListItemType = {
	label: string
	selected: boolean
	onPress: () => void
}
export const RadioListItem = ({ label, selected, onPress }: RadioListItemType) => {
	const theme = useColorScheme()
	return (
		<Button
			title={label}
			onPress={onPress}
			icon={
				<Icon
					name={selected ? "checkbox-marked-circle" : "checkbox-blank-circle-outline"}
					type="material-community"
					size={18}
					color={Colors[theme][selected ? "textHighlight" : "borderDefault"]}
					containerStyle={{ marginHorizontal: 12 }}
				/>
			}
			iconPosition="right"
			buttonStyle={{
				backgroundColor: "transparent",
				justifyContent: "space-between",
				paddingVertical: 18,
			}}
			containerStyle={{ marginVertical: 0 }}
			titleStyle={{ color: Colors[theme]["text"], marginLeft: 8 }}
		/>
	)
}
