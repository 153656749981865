import axios, { AxiosError } from "axios"
import Colors from "../../../constants/Colors"
import I18n from "i18n-js"
import React, { useEffect, useLayoutEffect, useRef, useState } from "react"
import useAnalytics from "../../../hooks/useAnalytics"
import useColorScheme from "../../../hooks/useColorScheme"
import { ActivityIndicator, Pressable, StyleSheet } from "react-native"
import { BalanceCards } from "./BalanceCards"
import { Button, TertiaryButton } from "../../../components/Button"
import { Checkbox } from "../../../components/Checkbox"
import { formatCurrency } from "../../../utils/formatCurrency"
import { Headline, LegalText, MetroText } from "../../../components/StyledText"
import { Icon } from "react-native-elements"
import { OnboardingStackParamList } from "../../../types"
import { Overlay } from "../../../components/Overlay"
import { ScreenWrapper } from "../../../components/ScreenWrapper"
import { SET_USER_STATE } from "../../../context/actionTypes"
import { StackScreenProps } from "@react-navigation/stack"
import { TextInputMaskMethods } from "react-native-masked-text"
import { TourModal } from "./TourModal"
import { useAdvanceOverviewQuery } from "../../../queries/useWageAdvance"
import { useAuthenticatedUserQuery } from "../../../hooks/useAuthenticatedUser"
import { useInnerContentWidth } from "../../../hooks/useInnerContentWidth"
import { useMemo } from "react"
import { useOnboardingInit } from "../../../hooks/useOnboardingInit"
import { useOpenUrl } from "../../../hooks/useOpenUrl"
import { useQueryClient } from "react-query"
import { useUserContext } from "../../../context/user/UserContext"
import { View } from "../../../components/Themed"
import { VirtualNumPad } from "../../../components/VirtualNumPad"

import {
	getWageAdvancePDFAgreement,
	postWageAdvance,
	skipWageAdvanceStage,
} from "../../../api/wageAdvance"

export default function WageAdvance({
	navigation,
}: StackScreenProps<OnboardingStackParamList, "WageAdvance">) {
	const numpadRef = useRef<TextInputMaskMethods>(null)
	const theme = useColorScheme()
	const queryClient = useQueryClient()
	const { openUrl } = useOpenUrl()
	const { track } = useAnalytics()
	const innerContentWidth = useInnerContentWidth()

	const [amount, setAmount] = useState("0")
	const [rawAmountFromNumberPad, setRawAmountFromNumberPad] = useState("")
	const [focusedIndex, setFocusedIndex] = useState(0)
	const [agreementLoading, setAgreementLoading] = useState(false)
	const [exceedsAdvanceError, setExceedsAdvanceError] = useState(false)
	const [agreementVisible, setAgreementVisible] = useState(false)
	const [hasAgreedToAgreement, setHasAgreedToAgreement] = useState(false)
	const [isEmptyError, setIsEmptyError] = useState(false)
	const [agreementError, setAgreementError] = useState(false)
	const [advanceInProgress, setAdvanceInProgress] = useState(false)
	const [agreementUrl, setAgreementUrl] = useState("")
	const { seenWageAdvanceTour, dispatch } = useUserContext()
	const { data: wageAdvanceData } = useAdvanceOverviewQuery()
	const { availableForAdvance, spendingAccountBalance } = wageAdvanceData || {}
	const { data: userData, isFetching: isAuthUserFetching } = useAuthenticatedUserQuery()
	const { refetch: refetchInit } = useOnboardingInit()
	const { metaData } = userData || {}

	useEffect(() => {
		if (isEmptyError) setIsEmptyError(false)
		if (amount > availableForAdvance) setExceedsAdvanceError(true)
		else if (exceedsAdvanceError) setExceedsAdvanceError(false)
	}, [amount])

	useEffect(() => {
		setAmount(
			rawAmountFromNumberPad && rawAmountFromNumberPad !== "0"
				? String(Number(rawAmountFromNumberPad) / 100)
				: "0"
		)
	}, [rawAmountFromNumberPad])

	useLayoutEffect(() => {
		if (seenWageAdvanceTour) {
			navigation.setOptions({
				headerLeft: () => (
					<Icon
						name="loop"
						type="material"
						size={26}
						color={Colors[theme]["textHighlight"]}
						style={{ flex: 1, lineHeight: 32, marginBottom: 0, marginLeft: 24 }}
						hitSlop={{ top: 10, bottom: 10, left: 10, right: 10 }}
						onPress={() => {
							dispatch({ type: SET_USER_STATE, payload: { showWageAdvanceTour: true } })
						}}
					/>
				),
			})
		} else {
			navigation.setOptions({
				headerLeft: () => null,
			})
		}
	}, [focusedIndex])

	const inputTitle = useMemo(() => {
		if (exceedsAdvanceError)
			return I18n.t("PaydayWallet.TitleExceedsAdvanceFailure", {
				amount: formatCurrency(availableForAdvance, true),
			})
		if (isEmptyError) return I18n.t("PaydayWallet.InputTitleFailure")

		return I18n.t("PaydayWallet.InputTitle")
	}, [amount, isEmptyError, exceedsAdvanceError])

	const handleAdvance = async () => {
		if (!amount || amount == "0") setIsEmptyError(true)
		else {
			setIsEmptyError(false)
			setHasAgreedToAgreement(false)
			setAgreementVisible(true)
		}
	}
	const handleAgreementOpen = async () => {
		setAgreementUrl("")
		setAgreementLoading(true)
		const res = await getWageAdvancePDFAgreement(new Date().toLocaleDateString("en-CA"), amount)
		try {
			setAgreementUrl(res?.data?.url)
		} catch (error) {
			throw error
		} finally {
			setAgreementLoading(false)
		}
	}

	const handleAcceptAdvance = async () => {
		if (!hasAgreedToAgreement) setAgreementError(true)
		else {
			try {
				setAdvanceInProgress(true)
				let nowTimestamp = new Date().toISOString().split("T")[0]
				await postWageAdvance(Number(amount), nowTimestamp, hasAgreedToAgreement)

				setAgreementVisible(false)
				// Ensure that EWASuccess get's an updated stage from authUser query
				await queryClient.invalidateQueries("authUser")
				return navigation.reset({
					routes: [{ name: "EWASuccess", params: { amount } }],
				})
			} catch (error: any | AxiosError) {
				if (axios.isAxiosError(error) && error?.response?.status == 403) {
					await queryClient.refetchQueries("authUser")
					const { data } = await refetchInit()
					if (data?.ewaEnabled === false)
						return navigation.reset({
							routes: [
								{ name: "CardActivationEntry" },
								{ name: "PaydayWalletDisabledAfterKYCPass" },
							],
						})

					return navigation.reset({ routes: [{ name: "PDWActionRequired" }] })
				}
				throw error
			} finally {
				setAdvanceInProgress(false)
			}
		}
	}

	const handleSkipAdvance = async () => {
		track("tap_skip_advance")
		await skipWageAdvanceStage()
		// Ensure that CardActivationEntry gets an updated stage from authUser query
		await queryClient.refetchQueries("authUser")
		const { stage } = metaData
		let route = ""
		if (stage === "READY_TO_GET_PAID_EARLY") {
			route = "OnboardingCompleteVariant"
		} else {
			route = "CardActivationEntry"
		}

		navigation.reset({ index: 0, routes: [{ name: route }] })
	}
	return (
		<ScreenWrapper
			center
			nonMobileContentStyle={{ paddingHorizontal: 0 }}
			contentContainerStyle={{ paddingHorizontal: 0 }}
		>
			<BalanceCards showArrow />
			<VirtualNumPad
				ref={numpadRef}
				valueSetter={setRawAmountFromNumberPad}
				value={rawAmountFromNumberPad}
				inputTitle={inputTitle}
				isError={exceedsAdvanceError || isEmptyError}
			/>
			{/* Override default button styling to work with ScreenWrapper with no paddingHorizontal */}
			<Button
				buttonStyle={{ minWidth: innerContentWidth }}
				containerStyle={{ width: "auto" }}
				disabled={exceedsAdvanceError || isEmptyError}
				title={I18n.t("PaydayWallet.Button1")}
				onPress={() => {
					handleAdvance()
				}}
			/>
			<TertiaryButton
				title={I18n.t("PaydayWallet.Button2")}
				onPress={handleSkipAdvance}
				loading={isAuthUserFetching}
			/>
			<LegalText> {I18n.t("BankingServicesFooter")}</LegalText>
			<Overlay
				isVisible={agreementVisible}
				showClose
				setShowOverlay={() => {
					setAgreementVisible(false)
					setAgreementError(false)
					setAgreementUrl("")
				}}
				style={{ maxWidth: 360, paddingBottom: 5, paddingTop: 55 }}
			>
				<Headline center style={{ marginHorizontal: 10 }}>
					{" "}
					{I18n.t("PaydayWallet.Agreement.Headline")}
				</Headline>
				<MetroText center h4 style={{ marginHorizontal: 15 }}>
					{I18n.t("PaydayWallet.Agreement.Body", { amount: formatCurrency(amount, true) })}
				</MetroText>
				<Checkbox
					style={styles.checkbox}
					checked={hasAgreedToAgreement}
					text={
						<MetroText>
							{I18n.t("PaydayWallet.Agreement.CheckboxLabelText")}{" "}
							<Pressable onPress={handleAgreementOpen}>
								<MetroText highlight>
									{I18n.t("PaydayWallet.Agreement.CheckboxLabelLinkText")}
								</MetroText>
							</Pressable>
							{agreementError && (
								<MetroText
									style={{
										color: Colors[theme]["textError"],
									}}
								>
									{"\n"}
									{I18n.t("PaydayWallet.Agreement.MustAgreeToTerms")}
								</MetroText>
							)}
						</MetroText>
					}
					onPress={() => {
						if (!hasAgreedToAgreement) setAgreementError(false)
						setHasAgreedToAgreement(!hasAgreedToAgreement)
					}}
				/>
				{!agreementUrl && agreementLoading && (
					<View
						style={{
							justifyContent: "center",
							flexDirection: "row",
							height: 20,
						}}
					>
						<MetroText>{I18n.t("PaydayWallet.Agreement.LoadingText")} </MetroText>
						<ActivityIndicator size={15} color={Colors[theme]["textHighlight"]} />
					</View>
				)}
				{agreementUrl && !agreementLoading && (
					<MetroText
						highlight
						onPress={async () => {
							await openUrl(agreementUrl)
						}}
					>
						{I18n.t("PaydayWallet.Agreement.AgreementText")}
					</MetroText>
				)}
				<Button
					loading={advanceInProgress}
					disabled={advanceInProgress}
					containerStyle={{ width: "auto" }}
					buttonStyle={{ minWidth: 290 }}
					titleStyle={{ textAlign: "center" }}
					title={I18n.t("PaydayWallet.Agreement.Button1")}
					onPress={() => {
						handleAcceptAdvance()
					}}
				/>
				<TertiaryButton
					title={I18n.t("PaydayWallet.Agreement.Button2")}
					onPress={() => {
						setAgreementVisible(false)
						setAgreementError(false)
						setAgreementUrl("")
					}}
				/>
			</Overlay>
			<TourModal
				spendingBal={spendingAccountBalance}
				advanceBal={availableForAdvance}
				setFocusedIndex={setFocusedIndex}
				focusedIndex={focusedIndex}
			/>
		</ScreenWrapper>
	)
}
const styles = StyleSheet.create({
	checkbox: { marginLeft: 13, marginRight: 15, marginVertical: 20 },
})
