import _ from "lodash"
import Colors from "../../constants/Colors"
import I18n from "i18n-js"
import React, { useContext } from "react"
import useColorScheme from "../../hooks/useColorScheme"
import { AxiosError } from "axios"
import { cardAPI } from "../../api"
import { differenceInMilliseconds } from "date-fns"
import { FeatureImage } from "../../components/FeatureImage"
import { FlexView } from "../../components/FlexView"
import { formatSSN } from "../../utils/formatSSN"
import { Headline, LegalText, MetroText } from "../../components/StyledText"
import { INITIAL_ONBOARDING_STATE } from "../../context/onboarding/OnboardingProvider"
import { OnboardingContext } from "../../context/onboarding/OnboardingContext"
import { OnboardingProgressBar } from "../../components/OnboardingProgressBar"
import { OnboardingStackParamList } from "../../types"
import { register } from "../../api/onboarding"
import { ScreenWrapper } from "../../components/ScreenWrapper"
import { StackScreenProps } from "@react-navigation/stack"
import { useEffect } from "react"
import { useLinkTo } from "@react-navigation/native"
import { useMutation, useQueryClient } from "react-query"
import { useUserContext } from "../../context/user/UserContext"
import { wait } from "../../utils/wait"

export default function KYCLoading({
	navigation,
}: StackScreenProps<OnboardingStackParamList, "KYCLoading">) {
	const theme = useColorScheme()
	const onboardingState = useContext(OnboardingContext)
	const { login } = useUserContext()
	const queryClient = useQueryClient()
	const linkTo = useLinkTo()
	const backgroundColor = Colors[theme]["backgroundSuccess"]
	const firstRenderTime = new Date()
	const minWaitTime = 4000

	const onboardingMutation = useMutation(
		() => {
			const targetKeys = Object.keys(INITIAL_ONBOARDING_STATE)
			const wantedKvPairs = _.pick(onboardingState, targetKeys)
			return register({
				...wantedKvPairs,
				tosAccepted: true,
				ssn: formatSSN("999-99-9999", onboardingState?.ssn),
			})
		},
		{
			onError: async (err: AxiosError) => {
				if (err.response?.status === 503) {
					linkTo("/MaintenanceMode")
				} else {
					const responseTime = differenceInMilliseconds(new Date(), firstRenderTime)
					if (responseTime < minWaitTime) {
						await wait(minWaitTime - responseTime)
					}
					navigation.reset({
						routes: [{ name: "KYCError" }],
					})
				}
			},
			onSuccess: async ({ galileoCipStatus }) => {
				queryClient.invalidateQueries("onboardingInit")
				const responseTime = differenceInMilliseconds(new Date(), firstRenderTime)
				if (responseTime < minWaitTime) {
					await wait(minWaitTime - responseTime)
				}
				if (galileoCipStatus == "Pass") {
					// Log in as set authentication header for cardAPI & fetchCardData so card delivery dates are pre-loaded before we navigate
					const { access_token } = await login(onboardingState.email, onboardingState.password)
					cardAPI.defaults.headers["Authorization"] = `Bearer ${access_token}`
					navigation.reset({
						routes: [{ name: "KYCPass" }],
					})
				} else if (galileoCipStatus == "Refer") {
					navigation.reset({
						routes: [{ name: "KYCRefer" }],
					})
				} else {
					navigation.reset({
						routes: [{ name: "KYCFail" }],
					})
				}
			},
		}
	)

	useEffect(() => {
		onboardingMutation.mutate()
	}, [])

	return (
		<ScreenWrapper
			center
			contentContainerStyle={{ backgroundColor }}
			nonMobileContentStyle={{ backgroundColor }}
		>
			<OnboardingProgressBar position={10} />
			<FeatureImage medium source={require("../../assets/images/kyc_loading.gif")} />
			<Headline center style={{ marginBottom: 30 }} testID="kycLoadingHeadline">
				{I18n.t("Loading.PageHeader")}
			</Headline>
			<MetroText h4 center style={{ marginBottom: 29 }}>
				{I18n.t("Loading.Body")}
			</MetroText>
			<FlexView />
			<LegalText>{I18n.t("BankingServicesFooter")}</LegalText>
		</ScreenWrapper>
	)
}
