const tintColorLight = "#1e2f6e"
const tintColorDark = "#e5e5e5"

export default {
	light: {
		text: "#141414",
		textButtonDefault: "#e5e5e5",
		textError: "#E02020",
		textInputLabel: "#4F4F4F",
		textHighlight: "#1e2f6e",
		textLegal: "#5f5f5f",
		textLegalSecondary: "#727272",
		textTouchableFrameSecondary: "#888888",
		background: "#ffffff",
		backgroundDesktopFrame: "#EFEFEF",
		backgroundButtonDefault: "#1e2f6e",
		backgroundSecondary: "#D9DCF3",
		backgroundCard: "#DBEFFC",
		backgroundOTPInput: "#FAFAFA",
		backgroundSplashDefault: "#ffffff",
		backgroundSuccess: "#EDF7FE",
		backgroundClairCard: "#D3EDF3",
		backgroundInstructions: "#F5F5F5",
		backgroundError: "#F8EBEB",
		borderActive: "#1e2f6e",
		borderDefault: "#C0C1C2",
		borderLight: "#F2F2F2",
		borderCodeInput: "#f2f2f2",
		borderDDBox: "#C0C1C2",
		borderAddressSuggestion: "#6D6D6D",
		borderModal: "#f4f4f4",
		checkboxChecked: "#1e2f6e",
		primary: "#1e2f6e",
		primaryGray: "#f6f6f6",
		skeleton: "#F0F0F0",
		successGreen: "#64C556",
		tint: tintColorLight,
		tabDefault: "#DBEFFC",
		tabTextDefault: "#949BB8",
		tabSelected: "#1e2f6e",
		errorRed: "#D8524F",
		progressBarStart: "#1CCAF9",
		progressBarEnd: "#1e2f6e",
		progressBarBackground: "#C0C1C2",
	},
	dark: {
		text: "#e5e5e5",
		textButtonDefault: "#e5e5e5",
		textError: "#E02020",
		textInputLabel: "#f4f4f4",
		textHighlight: "#f4f4f4",
		textLegal: "#f4f4f4",
		textLegalSecondary: "#f4f4f4",
		textTouchableFrameSecondary: "#f4f4f4",
		background: "#000",
		backgroundDesktopFrame: "#1e2f6e",
		backgroundButtonDefault: "#1e2f6e",
		backgroundCard: "#1e2f6e",
		backgroundSecondary: "#D9DCF3",
		backgroundOTPInput: "#a6a6a6",
		backgroundSplashDefault: "#1e2f6e",
		backgroundSuccess: "#EDF7FE",
		backgroundClairCard: "#D3EDF3",
		backgroundInstructions: "#F5F5F5",
		backgroundError: "#F8EBEB",
		borderActive: "#f4f4f4",
		borderDefault: "#C0C1C2",
		borderLight: "#F2F2F2",
		borderCodeInput: "#f2f2f2",
		borderDDBox: "#C0C1C2",
		borderAddressSuggestion: "#6D6D6D",
		borderModal: "#f4f4f4",
		checkboxChecked: "#1e2f6e",
		primary: "#1e2f6e",
		primaryGray: "#a6a6a6",
		skeleton: "#F0F0F0",
		successGreen: "#64C556",
		tint: tintColorDark,
		tabDefault: "#1e2f6e",
		tabTextDefault: "#f4f4f4",
		tabSelected: "#DBEFFC",
		errorRed: "#D8524F",
		progressBarStart: "#1CCAF9",
		progressBarEnd: "#1e2f6e",
		progressBarBackground: "#C0C1C2",
	},
}
