import _ from "lodash"
import Loading from "../components/Loading"
import React, { useCallback } from "react"
import { INITIAL_USER_STATE } from "../context/user/UserProvider"
import { OnboardingStackParamList } from "../types"
import { POST_KYC_PRE_ONBOARDING_COMPLETE_STAGES, Stage } from "../constants/stages"
import { SET_USER_STATE } from "../context/actionTypes"
import { useAuthenticatedUserQuery } from "../hooks/useAuthenticatedUser"
import { useFocusEffect, useNavigation, useRoute } from "@react-navigation/native"
import { useOnboardingInit } from "../hooks/useOnboardingInit"
import { useSignupNavigation } from "../hooks/useSignupNavigation"
import { useUserContext } from "../context/user/UserContext"
import { withSplitTreatments } from "@splitsoftware/splitio-react"
import {
	SHOW_EXPERIMENTAL_WELCOME_SCREEN,
	SIGN_UP_SCREEN_ORDERING,
} from "../constants/featureFlags"

export const Splash = withSplitTreatments([
	SIGN_UP_SCREEN_ORDERING,
	SHOW_EXPERIMENTAL_WELCOME_SCREEN,
])(({ isReady }) => {
	const navigation = useNavigation()
	const { accessToken, dispatch } = useUserContext()
	const {
		data: onboardingData,
		isError: isOnboardingError,
		isFetching,
		isFetched,
		isLoading,
		error: onboardingError,
	} = useOnboardingInit()
	const {
		data: rqUserData,
		isError: isAuthError,
		isSuccess: isAuthSuccess,
		isFetched: isAuthFetched,
		isLoading: isAuthLoading,
	} = useAuthenticatedUserQuery()
	const { userData, metaData } = rqUserData || {}
	const { firstName, status, hasState, hideCarousel, ewaEnabled } = onboardingData || {}
	const currentRoute = useRoute().name
	const { orderedSignUpRoutes, setTranslationOverrides } = useSignupNavigation()

	const navigateToTargetRoute = async () => {
		// Clear the user state if non-complete user and token is present
		if (accessToken) dispatch({ type: SET_USER_STATE, payload: INITIAL_USER_STATE })
		let targetRoute: keyof OnboardingStackParamList = "WelcomeCarousel"
		switch (status) {
			case "KYC Fail":
				targetRoute = "KYCFail"
				break
			// A "Submitted" status indicates the user has completed onboarding but their KYC
			// status is refer / pending and they need to submit more information
			case "Submitted":
				targetRoute = "KYCRefer"
				break
			// If there is a "Invited" or "Created" status and user has started onboarding but did not complete KYC,
			// the user will be navigated to the Already Started screen to decide if they want to autofill the information
			// they previously completed. If there is no data, the user will be brought to the Welcome screen to start or
			// resume the onboarding process or the Mobile number screen if the Welcome Carousel is hidden (handled by partner
			// before launching the widget).

			case "Invited":
			case "Created":
				// skip the carousel if it's in the partner's app or if the user has already started onboarding
				if (hideCarousel || hasState) {
					// if the user has state and VerifyNumber is the second route, skip to it
					setTranslationOverrides()
					targetRoute =
						// this check is important because we don't want to jump to VerifyNumber if there are signup screens before it that need to be completed.
						hasState && orderedSignUpRoutes[1] === "VerifyNumber"
							? "VerifyNumber"
							: orderedSignUpRoutes[0]
				} else {
					targetRoute = "WelcomeCarousel"
				}
				break
			default:
				break
		}
		// Always take a pre-signed up user who is Payday wallet disabled (ewaEnabled=false) user to the PDWDisabled screen
		if (ewaEnabled == false) targetRoute = "PaydayWalletDisabledBeforeKYCPass"

		currentRoute !== targetRoute && navigation.reset({ index: 0, routes: [{ name: targetRoute }] })
	}

	const checkAuth = () => {
		if (!isAuthFetched && !isAuthLoading) {
			return currentRoute !== "Login" && navigation.reset({ index: 0, routes: [{ name: "Login" }] })
		}
		if (isAuthError && !isAuthLoading) {
			dispatch({ type: SET_USER_STATE, payload: INITIAL_USER_STATE })
			return currentRoute !== "Login" && navigation.reset({ index: 0, routes: [{ name: "Login" }] })
		}
		if (isAuthSuccess) {
			dispatch({
				type: SET_USER_STATE,
				payload: _.pick({ ...userData, ...metaData }, Object.keys(INITIAL_USER_STATE)),
			})
			const stageRouteMapping: { [key in Stage]: keyof OnboardingStackParamList } = {
				DIRECT_DEPOSIT: "DirectDepositEntry",
				WAGE_ADVANCE: "WageAdvance",
				CARD_ACTIVATION: "CardActivationEntry",
				// User completed card activation but skipped wage advance
				READY_TO_GET_PAID_EARLY: "OnboardingCompleteVariant",
				DOWNLOAD_APP: "OnboardingComplete",
			}
			const currentStage: Stage = metaData.stage
			const routes: { name: keyof OnboardingStackParamList }[] = [
				{ name: metaData?.stage ? stageRouteMapping[currentStage] : "Splash" },
			]

			if (ewaEnabled == false && POST_KYC_PRE_ONBOARDING_COMPLETE_STAGES.includes(metaData?.stage))
				routes.push({ name: "PaydayWalletDisabledAfterKYCPass" })

			return (
				currentRoute !== stageRouteMapping[currentStage] &&
				navigation.reset({
					index: 0,
					routes,
				})
			)
		}
	}

	useFocusEffect(
		useCallback(() => {
			if (status === "Complete") {
				accessToken
					? checkAuth()
					: currentRoute !== "Login" && navigation.reset({ index: 0, routes: [{ name: "Login" }] })
			} else if (!isLoading && isFetched && status && firstName && !isFetching && isReady)
				navigateToTargetRoute()
		}, [
			isAuthSuccess,
			isAuthFetched,
			isAuthLoading,
			isAuthError,
			isReady,
			status,
			userData,
			accessToken,
			metaData?.stage,
			currentRoute,
		])
	)

	useFocusEffect(
		useCallback(() => {
			if (isOnboardingError) {
				if (currentRoute !== "GeneralError" && currentRoute !== "MaintenanceMode") {
					const nextRoute = onboardingError?.status === 503 ? "MaintenanceMode" : "GeneralError"
					navigation.reset({ index: 0, routes: [{ name: nextRoute }] })
				}
			}
		}, [isOnboardingError])
	)
	return <Loading showLogo />
})
