import Colors from "../../../constants/Colors"
import I18n from "i18n-js"
import React from "react"
import useAnalytics from "../../../hooks/useAnalytics"
import useColorScheme from "../../../hooks/useColorScheme"
import useDirectDepositStatus from "../../../hooks/useDirectDepositStatus"
import { AccountInfoCard } from "../../../components/AccountInfoCard"
import { Bold, Headline } from "../../../components/StyledText"
import { FAQsLink } from "./DirectDepositFAQLink"
import { LegalText, MetroText, SecondaryButton } from "@getclair/react-native-storybook"
import { OnboardingProgressBar } from "../../../components/OnboardingProgressBar"
import { OnboardingStackParamList } from "../../../types"
import { ScreenWrapper } from "../../../components/ScreenWrapper"
import { StackScreenProps } from "@react-navigation/stack"
import { urls } from "../../../constants/urls"
import { useLinkTo } from "@react-navigation/native"
import { View } from "react-native"

export default function ManualSwitchHome({
	navigation,
}: StackScreenProps<OnboardingStackParamList, "ManualSwitchHome">) {
	const { data } = useDirectDepositStatus()
	const payrollProviderName = data?.payrollProvider?.name
	const theme = useColorScheme()
	const linkTo = useLinkTo()
	const { track } = useAnalytics()

	return (
		<ScreenWrapper noPadding>
			<View style={{ paddingHorizontal: 24, flex: 1 }}>
				<OnboardingProgressBar section={1} position={7} />
				<Headline smallText testID="manualSwitchHomeHeadline">
					{I18n.t("ManualSwitchHome.PageHeader")}
				</Headline>
				<MetroText style={{ marginBottom: 24 }}>
					{payrollProviderName ? (
						<>
							{I18n.t("ManualSwitchHome.KnownProviderBody")}
							<Bold>{payrollProviderName}</Bold>
						</>
					) : (
						I18n.t("ManualSwitchHome.Body")
					)}
				</MetroText>
				<Bold style={{ marginBottom: 8, color: Colors[theme].textLegal }}>
					{I18n.t("ManualSwitchHome.AccountDetailsHeading")}
				</Bold>
				<AccountInfoCard
					style={{ marginBottom: 14 }}
					onAccountCopy={() => track("tap_copyacc_diy")}
					onRoutingCopy={() => track("tap_copyrouting_diy")}
				/>
				<FAQsLink
					style={{ alignSelf: "center", marginBottom: 30 }}
					eventName="tap_FAQs_ManualHome"
					url={urls.howToSwitchToClairManual}
				/>
			</View>
			<View
				style={{
					backgroundColor: Colors[theme]["backgroundSecondary"],
					paddingBottom: 16,
					paddingHorizontal: 24,
					paddingTop: 24,
					minHeight: 128,
				}}
			>
				<SecondaryButton
					title={I18n.t("ManualSwitchHome.BottomPanelButton")}
					onPress={() => linkTo("/ScreenshotEducation")}
					containerStyle={{ marginBottom: 24 }}
					buttonStyle={{ backgroundColor: Colors[theme]["background"] }}
					testID="ManualSwitchHome-EmailButton"
				/>
				<LegalText center>{I18n.t("BankingServicesFooter")}</LegalText>
			</View>
		</ScreenWrapper>
	)
}
