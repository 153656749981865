type Status = "neutral" | "valid" | "invalid"

export type Result = {
	lowercase: Status
	uppercase: Status
	numeric: Status
	eightChars: Status
	specialChars: Status
	isValid: boolean
}
export const validatePasswordStrength = (password: string) => {
	let result: Result = {
		lowercase: "neutral",
		uppercase: "neutral",
		numeric: "neutral",
		eightChars: "neutral",
		specialChars: "neutral",
		isValid: false,
	}
	if (password) {
		// at least one lowercase character
		const lowercaseRegex = new RegExp(/(?=.*[a-z])/g, "g")
		result = { ...result, lowercase: lowercaseRegex.test(password) ? "valid" : "invalid" }

		// at least one uppercase character
		const uppercaseRegex = new RegExp(/(?=.*[A-Z])/g, "g")
		result = { ...result, uppercase: uppercaseRegex.test(password) ? "valid" : "invalid" }

		// at least one special character
		const specialCharsRegex = new RegExp(/(?=.*[!@#\$%\^&\*\-])/g, "g")
		result = { ...result, specialChars: specialCharsRegex.test(password) ? "valid" : "invalid" }

		// at least 8 characters
		const eightCharsRegex = new RegExp(/(?=.{8,})/g, "g")
		result = { ...result, eightChars: eightCharsRegex.test(password) ? "valid" : "invalid" }

		// at least one numeric character
		const numericRegex = new RegExp(/(?=.*[0-9])/g, "g")
		result = { ...result, numeric: numericRegex.test(password) ? "valid" : "invalid" }

		// find one false value
		const invalid = Object.values(result).filter((value) => value !== "valid")
		if (invalid?.length <= 1)
			// length of 1 because isValid will always return false
			result = { ...result, isValid: true }
	}
	return result
}
