import { surveyAPI } from "./index"
import { SurveyId, SurveyResponse } from "./../models/Survey"

export const getSurveys = async () => {
	const { data } = await surveyAPI.get("")
	return data.surveys
}

export const submitSurveyResponse = async (surveyId: SurveyId, surveyResponse: SurveyResponse) =>
	surveyAPI.post(`${surveyId}`, surveyResponse)
