import { Action } from "./actionTypes"
import { useEffect } from "react"
export interface GenericAction {
	type: Action
	payload: object
}

export function useAsyncStateEffect(
	dispatch: React.Dispatch<GenericAction>,
	action: GenericAction
) {
	function dispatchState() {
		if (action.payload) dispatch(action)
	}
	useEffect(() => {
		dispatchState()
	}, [action.payload])
}
