import I18n from "i18n-js"
import React, { useContext } from "react"
import { Button } from "../../components/Button"
import { FeatureImage } from "../../components/FeatureImage"
import { FlexView } from "../../components/FlexView"
import { Headline, MetroText } from "../../components/StyledText"
import { OnboardingProgressBar } from "../../components/OnboardingProgressBar"
import { QueryParamContext } from "../../context/queryParam/QueryParamContext"
import { ScreenWrapper } from "../../components/ScreenWrapper"
import { useBroadcastEvent } from "../../hooks/useBroadcastEvent"

export default function KYCErrorScreen() {
	const { inSdk } = useContext(QueryParamContext)
	const broadcastEvent = useBroadcastEvent()
	const handlePrimaryAction = () =>
		broadcastEvent({ event: "clair-onboarding-complete", payload: { status: "Pending" } })

	return (
		<ScreenWrapper center>
			<OnboardingProgressBar position={10} />
			<FeatureImage medium source={require("../../assets/images/kyc_error.png")} />
			<Headline center>{I18n.t("KYCError.PageHeader")}</Headline>
			<MetroText style={{ marginBottom: 25 }}>{I18n.t("KYCError.Body1")}</MetroText>
			<MetroText style={{ marginBottom: 29 }}>{I18n.t("KYCError.Body2")}</MetroText>
			<FlexView />
			{inSdk && (
				<Button title={I18n.t("Return")} onPress={handlePrimaryAction} testID="kycErrorReturn" />
			)}
		</ScreenWrapper>
	)
}
