import React, { FunctionComponent, useReducer } from "react"
import { QueryParamContext } from "./QueryParamContext"
import { queryParamReducer } from "./queryParamReducer"
import { SET_QUERY_PARAMS_STATE } from "../actionTypes"
import { useAsyncStateEffect } from "../useAsyncStateEffect"

export const INITIAL_QUERY_PARAM_STATE = {
	src: "",
	businessId: "",
	employeeId: "",
	widgetId: "",
	inSdk: false,
	appAccessToken: "",
	clairEmbed: false,
}
interface QueryParamProps extends FunctionComponent {
	asyncState: object
}
export const QueryParamProvider: FunctionComponent<QueryParamProps> = ({
	children,
	asyncState,
}) => {
	const [state, dispatch] = useReducer(queryParamReducer, INITIAL_QUERY_PARAM_STATE)
	useAsyncStateEffect(dispatch, { type: SET_QUERY_PARAMS_STATE, payload: asyncState })
	return (
		<QueryParamContext.Provider value={{ ...state, dispatch }}>
			{children}
		</QueryParamContext.Provider>
	)
}
