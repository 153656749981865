import Colors from "../constants/Colors"
import React, { FunctionComponent } from "react"
import useColorScheme from "../hooks/useColorScheme"
import { DefaultTextProps, Text as BaseText } from "./Themed"
import { useIsVisibleScreenShort } from "../hooks/useIsVisibleScreenShort"

export type MetroTextProps = {
	center?: boolean
} & DefaultTextProps

export const P3: FunctionComponent<MetroTextProps> = ({ style, ...rest }) => {
	const theme = useColorScheme()
	return (
		<BaseText
			style={[
				{
					fontSize: 13,
					lineHeight: 16,
				},
				style,
			]}
			lightColor={Colors[theme]["textLegal"]}
			{...rest}
		/>
	)
}

export const MetroText: FunctionComponent<MetroTextProps> = ({ style, center, ...rest }) => {
	const isVisibleScreenShort = useIsVisibleScreenShort()

	return (
		<BaseText
			{...rest}
			h1Style={[
				{
					fontFamily: "metropolis-medium",
					fontSize: isVisibleScreenShort ? 18 : 24,
					lineHeight: isVisibleScreenShort ? 20 : 32,
					textAlign: center ? "center" : "auto",
				},
				style,
			]}
			h2Style={[
				{
					fontFamily: "metropolis-medium",
					fontSize: isVisibleScreenShort ? 16 : 18,
					lineHeight: 20,
					textAlign: center ? "center" : "auto",
				},
				style,
			]}
			h3Style={[
				{
					fontFamily: "metropolis-medium",
					fontSize: isVisibleScreenShort ? 14 : 16,
					lineHeight: 20,
					textAlign: center ? "center" : "auto",
				},
				style,
			]}
			h4Style={[
				{
					fontSize: isVisibleScreenShort ? 14 : 16,
					lineHeight: 20,
					textAlign: center ? "center" : "auto",
				},
				style,
			]}
			style={[
				style,
				{
					textAlign: center ? "center" : "auto",
				},
			]}
		/>
	)
}

export const ErrorText: FunctionComponent<MetroTextProps> = ({ style, ...rest }) => {
	const theme = useColorScheme()
	return (
		<MetroText
			{...rest}
			style={[{ fontSize: 13, lineHeight: 18, color: Colors[theme]["errorRed"] }, style]}
		/>
	)
}

type HeadlineProps = {
	smallText?: boolean
} & MetroTextProps

export const Headline: FunctionComponent<HeadlineProps> = ({ style, smallText, ...rest }) => {
	const isVisibleScreenShort = useIsVisibleScreenShort()

	return (
		<MetroText
			style={[
				{
					fontFamily: "metropolis-medium",
					fontSize: isVisibleScreenShort ? 18 : smallText ? 24 : 28,
					lineHeight: isVisibleScreenShort ? 24 : 32,
					marginBottom: isVisibleScreenShort ? 8 : 24,
					width: "100%",
				},
				style,
			]}
			{...rest}
		/>
	)
}

export const LegalText: FunctionComponent<MetroTextProps> = ({ style, ...rest }) => {
	const theme = useColorScheme()
	return (
		<MetroText
			center
			style={[
				{
					color: Colors[theme]["textLegal"],
					fontSize: 10,
					lineHeight: 12,
					marginTop: 6,
					marginBottom: 10,
					alignSelf: "center",
				},
				style,
			]}
			{...rest}
		/>
	)
}

export const Bold: FunctionComponent<DefaultTextProps> = ({ style, ...rest }) => {
	return (
		<MetroText
			style={[
				{
					fontFamily: "metropolis-semi",
				},
				style,
			]}
			{...rest}
		/>
	)
}

export const Italic: FunctionComponent<DefaultTextProps> = ({ style, ...rest }) => {
	return (
		<MetroText
			style={[
				{
					fontFamily: "metropolis-medium-italic",
				},
				style,
			]}
			{...rest}
		/>
	)
}

export const SuperScript: FunctionComponent<DefaultTextProps> = ({ style, ...rest }) => {
	return (
		<MetroText
			style={[{ textAlignVertical: "top", fontSize: 9, lineHeight: 20 }, style]}
			{...rest}
		/>
	)
}

export const SecondaryText: FunctionComponent<DefaultTextProps> = ({ style, ...rest }) => {
	const theme = useColorScheme()
	return (
		<MetroText
			style={[{ fontSize: 12, lineHeight: 18, color: Colors[theme]["textInputLabel"] }, style]}
			{...rest}
		/>
	)
}
