import Colors from "../../constants/Colors"
import I18n from "i18n-js"
import React from "react"
import useColorScheme from "../../hooks/useColorScheme"
import { AutoHeightImage } from "../../components/AutoHeightImage"
import { Bold, Headline, LegalText, MetroText } from "../../components/StyledText"
import { Button, SecondaryButton } from "../../components/Button"
import { ClairCardWrapper } from "../../components/ClairCardWrapper"
import { ImageBullet } from "../../components/ImageBullet"
import { OnboardingStackParamList } from "../../types"
import { Placeholder, PlaceholderLine, Shine } from "rn-placeholder"
import { ScreenWrapper } from "../../components/ScreenWrapper"
import { StackScreenProps } from "@react-navigation/stack"
import { useAuthenticatedUserQuery } from "../../hooks/useAuthenticatedUser"
import { useCardData } from "../../hooks/useCardData"
import { useDeviceAndWalletType } from "../../hooks/useDeviceAndWalletType"
import { View } from "react-native"
import { WalletButtonIcon } from "../../components/WalletButtonIcon"

type LinkingInstructions = { [key: string]: string[] }

export default function AddToWalletScreen({
	navigation,
}: StackScreenProps<OnboardingStackParamList, "AddToWallet">) {
	const { data: userData } = useAuthenticatedUserQuery()
	const { metaData } = userData || {}
	const { url, receiveDate } = useCardData()
	const { isMobileDevice, walletType, launchWallet } = useDeviceAndWalletType()

	const theme = useColorScheme()

	const linkingInstructions: LinkingInstructions = {
		Apple: ["Apple1", "Apple2", "Apple3", "Apple4"],
		Google: ["Google1", "Google2", "Google3", "Google4"],
		Samsung: ["Samsung1", "Samsung2", "Samsung3", "Samsung4"],
		Desktop: ["Desktop1", "Desktop2", "Desktop3", "Desktop4"],
	}

	const handleContinue = () => {
		const { stage } = metaData
		let route = ""
		if (stage === "DOWNLOAD_APP") {
			route = "OnboardingComplete"
		} else if (stage === "READY_TO_GET_PAID_EARLY") {
			route = "OnboardingCompleteVariant"
		}
		route
			? navigation.reset({ index: 0, routes: [{ name: route }] })
			: navigation.reset({ index: 0, routes: [{ name: "Splash" }] })
	}

	return (
		<ScreenWrapper center>
			<View style={{ paddingHorizontal: 24 }}>
				<ClairCardWrapper
					loading={!url}
					style={{
						alignSelf: "center",
						height: 176,
					}}
					loadingHeight={176}
					loadingWidth={280}
				>
					<div data-private>
						<AutoHeightImage
							style={{
								borderRadius: 10,
								marginVertical: 10,
							}}
							width={280}
							source={{ uri: url }}
						/>
					</div>
				</ClairCardWrapper>
			</View>
			<LegalText style={{ marginBottom: 32, color: Colors[theme]["textLegalSecondary"] }}>
				{I18n.t("MastercardLegalText")}
			</LegalText>
			<Headline center>{I18n.t("AddToWallet.PageHeader")}</Headline>
			{receiveDate ? (
				<MetroText
					h4
					center
					style={{
						marginBottom: 30,
					}}
				>
					{I18n.t("AddToWallet.Body")}
					<Bold h4> {I18n.t("AddToWallet.BodyDate", { date: receiveDate })}</Bold>
				</MetroText>
			) : (
				<Placeholder Animation={Shine}>
					<PlaceholderLine />
					<PlaceholderLine />
				</Placeholder>
			)}

			<View
				style={{
					backgroundColor: Colors[theme]["backgroundInstructions"],
					borderRadius: 7,
					padding: 20,
					marginBottom: 20,
				}}
			>
				<MetroText
					h4
					style={{
						marginBottom: 20,
					}}
				>
					{I18n.t("AddToWallet.Body2", {
						walletType: isMobileDevice ? walletType + " Pay" : "your mobile wallet",
					})}
				</MetroText>
				{!!walletType &&
					linkingInstructions[walletType].map((bullet: string, index: number) => {
						return (
							<ImageBullet
								key={index}
								text={I18n.t(`AddToWallet.${bullet}`)}
								image={require(`../../assets/images/numbers_${index + 1}_blue.png`)}
								imageSize={24}
								style={{ marginBottom: 18 }}
								textStyle={{ fontSize: 14 }}
							/>
						)
					})}
			</View>
			{isMobileDevice && (
				<Button
					icon={<WalletButtonIcon walletType={walletType} buttonType="primary" />}
					iconPosition="right"
					title={I18n.t("AddToWallet.Button")}
					onPress={launchWallet}
					buttonStyle={{ paddingVertical: walletType === "Samsung" ? 8 : 12 }}
				/>
			)}
			{isMobileDevice ? (
				<SecondaryButton title={I18n.t("Continue")} onPress={handleContinue} />
			) : (
				<Button title={I18n.t("Continue")} onPress={handleContinue} />
			)}
			<LegalText> {I18n.t("BankAndCardLegalText")}</LegalText>
		</ScreenWrapper>
	)
}
