import * as Device from "expo-device"
import React, { useCallback, useContext, useRef } from "react"
import { ActivityIndicator, Animated } from "react-native"
import { AVPlaybackStatus, Video } from "expo-av"
import { CarouselButton } from "./components/CarouselButton"
import { CarouselIndicator } from "./components/CarouselIndicator"
import { CustomColors } from "./utils"
import { MaterialTopTabScreenProps } from "@react-navigation/material-top-tabs"
import { MuteButton } from "./components/MuteButton"
import { osName } from "expo-device"
import { PlayButton } from "./components/PlayButton"
import { QueryParamContext } from "../../../context/queryParam/QueryParamContext"
import { ScreenWrapper } from "../../../components/ScreenWrapper"
import { SHOW_EXPERIMENTAL_WELCOME_SCREEN } from "../../../constants/featureFlags"
import { useCalculatedFrameDimensions } from "../../../hooks/useCalculatedFrameDimensions"
import { useFocusEffect } from "@react-navigation/native"
import { useIs19by9 } from "../../../hooks/useIs19by9"
import { useIsNonMobileScreen } from "../../../hooks/useIsNonMobileScreen"
import { useTreatments } from "@splitsoftware/splitio-react"
import { VideoCarouselConfig } from "./types"
import { VideoCarouselParamList } from "../../../types"
import { wait } from "../../../utils/wait"

export default function Carousel2({
	navigation,
}: MaterialTopTabScreenProps<VideoCarouselParamList, "Carousel2">) {
	const video = useRef<Video>(null)
	const fadeAnim = useRef(new Animated.Value(0)).current
	const is19by9 = useIs19by9()
	const treatments = useTreatments([SHOW_EXPERIMENTAL_WELCOME_SCREEN])
	const { source } = JSON.parse(
		treatments[SHOW_EXPERIMENTAL_WELCOME_SCREEN].config || "{}"
	) as unknown as VideoCarouselConfig
	const configSource19x9 = source && source["19x9"]
	const configSource16x9 = source && source["16x9"]
	const { width, height } = useCalculatedFrameDimensions()
	const isNonMobile = useIsNonMobileScreen()
	const { inSdk } = useContext(QueryParamContext)

	const playVideo = () => {
		video.current?.setOnPlaybackStatusUpdate(onPlaybackStatusUpdate)
		if (!inSdk || osName !== "iOS") video.current?.playAsync()
	}

	useFocusEffect(
		useCallback(() => {
			playVideo()
			fadeIn()
			return () => {
				video.current?.stopAsync().catch(() => {})
				video.current?.setPositionAsync(0)
			}
		}, [])
	)

	const fadeIn = async () => {
		await wait(1000)
		// Will change fadeAnim value to 1 in 5 seconds
		Animated.timing(fadeAnim, {
			toValue: 1,
			duration: 3000,
			useNativeDriver: false,
		}).start()
	}

	const onPlaybackStatusUpdate = (status: AVPlaybackStatus) => {
		if (status.didJustFinish) navigation.jumpTo("Carousel3")
	}

	return (
		<ScreenWrapper
			noPadding
			contentContainerStyle={{
				backgroundColor: CustomColors.PRUSSIAN_BLUE,
			}}
			nonMobileContentStyle={{
				backgroundColor: CustomColors.PRUSSIAN_BLUE,
				overflow: "hidden",
			}}
		>
			<ActivityIndicator
				size="large"
				color={"white"}
				style={{
					position: "absolute",
					top: 0,
					left: 0,
					right: 0,
					bottom: 0,
				}}
			/>
			<Video
				ref={video}
				style={{
					maxWidth: isNonMobile ? width : window.innerWidth,
					maxHeight: isNonMobile ? height : window.innerHeight,
				}}
				source={
					is19by9
						? configSource19x9
							? { uri: source["19x9"] }
							: require("../../../assets/videos/carousel_frame_2_19x9.mp4")
						: configSource16x9
						? { uri: source["16x9"] }
						: require("../../../assets/videos/carousel_frame_2.mp4")
				}
				resizeMode={Video.RESIZE_MODE_COVER}
				usePoster={Device.osName == "Android"}
				posterStyle={{
					backgroundColor: CustomColors.PRUSSIAN_BLUE,
					width: "100%",
					height: window.innerHeight,
				}}
				useNativeControls={false}
				isMuted={!inSdk || osName !== "iOS"}
			/>
			<Animated.View
				style={{
					position: "absolute",
					bottom: 40,
					left: 0,
					right: 0,
					// Bind opacity to animated value
					opacity: fadeAnim,
				}}
			>
				<CarouselIndicator active={1} />
				<CarouselButton title="Continue" onPress={() => navigation.jumpTo("Carousel3")} />
			</Animated.View>

			{inSdk && osName === "iOS" ? (
				<PlayButton onPlayPress={() => video.current?.playAsync()} />
			) : (
				<MuteButton
					onMutePress={async (shouldMute) => {
						video.current?.setIsMutedAsync(shouldMute)
					}}
				/>
			)}
		</ScreenWrapper>
	)
}
