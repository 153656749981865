import Colors from "../../../constants/Colors"
import I18n from "i18n-js"
import React from "react"
import useColorScheme from "../../../hooks/useColorScheme"
import { AutoHeightImage } from "../../../components/AutoHeightImage"
import { Button } from "../../../components/Button"
import { Card } from "../../../components/Card"
import { formatCurrency } from "../../../utils/formatCurrency"
import { MetroText } from "../../../components/StyledText"
import { Overlay } from "../../../components/Overlay"
import { SET_USER_STATE } from "../../../context/actionTypes"
import { StyleSheet } from "react-native"
import { useAdvanceOverviewQuery } from "../../../queries/useWageAdvance"
import { useUserContext } from "../../../context/user/UserContext"

interface TourModalProps {
	spendingBal: number | null
	advanceBal: number | null
	setFocusedIndex: any
	focusedIndex: number
}

export const TourModal = ({
	spendingBal,
	advanceBal,
	focusedIndex,
	setFocusedIndex,
}: TourModalProps) => {
	const theme = useColorScheme()
	const { isLoading } = useAdvanceOverviewQuery()
	const { showWageAdvanceTour, dispatch } = useUserContext()

	const MODAL_CONTENT = [
		_walletCardModalContent,
		_spendingCardModalContent,
		_advanceInputModalContent,
	]

	function _spendingCardModalContent() {
		return (
			<>
				<Card
					loading={isLoading}
					style={[
						styles.card,
						styles.modalCardShadow,
						{ borderTopRightRadius: 0, borderBottomRightRadius: 0 },
					]}
				>
					<AutoHeightImage
						source={require("../../../assets/images/card.png")}
						width={27}
						resizeMode="contain"
						style={styles.icon}
					/>
					<MetroText highlight style={styles.cardTitle}>
						{I18n.t("PaydayWallet.SpendingTitle")}
					</MetroText>
					<MetroText h1 highlight style={styles.cardAmount}>
						{formatCurrency(spendingBal, true)}
					</MetroText>
					<AutoHeightImage
						source={require("../../../assets/images/mastercard.png")}
						width={32}
						style={{
							position: "absolute",
							right: 21,
							bottom: 16,
							opacity: String(spendingBal?.toFixed(2)).split(".")[0].length > 3 ? 0.2 : 1,
						}}
					/>
				</Card>
				<MetroText h4 center style={styles.modalText}>
					{I18n.t("PaydayWallet.Tour.SpendingCard.Body")}
				</MetroText>
				<Button
					buttonStyle={styles.modalNextButton}
					title={I18n.t("Next")}
					onPress={() => {
						setFocusedIndex(focusedIndex + 1)
					}}
				/>
			</>
		)
	}

	function _walletCardModalContent() {
		return (
			<>
				<Card
					loading={isLoading}
					style={[
						styles.card,
						styles.modalCardShadow,

						{
							backgroundColor: Colors[theme]["backgroundSecondary"],
							borderTopLeftRadius: 0,
							borderBottomLeftRadius: 0,
						},
					]}
				>
					<AutoHeightImage
						source={require("../../../assets/images/wallet.png")}
						width={25}
						resizeMode="contain"
						style={styles.icon}
					/>
					<MetroText highlight style={styles.cardTitle}>
						{I18n.t("PaydayWallet.WalletTitle")}
					</MetroText>
					<MetroText h1 highlight style={styles.cardAmount}>
						{formatCurrency(advanceBal, true)}
					</MetroText>
				</Card>
				<MetroText h4 center style={styles.modalText}>
					{I18n.t("PaydayWallet.Tour.WalletCard.Body")}
				</MetroText>

				<Button
					buttonStyle={styles.modalNextButton}
					title={I18n.t("Next")}
					onPress={() => {
						setFocusedIndex(focusedIndex + 1)
					}}
				/>
			</>
		)
	}
	function _advanceInputModalContent() {
		return (
			<>
				<Card
					loading={isLoading}
					style={[
						styles.modalCardShadow,
						{
							backgroundColor: Colors[theme]["background"],
							paddingHorizontal: 10,
							minHeight: 110,
							marginBottom: 25,
							justifyContent: "center",
						},
					]}
				>
					<MetroText
						center
						style={{
							fontFamily: "metropolis-medium",
							fontSize: 38,
							lineHeight: 48,
							marginBottom: 0,
						}}
						numberOfLines={1}
					>
						{formatCurrency(advanceBal, true)}
					</MetroText>
					<MetroText style={{ color: Colors[theme]["textHighlight"] }}>
						{I18n.t("PaydayWallet.InputTitle")}
					</MetroText>
				</Card>
				<MetroText h4 center style={styles.modalText}>
					{I18n.t("PaydayWallet.Tour.AdvanceInput.Body", {
						amount: formatCurrency(advanceBal, true),
					})}
				</MetroText>
				<Button
					buttonStyle={styles.modalNextButton}
					title={I18n.t("LetsGo")}
					onPress={() => {
						setFocusedIndex(0)
						dispatch({
							type: SET_USER_STATE,
							payload: { seenWageAdvanceTour: true, showWageAdvanceTour: false },
						})
					}}
				></Button>
			</>
		)
	}
	return (
		<Overlay
			isVisible={!!showWageAdvanceTour}
			showClose={false}
			setShowOverlay={() => {}}
			style={{ maxWidth: 360 }}
		>
			{showWageAdvanceTour && MODAL_CONTENT[focusedIndex]()}
		</Overlay>
	)
}
const styles = StyleSheet.create({
	card: {
		paddingHorizontal: 10,
		width: 165,
		minHeight: 110,
		marginBottom: 25,
		justifyContent: "center",
	},
	cardTitle: { fontWeight: "700", position: "absolute", left: 13, top: 45 },

	cardAmount: {
		position: "absolute",
		left: 11,
		bottom: 11,
	},
	icon: {
		position: "absolute",
		left: 13,
		top: 15,
	},
	modalNextButton: {
		minWidth: 290,
	},
	modalCardShadow: {
		shadowOffset: {
			width: 0,
			height: 4,
		},
		shadowRadius: 30,
		shadowColor: "rgba(0, 0, 0, 0.1)",
		shadowOpacity: 1,
	},
	modalText: { marginLeft: 25, marginRight: 25, marginBottom: 16 },
})
