import _ from "lodash"
import I18n from "i18n-js"
import Loading from "../../../components/Loading"
import React, { useCallback } from "react"
import { Bold, LegalText, MetroText } from "../../../components/StyledText"
import { Button } from "../../../components/Button"
import { FeatureImageProto } from "../../../components/FeatureImageProto"
import { FlexView } from "../../../components/FlexView"
import { formatCurrency } from "../../../utils/formatCurrency"
import { OnboardingProgressBar } from "../../../components/OnboardingProgressBar"
import { OnboardingStackParamList } from "../../../types"
import { ScreenWrapper } from "../../../components/ScreenWrapper"
import { StackScreenProps } from "@react-navigation/stack"
import { useDirectDepositFinalStatus } from "../../../hooks/useDirectDepositStatus"
import { useFocusEffect, useLinkTo } from "@react-navigation/native"
import { useOnboardingInit } from "../../../hooks/useOnboardingInit"

export default function DirectDepositEntry({
	navigation,
	route,
}: StackScreenProps<OnboardingStackParamList, "DirectDepositEntry">) {
	const { data: onboardingData } = useOnboardingInit()
	const { businessMaxCreditLine } = onboardingData || {}

	const linkTo = useLinkTo()
	const { stayOnActionRequired } = route?.params || {}
	const { status, isManualSwitch, forceManual, isFetching, requestType } =
		useDirectDepositFinalStatus()

	useFocusEffect(
		useCallback(() => {
			if (status == "COMPLETED") {
				navigation.reset({
					routes: [{ name: "DDSuccess" }],
				})
			} else if (status == "ACTION_REQUIRED" && !stayOnActionRequired) {
				navigation.reset({
					routes: [
						{
							name: isManualSwitch
								? "FormActionRequired"
								: forceManual
								? "TryAnotherWay"
								: "TryAgain",
						},
					],
				})
			} else if (status == "IN_PROGRESS") {
				isManualSwitch
					? navigation.reset({
							routes: [
								{
									name: requestType === "DIRECT_DEPOSIT_INBOX" ? "DIYPending" : "FormPending",
								},
							],
					  })
					: navigation.reset({
							routes: [{ name: "PendingText" }],
					  })
			}
		}, [status, forceManual])
	)

	return (status == "NOT_STARTED" || stayOnActionRequired) && !isFetching ? (
		<ScreenWrapper center>
			<OnboardingProgressBar section={1} position={2} />
			<FeatureImageProto source={require("../../../assets/images/money_falling.png")} />
			<MetroText h1 center testID="ddEntryHeadline">
				{I18n.t("DirectDepositEntry.PageHeader")}
			</MetroText>
			<MetroText center style={{ lineHeight: 32 }}>
				{I18n.t("DirectDepositEntry.Body1")}
			</MetroText>
			<FlexView></FlexView>
			<MetroText center>
				{I18n.t("DirectDepositEntry.Body2", {
					businessMaxCreditLine: formatCurrency(businessMaxCreditLine),
				})}
			</MetroText>

			<MetroText center style={{ textAlign: "center" }}>
				<MetroText center style={{ fontStyle: "italic" }}>
					{I18n.t("DirectDepositEntry.BodyItalic1")}
				</MetroText>
				<MetroText center>{I18n.t("DirectDepositEntry.Body3")}</MetroText>
				<Bold>{I18n.t("DirectDepositEntry.Body3Bold")}</Bold>
				<MetroText center>{I18n.t("DirectDepositEntry.Body4")}</MetroText>
			</MetroText>
			<FlexView />
			<Button
				title={I18n.t("Continue")}
				onPress={() => linkTo("/DirectDepositEntryB")}
				testID={"ddEntryContinue"}
			/>
			<LegalText>{I18n.t("IssuerStatement")}</LegalText>
		</ScreenWrapper>
	) : (
		<Loading />
	)
}
