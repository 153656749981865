import Colors from "../../../constants/Colors"
import I18n from "i18n-js"
import React, { useCallback, useState } from "react"
import useAnalytics from "../../../hooks/useAnalytics"
import useColorScheme from "../../../hooks/useColorScheme"
import { Button } from "../../../components/Button"
import { DDOptionsButtonGroup } from "./DDOptionsButtonGroup"
import { ErrorText, Headline, LegalText, MetroText } from "../../../components/StyledText"
import { FlexView } from "../../../components/FlexView"
import { Input } from "../../../components/Input"
import { OnboardingProgressBar } from "../../../components/OnboardingProgressBar"
import { OnboardingStackParamList } from "../../../types"
import { ScreenWrapper } from "../../../components/ScreenWrapper"
import { SET_USER_STATE } from "../../../context/actionTypes"
import { StackScreenProps } from "@react-navigation/stack"
import { useDirectDepositOptions } from "../../../hooks/useDirectDepositStatus"
import { useFocusEffect, useLinkTo } from "@react-navigation/native"
import { useInnerContentWidth } from "../../../hooks/useInnerContentWidth"
import { useUserContext } from "../../../context/user/UserContext"
import { validateField, validateForm } from "../../../utils/validate"
import { View } from "react-native"

export default function CalculatorPercentage({
	navigation,
	route,
}: StackScreenProps<OnboardingStackParamList, "CalculatorPercentage">) {
	const { isFormEdit } = route.params || {}
	const [currentButton, setCurrentButton] = useState("")
	const [hasError, setHasError] = useState(false)
	const [errorMessage, setErrorMessage] = useState("")
	const [percentage, setPercentage] = useState("")
	const [hasSelectionError, setHasSelectionError] = useState(false)
	const innerContentWidth = useInnerContentWidth()

	const { dispatch } = useUserContext()
	const linkTo = useLinkTo()
	const theme = useColorScheme()
	const { track } = useAnalytics()
	const { showManualOptions, allowPartialDirectDepositChange } = useDirectDepositOptions()

	useFocusEffect(
		useCallback(() => {
			setPercentage("")
			setPercentageError(false, "")
		}, [currentButton])
	)

	const setPercentageError = (hasError: boolean, errorMessage = "") => {
		setHasError(hasError)
		setErrorMessage(errorMessage)
	}

	const handleNext = () => {
		const isPercentageValid =
			validateForm([["percentage", percentage, setPercentageError]]) || currentButton !== "Other"
		if (currentButton && isPercentageValid) {
			let value
			if (currentButton === "Other") {
				track("tap_percentage_other")
				value = percentage
			} else {
				const trackingAmount =
					currentButton === "75%" ? "one" : currentButton === "50%" ? "two" : "three"
				value = currentButton.replace(/\%/g, "")
				track(`tap_percentage_${trackingAmount}`)
			}
			dispatch({
				type: SET_USER_STATE,
				payload: { distributionType: "PERCENTAGE", distributionValue: value },
			})
			// if the user wants to do a partial switch but Atomic doesn't allow it for the payroll provider, we need to show the manual options
			const showManual =
				showManualOptions || (!allowPartialDirectDepositChange && percentage !== "100")
			isFormEdit && isFormEdit !== "false"
				? navigation.reset({
						routes: [{ name: "Options", params: { showManual } }, { name: "VerifyFormInfo" }],
				  })
				: linkTo(`/Options?showManual=${showManual}`)
		} else if (!currentButton) {
			setHasSelectionError(true)
		}
	}

	return (
		<ScreenWrapper center>
			<OnboardingProgressBar section={1} position={isFormEdit ? 7 : 5} />
			<Headline testID="calculatorPercentageHeadline">
				{I18n.t("CalculatorPercentage.PageHeader")}
			</Headline>
			<MetroText style={{ marginBottom: 25 }}>{I18n.t("CalculatorPercentage.Body")}</MetroText>
			<DDOptionsButtonGroup
				currentButton={currentButton}
				setCurrentButton={setCurrentButton}
				onPress={() => setHasSelectionError(false)}
				buttonValues={[
					I18n.t("CalculatorPercentage.Button1"),
					I18n.t("CalculatorPercentage.Button2"),
					I18n.t("CalculatorPercentage.Button3"),
					I18n.t("CalculatorPercentage.Button4"),
				]}
			/>
			{currentButton === "Other" && (
				<View
					style={{
						flexDirection: "row",
						alignItems: "center",
						width: innerContentWidth,
					}}
				>
					<Input
						label={I18n.t("CalculatorPercentage.InputLabel")}
						value={percentage}
						maxLength={3}
						onChangeText={(value: string) => {
							setPercentageError(false, "")
							setPercentage(value)
							validateField("percentage", value, setPercentageError)
						}}
						mask="numeric"
						onBlur={() => validateField("percentage", percentage, setPercentageError)}
						keyboardType="numeric"
						containerStyle={{ marginBottom: 24, width: 128 }}
						hasError={hasError}
						errorMessage={errorMessage}
						errorStyle={{ width: 265, zIndex: 1 }}
						placeholder={I18n.t("CalculatorPercentage.InputPlaceholder")}
						testID="calculatorPercentageInput"
					/>
					<MetroText
						h4
						style={{
							color: Colors[theme]["textInputLabel"],
						}}
					>
						%
					</MetroText>
				</View>
			)}
			<FlexView />
			{hasSelectionError && <ErrorText>{I18n.t("CalculatorAmount.ErrorText")}</ErrorText>}
			<Button title={I18n.t("Next")} onPress={handleNext} />
			<LegalText>{I18n.t("BankingServicesFooter")}</LegalText>
		</ScreenWrapper>
	)
}
