import I18n from "i18n-js"
import React, { useContext, useState } from "react"
import { Button, SecondaryButton } from "../../../components/Button"
import { FeatureImage } from "../../../components/FeatureImage"
import { FlexView } from "../../../components/FlexView"
import { Headline, MetroText } from "../../../components/StyledText"
import { INITIAL_ONBOARDING_STATE } from "../../../context/onboarding/OnboardingProvider"
import { OnboardingContext } from "../../../context/onboarding/OnboardingContext"
import { OnboardingStackParamList } from "../../../types"
import { ScreenWrapper } from "../../../components/ScreenWrapper"
import { SET_ONBOARDING_STATE } from "../../../context/actionTypes"
import { StackScreenProps } from "@react-navigation/stack"
import { startNewState } from "../../../api/onboarding"
import { useSignupNavigation } from "../../../hooks/useSignupNavigation"

export default function AlreadyStartedScreen({}: StackScreenProps<
	OnboardingStackParamList,
	"AlreadyStarted"
>) {
	const [loadingSecondary, setLoadingSecondary] = useState(false)
	const { phoneNumber, invitationCode, dispatch } = useContext(OnboardingContext)
	const { navigateToNextSignupScreen } = useSignupNavigation()

	const handleContinue = () => {
		navigateToNextSignupScreen()
	}

	const handleStartOver = async () => {
		setLoadingSecondary(true)
		await startNewState({ phoneNumber }).catch((reason) => {
			console.error(reason)
		})
		dispatch({
			type: SET_ONBOARDING_STATE,
			payload: {
				...INITIAL_ONBOARDING_STATE,
				phoneNumber,
				invitationCode,
				isPhoneVerified: true,
			},
		})
		setLoadingSecondary(false)
		handleContinue()
	}

	return (
		<ScreenWrapper center>
			<FeatureImage medium source={require("../../../assets/images/phone_question.png")} />
			<Headline smallText center>
				{I18n.t("AlreadyStarted.PageHeader")}
			</Headline>
			<MetroText center style={{ marginBottom: 29 }}>
				{I18n.t("AlreadyStarted.Body")}
			</MetroText>
			<FlexView />
			<Button
				title={I18n.t("AlreadyStarted.Button1")}
				onPress={handleContinue}
				testID="alreadyStartedYes"
			/>
			<SecondaryButton
				title={I18n.t("AlreadyStarted.Button2")}
				onPress={handleStartOver}
				loading={loadingSecondary}
				disabled={loadingSecondary}
				testID="alreadyStartedNo"
			/>
		</ScreenWrapper>
	)
}
