import * as Linking from "expo-linking"
import { LinkingOptions } from "@react-navigation/native/lib/typescript/src/types"

type stage =
	| "DIRECT_DEPOSIT"
	| "WAGE_ADVANCE"
	| "CARD_ACTIVATION"
	| "READY_TO_GET_PAID_EARLY"
	| "DOWNLOAD_APP"

/**
 * Learn more about deep linking with React Navigation
 * https://reactnavigation.org/docs/deep-linking
 * https://reactnavigation.org/docs/configuring-links
 */
/**
 * @description Generates linking configuration based off of stage, status, and authentication state.
 */
export function getLinkingConfig(
	stage: stage | null = null,
	status: string | undefined = undefined,
	authenticated: boolean | undefined = undefined,
	ewaEnabled: boolean | undefined = undefined,
	hideCarousel: boolean | undefined = undefined
): LinkingOptions {
	const prefixes = [Linking.createURL("/"), "https://registration.getclair.com"]

	const configErrorScreens = { GeneralError: "*" }
	const commonScreens = {
		Splash: "Splash",
		GeneralError: "GeneralError",
		AreYouSure: "AreYouSure",
		MaintenanceMode: "MaintenanceMode",
	}
	const postKYCPreOnboardingCompleteCommonScreens = {
		PaydayWalletDisabledAfterKYCPass: "PaydayWalletDisabledAfterKYCPass",
	}
	const PDWDisabledPreKYCPassScreens = {
		PaydayWalletDisabledBeforeKYCPass: "*",
	}
	const preSignedUpScreens = {
		WelcomeCarousel: "*",
		...commonScreens,
		MobileNumber: "MobileNumber",
		VerifyNumber: "VerifyNumber",
		AlreadyStarted: "AlreadyStarted",
		TextMeALink: "TextMeALink",
		PersonalInfo: "PersonalInfo",
		PermanentAddress: "PermanentAddress",
		MailingAddressOne: "MailingAddressOne",
		MailingAddressTwo: "MailingAddressTwo",
		EmploymentInfo: "EmploymentInfo",
		Email: "Email",
		PasswordSet: "PasswordSet",
		SSN: "SSN",
		KYCLoading: "KYCLoading",
		KYCPass: "KYCPass",
		KYCRefer: "KYCRefer",
		KYCFail: "KYCFail",
	}

	const preSignedUpScreensNoCarousel = {
		MobileNumber: "*",
		...commonScreens,
		VerifyNumber: "VerifyNumber",
		AlreadyStarted: "AlreadyStarted",
		TextMeALink: "TextMeALink",
		PersonalInfo: "PersonalInfo",
		PermanentAddress: "PermanentAddress",
		MailingAddressOne: "MailingAddressOne",
		MailingAddressTwo: "MailingAddressTwo",
		EmploymentInfo: "EmploymentInfo",
		Email: "Email",
		PasswordSet: "PasswordSet",
		SSN: "SSN",
		KYCLoading: "KYCLoading",
		KYCPass: "KYCPass",
		KYCRefer: "KYCRefer",
		KYCFail: "KYCFail",
	}

	const directDepositScreens = {
		DirectDepositEntry: "*",
		...commonScreens,
		...postKYCPreOnboardingCompleteCommonScreens,
		DirectDepositEntryB: "DirectDepositEntryB",
		DDFormPreview: "DDFormPreview",
		SendWithClair: "SendWithClair",
		SendMyself: "SendMyself",
		AtomicWelcome: "AtomicWelcome",
		PendingText: "PendingText",
		TryAgain: "TryAgain",
		TryAnotherWay: "TryAnotherWay",
		DDSuccess: "DDSuccess",
		ManualSwitchHome: "ManualSwitchHome",
		ManualSwitchConfirm: "ManualSwitchConfirm",
		ScreenshotEducation: "ScreenshotEducation",
		DIYLoading: "DIYLoading",
		DIYPending: "DIYPending",
		DIYError: "DIYError",
		CalculatorEntry: "CalculatorEntry",
		CalculatorAmount: "CalculatorAmount",
		CalculatorPercentage: "CalculatorPercentage",
		VerifyFormInfo: "VerifyFormInfo",
		FormActionRequired: "FormActionRequired",
		FormPending: "FormPending",
		PDWActionRequired: "PDWActionRequired",
		TextMeALink: "TextMeALink",
		Options: "Options",
	}

	const wageAdvanceScreens = {
		WageAdvance: "*",
		...commonScreens,
		...postKYCPreOnboardingCompleteCommonScreens,
		PDWGeneralError: "PDWGeneralError",
		EWASuccess: "EWASuccess/:amount",
		PDWActionRequired: "PDWActionRequired",
	}
	const cardActivationScreens = {
		CardActivationEntry: "*",
		...commonScreens,
		...postKYCPreOnboardingCompleteCommonScreens,
		PasswordCheck: "PasswordCheck",
		ResetPassword: "ResetPassword",
		SetPin: "SetPin",
		PinSettingError: "PinSettingError",
		AddToWallet: "AddToWallet",
	}
	const onboardingCompleteVariantScreens = {
		OnboardingCompleteVariant: "*",
		...commonScreens,
		WageAdvance: "WageAdvance",
		TextMeALink: "TextMeALink",
	}

	const onboardingCompleteScreens = {
		OnboardingComplete: "*",
		...commonScreens,
		AccountInfo: "AccountInfo",
		TextMeALink: "TextMeALink",
	}
	const signedUpUnauthScreens = {
		Login: "*",
		LoginVerifyOTP: "LoginVerifyOTP/:method",
		...commonScreens,
		ResetPassword: "ResetPassword",
	}

	let screens = null
	if (!authenticated) {
		if (status == "Complete") {
			screens = signedUpUnauthScreens
		} else {
			// Non-complete init status means the user has not completed signup / KYC
			if (ewaEnabled === true)
				screens = hideCarousel ? preSignedUpScreensNoCarousel : preSignedUpScreens
			else if (ewaEnabled == false) screens = PDWDisabledPreKYCPassScreens
		}
	} else {
		switch (stage) {
			case "DIRECT_DEPOSIT":
				screens = directDepositScreens
				break
			case "WAGE_ADVANCE":
				screens = wageAdvanceScreens
				break
			case "CARD_ACTIVATION":
				screens = cardActivationScreens
				break
			// User completed card activation but skipped wage advance
			case "READY_TO_GET_PAID_EARLY":
				screens = onboardingCompleteVariantScreens
				break
			case "DOWNLOAD_APP":
				screens = onboardingCompleteScreens
				break
			default:
				screens = preSignedUpScreens
				break
		}
	}

	// Either a stage or a status is always needed to determine were a user should go and can navigate to.
	if (!stage && !status) screens = configErrorScreens

	return {
		prefixes,
		config: {
			screens,
		},
	}
}
