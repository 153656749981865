import Colors from "../../../../constants/Colors"
import React from "react"
import useColorScheme from "../../../../hooks/useColorScheme"
import { CustomColors } from "../utils"
import { View } from "react-native"

type CarouselIndicatorProps = {
	active: number
}
export const CarouselIndicator = (props: CarouselIndicatorProps) => {
	const theme = useColorScheme()

	const Circle = ({ filled }: { filled: boolean }) => (
		<View
			style={{
				width: 6,
				height: 6,
				borderRadius: 6 / 2,
				backgroundColor: filled
					? CustomColors.BRIGHT_TURQUOISE
					: Colors[theme]["backgroundSecondary"],
			}}
		/>
	)

	return (
		<View
			style={{
				flexDirection: "row",
				justifyContent: "space-between",
				alignItems: "center",
				alignSelf: "center",
				marginBottom: 16,
				width: 25,
			}}
		>
			<Circle filled={props.active === 0} />
			<Circle filled={props.active === 1} />
			<Circle filled={props.active === 2} />
		</View>
	)
}
