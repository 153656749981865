import { getAnnualSalaryRanges } from "../api/getAnnualSalaryRanges"
import { useEffect, useState } from "react"

export const useMappedAnnualSalaryRanges = () => {
	const [annualSalaryRanges, setAnnualSalaryRanges] = useState([])
	useEffect(() => {
		const fetchData = async () => {
			try {
				const ranges = await getAnnualSalaryRanges()
				setAnnualSalaryRanges(
					ranges.map((range: string) => {
						return { label: range, value: range }
					})
				)
			} catch (err) {
				console.error(err)
			}
		}
		fetchData()
	}, [])
	return annualSalaryRanges
}
