import Colors from "../constants/Colors"
import React, { forwardRef } from "react"
import useColorScheme from "../hooks/useColorScheme"
import { Icon } from "react-native-elements/dist/icons/Icon"
import { MetroText } from "./StyledText"
import { TextInputMask } from "react-native-masked-text"
import { TouchableOpacity, View } from "react-native"
import { useIsVisibleScreenShort } from "../hooks/useIsVisibleScreenShort"

interface VirtualNumPadProps {
	inputTitle: string
	value: string
	isError?: boolean
	valueSetter: React.Dispatch<React.SetStateAction<string>>
}

export const VirtualNumPad = forwardRef(
	({ inputTitle, valueSetter, isError, value }: VirtualNumPadProps, ref) => {
		const theme = useColorScheme()
		const isVisibleScreenShort = useIsVisibleScreenShort()

		const _cell = (num: number | string, disabled = false) => {
			return (
				<TouchableOpacity
					style={{
						flex: 1,
						justifyContent: "center",
					}}
					key={num}
					accessibilityLabel={num.toString()}
					disabled={disabled}
					onPress={() => {
						if (value.length > 7 || (value.length == 0 && num === 0)) return
						valueSetter(value + num.toString())
					}}
				>
					<MetroText
						h1
						style={{
							fontSize: 25,
							textAlign: "center",
							lineHeight: 32,
							marginBottom: isVisibleScreenShort ? 8 : 24,
						}}
					>
						{num}
					</MetroText>
				</TouchableOpacity>
			)
		}
		const _numberRow = (arr: Array<number>) => (
			<View
				style={{
					flexDirection: "row",
					flex: 1,
					marginTop: 15,
					justifyContent: "space-evenly",
					width: "100%",
					textAlign: "center",
				}}
			>
				{arr.map((v: number) => _cell(v))}
			</View>
		)

		return (
			<View
				style={{
					flex: 1,
					width: "100%",
					justifyContent: "center",
					alignItems: "center",
					marginLeft: 35,
					marginRight: 35,
					marginTop: 45,
				}}
			>
				<TextInputMask
					ref={ref}
					placeholder={"$0.00"}
					placeholderTextColor={Colors[theme]["text"]}
					type={"money"}
					options={{
						precision: 2,
						separator: ".",
						delimiter: ",",
						unit: "$",
						suffixUnit: "",
					}}
					value={value.toString()}
					includeRawValueInChangeText={true}
					editable={false}
					style={{
						fontFamily: "metropolis-medium",
						fontSize: 58,
						textAlign: "center",
					}}
					returnKeyType="done"
				/>
				<View
					style={{
						justifyContent: "center",
						alignItems: "center",
						width: "100%",
						height: 270,
					}}
				>
					{inputTitle && (
						<MetroText
							style={{
								color: isError ? Colors[theme]["textError"] : Colors[theme]["textHighlight"],
								marginBottom: 12,
							}}
						>
							{inputTitle}
						</MetroText>
					)}
					{_numberRow([1, 2, 3])}
					{_numberRow([4, 5, 6])}
					{_numberRow([7, 8, 9])}
					<View
						style={{
							flexDirection: "row",
							flex: 1,
							marginTop: 15,
							justifyContent: "space-evenly",
							width: "100%",
							textAlign: "center",
						}}
					>
						{_cell(" ", true)}
						{_cell(0)}

						<TouchableOpacity
							style={{
								flex: 1,
								justifyContent: "center",
							}}
							onPress={() => {
								valueSetter(value.slice(0, -1))
							}}
						>
							<MetroText
								h1
								style={{
									flex: 1,
									alignItems: "center",
									marginBottom: isVisibleScreenShort ? 8 : 24,
								}}
							>
								<Icon
									name="backspace-outline"
									type="material-community"
									size={22}
									color={Colors[theme]["text"]}
									style={{ flex: 1, lineHeight: 32, marginBottom: 0 }}
								/>
							</MetroText>
						</TouchableOpacity>
					</View>
				</View>
			</View>
		)
	}
)
