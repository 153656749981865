import _ from "lodash"
import getMappedStateCodes from "../../../utils/getMappedStateCodes"
import I18n from "i18n-js"
import React, { useContext, useEffect, useState } from "react"
import { Address, MailingAddress } from "../../../models/Address"
import { AddressAutoComplete } from "../../../components/AddressAutoComplete"
import { addressDeliverability } from "../../../api/addressDeliverability"
import { AddressWarningOverlay } from "../AddressWarningOverlay"
import { Bold, Headline, MetroText } from "../../../components/StyledText"
import { Button } from "../../../components/Button"
import { getAddressByPostalCode } from "../../../api/getAddressByPostalCode"
import { getAddressError, getAddressWarning } from "../../../utils/address"
import { ImageBullet } from "../../../components/ImageBullet"
import { Input } from "../../../components/Input"
import { KeyboardAvoidingView, Platform } from "react-native"
import { OnboardingContext } from "../../../context/onboarding/OnboardingContext"
import { OnboardingProgressBar } from "../../../components/OnboardingProgressBar"
import { OnboardingStackParamList } from "../../../types"
import { Overlay } from "../../../components/Overlay"
import { patchState } from "../../../api/onboarding"
import { Picker } from "../../../components/Picker"
import { ScreenWrapper } from "../../../components/ScreenWrapper"
import { SET_ONBOARDING_STATE } from "../../../context/actionTypes"
import { StackScreenProps } from "@react-navigation/stack"
import { TouchableFrame } from "../../../components/TouchableFrame"
import { useSignupNavigation } from "../../../hooks/useSignupNavigation"
import { validateField, validateForm, ValidationArray } from "../../../utils/validate"

export default function MailingAddressTwoScreen({}: StackScreenProps<
	OnboardingStackParamList,
	"MailingAddressTwo"
>) {
	const [loading, setLoading] = useState(false)
	const {
		shippingAddressLine1,
		shippingAddressLine2,
		shippingCity,
		shippingState,
		shippingPostalCode,
		dispatch,
	} = useContext(OnboardingContext)
	const mailingAddress = {
		shippingAddressLine1,
		shippingAddressLine2,
		shippingCity,
		shippingState,
		shippingPostalCode,
	}
	// @ts-ignore
	const [hasAddressLine1Error, setHasAddressLine1Error] = useState(false)
	const [hasCityError, setHasCityError] = useState(false)
	const [hasStateError, setHasStateError] = useState(false)
	const [hasPostalCodeError, setHasPostalCodeError] = useState(false)
	const [suggestion, setSuggestion] = useState<Address | null>()
	const [warning, setWarning] = useState<string | null>()
	const [addressDeliverabilityError, setAddressDeliverabilityError] = useState("")
	const [hideResults, setHideResults] = useState(true)
	const stateCodes = getMappedStateCodes()
	const { navigateToNextSignupScreen } = useSignupNavigation()

	useEffect(() => {
		if (shippingPostalCode?.length == 5) {
			handleAddressSearch()
		}
	}, [shippingPostalCode])

	useEffect(() => {
		if (addressDeliverabilityError) {
			setAddressDeliverabilityError("")
		}
	}, [shippingAddressLine1])

	const onContinue = (address: MailingAddress, type: "suggestion" | "warning") => {
		patchState(address)
		type === "suggestion" ? setSuggestion(null) : setWarning(null)
		navigateToNextSignupScreen()
	}

	const onSubmit = async () => {
		setLoading(true)
		setAddressDeliverabilityError("")
		const formToValidate: ValidationArray = [
			["addressLine1", shippingAddressLine1, setHasAddressLine1Error],
			["city", shippingCity, setHasCityError],
			["state", shippingState, setHasStateError],
			["postalCode", shippingPostalCode, setHasPostalCodeError],
		]
		const isValid = validateForm(formToValidate)
		if (isValid) {
			const response = await addressDeliverability({
				addressLine1: shippingAddressLine1,
				addressLine2: shippingAddressLine2,
				city: shippingCity,
				state: shippingState,
				postalCode: shippingPostalCode,
			}).catch(() => setAddressDeliverabilityError(I18n.t("AddressErrors.Generic")))
			const {
				isAcceptableAddress,
				suggestion: newSuggestion,
				dpvMatchCode,
				recordType,
				dpvVacantCode,
			} = response || {}
			const addressWarning = getAddressWarning(recordType, dpvMatchCode, dpvVacantCode)
			if (isAcceptableAddress) {
				if (addressWarning) {
					setWarning(addressWarning)
				} else if (newSuggestion) setSuggestion(newSuggestion)
				else onContinue(mailingAddress, "suggestion")
			} else {
				const error = getAddressError(recordType)
				setAddressDeliverabilityError(error)
			}
		}
		setLoading(false)
	}

	const handleAddressSearch = async () => {
		const hasError = validateField("postalCode", shippingPostalCode, setHasPostalCodeError)
		if (!hasError) {
			const response = await getAddressByPostalCode(shippingPostalCode)
			if (response) {
				dispatch({
					type: SET_ONBOARDING_STATE,
					payload: {
						shippingCity: response.city,
						shippingState: response.state,
					},
				})
			}
		}
	}

	const acceptSuggestion = () => {
		const payload = {
			shippingAddressLine1: suggestion?.addressLine1 || shippingAddressLine1,
			shippingAddressLine2: suggestion?.addressLine2 || shippingAddressLine2,
			shippingCity: suggestion?.city || shippingCity,
			shippingState: suggestion?.state || shippingState,
			shippingPostalCode: suggestion?.postalCode || shippingPostalCode,
		}
		dispatch({ type: SET_ONBOARDING_STATE, payload })
		onContinue(payload, "suggestion")
	}

	return (
		<ScreenWrapper>
			<KeyboardAvoidingView
				behavior={Platform.OS == "ios" ? "padding" : "height"}
				keyboardVerticalOffset={120}
				onStartShouldSetResponder={(event) => {
					setHideResults(true)
					return true
				}}
			>
				<OnboardingProgressBar position={7} />
				<Headline smallText testID="mailingAddressTwoHeadline">
					{I18n.t("MailingAddressTwo.PageHeader")}
				</Headline>
				<ImageBullet
					text={I18n.t("MailingAddressTwo.BulletText")}
					image={require("../../../assets/images/home.png")}
					imageSize={24}
					style={{ marginBottom: 0 }}
				/>
				<AddressAutoComplete
					addressType="mailing"
					hideResults={hideResults}
					setHideResults={setHideResults}
				/>
				<Input
					label={I18n.t("MailingAddressTwo.AddressLine2Label")}
					textContentType="streetAddressLine2"
					value={shippingAddressLine2}
					onChangeText={(value) =>
						dispatch({
							type: SET_ONBOARDING_STATE,
							payload: { shippingAddressLine2: value },
						})
					}
					testID="mailingAddressTwoStreet2Input"
				/>
				<Input
					label={I18n.t("MailingAddressTwo.PostalCodeLabel")}
					textContentType="postalCode"
					value={shippingPostalCode}
					onChangeText={(value: string) => {
						dispatch({
							type: SET_ONBOARDING_STATE,
							payload: { shippingPostalCode: value },
						})
						if (hasPostalCodeError) {
							validateField("postalCode", value, setHasPostalCodeError)
						}
					}}
					mask="postalCode"
					errorMessage={
						_.isEmpty(shippingPostalCode)
							? I18n.t("Form.EmptyField", {
									label: I18n.t("MailingAddressTwo.PostalCodeLabel"),
							  })
							: I18n.t("Form.IsRequired", {
									label: I18n.t("MailingAddressTwo.PostalCodeLabel"),
							  })
					}
					hasError={hasPostalCodeError}
					testID="mailingAddressTwoPostalCodeInput"
				/>
				<Input
					label={I18n.t("MailingAddressTwo.CityLabel")}
					textContentType="addressCity"
					value={shippingCity}
					onChangeText={(value: string) => {
						dispatch({
							type: SET_ONBOARDING_STATE,
							payload: { shippingCity: value },
						})
						if (hasCityError) {
							validateField("city", value, setHasCityError)
						}
					}}
					mask="name"
					onBlur={() => validateField("city", shippingCity, setHasCityError)}
					errorMessage={
						_.isEmpty(shippingCity)
							? I18n.t("Form.EmptyField", {
									label: I18n.t("MailingAddressTwo.CityLabel"),
							  })
							: I18n.t("Form.IsRequired", {
									label: I18n.t("MailingAddressTwo.CityLabel"),
							  })
					}
					hasError={hasCityError}
					testID="mailingAddressTwoCityInput"
				/>
				<Picker
					label={I18n.t("MailingAddressTwo.StateLabel")}
					items={stateCodes}
					selectedValue={shippingState}
					onValueChange={(value) => {
						validateField("state", value, setHasStateError)
						dispatch({
							type: SET_ONBOARDING_STATE,
							payload: { shippingState: value },
						})
					}}
					errorMessage={I18n.t("Form.IsRequired", {
						label: I18n.t("MailingAddressTwo.StateLabel"),
					})}
					hasError={hasStateError}
					testID="mailingAddressTwoStateInput"
				/>
				<MetroText style={{ fontSize: 12, marginBottom: 5, lineHeight: 12 }} error>
					{addressDeliverabilityError}
				</MetroText>
				<Button
					title={I18n.t("Next")}
					onPress={onSubmit}
					containerStyle={{ marginTop: 14 }}
					loading={loading}
					testID="mailingAddressTwoNext"
				/>
				<Overlay
					showClose
					isVisible={!!suggestion}
					setShowOverlay={() => setSuggestion(null)}
					overlayName="mailingAddressSuggestion"
					style={{ paddingHorizontal: 38 }}
				>
					<Headline smallText style={{ marginVertical: 24 }} testID="mailingAddressOverlayHeadline">
						{I18n.t("MailingAddressTwo.SuggestionHeader")}
					</Headline>
					<TouchableFrame
						onPress={acceptSuggestion}
						style={{ width: "100%" }}
						testID="acceptMailingAddressSuggestion"
					>
						<MetroText h4 style={{ lineHeight: 24 }}>
							{suggestion?.addressLine1}
							{suggestion?.addressLine2 && <MetroText>, {suggestion?.addressLine2}</MetroText>}
						</MetroText>
						<MetroText h4 style={{ lineHeight: 24 }}>
							{suggestion?.city}, {suggestion?.state} {suggestion?.postalCode}
						</MetroText>
					</TouchableFrame>
					<TouchableFrame
						onPress={() => onContinue(mailingAddress, "suggestion")}
						style={{ marginVertical: 24, width: "100%" }}
						testID="useEnteredMailingAddress"
					>
						<Bold highlight>{I18n.t("MailingAddressTwo.SuggestionNo")}</Bold>
						<MetroText h4 style={{ lineHeight: 24 }}>
							{shippingAddressLine1}
							{shippingAddressLine2 && <MetroText>, {shippingAddressLine2}</MetroText>}
						</MetroText>
						<MetroText h4 style={{ lineHeight: 24 }}>
							{shippingCity}, {shippingState} {shippingPostalCode}
						</MetroText>
					</TouchableFrame>
				</Overlay>
				<AddressWarningOverlay
					warning={warning}
					setWarning={setWarning}
					address={{
						addressLine1: shippingAddressLine1,
						addressLine2: shippingAddressLine2,
						city: shippingCity,
						state: shippingState,
						postalCode: shippingPostalCode,
					}}
					onContinue={onContinue}
					addressType="mailing"
				/>
			</KeyboardAvoidingView>
		</ScreenWrapper>
	)
}
