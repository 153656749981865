import { directDepositAPI } from "."
import { DistributionType } from "./../types"
import { ONE_SECOND } from "../constants/time"

export const getDirectDepositStatus = async (accessToken?: string | null) => {
	directDepositAPI.defaults.headers.common["Authorization"] = `Bearer ${accessToken}`
	const { data } = await directDepositAPI.get("")
	return {
		...data.data,
		status: data.status,
		type: data.type,
		allowPartialDirectDepositChange: data.allowPartialDirectDepositChange,
		requestType: data.data?.requestType || data.data?.clairCare?.requestType,
		directDepositInboxAddress:
			data.data?.directDepositInboxAddress || data.data?.clairCare?.directDepositInboxAddress,
	}
}

export const requestDirectDepositForm = (body?: {
	distributionType?: DistributionType | ""
	distributionValue?: string
}) => {
	return directDepositAPI.post("request-form", body)
}
export const getGeneratedDDFormImage = (params: object) =>
	directDepositAPI.get("form", {
		params: {
			contentType: "image/png",
			...params,
		},
		responseType: "blob",
		timeout: ONE_SECOND * 10,
	})
export const getGeneratedDDFormUrl = (params: object) =>
	directDepositAPI
		.get("form", {
			params: {
				contentType: "application/pdf",
				url: true,
				...params,
			},
			timeout: ONE_SECOND * 10,
		})
		.then((r) => r.data)

export const updateDirectDeposit = (body: object) => directDepositAPI.post("change", { ...body })
