import _ from "lodash"
import Colors from "../constants/Colors"
import I18n from "i18n-js"
import React, { FunctionComponent } from "react"
import useColorScheme from "../hooks/useColorScheme"
import { MetroText } from "./StyledText"
import { Picker as RNPicker } from "@react-native-picker/picker"
import { PickerProps as RNPickerProps, StyleSheet, TextStyle, View } from "react-native"
import { StyleProp } from "react-native"

type PickerProps = {
	label: string
	labelStyle?: StyleProp<TextStyle>
	items: { label: string; value: string }[]
	errorMessage?: string
	hasError?: boolean
	pickerDefault?: string
} & RNPickerProps
export const Picker: FunctionComponent<PickerProps> = ({
	items,
	label,
	labelStyle,
	errorMessage,
	hasError,
	pickerDefault,
	...rest
}) => {
	const theme = useColorScheme()

	return (
		<>
			<MetroText
				style={[styles.pickerLabel, { color: Colors[theme]["textInputLabel"] }, labelStyle]}
			>
				{label}
			</MetroText>
			<View style={styles.wrapper}>
				<RNPicker
					style={[
						styles.pickerStyle,
						{
							color: Colors[theme]["textInputLabel"],
							backgroundColor: Colors[theme]["background"],
						},
					]}
					dropdownIconColor={Colors[theme]["textHighlight"]}
					{...rest}
				>
					<RNPicker.Item label={I18n.t(pickerDefault || "PickerDefault")} value="" />
					{_.map(items, (item) => (
						<RNPicker.Item
							label={item.label}
							value={item.value}
							key={item.value}
							testID={_.camelCase(item.label)}
						/>
					))}
				</RNPicker>
			</View>
			<MetroText style={{ fontSize: 12, marginTop: 10, marginBottom: 5, lineHeight: 12 }} error>
				{hasError ? errorMessage : " "}
			</MetroText>
		</>
	)
}

const styles = StyleSheet.create({
	pickerLabel: {
		fontFamily: "metropolis-regular",
		fontSize: 13,
		marginTop: 28,
	},
	pickerStyle: {
		borderRadius: 0,
		minHeight: 40,
		borderWidth: 0,
		borderBottomWidth: 1,
		flex: 1,
	},
	wrapper: {
		flexDirection: "row",
		alignItems: "stretch",
		width: "100%",
	},
})
