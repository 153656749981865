import _ from "lodash"
import env from "../../../utils/variables"
import four_ball_loading from "../../../assets/images/four_ball_loading.gif"
import I18n from "i18n-js"
import React, { useEffect, useState } from "react"
import { Button, TertiaryButton } from "../../../components/Button"
import { FlexView } from "../../../components/FlexView"
import { getGeneratedDDFormImage, requestDirectDepositForm } from "../../../api/directDeposit"
import { Headline, LegalText, MetroText } from "../../../components/StyledText"
import { Image } from "react-native"
import { OnboardingProgressBar } from "../../../components/OnboardingProgressBar"
import { OnboardingStackParamList } from "../../../types"
import { ScreenWrapper } from "../../../components/ScreenWrapper"
import { StackScreenProps } from "@react-navigation/stack"
import { useInnerContentWidth } from "../../../hooks/useInnerContentWidth"
import { useLinkTo } from "@react-navigation/native"
import { useMutation } from "react-query"
import { useUserContext } from "../../../context/user/UserContext"
import { View } from "../../../components/Themed"

export default function DDFormPreview({}: StackScreenProps<
	OnboardingStackParamList,
	"DDFormPreview"
>) {
	const linkTo = useLinkTo()
	const innerContentWidth = useInnerContentWidth()
	const [previewImage, setPreviewImage] = useState("")
	const [previewFailed, setPreviewFailed] = useState(false)
	const { distributionType = "PERCENTAGE", distributionValue = "100" } = useUserContext()
	const getFormMutation = useMutation(
		() =>
			getGeneratedDDFormImage({
				distributionType,
				distributionValue,
			}),
		{
			onMutate: () => {
				if (previewImage) setPreviewImage("")
				if (env.environment === "dev" && distributionValue == "420") throw new Error()
			},
			onSuccess: ({ data }: { data: Blob }) => {
				const fr = new FileReader()
				fr.readAsDataURL(data)
				fr.onload = () => {
					const base64data = fr.result
					if (typeof base64data == "string") setPreviewImage(base64data)
					else setPreviewFailed(true)
				}
			},
			onError: () => {
				setPreviewFailed(true)
			},
		}
	)

	useEffect(() => {
		if (distributionType && distributionValue) getFormMutation.mutate()
	}, [distributionType, distributionValue])

	return (
		<ScreenWrapper center>
			<OnboardingProgressBar section={1} position={8} />
			<Headline smallText testID="ddFormPreviewHeadline">
				{I18n.t("DDFormPreview.PageHeader")}
			</Headline>
			<MetroText>{I18n.t("DDFormPreview.Body")}</MetroText>
			<div data-private>
				<View
					style={{
						shadowOffset: {
							width: 0,
							height: -0.5,
						},
						shadowOpacity: 0.1,
						shadowRadius: 7,
						minHeight: 423,
						maxHeight: 423,
						width: innerContentWidth,
						elevation: 2,
						justifyContent: "center",
						marginVertical: 18,
					}}
				>
					{previewFailed ? (
						<View
							style={{
								height: "100%",
								justifyContent: "center",
							}}
						>
							<MetroText center style={{ marginHorizontal: 20 }}>
								{I18n.t("DDFormPreview.PreviewFailed")}
							</MetroText>
						</View>
					) : previewImage ? (
						<Image
							style={{ height: "100%", flex: 1 }}
							resizeMode="contain"
							source={{ uri: previewImage }}
						/>
					) : (
						<View
							style={{
								width: "100%",
								height: "100%",
								justifyContent: "center",
								alignItems: "center",
							}}
						>
							<Image style={{ width: 60, height: 60 }} source={{ uri: four_ball_loading }} />
						</View>
					)}
				</View>
			</div>
			<FlexView />
			<Button
				title={I18n.t("DDFormPreview.Button1")}
				onPress={() => linkTo("/SendWithClair")}
				testID="ddFormPreviewButton1"
			/>
			<TertiaryButton
				title={I18n.t("DDFormPreview.Button2")}
				onPress={() => {
					requestDirectDepositForm({
						distributionType,
						distributionValue,
					})
					linkTo("/SendMyself")
				}}
				testID="ddFormPreviewButton2"
			/>
			<LegalText>{I18n.t("BankingServicesFooter")}</LegalText>
		</ScreenWrapper>
	)
}
