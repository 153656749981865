import { createContext, useContext } from "react"
import { TokenResponse } from "../../models/User"
import { UserAction, UserState } from "./userReducer"

type UserContextType = {
	dispatch: (value: UserAction) => void
	login: (emailOrUsername: string, password: string) => Promise<TokenResponse>
} & Partial<UserState>
export const UserContext = createContext<UserContextType>({
	dispatch: () => {},
	login: () => new Promise(() => {}),
})
export const useUserContext = () => useContext(UserContext)
