export const formatSSN = (format: string, data?: string) => {
	// expected format: 9 = number, - = space
	let splitcount = 0
	const split = format?.split("")
	const dataSplit = data?.split("") || []
	const txt = split.map((info, j) => {
		const isLineDash = info == "-"
		const isNumber = info == "9"
		splitcount += isLineDash && j > 0 ? 1 : 0
		const index = splitcount && splitcount > 0 ? j - splitcount : j
		return isNumber ? dataSplit?.length && dataSplit[index] : isLineDash ? "-" : null
	})
	return txt.join("")
}
