import * as SmartySDK from "smartystreets-javascript-sdk"
import _ from "lodash"
import Colors from "../constants/Colors"
import I18n from "i18n-js"
import React, { Dispatch, FunctionComponent, SetStateAction, useContext, useState } from "react"
import useColorScheme from "../hooks/useColorScheme"
import { Address, MailingAddress } from "../models/Address"
import { Bold, SecondaryText } from "./StyledText"
import { FlatList, Platform, Pressable, StyleSheet, View } from "react-native"
import { formatAutocompleteSuggestion } from "../utils/address"
import { Icon } from "react-native-elements"
import { Input } from "./Input"
import { OnboardingContext } from "../context/onboarding/OnboardingContext"
import { SET_ONBOARDING_STATE } from "../context/actionTypes"
import { useStaticConfig } from "../hooks/useStaticConfig"
import { validateField } from "../utils/validate"

type AddressAutoCompleteProps = {
	addressType: "permanent" | "mailing"
	hideResults: boolean
	setHideResults: Dispatch<SetStateAction<boolean>>
}

export type Suggestion = {
	streetLine: string
	secondary: string
	city: string
	state: string
	zipcode: string
	entries: number
}

export const AddressAutoComplete: FunctionComponent<AddressAutoCompleteProps> = ({
	addressType,
	hideResults,
	setHideResults,
}) => {
	const [suggestions, setSuggestions] = useState<Suggestion[] | null>()
	const { addressLine1, shippingAddressLine1, dispatch } = useContext(OnboardingContext)
	const [hasError, setHasError] = useState(false)
	const [pressedItem, setPressedItem] = useState<number | null>()
	const theme = useColorScheme()
	const { data } = useStaticConfig()
	const { smarty_streets } = data || {}
	const addressValue = addressType === "permanent" ? addressLine1 : shippingAddressLine1

	// Set up for the Smarty Streets SDK: https://github.com/smarty/smartystreets-javascript-sdk-react-example
	const SmartyCore = SmartySDK.core
	const smartySharedCredentials = new SmartyCore.SharedCredentials(smarty_streets?.embedded_key)
	const autoCompleteClientBuilder = new SmartyCore.ClientBuilder(
		smartySharedCredentials
	).withLicenses(["us-autocomplete-pro-cloud"])
	const autoCompleteClient = autoCompleteClientBuilder.buildUsAutocompleteProClient()

	const queryAutocompleteForSuggestions = (query: string, hasSecondaries = false) => {
		setPressedItem(null)
		const lookup = new SmartySDK.usAutocompletePro.Lookup(query)
		if (hasSecondaries) {
			lookup.selected = query
		}
		autoCompleteClient
			.send(lookup)
			.then((results) => {
				setSuggestions(results.result)
			})
			.catch((error) => console.error(error))
	}

	const selectSuggestion = (suggestion: Suggestion) => {
		validateField("addressLine1", suggestion.streetLine, setHasError)
		if (suggestion.entries > 1) {
			queryAutocompleteForSuggestions(formatAutocompleteSuggestion(suggestion), true)
		} else {
			acceptAutoCompleteSuggestion(suggestion)
		}
	}

	const acceptAutoCompleteSuggestion = (suggestion: Suggestion) => {
		const payload: Address | MailingAddress =
			addressType === "permanent"
				? {
						addressLine1: suggestion.streetLine,
						addressLine2: suggestion.secondary,
						city: suggestion.city,
						state: suggestion.state,
						postalCode: suggestion.zipcode,
				  }
				: {
						shippingAddressLine1: suggestion.streetLine,
						shippingAddressLine2: suggestion.secondary,
						shippingCity: suggestion.city,
						shippingState: suggestion.state,
						shippingPostalCode: suggestion.zipcode,
				  }
		dispatch({
			type: SET_ONBOARDING_STATE,
			payload,
		})
		setHideResults(true)
	}

	const renderListItem = ({ item, index }: { item: Suggestion; index: number }) => (
		<Pressable
			onPress={() => {
				selectSuggestion(item)
			}}
			onPressIn={() => setPressedItem(index)}
			style={{
				borderWidth: 1,
				borderBottomColor:
					suggestions?.length && index === suggestions.length - 1
						? Colors[theme]["borderAddressSuggestion"]
						: Colors[theme]["skeleton"],
				borderTopColor:
					index === 0 ? Colors[theme]["borderAddressSuggestion"] : Colors[theme]["skeleton"],
				flexDirection: "row",
				minHeight: 60,
				justifyContent: "center",
				alignItems: "center",
				backgroundColor:
					index === pressedItem ? Colors[theme]["tabDefault"] : Colors[theme]["background"],
			}}
		>
			<Icon name="map-marker-alt" size={12} type="font-awesome-5" style={{ padding: 15 }} />
			<View style={{ flex: 1 }}>
				<Bold style={{ fontSize: 13, lineHeight: 18 }}>
					{`${item.streetLine}, ${item.secondary}`}
					{item.entries > 1 ? ` (${item.entries})\n` : ""}
				</Bold>
				<SecondaryText>{`${item.city}, ${item.state} ${item.zipcode}`}</SecondaryText>
			</View>
		</Pressable>
	)

	return (
		<View style={styles.container}>
			<View>
				<View>
					<Input
						label={I18n.t(
							addressType === "permanent"
								? "PermanentAddress.AddressLine1Label"
								: "MailingAddressTwo.AddressLine1Label"
						)}
						value={addressValue}
						autoComplete={Platform.OS === "web" ? "none" : "off"}
						textContentType="streetAddressLine1"
						onChangeText={(value: string) => {
							setHideResults(false)
							const payload =
								addressType === "permanent"
									? { addressLine1: value }
									: { shippingAddressLine1: value }
							dispatch({
								type: SET_ONBOARDING_STATE,
								payload,
							})
							queryAutocompleteForSuggestions(value)
							if (hasError) {
								validateField("addressLine1", value, setHasError)
							}
						}}
						onBlur={() => {
							validateField("addressLine1", addressValue, setHasError)
						}}
						errorMessage={
							_.isEmpty(addressValue)
								? I18n.t("Form.EmptyField", {
										label: I18n.t("PermanentAddress.AddressLine1Label"),
								  })
								: I18n.t("Form.IsRequired", {
										label: I18n.t("PermanentAddress.AddressLine1Label"),
								  })
						}
						hasError={hasError && hideResults}
						testID={
							addressType === "permanent" ? "permAddressLine1Input" : "mailingAddressTwoLine1Input"
						}
					/>
				</View>
				{!hideResults && (
					<View>
						<FlatList
							data={suggestions}
							showsHorizontalScrollIndicator={false}
							style={[
								styles.list,
								{
									borderColor: Colors[theme]["textInputLabel"],
									backgroundColor: Colors[theme]["background"],
								},
							]}
							keyboardShouldPersistTaps="always"
							initialNumToRender={7}
							keyExtractor={(item, index) => index.toString()}
							renderItem={renderListItem}
						/>
					</View>
				)}
			</View>
		</View>
	)
}

const styles = StyleSheet.create({
	list: {
		borderRadius: 1,
		position: "absolute",
		left: 0,
		right: 0,
		width: "100%",
		shadowColor: "rgba(24, 39, 75, 0.12)",
		shadowOffset: { width: 0, height: 6 },
		shadowRadius: 8,
		flexGrow: 0,
		maxHeight: 400,
	},
	container: {
		position: "relative",
		flex: 1,
		width: "100%",
		zIndex: 10,
		border: 0,
	},
})
