import Colors from "../constants/Colors"
import React from "react"
import useColorScheme from "../hooks/useColorScheme"
import { Bold, MetroText } from "./StyledText"
import { StyleSheet, View } from "react-native"

export const InfoBox = ({ title, body }: { title: string; body: string }) => {
	const theme = useColorScheme()

	return (
		<View
			style={{
				backgroundColor: Colors[theme]["backgroundSecondary"],
				padding: 20,
				borderRadius: 8,
				marginVertical: 20,
			}}
		>
			<MetroText style={styles.smallBodyText}>
				<Bold style={styles.smallBodyText}>{title}: </Bold>
				{body}
			</MetroText>
		</View>
	)
}

const styles = StyleSheet.create({
	smallBodyText: {
		fontSize: 13,
		lineHeight: 16,
	},
})
