import I18n from "i18n-js"
import React, { useContext, useState } from "react"
import useAnalytics from "../../../hooks/useAnalytics"
import { Bold, Headline, MetroText } from "../../../components/StyledText"
import { Button } from "@getclair/react-native-storybook"
import { Checkbox } from "../../../components/Checkbox"
import { ImageBullet } from "../../../components/ImageBullet"
import { InfoText } from "../../../components/InfoText"
import { KeyboardAvoidingView, Platform, StyleSheet, View } from "react-native"
import { OnboardingContext } from "../../../context/onboarding/OnboardingContext"
import { OnboardingProgressBar } from "../../../components/OnboardingProgressBar"
import { OnboardingStackParamList } from "../../../types"
import { ScreenWrapper } from "../../../components/ScreenWrapper"
import { SET_ONBOARDING_STATE } from "../../../context/actionTypes"
import { SSNInput } from "../../../components/SSNInput"
import { StackScreenProps } from "@react-navigation/stack"
import { TextUrl } from "../../../components/TextUrl"
import { useSignupNavigation } from "../../../hooks/useSignupNavigation"
import { useStaticConfig } from "../../../hooks/useStaticConfig"
import { validateSSN } from "../../../utils/validateSSN"

export default function SSNScreen({}: StackScreenProps<OnboardingStackParamList, "SSN">) {
	const [error, setError] = useState("")
	const [verifyFocused, setVerifyFocused] = useState(false)
	const [hasAgreedToTerms, setHasAgreedToTerms] = useState(false)
	const [hasAgreedToBankingServices, setHasAgreedToBankingServices] = useState(false)
	const { ssn, ssnVerify, dispatch } = useContext(OnboardingContext)
	const { data } = useStaticConfig()
	const { pdf } = data || {}
	const { track } = useAnalytics()
	const { navigateToNextSignupScreen } = useSignupNavigation()

	const onSubmit = () => {
		setError("")
		if (ssn.length !== 9) {
			setError(I18n.t("SSN.SSNLengthError"))
		} else if (ssnVerify.length !== 9) {
			setError(I18n.t("SSN.SSNVerifyLengthError"))
		} else if (ssn !== ssnVerify) {
			setError(I18n.t("SSN.NoMatch"))
		} else if (!validateSSN(ssn)) {
			setError(I18n.t("SSN.SSNInvalid"))
		} else if (!hasAgreedToTerms) {
			setError(I18n.t("SSN.MustAgreeToTerms"))
		} else if (!hasAgreedToBankingServices) {
			setError(I18n.t("SSN.MustAgreeToBankingServices"))
		} else {
			navigateToNextSignupScreen()
		}
	}

	return (
		<ScreenWrapper>
			<KeyboardAvoidingView
				behavior={Platform.OS == "ios" ? "padding" : "height"}
				keyboardVerticalOffset={120}
			>
				<OnboardingProgressBar position={9} />
				<Headline smallText testID="ssnHeadline">
					{I18n.t("SSN.PageHeader")}
				</Headline>
				<MetroText>{I18n.t("SSN.Body1")}</MetroText>
				<Bold>{I18n.t("SSN.Body2")}</Bold>
				<Bold h4 style={styles.OTPLabelStyle}>
					{I18n.t("SSN.SocialSecurityNumber")}
				</Bold>
				<SSNInput
					setValue={(value) => {
						dispatch({ type: SET_ONBOARDING_STATE, payload: { ssn: value } })
					}}
					onEndReached={() => {
						setVerifyFocused(true)
						track("enter_SSN")
					}}
					hasError={error == I18n.t("SSN.SSNLengthError") || error == I18n.t("SSN.SSNInvalid")}
					iconTestID="ssnToggle"
					inputName="ssn"
				/>
				<Bold h4 style={styles.OTPLabelStyle}>
					{I18n.t("SSN.VerifySSNLabel")}
				</Bold>
				<SSNInput
					setValue={(value) => {
						dispatch({ type: SET_ONBOARDING_STATE, payload: { ssnVerify: value } })
					}}
					onEndReached={() => {
						setVerifyFocused(true)
						track("confirm_SSN")
					}}
					focused={verifyFocused}
					hasError={error == I18n.t("SSN.SSNVerifyLengthError") || error == I18n.t("SSN.NoMatch")}
					iconTestID="ssnConfirmToggle"
					inputName="ssnConfirm"
				/>
				<MetroText style={{ fontSize: 13, lineHeight: 16, marginTop: 9 }} error>
					{error}
				</MetroText>
				<InfoText headline={I18n.t("SSN.InfoText")} body={I18n.t("SSN.OverlayBody")} screen="SSN" />
				<ImageBullet
					image={require("../../../assets/images/Private_blue.png")}
					imageSize={20}
					text={I18n.t("SSN.ImageBulletText")}
					textStyle={styles.CustomBulletText}
					style={{ marginBottom: 40, marginTop: 32 }}
				/>
				<View style={{ flex: 1 }} />
				<Checkbox
					checked={hasAgreedToTerms}
					text={
						<MetroText style={styles.CustomCheckboxText}>
							{I18n.t("SSN.AgreeToTerms")}{" "}
							<TextUrl
								highlight
								textPath={"SSN.TermsOfUse"}
								url={pdf?.terms_of_use?.url}
								testID="ssnTermsLink"
							/>
							,{" "}
							<TextUrl
								highlight
								textPath={"SSN.PrivacyPolicy"}
								url={pdf?.privacy_policy?.url}
								testID="ssnPrivacyLink"
							/>{" "}
							{I18n.t("SSN.And")}{" "}
							<TextUrl
								highlight
								textPath={"SSN.ESIGNAgreement"}
								url={pdf?.e_sign.url}
								testID="ssnESignLink"
							/>
						</MetroText>
					}
					onPress={() => {
						if (!hasAgreedToTerms) track("tap_termsofuse")
						setHasAgreedToTerms(!hasAgreedToTerms)
					}}
					testID="ssnAgreeToClairTerms"
				/>
				<Checkbox
					checked={hasAgreedToBankingServices}
					text={
						<MetroText style={styles.CustomCheckboxText}>
							{I18n.t("SSN.BankingServicesAgree")}{" "}
							<TextUrl
								highlight
								textPath={"SSN.MetaBankDepositAgreement"}
								url={pdf?.meta_bank_deposit_agreement?.url}
								testID="ssnMetaBankLink"
							/>{" "}
							{I18n.t("SSN.And")}{" "}
							<TextUrl
								highlight
								textPath={"SSN.MetaBankSavingsAgreement"}
								url={pdf?.savings_agreement?.url}
								testID="ssnSavingsLink"
							/>
						</MetroText>
					}
					onPress={() => {
						if (!hasAgreedToBankingServices) track("tap_dda")
						setHasAgreedToBankingServices(!hasAgreedToBankingServices)
					}}
					style={{ marginBottom: 20 }}
					testID="ssnAgreetoBankTerms"
				/>
				<Button title={I18n.t("SSN.VerifyMyInformation")} onPress={onSubmit} testID="ssnVerify" />
			</KeyboardAvoidingView>
		</ScreenWrapper>
	)
}

const styles = StyleSheet.create({
	OTPLabelStyle: {
		marginTop: 32,
	},
	SSNInputWrapper: {
		flexDirection: "row",
		alignItems: "center",
		marginTop: 10,
	},
	CustomBulletText: {
		fontSize: 13,
		lineHeight: 20,
		fontFamily: "metropolis-regular",
	},
	CustomCheckboxText: {
		fontSize: 14,
		lineHeight: 22,
		fontFamily: "metropolis-regular",
		marginLeft: 12,
	},
})
