import React, { FunctionComponent, useReducer } from "react"
import { OnboardingContext } from "./OnboardingContext"
import { onboardingReducer } from "./onboardingReducer"
export const INITIAL_ONBOARDING_STATE = {
	id: null,
	phoneNumber: null,
	invitationCode: null,
	firstName: "",
	lastName: "",
	email: "",
	dateOfBirth: "",
	addressLine1: "",
	addressLine2: "",
	shippingAddressLine1: "",
	shippingAddressLine2: "",
	shippingCity: "",
	shippingState: "",
	shippingPostalCode: "",
	city: "",
	state: "",
	postalCode: "",
	industry: null,
	annualSalaryRange: null,
	password: "",
	confirmPassword: "",
	ssn: "",
	ssnVerify: "",
	baseUrl: "",
	hasSubmittedForm: false,
	isPhoneVerified: false,
}

export const OnboardingProvider: FunctionComponent = ({ children }) => {
	const [state, dispatch] = useReducer(onboardingReducer, INITIAL_ONBOARDING_STATE)

	return (
		<OnboardingContext.Provider value={{ ...state, dispatch }}>
			{children}
		</OnboardingContext.Provider>
	)
}
