import _ from "lodash"
import I18n from "i18n-js"
import React, { useContext, useState } from "react"
import { Button } from "../../../components/Button"
import { Headline, LegalText } from "../../../components/StyledText"
import { ImageBullet } from "../../../components/ImageBullet"
import { Input } from "../../../components/Input"
import { KeyboardAvoidingView, Platform } from "react-native"
import { OnboardingContext } from "../../../context/onboarding/OnboardingContext"
import { OnboardingProgressBar } from "../../../components/OnboardingProgressBar"
import { OnboardingStackParamList } from "../../../types"
import { patchState } from "../../../api/onboarding"
import { ScreenWrapper } from "../../../components/ScreenWrapper"
import { SET_ONBOARDING_STATE } from "../../../context/actionTypes"
import { StackScreenProps } from "@react-navigation/stack"
import { useSignupNavigation } from "../../../hooks/useSignupNavigation"
import { validateField, validateForm, ValidationArray } from "../../../utils/validate"

export default function PersonalInfoScreen({}: StackScreenProps<
	OnboardingStackParamList,
	"PersonalInfo"
>) {
	const { firstName, lastName, dateOfBirth, dispatch } = useContext(OnboardingContext)
	const [hasFirstNameError, setHasFirstNameError] = useState(false)
	const [hasLastNameError, setHasLastNameError] = useState(false)
	const [hasDateOfBirthError, setHasDateOfBirthError] = useState(false)
	const [dobErrorMessage, setDobErrorMessage] = useState("")
	const { navigateToNextSignupScreen } = useSignupNavigation()

	const setDateOfBirthError = (hasError: boolean, errorMessage = "") => {
		setHasDateOfBirthError(hasError)
		setDobErrorMessage(errorMessage)
	}
	const onSubmit = () => {
		const formToValidate: ValidationArray = [
			["name", firstName, setHasFirstNameError],
			["name", lastName, setHasLastNameError],
			["dateOfBirth", dateOfBirth, setDateOfBirthError],
		]

		const isValid = validateForm(formToValidate)
		if (isValid) {
			patchState({
				firstName: firstName.trim(),
				lastName: lastName.trim(),
				dateOfBirth,
			})
			navigateToNextSignupScreen()
		}
	}

	return (
		<ScreenWrapper>
			<KeyboardAvoidingView
				behavior={Platform.OS == "ios" ? "padding" : "height"}
				keyboardVerticalOffset={120}
			>
				<OnboardingProgressBar position={5} />
				<Headline smallText testID="personalInfoHeadline">
					{I18n.t("PersonalInfo.PageHeader")}
				</Headline>
				<ImageBullet
					body
					text={I18n.t("PersonalInfo.BulletText")}
					image={require("../../../assets/images/personal_info.png")}
					imageSize={24}
					style={{ marginBottom: 0 }}
				/>
				<Input
					label={I18n.t("PersonalInfo.FirstNameLabel")}
					value={firstName}
					onChangeText={(value: string) => {
						dispatch({
							type: SET_ONBOARDING_STATE,
							payload: { firstName: value },
						})
						if (hasFirstNameError) {
							validateField("name", value, setHasFirstNameError)
						}
					}}
					mask="name"
					onBlur={() => validateField("name", firstName, setHasFirstNameError)}
					errorMessage={
						_.isEmpty(firstName)
							? I18n.t("Form.EmptyField", {
									label: I18n.t("PersonalInfo.FirstNameLabel"),
							  })
							: I18n.t("Form.IsRequired", {
									label: I18n.t("PersonalInfo.FirstNameLabel"),
							  })
					}
					hasError={hasFirstNameError}
					testID="personalInfoFirstNameInput"
				/>
				<Input
					label={I18n.t("PersonalInfo.LastNameLabel")}
					value={lastName}
					onChangeText={(value: string) => {
						dispatch({
							type: SET_ONBOARDING_STATE,
							payload: { lastName: value },
						})
						if (hasLastNameError) {
							validateField("name", value, setHasLastNameError)
						}
					}}
					mask="name"
					onBlur={() => validateField("name", lastName, setHasLastNameError)}
					errorMessage={
						_.isEmpty(lastName)
							? I18n.t("Form.EmptyField", {
									label: I18n.t("PersonalInfo.LastNameLabel"),
							  })
							: I18n.t("Form.IsRequired", {
									label: I18n.t("PersonalInfo.LastNameLabel"),
							  })
					}
					hasError={hasLastNameError}
					testID="personalInfoLastNameInput"
				/>
				<Input
					label={I18n.t("PersonalInfo.DateOfBirthLabel")}
					value={dateOfBirth}
					onChangeText={(value: string) => {
						dispatch({
							type: SET_ONBOARDING_STATE,
							payload: { dateOfBirth: value },
						})
						if (hasDateOfBirthError) {
							validateField("dateOfBirth", value, setDateOfBirthError)
						}
					}}
					placeholder="mm/dd/yyyy"
					mask="date"
					onBlur={() => validateField("date", dateOfBirth, setHasDateOfBirthError)}
					errorMessage={dobErrorMessage}
					hasError={hasDateOfBirthError}
					containerStyle={{ marginBottom: 45 }}
					keyboardType="number-pad"
					testID="personalInfoDOBInput"
				/>
				<Button title={I18n.t("Next")} onPress={onSubmit} testID="personalInfoNext" />
				<LegalText style={{ marginTop: 14 }}>{I18n.t("PersonalInfo.PatriotAct")}</LegalText>
			</KeyboardAvoidingView>
		</ScreenWrapper>
	)
}
