import useAnalytics from "./useAnalytics"
import { INITIAL_USER_STATE } from "../context/user/UserProvider"
import { logout as logoutRequest } from "../api/logout"
import { NavigationContainerRefWithCurrent } from "@react-navigation/native"
import { SET_USER_STATE } from "../context/actionTypes"
import { useOnboardingInit } from "./useOnboardingInit"
import { useUserContext } from "../context/user/UserContext"

export const useLogout = () => {
	const { dispatch } = useUserContext()
	const { track } = useAnalytics()
	const { refetch } = useOnboardingInit()

	const logout = async (navigation: NavigationContainerRefWithCurrent<{}>) => {
		track("exit_widget")
		// need to refetch onboarding data before logging out to determine OTP eligibility
		await refetch()
		await logoutRequest()
		dispatch({ type: SET_USER_STATE, payload: INITIAL_USER_STATE })
		navigation.reset({ routes: [{ name: "Login" }] })
	}
	return logout
}
