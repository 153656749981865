import Colors from "../../constants/Colors"
import I18n from "i18n-js"
import React from "react"
import useColorScheme from "../../hooks/useColorScheme"
import { Button } from "../../components/Button"
import { FeatureImage } from "../../components/FeatureImage"
import { FlexView } from "../../components/FlexView"
import { Headline, LegalText, MetroText } from "../../components/StyledText"
import { OnboardingStackParamList } from "../../types"
import { ScreenWrapper } from "../../components/ScreenWrapper"
import { StackScreenProps } from "@react-navigation/stack"
import { useLinkTo } from "@react-navigation/native"
import { useUserContext } from "../../context/user/UserContext"

export default function CardActivationEntry({
	navigation,
}: StackScreenProps<OnboardingStackParamList, "CardActivationEntry">) {
	const linkTo = useLinkTo()
	const theme = useColorScheme()
	const { usedOTPLogin } = useUserContext()
	const backgroundColor = Colors[theme]["backgroundSuccess"]

	return (
		<ScreenWrapper
			center
			contentContainerStyle={{ backgroundColor }}
			nonMobileContentStyle={{ backgroundColor }}
		>
			<FeatureImage
				medium
				style={{ marginBottom: 30 }}
				source={require("../../assets/images/card_activation.gif")}
			/>
			<Headline center>{I18n.t("CardActivationEntry.PageHeader")}</Headline>
			<MetroText h4 center style={{ marginBottom: 29 }}>
				{I18n.t("CardActivationEntry.Body")}
			</MetroText>
			<FlexView />
			<Button
				title={I18n.t("CardActivationEntry.ButtonText")}
				onPress={() => linkTo(usedOTPLogin ? "/PasswordCheck" : "/SetPin")}
			/>
			<LegalText>{I18n.t("BankingServicesFooter")}</LegalText>
		</ScreenWrapper>
	)
}
