import _ from "lodash"
import Colors from "../../../constants/Colors"
import I18n from "i18n-js"
import React, { useEffect, useState } from "react"
import useAnalytics from "../../../hooks/useAnalytics"
import useColorScheme from "../../../hooks/useColorScheme"
import useGeneratedDDFormUrl from "../../../queries/useGeneratedDDFormUrl"
import { AutoHeightImage } from "../../../components/AutoHeightImage"
import { Bold, Headline, LegalText, MetroText } from "../../../components/StyledText"
import { DirectDepositQuery } from "../../../hooks/useDirectDepositStatus"
import { FAQsLink } from "./DirectDepositFAQLink"
import { FlexView } from "../../../components/FlexView"
import { OnboardingProgressBar } from "../../../components/OnboardingProgressBar"
import { OnboardingStackParamList } from "../../../types"
import { ScreenWrapper } from "../../../components/ScreenWrapper"
import { SecondaryButton, TertiaryButton } from "../../../components/Button"
import { StackScreenProps } from "@react-navigation/stack"
import { updateDirectDeposit } from "../../../api/directDeposit"
import { urls } from "../../../constants/urls"
import { useInnerContentWidth } from "../../../hooks/useInnerContentWidth"
import { useMutation, useQueryClient } from "react-query"
import { useOpenUrl } from "../../../hooks/useOpenUrl"
import { useUserContext } from "../../../context/user/UserContext"
import { View } from "react-native"

export default function SendMyself({
	navigation,
}: StackScreenProps<OnboardingStackParamList, "SendMyself">) {
	const innerContentWidth = useInnerContentWidth()
	const { openUrl } = useOpenUrl()
	const { track } = useAnalytics()
	const theme = useColorScheme()
	const titleColor = Colors[theme]["textHighlight"]
	const [downloadPressed, setDownloadPressed] = useState(false)
	const { distributionType, distributionValue } = useUserContext()
	const { isLoading, data, refetch } = useGeneratedDDFormUrl({
		distributionType,
		distributionValue,
	})
	const { url } = data || { url: null }
	const queryClient = useQueryClient()
	const handleSharedFormMutation = useMutation(
		() =>
			updateDirectDeposit({
				distributionType,
				distributionValue,
			}),
		{
			onMutate: () => {
				queryClient.setQueryData<DirectDepositQuery>("directDeposit", (oldData: any) => ({
					...oldData,
					clairCare: {
						...oldData.clairCare,
						status: "IN_PROGRESS",
						isLatest: true,
					},
					requestType: "SELF_SEND",
				}))
				navigation.reset({ routes: [{ name: "FormPending" }] })
			},
		}
	)

	useEffect(() => {
		refetch()
	}, [distributionType, distributionValue])

	useEffect(() => {
		if (downloadPressed && url) openUrl(url)
	}, [downloadPressed, url])

	return (
		<ScreenWrapper>
			<OnboardingProgressBar section={1} position={9} />
			<Headline smallText testID="sendMyselfHeadline">
				{I18n.t("SendMyself.PageHeader")}
			</Headline>
			<MetroText style={{ maxWidth: innerContentWidth, marginBottom: 20 }}>
				{I18n.t("SendMyself.Body1")}
			</MetroText>
			<TertiaryButton
				loading={downloadPressed && isLoading}
				title={
					<MetroText
						style={{
							color: titleColor,
							fontFamily: "metropolis-semi",
							lineHeight: 16,
						}}
					>
						{I18n.t("SendMyself.Button2")}
						<LegalText>{I18n.t("SendMyself.PDF")}</LegalText>
					</MetroText>
				}
				onPress={() => {
					track("tap_download_form")
					if (!url) setDownloadPressed(true)
					else openUrl(url)
				}}
				icon={
					<AutoHeightImage
						width={38}
						source={require("../../../assets/images/download_pdf.png")}
						style={{ marginRight: 10 }}
					/>
				}
				buttonStyle={{ justifyContent: "flex-start", padding: 0 }}
				testID="sendMyselfDownload"
			/>
			<View
				style={{
					backgroundColor: Colors[theme]["backgroundSecondary"],
					padding: 20,
					borderRadius: 8,
					marginVertical: 20,
				}}
			>
				<MetroText style={{ fontSize: 13, lineHeight: 16 }}>
					<Bold>{I18n.t("SendMyself.Body2")}</Bold>
					{I18n.t("SendMyself.Body3")}
				</MetroText>
			</View>
			<FlexView />
			<SecondaryButton
				title={I18n.t("SendMyself.Button1")}
				onPress={() => handleSharedFormMutation.mutate()}
				testID="sendMyselfShared"
			/>
			<FAQsLink url={urls.directDepositFormFAQUrl} />
			<LegalText>{I18n.t("BankingServicesFooter")}</LegalText>
		</ScreenWrapper>
	)
}
