import I18n from "i18n-js"
import React, { useEffect, useRef } from "react"
import { ActivityIndicator, Animated, useWindowDimensions } from "react-native"
import { AutoHeightImage } from "../../../components/AutoHeightImage"
import { CarouselButton } from "./components/CarouselButton"
import { CarouselIndicator } from "./components/CarouselIndicator"
import { CustomColors } from "./utils"
import { FlexView } from "../../../components/FlexView"
import { LegalText } from "@getclair/react-native-storybook"
import { MaterialTopTabScreenProps } from "@react-navigation/material-top-tabs"
import { ScreenWrapper } from "../../../components/ScreenWrapper"
import { useIsNonMobileScreen } from "../../../hooks/useIsNonMobileScreen"
import { VideoCarouselParamList } from "../../../types"

export default function Carousel1({
	navigation,
}: MaterialTopTabScreenProps<VideoCarouselParamList, "Carousel1">) {
	const fadeAnim = useRef(new Animated.Value(0)).current
	const growAnim = useRef(new Animated.Value(28)).current
	const { width } = useWindowDimensions()
	const isNonMobile = useIsNonMobileScreen()
	const nonMobileWidth = 450

	useEffect(() => {
		fadeIn()
	}, [])

	const fadeIn = async () => {
		// Will change fadeAnim value to 1 in 5 seconds
		Animated.parallel([
			Animated.timing(fadeAnim, {
				toValue: 1,
				duration: 5000,
				useNativeDriver: false,
			}),
			Animated.timing(growAnim, {
				toValue: 32,
				duration: 5000,
				useNativeDriver: false,
			}),
		]).start()
	}

	return (
		<ScreenWrapper
			noPadding
			contentContainerStyle={{
				backgroundColor: CustomColors.PRUSSIAN_BLUE,
				paddingTop: 92,
			}}
			nonMobileContentStyle={{
				paddingTop: 64,
				backgroundColor: CustomColors.PRUSSIAN_BLUE,
				overflow: "hidden",
				maxWidth: nonMobileWidth,
				minWidth: nonMobileWidth,
				minHeight: 900,
			}}
		>
			<ActivityIndicator
				size="large"
				color={"white"}
				style={{
					position: "absolute",
					top: 0,
					left: 0,
					right: 0,
					bottom: 0,
				}}
			/>
			<AutoHeightImage
				source={require("../../../assets/images/carousel_1.gif")}
				width={isNonMobile ? nonMobileWidth : width}
				maxHeight={isNonMobile ? 600 : 778}
			/>
			<FlexView />
			<Animated.View
				style={{
					// Bind opacity to animated value
					opacity: fadeAnim,
				}}
			>
				<CarouselIndicator active={0} />
				<LegalText style={{ marginHorizontal: 24, marginBottom: 16, color: "white" }}>
					{I18n.t("IssuerStatement")}
				</LegalText>
				<CarouselButton
					title="Continue"
					onPress={() => navigation.jumpTo("Carousel2")}
					style={{ marginBottom: 16 }}
				/>
			</Animated.View>
		</ScreenWrapper>
	)
}
