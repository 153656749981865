import Colors from "../../../constants/Colors"
import I18n from "i18n-js"
import React, { useEffect } from "react"
import useColorScheme from "../../../hooks/useColorScheme"
import { AutoHeightImage } from "../../../components/AutoHeightImage"
import { Card } from "../../../components/Card"
import { formatCurrency } from "../../../utils/formatCurrency"
import { Icon } from "react-native-elements"
import { MetroText } from "../../../components/StyledText"
import { StyleSheet, View } from "react-native"
import { useAdvanceOverviewQuery } from "../../../queries/useWageAdvance"
import { useQueryClient } from "react-query"
interface BalanceCardsProps {
	showArrow?: boolean
	pdwDisabled?: boolean
}
export const BalanceCards = ({ showArrow = false, pdwDisabled = false }: BalanceCardsProps) => {
	const theme = useColorScheme()
	const queryClient = useQueryClient()
	const { data: wageAdvanceData, isFetching } = useAdvanceOverviewQuery()
	const { availableForAdvance, spendingAccountBalance } = wageAdvanceData || {}

	useEffect(() => {
		queryClient.invalidateQueries("advanceOverview")
	}, [])

	return (
		<View
			style={{
				justifyContent: "space-between",
				width: "100%",
				flexDirection: "row",
				alignItems: "center",
			}}
		>
			<Card
				loading={isFetching}
				style={[
					styles.card,
					{
						backgroundColor: Colors[theme]["backgroundSecondary"],
						borderTopLeftRadius: 0,
						borderBottomLeftRadius: 0,
					},
				]}
			>
				<AutoHeightImage
					source={require("../../../assets/images/wallet.png")}
					width={25}
					resizeMode="contain"
					style={styles.icon}
				/>
				<MetroText highlight style={[styles.cardTitle]}>
					{I18n.t("PaydayWallet.WalletTitle")}
				</MetroText>
				{pdwDisabled ? (
					<View style={[styles.pdwDisabled, { backgroundColor: Colors[theme]["backgroundError"] }]}>
						<MetroText
							h4
							center
							style={{ color: Colors[theme]["textError"], fontSize: 14, paddingVertical: 3 }}
						>
							{I18n.t("PaydayWallet.NotAvailable")}
						</MetroText>
					</View>
				) : (
					<MetroText h1 highlight style={styles.cardAmount}>
						{formatCurrency(availableForAdvance, true)}
					</MetroText>
				)}
			</Card>
			{showArrow && (
				<Icon name="arrowright" type="antdesign" size={25} color={Colors[theme]["text"]} />
			)}
			<Card
				loading={isFetching}
				style={[
					styles.card,
					{
						borderTopRightRadius: 0,
						borderBottomRightRadius: 0,
					},
				]}
			>
				<AutoHeightImage
					source={require("../../../assets/images/card.png")}
					width={27}
					resizeMode="contain"
					style={styles.icon}
				/>
				<MetroText highlight style={styles.cardTitle}>
					{I18n.t("PaydayWallet.SpendingTitle")}
				</MetroText>
				<MetroText h1 highlight style={styles.cardAmount}>
					{formatCurrency(spendingAccountBalance, true)}
				</MetroText>
				<AutoHeightImage
					source={require("../../../assets/images/mastercard.png")}
					width={32}
					style={{
						position: "absolute",
						right: 21,
						bottom: 16,
						opacity: String(spendingAccountBalance?.toFixed(2)).split(".")[0].length > 3 ? 0.2 : 1,
					}}
				/>
			</Card>
		</View>
	)
}
const styles = StyleSheet.create({
	card: {
		paddingHorizontal: 10,
		width: 165,
		minHeight: 110,
		marginBottom: 25,
		justifyContent: "center",
	},
	cardTitle: { fontWeight: "700", position: "absolute", left: 13, top: 45 },

	cardAmount: {
		position: "absolute",
		left: 11,
		bottom: 11,
	},
	icon: {
		position: "absolute",
		left: 13,
		top: 15,
	},
	pdwDisabled: { position: "absolute", left: 11, bottom: 13, right: 40, borderRadius: 6 },
})
