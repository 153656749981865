import _ from "lodash"
import Colors from "../../../constants/Colors"
import I18n from "i18n-js"
import React, { useContext, useRef, useState } from "react"
import useAnalytics from "../../../hooks/useAnalytics"
import useColorScheme from "../../../hooks/useColorScheme"
import { Button } from "../../../components/Button"
import { Checkbox } from "../../../components/Checkbox"
import { ErrorText, Headline, MetroText } from "../../../components/StyledText"
import { FlexView } from "../../../components/FlexView"
import { Input } from "../../../components/Input"
import { KeyboardAvoidingView, Platform, ScrollView } from "react-native"
import { OnboardingContext } from "../../../context/onboarding/OnboardingContext"
import { OnboardingProgressBar } from "../../../components/OnboardingProgressBar"
import { OnboardingStackParamList } from "../../../types"
import { PasswordBullet } from "../../../components/PasswordBullet"
import { Result, validatePasswordStrength } from "../../../utils/validatePasswordStrength"
import { RightPasswordIcon } from "../../../components/RightPasswordIcon"
import { ScreenWrapper } from "../../../components/ScreenWrapper"
import { SET_ONBOARDING_STATE, SET_USER_STATE } from "../../../context/actionTypes"
import { StackScreenProps } from "@react-navigation/stack"
import { useSignupNavigation } from "../../../hooks/useSignupNavigation"
import { useUserContext } from "../../../context/user/UserContext"
import { validateField, validateForm, ValidationArray } from "../../../utils/validate"
import { validatePassword } from "../../../api/util"

export default function PasswordSetScreen({}: StackScreenProps<
	OnboardingStackParamList,
	"PasswordSet"
>) {
	const theme = useColorScheme()
	const scrollRef = useRef<ScrollView>(null)
	const passwordInput = useRef()
	const confirmPasswordInput = useRef()
	const { password, confirmPassword, dispatch: onboardingDispatch } = useContext(OnboardingContext)
	const { dispatch: userDispatch } = useUserContext()
	const { navigateToNextSignupScreen } = useSignupNavigation()
	const { track } = useAnalytics()
	const [hasPasswordError, setHasPasswordError] = useState(false)
	const [hasConfirmPasswordError, setHasConfirmPasswordError] = useState(false)
	const [isConcealed, setIsConcealed] = useState(true)
	const [isConfirmValidated, setIsConfirmValidated] = useState(false)
	const [isLoading, setIsLoading] = useState(false)
	const [passwordErrorMessage, setPasswordErrorMessage] = useState("")
	const [confirmPasswordErrorMessage, setConfirmPasswordErrorMessage] = useState("")
	const [isPasswordValidated, setIsPasswordValidated] = useState(false)
	const [rememberMe, setRememberMe] = useState(false)
	const [errorMessage, setErrorMessage] = useState("")
	const [passwordValidators, setPasswordValidators] = useState<Result>({
		lowercase: "neutral",
		uppercase: "neutral",
		numeric: "neutral",
		eightChars: "neutral",
		specialChars: "neutral",
		isValid: false,
	})

	const isPasswordFieldValid = !!password && !hasPasswordError && isPasswordValidated
	const isConfirmPasswordFieldValid =
		confirmPassword === password && isPasswordFieldValid && isConfirmValidated

	const setPasswordError = (hasError: boolean, errorMessage = "") => {
		setHasPasswordError(hasError)
		setPasswordErrorMessage(errorMessage)
		setIsPasswordValidated(!hasError)
	}

	const setConfirmPasswordError = (hasError: boolean, errorMessage = "") => {
		setHasConfirmPasswordError(hasError)
		setConfirmPasswordErrorMessage(errorMessage)
		setIsConfirmValidated(!hasError)
	}

	const onSubmit = async () => {
		setIsLoading(true)
		setErrorMessage("")
		const formToValidate: ValidationArray = [
			["password", password, setPasswordError],
			["confirmPassword", confirmPassword, setConfirmPasswordError, password],
		]
		const isFormValid = validateForm(formToValidate)
		if (isFormValid) {
			const isPasswordValid = await validatePassword({ password }).catch((error) =>
				setPasswordError(true, error?.data?.message)
			)
			if (isPasswordValid) {
				navigateToNextSignupScreen()
			}
		} else setErrorMessage(I18n.t("PasswordSet.GenericError"))
		setIsLoading(false)
	}

	return (
		<ScreenWrapper ref={scrollRef}>
			<KeyboardAvoidingView
				behavior={Platform.OS == "ios" ? "padding" : "height"}
				keyboardVerticalOffset={120}
			>
				<OnboardingProgressBar position={4} />
				<Headline smallText testID="passwordSetHeadline">
					{I18n.t("PasswordSet.PageHeader")}
				</Headline>
				<MetroText>{I18n.t("PasswordSet.Body1")}</MetroText>
				<Input
					refInput={passwordInput}
					label={I18n.t("PasswordSet.PasswordLabel")}
					value={isConcealed ? "•".repeat(password.length) : password}
					onBlur={() => {
						validateField("password", password, setPasswordError)
						setPasswordValidators(validatePasswordStrength(password))
					}}
					onChangeText={(value: string) => {
						setErrorMessage("")
						setIsPasswordValidated(false)
						let newValue = value
						if (isConcealed && value.length > password.length) {
							const newText = value.replace(/•/g, "")
							newValue = password + newText
						} else if (isConcealed && value.length < password.length) {
							newValue = password.substring(0, value.length)
						}
						onboardingDispatch({
							type: SET_ONBOARDING_STATE,
							payload: { password: newValue },
						})
					}}
					mask="password"
					autoCorrect={false}
					autoCapitalize="none"
					hasError={hasPasswordError}
					errorMessage={passwordErrorMessage}
					rightIcon={
						<RightPasswordIcon
							valid={isPasswordFieldValid}
							concealed={isConcealed}
							setConcealed={setIsConcealed}
							inputName="passwordInput"
						/>
					}
					inputContainerStyle={{
						borderBottomColor: isPasswordFieldValid
							? Colors[theme]["successGreen"]
							: Colors[theme]["textInputLabel"],
					}}
					returnKeyType="next"
					onSubmitEditing={() => confirmPasswordInput.current?.focus()}
					testID="passwordPasswordInput"
				/>
				<Input
					refInput={confirmPasswordInput}
					label={I18n.t("PasswordSet.ConfirmPasswordLabel")}
					value={isConcealed ? "•".repeat(confirmPassword.length) : confirmPassword}
					onChangeText={(value: string) => {
						setErrorMessage("")
						setIsConfirmValidated(false)
						let newValue = value
						if (isConcealed && value.length > confirmPassword.length) {
							const newText = value.replace(/•/g, "")
							newValue = confirmPassword + newText
						} else if (isConcealed && value.length < confirmPassword.length) {
							newValue = confirmPassword.substring(0, value.length)
						}
						onboardingDispatch({
							type: SET_ONBOARDING_STATE,
							payload: { confirmPassword: newValue },
						})
						if (hasConfirmPasswordError) {
							validateField("confirmPassword", newValue, setConfirmPasswordError, password)
						}
					}}
					autoCapitalize="none"
					autoCorrect={false}
					mask="password"
					errorMessage={confirmPasswordErrorMessage}
					hasError={hasConfirmPasswordError}
					containerStyle={{ marginBottom: 24 }}
					rightIcon={
						<RightPasswordIcon
							valid={isConfirmPasswordFieldValid}
							concealed={isConcealed}
							setConcealed={setIsConcealed}
							inputName="confirmPasswordInput"
						/>
					}
					onBlur={() =>
						validateField("confirmPassword", confirmPassword, setConfirmPasswordError, password)
					}
					inputContainerStyle={{
						borderBottomColor: isConfirmPasswordFieldValid
							? Colors[theme]["successGreen"]
							: Colors[theme]["textInputLabel"],
					}}
					onSubmitEditing={onSubmit}
					returnKeyType="go"
					testID="passwordConfirmInput"
				/>
				<Checkbox
					style={{ alignItems: "center" }}
					text={<MetroText> {I18n.t("PasswordSet.RememberLabel")}</MetroText>}
					checked={rememberMe}
					onPress={() => {
						track("tap_rememberme_password")
						userDispatch({
							type: SET_USER_STATE,
							payload: { rememberUser: !rememberMe },
						})
						setRememberMe(!rememberMe)
					}}
					testID="passwordRememberMe"
				/>
				<MetroText style={{ marginBottom: 15 }}>{I18n.t("PasswordSet.PasswordRule1")}</MetroText>
				<PasswordBullet
					status={passwordValidators.eightChars}
					text={I18n.t("PasswordSet.PasswordRule2")}
				/>
				<PasswordBullet
					status={passwordValidators.lowercase}
					text={I18n.t("PasswordSet.PasswordRule3")}
				/>
				<PasswordBullet
					status={passwordValidators.uppercase}
					text={I18n.t("PasswordSet.PasswordRule4")}
				/>
				<PasswordBullet
					status={passwordValidators.numeric}
					text={I18n.t("PasswordSet.PasswordRule5")}
				/>
				<PasswordBullet
					status={passwordValidators.specialChars}
					text={I18n.t("PasswordSet.PasswordRule6")}
					style={{ marginBottom: 25 }}
				/>
				{!!errorMessage && <ErrorText style={{ marginBottom: 20 }}>{errorMessage}</ErrorText>}
				<FlexView />
				<Button
					title={I18n.t("Next")}
					onPress={onSubmit}
					loading={isLoading}
					disabled={isLoading}
					testID="passwordNext"
				/>
			</KeyboardAvoidingView>
		</ScreenWrapper>
	)
}
