import _ from "lodash"
import Colors from "../../../constants/Colors"
import I18n from "i18n-js"
import parsePhoneNumber from "libphonenumber-js"
import React from "react"
import useAnalytics from "../../../hooks/useAnalytics"
import useColorScheme from "../../../hooks/useColorScheme"
import { Bold, Headline, LegalText, MetroText } from "../../../components/StyledText"
import { Button } from "../../../components/Button"
import { DirectDepositQuery } from "../../../hooks/useDirectDepositStatus"
import { FlexView } from "../../../components/FlexView"
import { InfoText } from "../../../components/InfoText"
import { Input } from "../../../components/Input"
import { OnboardingProgressBar } from "../../../components/OnboardingProgressBar"
import { OnboardingStackParamList } from "../../../types"
import { Overlay } from "../../../components/Overlay"
import { ScreenWrapper } from "../../../components/ScreenWrapper"
import { SEND_WITH_CLAIR_HR_PHONE } from "../../../constants/featureFlags"
import { StackScreenProps } from "@react-navigation/stack"
import { TouchableFrame } from "../../../components/TouchableFrame"
import { updateDirectDeposit } from "../../../api/directDeposit"
import { useAuthenticatedUserQuery } from "../../../hooks/useAuthenticatedUser"
import { useMutation, useQueryClient } from "react-query"
import { useRef } from "react"
import { useState } from "react"
import { useTreatments } from "@splitsoftware/splitio-react"
import { useUserContext } from "../../../context/user/UserContext"
import {
	validateEmailRemote,
	validateField,
	validateForm,
	ValidationArray,
} from "../../../utils/validate"

export default function SendWithClair({
	navigation,
}: StackScreenProps<OnboardingStackParamList, "SendWithClair">) {
	const { track } = useAnalytics()
	const emailInputRef = useRef()
	const [loading, setLoading] = useState(false)
	const [email, setEmail] = useState("")
	const [emailErrorMessage, setEmailErrorMessage] = useState("")
	const [hasEmailError, setHasEmailError] = useState(false)
	const [showSuggestion, setShowSuggestion] = useState(false)
	const [suggestion, setSuggestion] = useState("")
	const [recipientName, setRecipientName] = useState("")
	const [hasRecipientNameError, setHasRecipientNameError] = useState(false)
	const [recipientNameErrorMessage, setRecipientNameErrorMessage] = useState("")
	const [phoneNumber, setPhoneNumber] = useState("")
	const [hasPhoneError, setHasPhoneError] = useState(false)
	const [phoneErrorMessage, setPhoneErrorMessage] = useState("")
	const { distributionType, distributionValue } = useUserContext()
	const { data } = useAuthenticatedUserQuery()
	const { phoneNumber: userPhoneNumber, email: userEmail } = data?.userData || {}
	const queryClient = useQueryClient()
	const theme = useColorScheme()
	const treatments = useTreatments([SEND_WITH_CLAIR_HR_PHONE])

	const updateDirectDepositMutation = useMutation(
		() =>
			updateDirectDeposit({
				humanResourcesEmail: email,
				humanResourcesTitle: recipientName,
				humanResourcesPhoneNumber: phoneNumber,
				distributionType,
				distributionValue,
			}),
		{
			onMutate: () => {
				track("tap_send_form")
				queryClient.setQueryData<DirectDepositQuery>("directDeposit", (oldData: any) => ({
					...oldData,
					clairCare: {
						...oldData.clairCare,
						status: "IN_PROGRESS",
						isLatest: true,
					},
					requestType: "HUMAN_RESOURCES",
				}))
				navigation.reset({ routes: [{ name: "FormPending" }] })
			},
		}
	)

	const setEmailError = (hasError: boolean, errorMessage = "") => {
		setHasEmailError(hasError)
		setEmailErrorMessage(errorMessage)
	}
	const setRecipientNameError = (hasError: boolean, errorMessage = "") => {
		setHasRecipientNameError(hasError)
		setRecipientNameErrorMessage(errorMessage)
	}

	const setPhoneError = (hasError: boolean, errorMessage = "") => {
		setHasPhoneError(hasError)
		setPhoneErrorMessage(errorMessage)
	}

	const isUserPhone = () => {
		if (phoneNumber && userPhoneNumber) {
			const userPhone = parsePhoneNumber(userPhoneNumber)
			const hrPhone = parsePhoneNumber(`+1 ${phoneNumber}`)
			return userPhone?.number === hrPhone?.number
		}
	}

	const onSubmit = async (submittedEmail: string) => {
		if (showSuggestion) setShowSuggestion(false)
		setLoading(true)
		const formToValidate: ValidationArray = [["email", submittedEmail, setHasEmailError]]
		const isValid =
			validateForm(formToValidate) && email?.toLowerCase() !== userEmail?.toLowerCase()
		if (isValid) {
			const { didYouMean, deliverable } = await validateEmailRemote(submittedEmail, setEmailError)
			if (didYouMean && suggestion !== didYouMean) {
				setShowSuggestion(true)
				setSuggestion(didYouMean)
			} else if (deliverable && !hasPhoneError) {
				updateDirectDepositMutation.mutate()
			}
		} else {
			validateField("email", submittedEmail, setEmailError)
			if (email?.toLowerCase() === userEmail?.toLowerCase())
				setEmailError(true, I18n.t("SendWithClair.EmailMatchError"))
		}
		setLoading(false)
	}

	return (
		<ScreenWrapper>
			<OnboardingProgressBar section={1} position={8} />
			<Headline smallText testID="sendWithClair-headline">
				{I18n.t("SendWithClair.PageHeader")}
			</Headline>
			<MetroText>{I18n.t("SendWithClair.Body")}</MetroText>
			<Input
				value={email}
				refInput={emailInputRef}
				autoCompleteType="email"
				keyboardType="email-address"
				autoCorrect={false}
				mask="email"
				autoCapitalize="none"
				returnKeyType="next"
				onBlur={() => validateField("email", email, setEmailError)}
				onChangeText={(value: string) => {
					setEmail(value)
					if (hasEmailError) setHasEmailError(false)
				}}
				errorMessage={emailErrorMessage}
				hasError={hasEmailError}
				label={I18n.t("SendWithClair.EmailAddress")}
				placeholder={I18n.t("SendWithClair.InputPlaceholderEmail")}
				labelStyle={{ color: Colors[theme]["textLegal"] }}
				testID="sendWithClair-input-email"
			/>
			<Input
				value={recipientName}
				mask="name"
				label={I18n.t("SendWithClair.InputLabelManagerName")}
				placeholder={I18n.t("SendWithClair.InputPlaceholderName")}
				errorMessage={recipientNameErrorMessage}
				hasError={hasRecipientNameError}
				onSubmitEditing={() => emailInputRef.current?.focus()}
				onChangeText={(value: string) => {
					setRecipientName(value)
					if (hasRecipientNameError) setHasRecipientNameError(false)
				}}
				onBlur={() => validateField("name", recipientName, setRecipientNameError)}
				labelStyle={{ color: Colors[theme]["textLegal"] }}
				testID="sendWithClair-input-name"
			/>
			{treatments[SEND_WITH_CLAIR_HR_PHONE].treatment === "on" && (
				<Input
					value={phoneNumber}
					mask="phone"
					label={I18n.t("SendWithClair.InputLabelManagerPhone")}
					placeholder={I18n.t("SendWithClair.InputPlaceholderManagerPhone")}
					errorMessage={phoneErrorMessage}
					hasError={hasPhoneError}
					onSubmitEditing={() => emailInputRef.current?.focus()}
					onChangeText={(value: string) => {
						if (hasPhoneError) setPhoneError(false)
						setPhoneNumber(value)
					}}
					onBlur={() => {
						if (phoneNumber) {
							validateField("phone", phoneNumber, setPhoneError)
							if (isUserPhone()) {
								setPhoneError(true, I18n.t("SendWithClair.PhoneMatchError"))
							}
						}
					}}
					labelStyle={{ color: Colors[theme]["textLegal"] }}
					testID="sendWithClair-input-phone"
				/>
			)}
			<InfoText
				headline={I18n.t("SendWithClair.InfoText")}
				body={I18n.t("SendWithClair.OverlayBody")}
				screen="SendWithClair"
			/>
			<FlexView />

			<MetroText h4>{I18n.t("SendWithClair.Footer")}</MetroText>
			<MetroText h3 testID="sendWithClair-footer-text">
				{I18n.t("SendWithClair.FooterBold", { email: userEmail })}
			</MetroText>
			<Button
				title={I18n.t("SendWithClair.Button1")}
				loading={loading}
				onPress={() => onSubmit(email)}
				testID="sendWithClair-button-send"
			/>
			<LegalText>{I18n.t("BankingServicesFooter")}</LegalText>

			<Overlay
				showClose
				isVisible={showSuggestion}
				setShowOverlay={() => setShowSuggestion(false)}
				overlayName="emailSuggestion"
			>
				<MetroText h2 style={{ marginBottom: 32 }} testID="emailOverlayHeadline">
					{I18n.t("SendWithClair.SuggestionHeader")}
				</MetroText>
				<TouchableFrame
					onPress={() => {
						setEmail(suggestion)
						onSubmit(suggestion)
					}}
					testID="acceptEmailSuggestion"
				>
					<MetroText h4 style={{ lineHeight: 24 }}>
						{suggestion}
					</MetroText>
				</TouchableFrame>
				<TouchableFrame
					onPress={() => onSubmit(email)}
					style={{ marginVertical: 24 }}
					testID="useEnteredEmailAddress"
				>
					<Bold highlight>{I18n.t("SendWithClair.SuggestionNo")}</Bold>
					<MetroText h4 style={{ lineHeight: 24 }}>
						{email}
					</MetroText>
				</TouchableFrame>
			</Overlay>
		</ScreenWrapper>
	)
}
