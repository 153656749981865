import { getIndustryOptions } from "../api/getIndustryOptions"
import { useEffect, useState } from "react"

export const useMappedIndustryOptions = () => {
	const [options, setOptions] = useState([])
	useEffect(() => {
		const fetchData = async () => {
			try {
				const options = await getIndustryOptions()
				setOptions(
					options.map((option: string) => {
						return { label: option, value: option }
					})
				)
			} catch (err) {
				console.error(err)
			}
		}
		fetchData()
	}, [])
	return options
}
