import Colors from "../constants/Colors"
import React, { forwardRef, FunctionComponent } from "react"
import useColorScheme from "../hooks/useColorScheme"
import { ScrollView, View } from "./Themed"
import { ScrollViewProps, useWindowDimensions, ViewProps, ViewStyle } from "react-native"
import { useIsNonMobileScreen } from "../hooks/useIsNonMobileScreen"
import { useSafeAreaInsets } from "react-native-safe-area-context"
import {
	NON_MOBILE_MAX_HEIGHT,
	NON_MOBILE_MAX_WIDTH,
	NON_MOBILE_MIN_WIDTH,
} from "../constants/Layout"

type ScreenWrapperProps = {
	center?: boolean
	nonMobileContentStyle?: ViewStyle
	noPadding?: boolean
} & ViewProps &
	ScrollViewProps

export const ScreenWrapper: FunctionComponent<ScreenWrapperProps> = forwardRef(
	(
		{
			center,
			contentContainerStyle,
			nonMobileContentStyle,
			noPadding,
			...rest
		}: ScreenWrapperProps,
		ref
	) => {
		const windowHeight = useWindowDimensions().height
		const theme = useColorScheme()
		const isNonMobile = useIsNonMobileScreen()
		const safeAreaInsets = useSafeAreaInsets()

		if (isNonMobile) {
			return (
				<ScrollView
					ref={ref}
					style={{ flex: 1, backgroundColor: Colors[theme]["backgroundDesktopFrame"] }}
				>
					<View
						style={[
							{
								flex: 1,
								paddingHorizontal: noPadding ? 0 : 24,
								alignItems: center ? "center" : "stretch",
								minWidth: NON_MOBILE_MIN_WIDTH,
								maxWidth: NON_MOBILE_MAX_WIDTH,
								minHeight: NON_MOBILE_MAX_HEIGHT,
								alignSelf: "center",
								borderRadius: 16,
								position: "absolute",
								top: windowHeight / 10,
								paddingTop: 18,
								paddingBottom: noPadding ? 0 : 45,
								shadowOffset: {
									width: 0,
									height: 8,
								},
								shadowRadius: 15,
								shadowColor: "rgba(0, 0, 0, 0.25)",
								shadowOpacity: 1,
							},
							nonMobileContentStyle,
						]}
						{...rest}
					/>
				</ScrollView>
			)
		}
		return (
			<ScrollView
				ref={ref}
				contentContainerStyle={[
					{
						flexGrow: 1,
						paddingHorizontal: noPadding ? 0 : 24,
						alignItems: center ? "center" : "stretch",
						paddingBottom: noPadding ? 0 : 45,
						paddingTop: 18,
						marginBottom: safeAreaInsets.bottom,
					},
					contentContainerStyle,
				]}
				{...rest}
			/>
		)
	}
)
