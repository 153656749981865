import _ from "lodash"
import I18n from "i18n-js"
import PendingTextImage from "../../../assets/images/pending_text.png"
import React, { useEffect, useState } from "react"
import useDirectDepositStatus from "../../../hooks/useDirectDepositStatus"
import { FeatureImage } from "../../../components/FeatureImage"
import { FlexView } from "../../../components/FlexView"
import { Headline, LegalText, MetroText } from "../../../components/StyledText"
import { OnboardingProgressBar } from "../../../components/OnboardingProgressBar"
import { OnboardingStackParamList } from "../../../types"
import { ScreenWrapper } from "../../../components/ScreenWrapper"
import { StackScreenProps } from "@react-navigation/stack"
import { useRefetchInterval } from "../../../hooks/useRefetchInterval"

export default function PendingTextScreen({
	navigation,
}: StackScreenProps<OnboardingStackParamList, "PendingText">) {
	const [refetchInterval, setRefetchInterval] = useState<false | number>(false)
	const { data, isFetching } = useDirectDepositStatus(refetchInterval)
	const updatedInterval = useRefetchInterval(isFetching)

	useEffect(() => {
		setRefetchInterval(updatedInterval)
	}, [updatedInterval])

	// if the status updates on refetch, navigate the user
	useEffect(() => {
		if (data?.status == "ACTION_REQUIRED") {
			navigation.reset({
				routes: [{ name: data?.forceManual ? "TryAnotherWay" : "TryAgain" }],
			})
		} else if (data?.status == "COMPLETED") {
			navigation.reset({
				routes: [{ name: "DDSuccess" }],
			})
		}
	}, [data?.status])

	return (
		<ScreenWrapper center>
			<OnboardingProgressBar section={1} position={6} />
			<FeatureImage medium source={PendingTextImage} />
			<Headline center>{I18n.t("PendingText.PageHeader")}</Headline>
			<MetroText h4 center style={{ paddingHorizontal: 0 }}>
				{I18n.t("PendingText.Body")}
			</MetroText>
			<FlexView />
			<LegalText>{I18n.t("BankingServicesFooter")}</LegalText>
		</ScreenWrapper>
	)
}
