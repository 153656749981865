import I18n from "i18n-js"
import React, { useContext } from "react"
import { Button } from "../../components/Button"
import { FeatureImage } from "../../components/FeatureImage"
import { FlexView } from "../../components/FlexView"
import { MetroText } from "../../components/StyledText"
import { QueryParamContext } from "../../context/queryParam/QueryParamContext"
import { ScreenWrapper } from "../../components/ScreenWrapper"
import { useBroadcastEvent } from "../../hooks/useBroadcastEvent"
import { useOnboardingInit } from "../../hooks/useOnboardingInit"

export default function KYCFailScreen() {
	const { data: onboardingData } = useOnboardingInit()
	const broadcastEvent = useBroadcastEvent()
	const { firstName } = onboardingData || {}
	const { inSdk } = useContext(QueryParamContext)
	const handlePrimaryAction = () =>
		broadcastEvent({ event: "clair-onboarding-complete", payload: { status: "Fail" } })

	return (
		<ScreenWrapper>
			<FeatureImage medium source={require("../../assets/images/kyc_fail.png")} />
			<MetroText h4 style={{ marginBottom: 25 }}>
				{I18n.t("KYCFail.Salutation")} {firstName},
			</MetroText>
			<MetroText h4 style={{ marginBottom: 25 }} testID="kycFailText">
				{I18n.t("KYCFail.Body1")}
			</MetroText>
			<MetroText h4 style={{ marginBottom: 25 }}>
				{I18n.t("KYCFail.Body2")}
			</MetroText>
			<MetroText h4>{I18n.t("KYCFail.Signature")},</MetroText>
			<MetroText h4 style={{ marginBottom: 25 }}>
				Clair
			</MetroText>
			<FlexView />
			{inSdk && (
				<Button title={I18n.t("Return")} onPress={handlePrimaryAction} testID="kycFailReturn" />
			)}
		</ScreenWrapper>
	)
}
