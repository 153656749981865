import Colors from "../constants/Colors"
import I18n from "i18n-js"
import React, { FunctionComponent } from "react"
import useColorScheme from "../hooks/useColorScheme"
import { AutoHeightImage } from "./AutoHeightImage"
import { Image, View } from "react-native"
import { MetroText } from "./StyledText"

type WalletButtonIconProps = {
	walletType?: string
	buttonType?: string
}

export const WalletButtonIcon: FunctionComponent<WalletButtonIconProps> = ({
	walletType = "Google",
	buttonType = "secondary",
}) => {
	const theme = useColorScheme()
	const titleColor =
		buttonType === "primary" ? Colors[theme]["textButtonDefault"] : Colors[theme]["textHighlight"]

	return (
		<View
			style={{
				flexDirection: "row",
				alignItems: "center",
				justifyContent: "center",
				marginLeft: 5,
				zIndex: 10,
			}}
		>
			{walletType !== "Samsung" ? (
				<AutoHeightImage
					width={45}
					source={
						walletType === "Apple"
							? buttonType === "primary"
								? require("../assets/images/apple_pay_icon_white.png")
								: require("../assets/images/apple_pay_icon.png")
							: buttonType === "primary"
							? require("../assets/images/google_pay_icon_white.png")
							: require("../assets/images/google_pay_icon.png")
					}
					resizeMode="contain"
				/>
			) : (
				<Image
					style={{ height: 34, width: 34, paddingTop: 5, marginVertical: 3 }}
					source={
						buttonType === "primary"
							? require("../assets/images/samsung_pay_icon_white.png")
							: require("../assets/images/samsung_pay_icon.png")
					}
				/>
			)}

			<MetroText
				style={{
					color: titleColor,
					fontFamily: "metropolis-semi",
					fontSize: 14,
					lineHeight: 24,
					marginLeft: 5,
				}}
			>
				{I18n.t("Now")}
			</MetroText>
		</View>
	)
}
