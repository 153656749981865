import _ from "lodash"
import Colors from "../constants/Colors"
import React from "react"
import useColorScheme from "../hooks/useColorScheme"
import { ActivityIndicator, Image, StyleSheet, View } from "react-native"
import { MetroText } from "./StyledText"
import { ViewProps } from "./Themed"

type LoadingProps = {
	showLogo?: boolean
	text?: string
} & ViewProps

export default function Loading({ showLogo, text }: LoadingProps) {
	const theme = useColorScheme()

	return (
		<View
			style={[
				{
					backgroundColor: Colors[theme]["backgroundSplashDefault"],
					justifyContent: text ? "center" : "space-around",
				},
				styles.coverStyle,
			]}
		>
			{showLogo && (
				<Image
					source={
						theme == "light"
							? require("../assets/images/logo.png")
							: require("../assets/images/logo_white.png")
					}
					style={{ width: 130, height: 130, marginBottom: 40 }}
					resizeMode="contain"
				/>
			)}
			<ActivityIndicator size="large" color={Colors[theme]["textHighlight"]} />
			{!!text && <MetroText style={{ marginVertical: 8 }}>{text}</MetroText>}
		</View>
	)
}

const styles = StyleSheet.create({
	coverStyle: {
		position: "absolute",
		top: 0,
		bottom: 0,
		left: 0,
		right: 0,
		zIndex: 99,
		alignItems: "center",
	},
})
