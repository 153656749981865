import analytics from "../analytics"
import LogRocket from "logrocket"
import { IUser } from "../models/User"
import { onboardingAPI } from "."
import { queryClient } from "../App"

/**
 * The registration widget will forward the `businessId ` and `employeeId` parameters that were provided by
 * the embedding application to all endpoints.
 * Invalid `businessId ` or `employeeId` values will result in HTTP status 404 responses from all endpoints.
 *
 * Additionally, all requests from the registration widget must include the `src` parameter that was provided by the
 * embedding application on the `X-CLAIR-WIDGET-SRC` header.
 * An unrecognized `src` will result in HTTP status 403 responses from all endpoints.
 *
 * All requests must also include 'widgetId', to be passed in as 'X-CLAIR-WIDGET-ID' header. A widgetId that
 * does not match the accompanying businessId and employeeId combination will result in a HTTP status 403 response.
 */

export type InitResponse = {
	userId: number
	allowPhoneEdit: boolean
	firstName: string
	partnerFriendlyName: string
	partnerImageUrl: string
	status: "Complete" | "KYC Fail" | "Submitted" | "Invited" | "Created"
	hasState: boolean
	hideCarousel: boolean
	ewaEnabled: boolean
	idologyIdScanUrl: string
	phoneNumberLastFour: string
	businessMaxCreditLine: number
	gracePeriodMaxCreditLine: number
	origin: string
	maskedEmail: string
	username: string
	isOtpAuthEligible: boolean
}
/**
 * Should be called as part of the registration widget bootstrapping process.
 * This endpoint will return the `status` value for the corresponding user (see statuses listed above) allowing
 * the widget to conditionally display the registration flow or the appropriate feedback screen for a status upon render.
 * It will also provide the corresponding users `firstName` value which will be displayed on the initial
 * splash screen for the registration flow.
 *
 * The response data for this endpoint will be extended as needed.
 */
export const onboardingInit = async (): Promise<InitResponse> => {
	const { data } = await onboardingAPI.get("init")
	analytics.identify(data.userId, data)
	LogRocket.identify(data.userId, data)
	return data
}

/**
 * The send invitation code endpoint will dispatch an SMS notification to the provided number that contains
 * the invitation code for the corresponding Clair user.
 *
 * A successful response will have HTTP status 204.
 * If an invalid phone number is provided, or the provided phone number is already in use by another
 * Clair user the standard Laravel validation error response with HTTP status 422 and details of the validation
 * violation(s) will be returned.
 */
export const sendInvitationCode = async (payload: { phoneNumber: string }) => {
	return onboardingAPI.post("send-invitation-code", payload)
}

export type VerifyInvitationCodeResponse = {
	providedUser: IUser
	cachedUser: IUser
}
/**
 * The verify invitation code endpoint should be called after the SMS notification containing the user's
 * invitation code has been dispatched.
 * Calls to this endpoint will contain the invitation code value that the user-provided.
 *
 * If the provided invitation code value matches the invitation code value that was sent via SMS,
 * the user's phone number will be set to the phone number value that the SMS notification was sent to.
 * The response data will be identical to the `GET /api/v2/invitation/{invitationCode}` endpoint that is utilized
 * by the existing mobile app onboarding flow.
 * The exception will be the addition of a `state` key that will contain the last `state` value provided to the
 * save state endpoint (outlined below) for the employee.
 * If the `state` value is `null`, the widget should not prompt the user to pick up from where they left off.
 *
 * If the provided invitation code value does not match the invitation code that was included in the SMS notification
 * a response with HTTP status 400 will be returned.
 */
export const verifyInvitationCode = async (
	invitationCode: string
): Promise<VerifyInvitationCodeResponse> => {
	const { data } = await onboardingAPI.get(`verify-invitation-code/${invitationCode}`)
	analytics.identify(data.data.id, data.data)
	LogRocket.identify(data.data.id, data.data)
	return { providedUser: data.data, cachedUser: data.state }
}

/**
 * The patch state endpoint will persist the serialized state of the widget's registration form.
 * The raw request body will be persisted. The last provided state value for each form field will be
 * provided on the successful response of the verify invitation code endpoint.
 *
 * The registration widget should call this endpoint as the registration form state changes.
 */
export const patchState = async (state: {}) => {
	await onboardingAPI.patch("patch-state", state)
	queryClient.invalidateQueries("onboardingUser")
}

/**
 * The save state endpoint can also be called in the same way as patch state with the exception that it
 * will overwrite the last provided state.
 */
export const startNewState = async (state: {}) => {
	await onboardingAPI.post("save-state", state)
	queryClient.invalidateQueries("onboardingUser")
}

/**
 * The register endpoint request/response is identical to the `PATCH /api/v2/invitation/{invitationCode}` endpoint
 * that is utilized by the existing mobile app onboarding flow.
 * The exception being the removal of the `password` and `password_confirmation` fields from the request body.
 */
export const register = async (user: IUser) => {
	const timeout = 1000 * 60 * 2
	const { data } = await onboardingAPI.post(`register/${user.invitationCode}`, user, { timeout })
	return data
}

export const sendAppDownloadNotification = () => {
	return onboardingAPI.post("app-download-notification")
}
