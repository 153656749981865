import I18n from "i18n-js"
import React from "react"
import useAnalytics from "../../../hooks/useAnalytics"
import { FlexView } from "../../../components/FlexView"
import { Headline, LegalText, MetroText } from "../../../components/StyledText"
import { OnboardingProgressBar } from "../../../components/OnboardingProgressBar"
import { OnboardingStackParamList } from "../../../types"
import { ScreenWrapper } from "../../../components/ScreenWrapper"
import { SecondaryButton, TertiaryButton } from "../../../components/Button"
import { SET_USER_STATE } from "../../../context/actionTypes"
import { StackScreenProps } from "@react-navigation/stack"
import { useDirectDepositOptions } from "../../../hooks/useDirectDepositStatus"
import { useLinkTo, useNavigationState } from "@react-navigation/native"
import { useUserContext } from "../../../context/user/UserContext"

export default function CalculatorEntry({
	navigation,
	route,
}: StackScreenProps<OnboardingStackParamList, "CalculatorEntry">) {
	const { isFormEdit } = route.params || {}
	const linkTo = useLinkTo()
	const { track } = useAnalytics()
	const { dispatch } = useUserContext()
	const { showManualOptions } = useDirectDepositOptions()
	const isTopOfStack = useNavigationState((state) => state.index) == 0
	const optionsRoute = `/Options?showManual=${!!showManualOptions}`

	return (
		<ScreenWrapper center>
			<OnboardingProgressBar section={1} position={isFormEdit && isFormEdit !== "false" ? 7 : 4} />
			<Headline testID="calculatorEntryHeadline">{I18n.t("CalculatorEntry.PageHeader")}</Headline>
			<MetroText style={{ marginBottom: 24 }}>{I18n.t("CalculatorEntry.Body")}</MetroText>
			<SecondaryButton
				title={I18n.t("CalculatorEntry.Button1")}
				onPress={() => {
					dispatch({
						type: SET_USER_STATE,
						payload: { distributionType: "PERCENTAGE", distributionValue: "100" },
					})
					isFormEdit
						? navigation.reset({
								routes: [
									{ name: "Options", params: { showManual: !!showManualOptions } },
									{ name: "VerifyFormInfo" },
								],
						  })
						: linkTo(optionsRoute)
				}}
				testID="calculatorEntryButton1"
			/>
			<SecondaryButton
				title={I18n.t("CalculatorEntry.Button2")}
				onPress={() => linkTo(`/CalculatorAmount?isFormEdit=${!!isFormEdit}`)}
				testID="calculatorEntryButton2"
			/>
			<SecondaryButton
				title={I18n.t("CalculatorEntry.Button3")}
				onPress={() => linkTo(`/CalculatorPercentage?isFormEdit=${!!isFormEdit}`)}
				testID="calculatorEntryButton3"
			/>
			<FlexView />

			{!isTopOfStack && (
				<TertiaryButton
					title={I18n.t("CalculatorEntry.Skip")}
					onPress={() => {
						track("tap_skip_CalculatorEntry")
						linkTo(optionsRoute)
					}}
					testID="calculatorEntryButton4"
				/>
			)}
			<LegalText>{I18n.t("BankingServicesFooter")}</LegalText>
		</ScreenWrapper>
	)
}
