import _ from "lodash"
import Colors from "../constants/Colors"
import I18n from "i18n-js"
import React, { useEffect, useState } from "react"
import useAnalytics from "../hooks/useAnalytics"
import useColorScheme from "../hooks/useColorScheme"
import { Bold, ErrorText, Headline, MetroText, P3 } from "../components/StyledText"
import { Button, TertiaryButton } from "../components/Button"
import { ButtonGroup } from "../components/ButtonGroup"
import { Checkbox } from "../components/Checkbox"
import { FlexView } from "../components/FlexView"
import { Input } from "../components/Input"
import { KeyboardAvoidingView, Platform, View } from "react-native"
import { LabeledText } from "../components/LabeledText"
import { OnboardingStackParamList } from "../types"
import { otpLoginAPI } from "../api"
import { Overlay } from "../components/Overlay"
import { ScreenWrapper } from "../components/ScreenWrapper"
import { SET_USER_STATE } from "../context/actionTypes"
import { StackScreenProps } from "@react-navigation/stack"
import { useAuthenticatedUserQuery } from "../hooks/useAuthenticatedUser"
import { useIsFocused, useLinkTo } from "@react-navigation/native"
import { useIsThinScreen } from "../hooks/useIsThinScreen"
import { useMutation } from "react-query"
import { useOnboardingInit } from "../hooks/useOnboardingInit"
import { useUserContext } from "../context/user/UserContext"
import { validateField } from "../utils/validate"

export function Login({ navigation }: StackScreenProps<OnboardingStackParamList, "AlreadyPassed">) {
	const { login, dispatch } = useUserContext()
	const theme = useColorScheme()
	const isThinScreen = useIsThinScreen()
	const linkTo = useLinkTo()
	const isFocused = useIsFocused()
	const { track } = useAnalytics()
	const [loading, setLoading] = useState(false)
	const [password, setPassword] = useState("")
	const [authError, setAuthError] = useState("")
	const [otpError, setOtpError] = useState("")
	const [showPassword, setShowPassword] = useState(false)
	const [passwordInvalid, setPasswordInvalid] = useState(false)
	const [rememberMe, setRememberMe] = useState(false)
	const [modalVisible, setModalVisible] = useState(false)
	const { isLoading } = useAuthenticatedUserQuery()
	const { data } = useOnboardingInit()
	// The username is the uuid sent from the BE in the onboardingInit response.
	const { isOtpAuthEligible, maskedEmail, username } = data || {
		username: "",
		isOtpAuthEligible: false,
		maskedEmail: "",
	}

	useEffect(() => {
		if (!isFocused) {
			setPassword("")
			setRememberMe(false)
		}
	}, [isFocused])

	const handleLogin = async () => {
		setLoading(true)
		track("tap_login")
		try {
			await login(username, password)
			linkTo("/Splash")
		} catch (err) {
			if (err?.status == 403) return setModalVisible(true)

			setAuthError(
				I18n.t(
					err?.status >= 400 && err?.status < 500
						? "Login.PasswordErrorUnauthorized"
						: "Login.PasswordErrorGeneric"
				)
			)
		} finally {
			setLoading(false)
		}
	}

	const requestOTPMutation = useMutation(
		(method: "email" | "sms") => otpLoginAPI.post(`/${method}`),
		{
			onSuccess: (_data, method) => navigation.navigate("LoginVerifyOTP", { method }),
			onError: () => setOtpError(I18n.t("Login.OTPError")),
		}
	)

	return (
		<ScreenWrapper center>
			<KeyboardAvoidingView
				behavior={Platform.OS == "ios" ? "padding" : "height"}
				style={{ flex: 1, width: "100%" }}
			>
				<Headline smallText style={{ marginTop: 20, marginBottom: 45 }} testID="loginHeadline">
					{I18n.t("Login.PageHeader")}
				</Headline>
				<LabeledText
					labelText={I18n.t("Login.EmailLabel")}
					labelTextStyle={{ fontSize: 13 }}
					valueText={maskedEmail}
					valueTextStyle={{ fontSize: 16 }}
					rightLabel={I18n.t("Login.NotYou")}
					onRightLabelPress={() => {
						track("tap_notyou_login")
						setModalVisible(!modalVisible)
					}}
					style={{ flex: 0, marginBottom: 32 }}
				/>
				<Input
					label={<P3>{I18n.t("Login.PasswordLabel")}</P3>}
					value={password}
					onChangeText={(value: string) => {
						setPassword(value)
						validateField("password", value, setPasswordInvalid)
						if (authError) setAuthError("")
					}}
					onBlur={() => validateField("password", password, setPasswordInvalid)}
					mask="password"
					secureTextEntry={showPassword ? false : true}
					autoCapitalize="none"
					hasError={passwordInvalid || Boolean(authError)}
					errorMessage={
						_.isEmpty(password)
							? I18n.t("Form.EmptyField", { label: I18n.t("Login.PasswordLabel") })
							: authError
					}
					rightIcon={{
						type: "font-awesome-5",
						name: !showPassword ? "eye" : "eye-slash",
						color: Colors[theme]["textHighlight"],
						size: 14,
						hitSlop: { top: 20, bottom: 20, left: 20, right: 20 },
						onPress: () => setShowPassword(!showPassword),
						containerStyle: { marginLeft: isThinScreen ? 10 : 20 },
					}}
					testID="loginPasswordInput"
				/>
				<Overlay
					overlayName="login"
					showClose
					isVisible={modalVisible}
					setShowOverlay={() => setModalVisible(!modalVisible)}
					style={{ paddingVertical: 40, paddingBottom: 10 }}
				>
					<MetroText center style={{ padding: 20, fontSize: 17, lineHeight: 22, marginBottom: 10 }}>
						{I18n.t("Login.OverlayBody")}
					</MetroText>

					<Button
						title={I18n.t("Close")}
						onPress={() => setModalVisible(!modalVisible)}
						testID="loginOverlayButton"
					/>
				</Overlay>
				<Checkbox
					style={{ alignItems: "center" }}
					text={<MetroText>{I18n.t("Login.RememberLabel")}</MetroText>}
					checked={rememberMe}
					onPress={() => {
						track("tap_rememberme_login")
						dispatch({
							type: SET_USER_STATE,
							payload: { rememberUser: !rememberMe },
						})
						setRememberMe(!rememberMe)
					}}
					testID="loginRememberMe"
				/>
				<Button
					title={I18n.t("Login.Button1")}
					onPress={handleLogin}
					loading={isLoading || loading}
					containerStyle={{ width: "100%" }}
					buttonStyle={{ minWidth: "100%" }}
					testID="loginButton"
				/>
				{isOtpAuthEligible && (
					<>
						<View
							style={{
								flexDirection: "row",
								marginVertical: 20,
								alignItems: "center",
							}}
						>
							<View
								style={{
									backgroundColor: Colors[theme]["borderDDBox"],
									height: 2,
									flex: 1,
								}}
							/>
							<Bold style={{ paddingHorizontal: 12 }}>{I18n.t("Login.Or")}</Bold>
							<View
								style={{
									backgroundColor: Colors[theme]["borderDDBox"],
									height: 2,
									flex: 1,
								}}
							/>
						</View>
						<ButtonGroup
							buttons={[
								{
									label: I18n.t("Login.Button3"),
									onPress: () => {
										track("tap_smsotp_login")
										requestOTPMutation.mutate("sms")
									},
								},
								{
									label: I18n.t("Login.Button4"),
									onPress: () => {
										track("tap_emailotp_login")
										requestOTPMutation.mutate("email")
									},
								},
							]}
						/>
						{otpError && <ErrorText>{otpError}</ErrorText>}
					</>
				)}
				<FlexView />
				<TertiaryButton
					title={I18n.t("Login.Button2")}
					titleStyle={{ fontFamily: "metropolis-semi" }}
					onPress={() => linkTo("/ResetPassword")}
					testID="loginForgotPassword"
				/>
			</KeyboardAvoidingView>
		</ScreenWrapper>
	)
}
