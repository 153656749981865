import React from "react"
import { OnboardingStackParamList } from "../../types"
import { parse } from "expo-linking"
import { StackScreenProps } from "@react-navigation/stack"
import { WebView } from "react-native-web-webview"

export function DocumentViewer({
	route,
}: StackScreenProps<OnboardingStackParamList, "DocumentViewer">) {
	const { uri } = route.params
	let { hostname } = parse(uri)
	let targetUri = new URL(uri)
	// Hides the Clair header on getclair.com's articles
	if (hostname == "getclair.com") targetUri.searchParams.append("hc", "1")

	return (
		<WebView
			source={{ uri: targetUri }}
			startInLoadingState
			originWhitelist={["http://*", "https://*"]}
			style={{ flexGrow: 1 }}
		/>
	)
}
