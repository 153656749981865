import * as Clipboard from "expo-clipboard"
import Colors from "../constants/Colors"
import I18n from "i18n-js"
import React, { FunctionComponent } from "react"
import useColorScheme from "../hooks/useColorScheme"
import { Bold, MetroText } from "./StyledText"
import { Icon } from "react-native-elements"
import { TouchableOpacity, View } from "react-native"
import { useIsThinScreen } from "../hooks/useIsThinScreen"

type SingleItemDataCardProps = {
	title: string
	value: string
	secretValue?: string
	setVisibility?: () => void
	onCopy?: () => void
	visible?: boolean
}
export const SingleItemDataCard: FunctionComponent<SingleItemDataCardProps> = ({
	title,
	value,
	secretValue,
	setVisibility,
	onCopy,
	visible = true,
	...rest
}) => {
	const theme = useColorScheme()
	const isThinScreen = useIsThinScreen()

	return (
		<View
			style={{
				flexDirection: "row",
				justifyContent: "center",
				borderWidth: 1,
				borderColor: Colors[theme]["borderDefault"],
				backgroundColor: Colors[theme]["background"],
				borderRadius: 8,
				paddingLeft: isThinScreen ? 12 : 28,
				paddingRight: isThinScreen ? 12 : 24,
				paddingVertical: 18,
				marginBottom: 16,
			}}
			{...rest}
		>
			<View style={{ flex: 1 }}>
				<MetroText
					style={{
						fontSize: 14,
						lineHeight: 20,
						color: Colors[theme]["text"],
						overflow: "visible",
					}}
					numberOfLines={1}
					adjustsFontSizeToFit
				>
					{title}
				</MetroText>
				<Bold
					style={{
						fontSize: isThinScreen ? 14 : 16,
						lineHeight: 24,
						overflow: "visible",
						color: Colors[theme]["text"],
					}}
					numberOfLines={1}
					adjustsFontSizeToFit
				>
					{value}
				</Bold>
			</View>
			<View style={{ alignItems: "center", justifyContent: "flex-end", flexDirection: "row" }}>
				{setVisibility && (
					<Icon
						name={visible ? "eye-slash" : "eye"}
						type="font-awesome-5"
						size={14}
						color={Colors[theme]["borderAddressSuggestion"]}
						onPress={setVisibility}
						hitSlop={{ top: 10, bottom: 10, left: 10, right: 10 }}
						containerStyle={{ marginRight: isThinScreen ? 10 : 24 }}
						testID={`${title?.replace(/ +/g, "-").toLowerCase()}-visibility-icon`}
					/>
				)}
				<TouchableOpacity
					style={{
						borderWidth: 1,
						borderColor: Colors[theme]["borderDefault"],
						borderRadius: 8,
						paddingHorizontal: 10,
					}}
					onPress={() => {
						onCopy && onCopy()
						Clipboard.setString(secretValue || value)
					}}
					testID={`${title?.replace(/ +/g, "-").toLowerCase()}-copy-button`}
				>
					<Bold
						style={{
							color: Colors[theme]["textHighlight"],
							paddingVertical: 6,
						}}
					>
						{I18n.t("Copy")}
					</Bold>
				</TouchableOpacity>
			</View>
		</View>
	)
}
