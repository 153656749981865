import _ from "lodash"
import I18n from "i18n-js"
import React from "react"
import useAnalytics from "../../hooks/useAnalytics"
import { Button, SecondaryButton } from "../../components/Button"
import { FeatureImage } from "../../components/FeatureImage"
import { FlexView } from "../../components/FlexView"
import { Headline, LegalText, MetroText } from "../../components/StyledText"
import { OnboardingStackParamList } from "../../types"
import { ScreenWrapper } from "../../components/ScreenWrapper"
import { StackScreenProps } from "@react-navigation/stack"
import { useAppDownload } from "../../hooks/useAppDownload"
import { useLinkTo } from "@react-navigation/native"

export default function OnboardingCompleteVariant({}: StackScreenProps<
	OnboardingStackParamList,
	"OnboardingCompleteVariant"
>) {
	const linkTo = useLinkTo()
	const { track } = useAnalytics()

	const handlePrimaryAction = () => {
		track("tap_advance_variant")
		linkTo("/WageAdvance")
	}
	const { sendDownloadLink } = useAppDownload()

	return (
		<ScreenWrapper center>
			<FeatureImage
				medium
				style={{ marginBottom: 32, height: 200 }}
				source={require("../../assets/images/money_to_phone.png")}
			/>
			<Headline
				center
				style={{
					marginBottom: 24,
					minWidth: 335,
				}}
			>
				{I18n.t("OnboardingCompleteVariant.PageHeader")}
			</Headline>
			<MetroText h4 center style={{ minWidth: 335 }}>
				{I18n.t("OnboardingCompleteVariant.Body")}
			</MetroText>
			<FlexView />
			<Button title={I18n.t("OnboardingCompleteVariant.Button")} onPress={handlePrimaryAction} />
			<SecondaryButton title={I18n.t("DownloadApp")} onPress={sendDownloadLink} />
			<LegalText>{I18n.t("BankingServicesFooter")}</LegalText>
		</ScreenWrapper>
	)
}
