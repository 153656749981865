import * as Device from "expo-device"
import { useEffect, useState } from "react"
import { useOpenUrl } from "./useOpenUrl"

export const useDeviceAndWalletType = () => {
	const [isMobileDevice, setIsMobileDevice] = useState(
		Device.osName === "iOS" || Device.osName === "Android"
	)
	const [walletType, setWalletType] = useState<string>("")
	const { openUrl } = useOpenUrl()

	type Links = {
		[key: string]: string
	}
	const deeplinks: Links = {
		// shoebox:// is the unofficial URL scheme to launch Apple Pay / Apple Wallet
		Apple: "shoebox://",
		// launches Samsung Pay / Samsung Wallet
		Samsung: "market://details?id=com.samsung.android.spay",
		// launches Google Pay
		Google: "https://gpay.app.goo.gl/kHXsJi",
	}

	const launchWallet = () => {
		openUrl(deeplinks[walletType])
	}

	useEffect(() => {
		if (Device.osName === "iOS" || Device.osName === "Android") {
			setIsMobileDevice(true)
		}
		if (isMobileDevice) {
			switch (Device.manufacturer) {
				case "Apple":
					return setWalletType("Apple")
				case "Samsung":
					return setWalletType("Samsung")
				case "Google":
					return setWalletType("Google")
				default:
					return setWalletType("Google")
			}
		} else setWalletType("Desktop")
	}, [Device.osName, isMobileDevice])

	return { isMobileDevice, walletType, launchWallet }
}
