import Colors from "../../../../constants/Colors"
import React from "react"
import useColorScheme from "../../../../hooks/useColorScheme"
import { Button, ButtonProps } from "react-native-elements"

export const CarouselButton = ({ buttonStyle, ...rest }: ButtonProps) => {
	const theme = useColorScheme()
	return (
		<Button
			buttonStyle={[
				{
					backgroundColor: Colors[theme]["backgroundSecondary"],
					borderRadius: 24,
					width: "75%",
					alignSelf: "center",
				},
				buttonStyle,
			]}
			titleStyle={{
				color: Colors[theme]["primary"],
				fontFamily: "metropolis-semi",
				fontSize: 20,
				lineHeight: 32,
			}}
			{...rest}
		/>
	)
}
