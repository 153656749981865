import { addressDeliverabilityAPI } from "."
// More context on SmartyStreets codes and values available here:
// https://www.smarty.com/docs/cloud/us-street-api#metadata.

type addressDeliverabilityResponse = {
	recordType: "F" | "G" | "H" | "P" | "R" | "S" | null
	dpvMatchCode: "Y" | "N" | "S" | "D" | null
	dpvVacantCode: "Y" | "N" | null
	isAcceptableAddress: boolean
	suggestion: {
		addressLine1: string
		addressLine2: string
		city: string
		state: string
		postalCode: string
	}
}

export const addressDeliverability = async (
	payload: object
): Promise<addressDeliverabilityResponse> => {
	const { data = {} } = await addressDeliverabilityAPI.post("/", payload).catch((error) => {
		if (error.status == 404) {
			return {
				data: {
					data: {
						isAcceptableAddress: false,
					},
				},
			}
		} else {
			throw error.response
		}
	})
	return data.data
}
