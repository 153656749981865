import Colors from "../constants/Colors"
import React, { FunctionComponent } from "react"
import useColorScheme from "../hooks/useColorScheme"
import { TouchableOpacity, TouchableOpacityProps } from "react-native"

export const TouchableFrame: FunctionComponent<TouchableOpacityProps> = ({
	children,
	style,
	...rest
}) => {
	const theme = useColorScheme()

	return (
		<TouchableOpacity
			style={[
				{
					paddingHorizontal: 23,
					paddingVertical: 17,
					borderWidth: 1,
					borderColor: Colors[theme]["borderDefault"],
					backgroundColor: Colors[theme]["background"],
					minWidth: 263,
					justifyContent: "center",
				},
				style,
			]}
			{...rest}
		>
			{children}
		</TouchableOpacity>
	)
}
