import { useOnboardingInit } from "./useOnboardingInit"

export type ClairEvent =
	| "clair-onboarding-open-url"
	| "clair-onboarding-complete"
	| "clair-onboarding-close"

declare global {
	interface Window {
		webkit?: any
		ClairEvents?: any
		ReactNativeWebView?: any
	}
}

export const useBroadcastEvent = () => {
	const { data } = useOnboardingInit()

	const broadcastEvent = ({ event, payload }: { event: ClairEvent; payload: object }) => {
		try {
			if (data?.origin && window?.parent) {
				window.parent.postMessage({ event, payload }, data?.origin)
			} // Embedded in an iframe web support

			if (
				window.webkit &&
				window.webkit.messageHandlers &&
				window.webkit.messageHandlers[event] &&
				window.webkit.messageHandlers[event].postMessage
			) {
				window.webkit.messageHandlers[event].postMessage(payload)
			} // iOS Support

			if (window?.ClairEvents) {
				if (window.ClairEvents.postMessage) {
					let data = JSON.stringify({
						event,
						payload: payload || {},
					})
					window.ClairEvents.postMessage(data)
				}
			} // Android Support

			if (window.ReactNativeWebView && window?.ReactNativeWebView?.postMessage) {
				let data = JSON.stringify({
					event,
					payload,
				})
				window.ReactNativeWebView.postMessage(data)
			} // React Native Support

			// @ts-ignore
			if (typeof FlutterEvents !== "undefined") {
				// @ts-ignore
				if (FlutterEvents.postMessage) {
					let data = JSON.stringify({
						event,
						payload: payload || {},
					})
					// @ts-ignore
					FlutterEvents.postMessage(data)
				}
			} // Flutter Support
		} catch (error) {
			console.error(error)
			throw error
		}
	}
	return broadcastEvent
}
