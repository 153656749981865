import Colors from "../../../constants/Colors"
import I18n from "i18n-js"
import React, { useCallback, useContext, useEffect, useState } from "react"
import useAnalytics from "../../../hooks/useAnalytics"
import useColorScheme from "../../../hooks/useColorScheme"
import { Bold, Headline, LegalText, MetroText } from "../../../components/StyledText"
import { Button, SecondaryButton } from "../../../components/Button"
import { FAQsLink } from "./DirectDepositFAQLink"
import { FeatureImage } from "../../../components/FeatureImage"
import { FlexView } from "../../../components/FlexView"
import { InfoBox } from "../../../components/InfoBox"
import { OnboardingProgressBar } from "../../../components/OnboardingProgressBar"
import { OnboardingStackParamList } from "../../../types"
import { QueryParamContext } from "../../../context/queryParam/QueryParamContext"
import { ScreenWrapper } from "../../../components/ScreenWrapper"
import { StackScreenProps } from "@react-navigation/stack"
import { urls } from "../../../constants/urls"
import { useAppDownload } from "../../../hooks/useAppDownload"
import { useFocusEffect, useLinkTo, useNavigation } from "@react-navigation/native"
import { useRefetchInterval } from "../../../hooks/useRefetchInterval"
import useDirectDepositStatus, {
	useDirectDepositFinalStatus,
} from "../../../hooks/useDirectDepositStatus"

export default function FormPending({}: StackScreenProps<OnboardingStackParamList, "FormPending">) {
	const { data } = useDirectDepositStatus()
	const { clairEmbed } = useContext(QueryParamContext)
	const { requestType = "SELF_SEND" } = data || {}
	const { track } = useAnalytics()
	const { sendDownloadLink } = useAppDownload()
	const linkTo = useLinkTo()
	const navigation = useNavigation()
	const theme = useColorScheme()
	const [refetchInterval, setRefetchInterval] = useState<false | number>(false)
	const { status, isFetching } = useDirectDepositFinalStatus(refetchInterval)
	const updatedInterval = useRefetchInterval(isFetching)

	useEffect(() => {
		setRefetchInterval(updatedInterval)
	}, [updatedInterval])

	useFocusEffect(
		useCallback(() => {
			if (status == "COMPLETED")
				navigation.reset({
					routes: [{ name: "DDSuccess" }],
				})
			else if (status == "ACTION_REQUIRED")
				navigation.reset({
					routes: [{ name: "FormActionRequired" }],
				})
		}, [status])
	)

	return (
		<ScreenWrapper center>
			<OnboardingProgressBar section={1} position={9} />
			<FeatureImage source={require("../../../assets/images/envelope_clair.png")} width={100} />
			<Headline center smallText style={{ marginBottom: 20 }} testID="formPendingHeadline">
				{I18n.t(`FormPending.PageHeader_${requestType}`)}
			</Headline>
			<MetroText h4 center style={{ marginBottom: 30, fontSize: 14 }}>
				{I18n.t("FormPending.Body1")}
			</MetroText>
			<Bold h4 style={{ marginBottom: 8 }}>
				{I18n.t("FormPending.Body2")}
			</Bold>
			<MetroText
				center
				style={{
					fontSize: 13,
					lineHeight: 16,
					marginBottom: 10,
					marginHorizontal: 50,
					color: Colors[theme]["textInputLabel"],
				}}
			>
				{I18n.t("FormPending.Body3")}
			</MetroText>
			<InfoBox title={I18n.t("FormPending.Body4")} body={I18n.t("FormPending.Body5")} />
			<FlexView />
			{!clairEmbed && (
				<Button
					title={I18n.t("DownloadApp")}
					onPress={() => {
						track("tap_download_formpending")
						sendDownloadLink()
					}}
				/>
			)}
			<SecondaryButton
				title={I18n.t("FormPending.Button")}
				onPress={() => {
					track("tap_redo_formpending")
					linkTo("/CalculatorEntry")
				}}
			/>
			<FAQsLink url={urls.directDepositFormFAQUrl} eventName="tap_FAQs_formpending" />
			<LegalText>{I18n.t("IssuerStatement")}</LegalText>
		</ScreenWrapper>
	)
}
