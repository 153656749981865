export const urls = {
	howToSwitchToClair:
		"https://support.getclair.com/support/solutions/articles/63000270821-how-can-i-switch-my-direct-deposit-to-my-clair-spending-account-",
	howToSwitchToClairManual:
		"https://support.getclair.com/support/solutions/articles/63000271088-how-can-i-switch-my-direct-deposit-to-my-clair-spending-account-method-2-",
	clairSignupFAQUrl:
		"https://support.getclair.com/support/solutions/articles/63000270599-clair-signup-frequently-asked-questions",
	directDepositFormFAQUrl:
		"https://support.getclair.com/support/solutions/articles/63000270598-direct-deposit-form-frequently-asked-questions",
	howClairWorks: "https://getclair.com/legal/how-Clair-works",
	atomicWelcomeFAQUrl:
		"https://support.getclair.com/support/solutions/articles/63000270822-where-can-i-find-my-direct-deposit-",
	clairStatus: "https://status.getclair.com/",
	clairCareSupportHome: "https://support.getclair.com/support/home",
}
