import Colors from "../constants/Colors"
import React, { FunctionComponent, useState } from "react"
import useColorScheme from "../hooks/useColorScheme"
import { Bold, MetroText } from "./StyledText"
import { Icon } from "react-native-elements"
import { TextStyle, View, ViewProps } from "react-native"

type LabeledTextProps = {
	labelText: string
	labelTextStyle?: TextStyle
	valueText: string
	valueTextStyle?: TextStyle
	bankingDetails?: boolean
	signature?: boolean
	rightLabel?: string
	onRightLabelPress?: Function
} & ViewProps

export const LabeledText: FunctionComponent<LabeledTextProps> = ({
	labelText,
	labelTextStyle,
	valueText,
	valueTextStyle,
	bankingDetails = false,
	signature = false,
	rightLabel,
	onRightLabelPress,
	style,
	...rest
}) => {
	const [hidden, setHidden] = useState(true)
	const theme = useColorScheme()
	const concealBankingDetailsRegex = /.(?=.{4,}$)/g
	const bankingDetailsValue =
		bankingDetails && hidden ? valueText?.replace(concealBankingDetailsRegex, "•") : valueText

	return (
		<View style={[{ flex: 1, marginBottom: 22 }, style]} {...rest}>
			<MetroText
				style={[
					{
						color: Colors[theme]["textLegal"],
						fontSize: 11,
						lineHeight: 12,
						marginBottom: 8,
					},
					labelTextStyle,
				]}
				testID="labelText"
			>
				{labelText}
			</MetroText>
			{bankingDetails ? (
				<div data-private>
					<View
						style={{ flexDirection: "row", alignItems: "center", justifyContent: "space-between" }}
					>
						<MetroText style={valueTextStyle} testID="bankingDetails">
							{bankingDetailsValue}
						</MetroText>
						<Icon
							name={hidden ? "eye" : "eye-slash"}
							type="font-awesome-5"
							size={12}
							color={Colors[theme]["backgroundButtonDefault"]}
							onPress={() => setHidden(!hidden)}
							accessibilityLabel="Toggle visibility"
							testID="toggleBankingDetails"
						/>
					</View>
				</div>
			) : signature ? (
				<View
					style={{ flexDirection: "row", alignItems: "center", justifyContent: "space-between" }}
					testID="signature"
				>
					<MetroText style={[{ fontFamily: "pacifico-regular", fontSize: 17 }, valueTextStyle]}>
						{valueText}
					</MetroText>
					<MetroText style={{ color: Colors[theme]["textLegal"], fontSize: 12 }}>
						{new Date().toLocaleDateString()}
					</MetroText>
				</View>
			) : rightLabel ? (
				<View
					style={{ flexDirection: "row", alignItems: "center", justifyContent: "space-between" }}
					testID="rightLabel"
				>
					<MetroText style={valueTextStyle}>{valueText}</MetroText>

					<Bold highlight onPress={() => onRightLabelPress && onRightLabelPress()}>
						{rightLabel}
					</Bold>
				</View>
			) : (
				<MetroText testID="valueText" style={valueTextStyle}>
					{valueText}
				</MetroText>
			)}
		</View>
	)
}
