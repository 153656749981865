import Colors from "../constants/Colors"
import React, { ReactNode } from "react"
import useColorScheme from "../hooks/useColorScheme"
import { Icon, ListItem, ListItemProps } from "react-native-elements"
import { StyleSheet, Text } from "react-native"

type PasswordBulletProps = {
	text: string | ReactNode
	status: "valid" | "invalid" | "neutral"
} & ListItemProps

export const PasswordBullet = ({ text, status, style }: PasswordBulletProps) => {
	const theme = useColorScheme()
	const color =
		status === "valid"
			? Colors[theme]["successGreen"]
			: status === "invalid"
			? Colors[theme]["textError"]
			: Colors[theme]["backgroundSecondary"]
	const iconName = status === "valid" ? "check" : status === "invalid" ? "x" : "circle"
	const iconType = status === "neutral" ? "font-awesome" : "feather"
	const iconSize = status === "neutral" ? 11 : 16
	return (
		<ListItem style={style} containerStyle={styles.containerStyle}>
			<Icon size={iconSize} type={iconType} name={iconName} color={color}></Icon>
			<ListItem.Content>
				<Text style={styles.bulletText}>{text}</Text>
			</ListItem.Content>
		</ListItem>
	)
}

const styles = StyleSheet.create({
	containerStyle: {
		padding: 10,
	},
	bulletText: {
		fontFamily: "metropolis-regular",
		fontSize: 14,
		fontWeight: "400",
	},
})
