import _ from "lodash"
import Colors from "../../../constants/Colors"
import I18n from "i18n-js"
import React, { useContext, useEffect, useRef, useState } from "react"
import useColorScheme from "../../../hooks/useColorScheme"
import { Button } from "../../../components/Button"
import { FlexView } from "../../../components/FlexView"
import { Headline, MetroText, Bold } from "../../../components/StyledText"
import { Icon } from "react-native-elements"
import { InfoText } from "../../../components/InfoText"
import { Input } from "../../../components/Input"
import { isEmailAddressValid } from "../../../utils/isEmailValid"
import { OnboardingContext } from "../../../context/onboarding/OnboardingContext"
import { OnboardingProgressBar } from "../../../components/OnboardingProgressBar"
import { OnboardingStackParamList } from "../../../types"
import { patchState } from "../../../api/onboarding"
import { ScreenWrapper } from "../../../components/ScreenWrapper"
import { ScrollView } from "react-native"
import { SET_ONBOARDING_STATE } from "../../../context/actionTypes"
import { StackScreenProps } from "@react-navigation/stack"
import { useIsThinScreen } from "../../../hooks/useIsThinScreen"
import { useSignupNavigation } from "../../../hooks/useSignupNavigation"
import {
	validateField,
	validateForm,
	ValidationArray,
	validateEmailRemote,
} from "../../../utils/validate"
import { Overlay } from "../../../components/Overlay"
import { TouchableFrame } from "../../../components/TouchableFrame"
import { validateEmailPhone } from "../../../api/util"

export default function EmailScreen({}: StackScreenProps<OnboardingStackParamList, "Email">) {
	const theme = useColorScheme()
	const scrollRef = useRef<ScrollView>(null)
	const emailInput = useRef()
	const { email, phoneNumber, dispatch } = useContext(OnboardingContext)
	const [prefilled, setPrefilled] = useState(false)
	const [hasEmailError, setHasEmailError] = useState(false)
	const [isLoading, setIsLoading] = useState(false)
	const [suggestion, setSuggestion] = useState("")
	const [showSuggestion, setShowSuggestion] = useState(false)
	const [emailErrorMessage, setEmailErrorMessage] = useState("")
	const [dirty, setDirty] = useState(false)
	const [hasValidated, setHasValidated] = useState(false)
	const isThinScreen = useIsThinScreen()
	const { navigateToNextSignupScreen } = useSignupNavigation()

	useEffect(() => {
		if (email) {
			validateField("email", email, setEmailError)
			setPrefilled(true)
		}
	}, [])

	const setEmailError = (hasError: boolean, errorMessage = "") => {
		setHasEmailError(hasError)
		setEmailErrorMessage(errorMessage)
		setHasValidated(true)
	}

	const handleEmailValidation = async (email: string) => {
		if (showSuggestion) setShowSuggestion(false)
		setIsLoading(true)
		const formToValidate: ValidationArray = [["email", email, setEmailError]]
		const isFormValid = validateForm(formToValidate)

		if (isFormValid) {
			const { didYouMean, deliverable } = await validateEmailRemote(email, setEmailError)

			if (didYouMean && suggestion !== didYouMean) {
				setShowSuggestion(true)
				setSuggestion(didYouMean)
			} else if (deliverable) {
				const isEmailAvailable = await validateEmailPhone({ email, phoneNumber })
					.then(() => true)
					.catch((error) => {
						setEmailError(true, error?.data?.message)
						return false
					})

				if (isEmailAvailable) {
					patchState({
						email,
					})
					navigateToNextSignupScreen()
				}
			}
		} else {
			validateField("email", email, setEmailError)
			setEmailError(true, I18n.t("Form.EmailInvalid"))
		}
		setIsLoading(false)
	}

	return (
		<ScreenWrapper ref={scrollRef}>
			<OnboardingProgressBar position={3} />
			<Headline smallText testID="emailHeadline">
				{I18n.t(`Email.PageHeader${prefilled ? "Prefilled" : ""}`)}
			</Headline>
			<MetroText>{I18n.t(`Email.Body${prefilled ? "Prefilled" : ""}`)}</MetroText>
			<Input
				refInput={emailInput}
				label={I18n.t("Email.EmailLabel")}
				value={email}
				autoCompleteType="email"
				keyboardType="email-address"
				autoCorrect={false}
				onChangeText={(value: string) => {
					setDirty(true)
					dispatch({
						type: SET_ONBOARDING_STATE,
						payload: { email: value },
					})
					if (!value || hasEmailError) {
						setHasEmailError(false)
						setHasValidated(false)
					} else if (dirty && !hasEmailError && isEmailAddressValid(value)) {
						validateField("email", value, setEmailError)
					} else setHasValidated(false)
				}}
				mask="email"
				autoCapitalize="none"
				returnKeyType="next"
				onSubmitEditing={() => handleEmailValidation(email)}
				onBlur={() => {
					if (email) validateField("email", email, setEmailError)
				}}
				errorMessage={emailErrorMessage}
				hasError={hasEmailError}
				inputContainerStyle={{
					borderBottomColor:
						dirty && !hasEmailError && hasValidated
							? Colors[theme]["successGreen"]
							: Colors[theme]["textInputLabel"],
				}}
				rightIcon={
					dirty &&
					!hasEmailError &&
					hasValidated && (
						<Icon
							name="check"
							color={Colors[theme]["successGreen"]}
							containerStyle={{ marginRight: 5 }}
							hitSlop={{ top: 20, bottom: 20, left: 20, right: 20 }}
							size={isThinScreen ? 14 : 18}
						/>
					)
				}
				containerStyle={{ marginBottom: 20 }}
				testID="emailInput"
			/>
			<InfoText
				headline={I18n.t("Email.InfoText")}
				body={I18n.t("Email.OverlayBody")}
				screen="Email"
			/>
			<Overlay
				showClose
				isVisible={showSuggestion}
				setShowOverlay={() => setShowSuggestion(false)}
				overlayName="emailSuggestion"
			>
				<MetroText h2 style={{ marginBottom: 32 }} testID="emailOverlayHeadline">
					{I18n.t("SendWithClair.SuggestionHeader")}
				</MetroText>
				<TouchableFrame
					onPress={async () => {
						dispatch({
							type: SET_ONBOARDING_STATE,
							payload: { email: suggestion },
						})

						handleEmailValidation(suggestion)
					}}
					testID="acceptEmailSuggestion"
				>
					<MetroText h4 style={{ lineHeight: 24 }}>
						{suggestion}
					</MetroText>
				</TouchableFrame>
				<TouchableFrame
					onPress={() => handleEmailValidation(email)}
					style={{ marginVertical: 24 }}
					testID="useEnteredEmailAddress"
				>
					<Bold highlight>{I18n.t("SendWithClair.SuggestionNo")}</Bold>
					<MetroText h4 style={{ lineHeight: 24 }}>
						{email}
					</MetroText>
				</TouchableFrame>
			</Overlay>
			<FlexView />
			<Button
				title={I18n.t("Next")}
				onPress={() => handleEmailValidation(email)}
				loading={isLoading}
				disabled={isLoading}
				testID="emailNext"
			/>
		</ScreenWrapper>
	)
}
