import Colors from "../constants/Colors"
import React from "react"
import useColorScheme from "../hooks/useColorScheme"
import { Icon } from "react-native-elements"
import { useIsThinScreen } from "../hooks/useIsThinScreen"
import { View } from "react-native"

type RightPasswordIconProps = {
	valid: boolean
	concealed: boolean
	inputName?: string
	setConcealed: (concealed: boolean) => void
}

export const RightPasswordIcon = ({
	valid,
	concealed,
	setConcealed,
	inputName,
}: RightPasswordIconProps) => {
	const theme = useColorScheme()
	const isThinScreen = useIsThinScreen()
	return (
		<View style={{ flexDirection: "row", alignItems: "center" }}>
			{valid && (
				<Icon
					name="check"
					color={Colors[theme]["successGreen"]}
					containerStyle={{ marginRight: 5 }}
					hitSlop={{ top: 20, bottom: 20, left: 20, right: 20 }}
					size={isThinScreen ? 14 : 18}
				/>
			)}
			<Icon
				type="font-awesome-5"
				name={concealed ? "eye" : "eye-slash"}
				color={Colors[theme]["textHighlight"]}
				size={14}
				onPress={() => setConcealed(!concealed)}
				hitSlop={{ top: 20, bottom: 20, left: 20, right: 20 }}
				containerStyle={{ marginLeft: 10 }}
				testID={`${inputName}EyeIcon`}
			/>
		</View>
	)
}
