import React, { FunctionComponent, useEffect, useState } from "react"
import reactImageSize from "react-image-size"
import { Image, ImageSourcePropType, ImageStyle, StyleProp } from "react-native"

const getHeightForWidth = async (
	image: ImageSourcePropType,
	desiredWidth: number,
	maxHeight = Infinity
) => {
	const { width, height } = await reactImageSize(image)
	if (!width || !height) return 0
	const scaledHeight = (desiredWidth * height) / width
	return Math.min(scaledHeight, maxHeight)
}

type AutoHeightImageProps = {
	source: ImageSourcePropType
	width: number
	maxHeight?: number
	style?: StyleProp<ImageStyle>
}
export const AutoHeightImage: FunctionComponent<AutoHeightImageProps> = ({
	source,
	width,
	maxHeight = 200,
	style,
	...rest
}) => {
	const [height, setHeight] = useState<number | null>(null)

	useEffect(() => {
		getImageHeight()
	}, [])

	const getImageHeight = async () => {
		const finalHeight = await getHeightForWidth(source.uri ? source.uri : source, width, maxHeight)
		setHeight(finalHeight)
	}
	return height ? (
		<Image source={source} style={[{ width, height }, style]} resizeMode={"contain"} {...rest} />
	) : null
}
