import _ from "lodash"
import I18n from "i18n-js"
import Loading from "../../../components/Loading"
import React, { useCallback, useEffect, useState } from "react"
import { Button, SecondaryButton } from "../../../components/Button"
import { FAQsLink } from "./DirectDepositFAQLink"
import { FeatureImageProto } from "../../../components/FeatureImageProto"
import { FlexView } from "../../../components/FlexView"
import { Headline, LegalText, MetroText } from "../../../components/StyledText"
import { OnboardingProgressBar } from "../../../components/OnboardingProgressBar"
import { OnboardingStackParamList } from "../../../types"
import { ScreenWrapper } from "../../../components/ScreenWrapper"
import { StackScreenProps } from "@react-navigation/stack"
import { urls } from "../../../constants/urls"
import { useContactSupport } from "../../../hooks/useContactSupport"
import { useDirectDepositFinalStatus } from "../../../hooks/useDirectDepositStatus"
import { useFocusEffect } from "@react-navigation/native"
import { useRefetchInterval } from "../../../hooks/useRefetchInterval"

export default function TryAnotherWayScreen({
	navigation,
}: StackScreenProps<OnboardingStackParamList, "TryAnotherWay">) {
	const [refetchInterval, setRefetchInterval] = useState<false | number>(false)
	const { status, failReason, failReason2, isLoading, isFetching } =
		useDirectDepositFinalStatus(refetchInterval)
	const { callSupport } = useContactSupport()
	const updatedInterval = useRefetchInterval(isFetching)

	useEffect(() => {
		setRefetchInterval(updatedInterval)
	}, [updatedInterval])

	useFocusEffect(
		useCallback(() => {
			if (status == "COMPLETED")
				navigation.reset({
					routes: [{ name: "DDSuccess" }],
				})
		}, [status])
	)

	const handlePrimaryAction = () => {
		navigation.reset({
			routes: [{ name: "CalculatorEntry" }, { name: "Options", params: { showManual: "true" } }],
		})
	}

	return isLoading ? (
		<Loading />
	) : (
		<ScreenWrapper center>
			<OnboardingProgressBar section={1} position={4} />
			<FeatureImageProto
				source={require("../../../assets/images/question_over_clair_app.png")}
				style={{ marginTop: 36 }}
			/>
			<Headline smallText center testID="tryAnotherWayHeadline">
				{I18n.t("SomethingWentWrong")}
			</Headline>
			<MetroText center style={{ marginBottom: 22 }} testID="tryAnotherWayBody1">
				{failReason}
			</MetroText>
			<MetroText center style={{ marginBottom: 32 }} testID="tryAnotherWayBody2">
				{failReason2}
			</MetroText>
			<FlexView />
			<Button
				title={I18n.t("TryAnotherWay.Button1")}
				onPress={handlePrimaryAction}
				testID="tryAnotherWayButton1"
			/>
			<SecondaryButton
				title={I18n.t("TryAnotherWay.ClairCareButton")}
				onPress={() => callSupport()}
				testID="tryAnotherWayButton2"
			/>
			<FAQsLink url={urls.howToSwitchToClair} eventName="tap_FAQs_tryanotherway" />
			<LegalText>{I18n.t("BankingServicesFooter")}</LegalText>
		</ScreenWrapper>
	)
}
