import _ from "lodash"
import I18n from "i18n-js"
import React, { useState } from "react"
import useAnalytics from "../hooks/useAnalytics"
import { Button } from "../components/Button"
import { FlexView } from "../components/FlexView"
import { forgotPassword } from "../api/forgotPassword"
import { Headline, MetroText } from "../components/StyledText"
import { Input } from "../components/Input"
import { KeyboardAvoidingView, View } from "react-native"
import { OnboardingStackParamList } from "../types"
import { Overlay } from "../components/Overlay"
import { ScreenWrapper } from "../components/ScreenWrapper"
import { StackScreenProps } from "@react-navigation/stack"
import { validateField } from "../utils/validate"

export function ResetPassword({}: StackScreenProps<OnboardingStackParamList, "AlreadyPassed">) {
	const [loading, setLoading] = useState(false)
	const [inputValue, setInputValue] = useState("")
	const [hasError, setHasError] = useState(false)
	const [showSuccess, setShowSuccess] = useState(false)
	const currentMask = inputValue.length > 8 && /^[^a-zA-Z]+$/.test(inputValue) ? "phone" : "email"
	const contactType = currentMask === "phone" ? "SMS" : "email"
	const { track } = useAnalytics()

	const handleResetPassword = async () => {
		setLoading(true)
		track("tap_request_password")
		const error = validateField(currentMask, inputValue, setHasError)
		if (!error) {
			try {
				await forgotPassword(inputValue)
				setShowSuccess(true)
			} catch (err) {
				setHasError(true)
			}
		}
		setLoading(false)
	}

	return (
		<ScreenWrapper
			center
			contentContainerStyle={{ justifyContent: "space-evenly" }}
			nonMobileContentStyle={{ justifyContent: "space-evenly" }}
		>
			<KeyboardAvoidingView>
				<FlexView />
				<Headline>{I18n.t("ResetPassword.PageHeader")}</Headline>
				<MetroText style={{ marginBottom: 22 }}>{I18n.t("ResetPassword.Body1")}</MetroText>
				<MetroText style={{ marginBottom: 14 }}>{I18n.t("ResetPassword.Body2")}</MetroText>
				<Input
					label={I18n.t("ResetPassword.InputLabel")}
					value={inputValue}
					onChangeText={(value: string) => {
						setInputValue(value)
						if (hasError) {
							validateField(currentMask, inputValue, setHasError)
						}
					}}
					mask={currentMask}
					onBlur={() => {}}
					errorMessage={
						_.isEmpty(inputValue)
							? I18n.t("Form.EmptyField", { label: I18n.t("ResetPassword.InputLabel") })
							: I18n.t("Form.IsInvalid", { label: I18n.t("ResetPassword.InputLabel") })
					}
					hasError={hasError}
				/>
				<Button
					title={I18n.t("ResetPassword.Button")}
					onPress={handleResetPassword}
					loading={loading}
				/>
				<FlexView />
				<Overlay
					showClose
					isVisible={showSuccess}
					setShowOverlay={() => setShowSuccess(false)}
					style={{ paddingBottom: 0, paddingTop: 66 }}
				>
					<View style={{ paddingHorizontal: 40, marginBottom: 30 }}>
						<MetroText h3 center>
							{I18n.t("ResetPassword.OverlayBody", { contactType })}
						</MetroText>
					</View>
					<Button title={I18n.t("Ok")} onPress={() => setShowSuccess(false)} />
				</Overlay>
			</KeyboardAvoidingView>
		</ScreenWrapper>
	)
}
