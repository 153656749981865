import analytics from "../analytics"
import LogRocket from "logrocket"
import { userAPI } from "."

export const getAuthenticatedUser = async () => {
	const { data } = await userAPI.get("")
	if (data?.data?.id?.toString()) {
		analytics.identify(data.data.id, data.data)
		LogRocket.identify(data.data.id, data.data)
	}
	return { userData: data?.data, metaData: data?.meta }
}
