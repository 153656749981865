import { format, parseISO } from "date-fns"
import { useCard } from "../queries/useCard"
import { useEffect, useState } from "react"

export const useCardData = () => {
	const [url, setUrl] = useState<string>("")
	const [receiveDate, setReceiveDate] = useState<string>("")
	const [receiveDateFull, setReceiveDateFull] = useState("")
	const { data: cardData } = useCard()

	useEffect(() => {
		if (cardData) {
			const { imageUrl, shouldReceiveDate } = cardData
			setUrl(imageUrl)
			setReceiveDateFull(shouldReceiveDate)
			const date = format(parseISO(shouldReceiveDate), "MMMM d")
			setReceiveDate(date)
		}
	}, [cardData])

	return { url, receiveDate, receiveDateFull, cardData }
}
