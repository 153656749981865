import _ from "lodash"
import I18n from "i18n-js"
import React from "react"
import useDirectDepositStatus from "../../hooks/useDirectDepositStatus"
import { Button } from "../../components/Button"
import { FeatureImage } from "../../components/FeatureImage"
import { FlexView } from "../../components/FlexView"
import { Headline, MetroText } from "../../components/StyledText"
import { OnboardingStackParamList } from "../../types"
import { ScreenWrapper } from "../../components/ScreenWrapper"
import { StackScreenProps } from "@react-navigation/stack"

export default function PDWActionRequired({
	navigation,
}: StackScreenProps<OnboardingStackParamList, "PDWActionRequired">) {
	const { refetch: refetchDDStatus, isFetching } = useDirectDepositStatus()
	return (
		<ScreenWrapper center>
			<FeatureImage
				medium
				style={{ width: 200 }}
				source={require("../../assets/images/pdw_general_error.png")}
			/>
			<Headline center style={{ marginBottom: 28 }}>
				{I18n.t("PDWActionRequired.PageHeader")}
			</Headline>
			<MetroText h4 center style={{ marginBottom: 29, lineHeight: 24 }}>
				{I18n.t("PDWActionRequired.Body")}
			</MetroText>
			<FlexView />
			<Button
				loading={isFetching}
				title={I18n.t("PDWActionRequired.Button1")}
				onPress={async () => {
					await refetchDDStatus()
					navigation.reset({ routes: [{ name: "DirectDepositEntry" }] })
				}}
			/>
		</ScreenWrapper>
	)
}
