import I18n from "i18n-js"
import React, { ReactElement } from "react"
import { FeatureImage } from "../components/FeatureImage"
import { FlexView } from "../components/FlexView"
import { Headline, MetroText } from "../components/StyledText"
import { OnboardingStackParamList } from "../types"
import { ScreenWrapper } from "../components/ScreenWrapper"
import { StackScreenProps } from "@react-navigation/stack"

export default function GeneralError({}: StackScreenProps<
	OnboardingStackParamList,
	"GeneralError"
>): ReactElement {
	return (
		<ScreenWrapper center>
			<FeatureImage medium source={require("../assets/images/general_error_phone.png")} />
			<Headline center smallText>
				{I18n.t("SomethingWentWrong")}
			</Headline>
			<MetroText h4 center>
				{I18n.t("Error.Body")}
			</MetroText>
			<FlexView />
		</ScreenWrapper>
	)
}
