import _ from "lodash"
import I18n from "i18n-js"
import { OnboardingContext } from "../context/onboarding/OnboardingContext"
import { overrideObject } from "../utils/overrideObject"
import { SET_ONBOARDING_STATE } from "../context/actionTypes"
import { SIGN_UP_SCREEN_ORDERING } from "../constants/featureFlags"
import { useContext, useState } from "react"
import { useLinkTo, useNavigation, useRoute } from "@react-navigation/native"
import { useOnboardingInit } from "./useOnboardingInit"
import { useTreatments } from "@splitsoftware/splitio-react"

export const useSignupNavigation = () => {
	const routeName = useRoute().name
	const linkTo = useLinkTo()
	const navigation = useNavigation()
	const { data } = useOnboardingInit()
	const { hasState } = data || {}
	const { dispatch } = useContext(OnboardingContext)
	const treatments = useTreatments([SIGN_UP_SCREEN_ORDERING])
	const { screenOrder, ...translationOverrides } = JSON.parse(
		treatments[SIGN_UP_SCREEN_ORDERING].config || "{}"
	)
	const [hasSetTranslationOverrides, setHasSetTranslationOverrides] = useState(false)

	const orderedSignUpRoutes = screenOrder || [
		"MobileNumber",
		"VerifyNumber",
		"AlreadyStarted",
		"Email",
		"PasswordSet",
		"PersonalInfo",
		"PermanentAddress",
		"MailingAddressOne",
		"MailingAddressTwo",
		"EmploymentInfo",
		"SSN",
	]

	const setTranslationOverrides = () => {
		if (!_.isEmpty(translationOverrides)) {
			const en = overrideObject(I18n.translations.en, translationOverrides)
			I18n.translations = {
				en,
			}
			setHasSetTranslationOverrides(true)
		}
	}

	const navigateToNextSignupScreen = (skipForward?: boolean) => {
		// make sure translation overrides are set before navigating
		if (!hasSetTranslationOverrides) setTranslationOverrides()
		const currentIndex = orderedSignUpRoutes.indexOf(routeName)
		// if we're at the last screen, it's time to submit KYC. If skipForward is true, treat the screen as if we're at the last screen
		if (currentIndex === orderedSignUpRoutes.length - (skipForward ? 2 : 1)) {
			dispatch({ type: SET_ONBOARDING_STATE, payload: { hasSubmittedForm: true } })
			navigation.reset({
				routes: [{ name: "KYCLoading" }],
			})
		} else if (routeName === "VerifyNumber") {
			// specific handling for VerifyNumber that impacts the navigation reset below.
			// explicitly look for if AlreadyStarted comes next and then show it or skip it based on hasState
			const alreadyStartedExistsAndIsAfterVerifyNumber =
				orderedSignUpRoutes.indexOf("AlreadyStarted") ===
				orderedSignUpRoutes.indexOf("VerifyNumber") + 1
			navigation.reset({
				routes: [
					{
						name:
							alreadyStartedExistsAndIsAfterVerifyNumber && !hasState
								? orderedSignUpRoutes[currentIndex + 2]
								: orderedSignUpRoutes[currentIndex + 1],
					},
				],
			})
		} else if (currentIndex === -1) {
			linkTo("/" + orderedSignUpRoutes[0])
		} else {
			linkTo("/" + orderedSignUpRoutes[currentIndex + (skipForward ? 2 : 1)])
		}
	}

	return {
		orderedSignUpRoutes,
		navigateToNextSignupScreen,
		setTranslationOverrides,
	}
}
