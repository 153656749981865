import * as Device from "expo-device"
import * as Linking from "expo-linking"
import { openInNewTab } from "../utils/url"
import { QueryParamContext } from "./../context/queryParam/QueryParamContext"
import { useBroadcastEvent } from "./useBroadcastEvent"
import { useContext } from "react"

export const useOpenUrl = () => {
	const { inSdk } = useContext(QueryParamContext)
	const broadcastEvent = useBroadcastEvent()
	const openUrl = async (url: string) => {
		if (inSdk) {
			return broadcastEvent({ event: "clair-onboarding-open-url", payload: { url } })
		}
		if (Device.osName === "iOS" || Device.osName === "Android") {
			const phoneIntents = ["tel:", "sms:"]
			if (phoneIntents.some((intent) => url.startsWith(intent)) || url.endsWith("app-download")) {
				const canOpenNatively = await Linking.canOpenURL(url)
				if (canOpenNatively) {
					await Linking.openURL(url)
					return
				}
			}
		}
		openInNewTab(url)
	}

	return { openUrl }
}
