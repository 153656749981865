import Colors from "../../constants/Colors"
import I18n from "i18n-js"
import React, { useLayoutEffect } from "react"
import useColorScheme from "../../hooks/useColorScheme"
import { FeatureImage } from "../../components/FeatureImage"
import { FlexView } from "../../components/FlexView"
import { formatCurrency } from "../../utils/formatCurrency"
import { Headline, LegalText, MetroText } from "../../components/StyledText"
import { OnboardingStackParamList } from "../../types"
import { ONE_SECOND } from "../../constants/time"
import { ScreenWrapper } from "../../components/ScreenWrapper"
import { StackScreenProps } from "@react-navigation/stack"
import { useAuthenticatedUserQuery } from "../../hooks/useAuthenticatedUser"

export function EWASuccess({
	navigation,
	route,
}: StackScreenProps<OnboardingStackParamList, "EWASuccess">) {
	const theme = useColorScheme()
	const backgroundColor = Colors[theme]["backgroundSuccess"]

	let { amount } = route.params

	const { data, isStale } = useAuthenticatedUserQuery()
	const { metaData } = data || {}

	useLayoutEffect(() => {
		let navigationTimeout: null | ReturnType<typeof setTimeout> = null
		if (metaData) {
			let { stage } = metaData
			if (stage && !isStale) {
				let url = ""
				if (stage == "CARD_ACTIVATION") url = "CardActivationEntry"
				else if (stage == "DOWNLOAD_APP") url = "OnboardingComplete"
				// Ensure that the gif plays though once
				navigationTimeout = setTimeout(
					() =>
						url
							? navigation.reset({ index: 0, routes: [{ name: url }] })
							: navigation.reset({ index: 0, routes: [{ name: "Splash" }] }),
					ONE_SECOND * 9
				)
			}
		}
		return () => {
			navigationTimeout && clearTimeout(navigationTimeout)
		}
	}, [metaData, isStale])

	return (
		<ScreenWrapper
			center
			contentContainerStyle={{ backgroundColor }}
			nonMobileContentStyle={{ backgroundColor }}
		>
			<FeatureImage medium source={require("../../assets/images/ewa_success.gif")} />
			<Headline center> {I18n.t("EWASuccess.PageHeader")}</Headline>
			<MetroText center style={{ marginHorizontal: 20 }}>
				{I18n.t("EWASuccess.Body", { amount: formatCurrency(amount, true) })}
			</MetroText>

			<FlexView />
			<LegalText>{I18n.t("BankingServicesFooter")}</LegalText>
		</ScreenWrapper>
	)
}
