import { getGeneratedDDFormUrl } from "../api/directDeposit"
import { ONE_MINUTE } from "../constants/time"
import { useQuery } from "react-query"
import { useUserContext } from "../context/user/UserContext"

export default function useGeneratedDDFormUrl(params: object) {
	const { distributionType, distributionValue } = useUserContext()
	return useQuery<{ url: string }>("generatedDdForm", () => getGeneratedDDFormUrl(params), {
		staleTime: ONE_MINUTE,
		enabled: Boolean(distributionType && distributionValue),
	})
}
