import _ from "lodash"
import I18n from "i18n-js"
import Loading from "../../components/Loading"
import React, { useState } from "react"
import { addDays, differenceInDays } from "date-fns"
import { Button } from "../../components/Button"
import { FeatureImageProto } from "../../components/FeatureImageProto"
import { FlexView } from "../../components/FlexView"
import { LegalText, MetroText } from "../../components/StyledText"
import { OnboardingProgressBar } from "../../components/OnboardingProgressBar"
import { OnboardingStackParamList } from "../../types"
import { ScreenWrapper } from "../../components/ScreenWrapper"
import { StackScreenProps } from "@react-navigation/stack"
import { useCard } from "../../queries/useCard"
import { useEffect } from "react"
import { useUserContext } from "../../context/user/UserContext"

export default function KYCPassScreen({
	navigation,
}: StackScreenProps<OnboardingStackParamList, "KYCPass">) {
	const { accessToken } = useUserContext()
	const { data: cardData, isLoading, isSuccess } = useCard()
	const daysOffset = isSuccess ? 5 : 4
	const { shouldReceiveDate } = cardData || { shouldReceiveDate: addDays(new Date(), daysOffset) }
	const [cardDeliveryDays, setCardDeliveryDays] = useState(1)
	useEffect(() => {
		if (isSuccess) setCardDeliveryDays(differenceInDays(new Date(shouldReceiveDate), new Date()))
		else setCardDeliveryDays(8)
	}, [cardData])

	const handlePrimaryAction = async () => {
		navigation.reset({
			routes: accessToken
				? [{ name: "KYCPass" }, { name: "DirectDepositEntry" }]
				: [{ name: "Login" }],
		})
	}

	return isLoading ? (
		<Loading />
	) : (
		<ScreenWrapper center>
			<OnboardingProgressBar section={1} position={1} style={{ marginBottom: 55 }} />
			<FeatureImageProto source={require("../../assets/images/clair_card_with_bow.png")} />
			<MetroText h1 center testID="kycPassHeadline">
				{I18n.t("KYCPass.PageHeader")}
			</MetroText>
			<FlexView />
			<MetroText center testID="kycPassBody">
				{I18n.t("KYCPass.Body", {
					daysLow: cardDeliveryDays,
					daysHigh: cardDeliveryDays + daysOffset,
				})}
			</MetroText>
			<FlexView />
			<Button
				title={I18n.t("KYCPass.Button1")}
				onPress={handlePrimaryAction}
				testID={"kycPassNext"}
			/>
			<LegalText>{I18n.t("IssuerStatement")}</LegalText>
		</ScreenWrapper>
	)
}
