import { NavigationContainerRef } from "@react-navigation/native"
import { OnboardingStackParamList } from "../types"
import { openInNewTab } from "../utils/url"
import { QueryParamContext } from "../context/queryParam/QueryParamContext"
import { useContext } from "react"

export const useOpenInternalUrl = () => {
	const { inSdk } = useContext(QueryParamContext)
	const openInternalUrl = (
		url: string,
		navigation: NavigationContainerRef<OnboardingStackParamList> | null
	) => {
		if (inSdk) navigation?.navigate("DocumentViewer", { uri: url })
		else openInNewTab(url)
	}
	return { openInternalUrl }
}
