import Colors from "../../../constants/Colors"
import I18n from "i18n-js"
import React, { useState } from "react"
import useAnalytics from "../../../hooks/useAnalytics"
import useColorScheme from "../../../hooks/useColorScheme"
import { AccountInfoCard } from "../../../components/AccountInfoCard"
import { AutoHeightImage } from "../../../components/AutoHeightImage"
import { Avatar, Icon } from "react-native-elements"
import { Bold, Headline } from "../../../components/StyledText"
import { Button, MetroText, P2 } from "@getclair/react-native-storybook"
import { ImageBullet } from "../../../components/ImageBullet"
import { OnboardingProgressBar } from "../../../components/OnboardingProgressBar"
import { OnboardingStackParamList } from "../../../types"
import { ScreenWrapper } from "../../../components/ScreenWrapper"
import { StackScreenProps } from "@react-navigation/stack"
import { TouchableFrame } from "../../../components/TouchableFrame"
import { View } from "react-native"

export default function ScreenshotEducation({
	navigation,
}: StackScreenProps<OnboardingStackParamList, "ScreenshotEducation">) {
	const [isVisible, setIsVisible] = useState(false)

	const theme = useColorScheme()
	const { track } = useAnalytics()

	const doBullets = [
		I18n.t("ScreenshotEducation.Bullet1"),
		I18n.t("ScreenshotEducation.Bullet2"),
		I18n.t("ScreenshotEducation.Bullet3"),
	]

	const dontBullets = [I18n.t("ScreenshotEducation.Bullet4"), I18n.t("ScreenshotEducation.Bullet5")]

	return (
		<ScreenWrapper>
			<OnboardingProgressBar section={1} position={8} />
			<Headline smallText testID="screenshotEducationHeadline">
				{I18n.t("ScreenshotEducation.PageHeader")}
			</Headline>
			<P2>
				{I18n.t("ScreenshotEducation.Body1")}
				<Bold> {I18n.t("ScreenshotEducation.Body2Bold")} </Bold>
				{I18n.t("ScreenshotEducation.Body2")}
				<Bold> {I18n.t("ScreenshotEducation.Body3Bold")} </Bold>
				{I18n.t("ScreenshotEducation.Body3")}
				<Bold> {I18n.t("ScreenshotEducation.Body4Bold")} </Bold>
				{I18n.t("ScreenshotEducation.Body4")}
			</P2>
			<TouchableFrame
				style={{ borderRadius: 8, marginBottom: 14 }}
				onPress={() => {
					track("tap_viewaccountinfo_screenshottop")
					setIsVisible(!isVisible)
				}}
				testID="screenshotEducationAccountInfoButton"
			>
				<View
					style={{ flexDirection: "row", alignItems: "center", justifyContent: "space-between" }}
				>
					<Bold>{I18n.t("ScreenshotEducation.Button1")}</Bold>
					<Icon name={isVisible ? "chevron-up" : "chevron-down"} size={14} type="feather" />
				</View>
				{isVisible && (
					<View style={{ paddingTop: 10 }}>
						<AccountInfoCard />
					</View>
				)}
			</TouchableFrame>
			<Button
				title={I18n.t("ScreenshotEducation.Button2")}
				icon={
					<Avatar
						source={require("../../../assets/icons/paperclip.png")}
						imageProps={{
							resizeMode: "contain",
						}}
						size={14}
						rounded={false}
						overlayContainerStyle={{ marginRight: 5 }}
					/>
				}
				onPress={() =>
					navigation.reset({
						routes: [{ name: "DIYLoading" }],
					})
				}
				buttonStyle={{ marginBottom: 20 }}
				testID="screenshotEducationButton1"
			/>
			<MetroText style={{ fontSize: 28, color: Colors[theme]["successGreen"], marginBottom: 12 }}>
				{I18n.t("ScreenshotEducation.Do")}
			</MetroText>
			<AutoHeightImage
				width={350}
				style={{ alignSelf: "center" }}
				source={require("../../../assets/images/payroll_do.png")}
			/>
			<View style={{ marginVertical: 25 }}>
				{doBullets.map((bullet, index) => (
					<ImageBullet
						key={index}
						text={bullet}
						image={require("../../../assets/icons/green_check.png")}
						imageSize={30}
						style={{ marginBottom: 8 }}
						textStyle={{ fontSize: 14 }}
					/>
				))}
			</View>
			<MetroText style={{ fontSize: 28, color: Colors[theme]["textError"] }}>
				{I18n.t("ScreenshotEducation.Don't")}
			</MetroText>
			<AutoHeightImage
				width={327}
				style={{ alignSelf: "center" }}
				source={require("../../../assets/images/payroll_dont.png")}
			/>
			<View style={{ marginVertical: 25 }}>
				{dontBullets.map((bullet, index) => (
					<ImageBullet
						key={index}
						text={bullet}
						image={require("../../../assets/icons/red_x.png")}
						imageSize={28}
						style={{ marginBottom: 8 }}
						textStyle={{ fontSize: 14 }}
					/>
				))}
			</View>
			<Button
				title={I18n.t("ScreenshotEducation.Button2")}
				icon={
					<Avatar
						source={require("../../../assets/icons/paperclip.png")}
						imageProps={{
							resizeMode: "contain",
						}}
						size={14}
						rounded={false}
						overlayContainerStyle={{ marginRight: 5 }}
					/>
				}
				onPress={() =>
					navigation.reset({
						routes: [{ name: "DIYLoading" }],
					})
				}
				testID="screenshotEducationButton2"
			/>
		</ScreenWrapper>
	)
}
