import analytics from "../analytics"
import LogRocket from "logrocket"
import variables from "../utils/variables"
import { QueryParamContext } from "../context/queryParam/QueryParamContext"
import { useContext, useEffect, useState } from "react"
import { useDeviceAndWalletType } from "./useDeviceAndWalletType"
import { wait } from "../utils/wait"

type AnalyticsEvent = "start_funnel" | string

export default function useAnalytics() {
	const { inSdk, src } = useContext(QueryParamContext)
	const { isMobileDevice } = useDeviceAndWalletType()
	const [source, setSource] = useState("")

	useEffect(() => {
		// Use the truthyness of src to ensure that the query params context is hydrated before source is set
		if (src) setSource(inSdk ? "embedded" : isMobileDevice ? "mobile_browser" : "desktop_browser")
	}, [inSdk, isMobileDevice, src])

	const track = async (event: AnalyticsEvent, properties?: {}) => {
		analytics.track(event, {
			...properties,
			web_or_app: source,
		})
		if (variables.environment === "production") {
			LogRocket.track(event, {
				...properties,
				web_or_app: source,
			})
		}
		await wait(100)
	}

	return {
		source,
		track,
	}
}
