import _ from "lodash"
import Colors from "../constants/Colors"
import I18n from "i18n-js"
import React, { Pressable, View, ViewStyle } from "react-native"
import useAnalytics from "../hooks/useAnalytics"
import useColorScheme from "../hooks/useColorScheme"
import { Button, MetroText, TertiaryButton } from "@getclair/react-native-storybook"
import { FlexView } from "./FlexView"
import { FunctionComponent, useContext, useState } from "react"
import { Icon } from "react-native-elements"
import { OnboardingContext } from "../context/onboarding/OnboardingContext"
import { OnboardingStackParamList, VideoCarouselParamList } from "../types"
import { Overlay } from "./Overlay"
import { QueryParamContext } from "../context/queryParam/QueryParamContext"
import { StackNavigationProp } from "@react-navigation/stack"
import { Survey } from "../models/Survey"
import { useBroadcastEvent } from "../hooks/useBroadcastEvent"
import { useDirectDepositFinalStatus } from "../hooks/useDirectDepositStatus"
import { useIsNonMobileScreen } from "../hooks/useIsNonMobileScreen"
import { useNavigation } from "@react-navigation/native"
import { useSurveyQuery } from "../hooks/useSurveyQuery"
import { useUserContext } from "../context/user/UserContext"

type QuestionMarkProps = {
	style?: ViewStyle
	textColor: string | undefined
	onPress: (showOverlay: boolean) => void
}
export const QuestionMark: FunctionComponent<QuestionMarkProps> = ({ style, onPress }) => {
	const theme = useColorScheme()
	const isNonMobileScreen = useIsNonMobileScreen()

	return (
		<Pressable
			style={[
				{
					flexDirection: "row",
					marginRight: isNonMobileScreen ? 25 : 0,
				},
				style,
			]}
			onPress={() => {
				if (onPress) onPress(true)
			}}
			hitSlop={{ top: 10, bottom: 10, left: 10, right: 10 }}
		>
			<Icon
				name="help-circle"
				type="feather"
				size={20}
				color={Colors[theme]["textHighlight"]}
				style={{ marginRight: isNonMobileScreen ? 10 : 0 }}
			/>
			{isNonMobileScreen && (
				<MetroText highlight style={{ paddingRight: 0, alignSelf: "center" }}>
					{I18n.t("Help")}
				</MetroText>
			)}
		</Pressable>
	)
}

export type HeaderRightProps = {
	routeName: keyof OnboardingStackParamList
	textColor?: string
	onPress?: () => void
	setShowOverlay: (showOverlay: boolean) => void
}
export const HeaderRight: FunctionComponent<HeaderRightProps> = ({
	routeName,
	textColor,
	onPress,
	setShowOverlay,
}) => {
	const broadcastEvent = useBroadcastEvent()
	const { inSdk = false, clairEmbed } = useContext(QueryParamContext)
	const navigation = useNavigation<StackNavigationProp<OnboardingStackParamList>>()
	const { accessToken: authenticatedUser, stage } = useUserContext()
	const { email, hasSubmittedForm } = useContext(OnboardingContext)
	const theme = useColorScheme()
	const { track } = useAnalytics()
	const { data: surveyData } = useSurveyQuery()
	const isNonMobileScreen = useIsNonMobileScreen()
	const { status } = useDirectDepositFinalStatus()
	const [selfEmbedModalVisible, setSelfEmbedModalVisible] = useState(false)
	const routesWithoutHelpOption: Array<
		keyof OnboardingStackParamList | keyof VideoCarouselParamList
	> = [
		"KYCLoading",
		"WelcomeCarousel",
		"Carousel1",
		"Carousel2",
		"Carousel3",
		"DIYLoading",
		"AtomicWebView",
		"MaintenanceMode",
		"KYCFail",
	]

	const routesWithoutLogoutOption: Array<keyof OnboardingStackParamList> = ["ExitSurvey"]
	let directDepositExitSurvey: Survey | undefined
	let directDepositPendingExitSurvey: Survey | undefined
	let mobileNumberExitSurvey: Survey | undefined
	let signUpExitSurvey: Survey | undefined
	_.map(surveyData, (survey) => {
		switch (survey.id) {
			case "WIDGET_DIRECT_DEPOSIT_EXIT_SURVEY":
				directDepositExitSurvey = survey
				break
			case "WIDGET_DIRECT_DEPOSIT_PENDING_EXIT_SURVEY":
				directDepositPendingExitSurvey = survey
				break
			case "WIDGET_MOBILE_NUMBER_EXIT_SURVEY":
				mobileNumberExitSurvey = survey
				break
			case "WIDGET_SIGN_UP_EXIT_SURVEY":
				signUpExitSurvey = survey
				break
			default:
				break
		}
	})

	const onHeaderRightPress = () => {
		if (onPress) onPress()
		else {
			if (
				stage === "DIRECT_DEPOSIT" &&
				(status == "ACTION_REQUIRED" || status == "NOT_STARTED") &&
				directDepositExitSurvey?.isComplete === false
			)
				navigation.navigate("ExitSurvey", { survey: directDepositExitSurvey })
			else if (
				stage === "DIRECT_DEPOSIT" &&
				status == "IN_PROGRESS" &&
				directDepositPendingExitSurvey?.isComplete === false
			)
				navigation.navigate("ExitSurvey", { survey: directDepositPendingExitSurvey })
			else if (
				(routeName === "MobileNumber" || routeName === "VerifyNumber") &&
				mobileNumberExitSurvey?.isComplete === false
			)
				navigation.navigate("ExitSurvey", { survey: mobileNumberExitSurvey })
			else if (email && !hasSubmittedForm && signUpExitSurvey?.isComplete === false)
				navigation.navigate("ExitSurvey", { survey: signUpExitSurvey })
			else {
				if (clairEmbed) {
					track("open_appareyousure")
					setSelfEmbedModalVisible(true)
				} else {
					navigation.navigate("AreYouSure")
				}
			}
		}
	}
	return inSdk ? (
		<View
			style={{
				flexDirection: "row",
				justifyContent: !isNonMobileScreen ? "space-around" : undefined,
				width: !isNonMobileScreen ? 105 : undefined,
			}}
		>
			<Overlay
				isVisible={selfEmbedModalVisible}
				setShowOverlay={() => {
					track("tap_close_areyousure")
					setSelfEmbedModalVisible(false)
				}}
				showClose
				style={{ maxHeight: 300, paddingHorizontal: 40 }}
			>
				<MetroText h2 style={{ alignSelf: "flex-start" }}>
					{I18n.t("AreYouSure.PostKYCClairEmbedded.PageHeader")}
				</MetroText>
				<MetroText style={{ marginBottom: 10 }}>
					{I18n.t("AreYouSure.PostKYCClairEmbedded.Body")}
				</MetroText>
				<FlexView />
				<Button
					title={I18n.t("AreYouSure.PostKYCClairEmbedded.Button1")}
					onPress={() => {
						track("tap_continue_areyousure")
						setSelfEmbedModalVisible(false)
					}}
					buttonStyle={{ width: 264, alignSelf: "center" }}
				/>

				<TertiaryButton
					title={I18n.t("AreYouSure.PostKYCClairEmbedded.ButtonTertiary")}
					onPress={() => {
						track("tap_later_areyousure")
						broadcastEvent({ event: "clair-onboarding-close", payload: {} })
					}}
					style={{ marginBottom: 30 }}
				/>
			</Overlay>
			{!routesWithoutHelpOption.includes(routeName) && (
				<QuestionMark onPress={setShowOverlay} textColor={textColor} />
			)}

			{!routesWithoutLogoutOption.includes(routeName) &&
				(authenticatedUser && !clairEmbed ? (
					<Icon
						name="log-out"
						type="feather"
						onPress={onHeaderRightPress}
						size={20}
						color={textColor || Colors[theme]["textHighlight"]}
						style={{ marginHorizontal: 10 }}
					/>
				) : (
					clairEmbed &&
					authenticatedUser && (
						<Icon
							name="close"
							type="antdesign"
							size={20}
							color={textColor || Colors[theme]["textHighlight"]}
							onPress={onHeaderRightPress}
							hitSlop={{ top: 10, bottom: 10, left: 10, right: 10 }}
							testID="closeButton"
						/>
					)
				))}
		</View>
	) : authenticatedUser ? (
		<View
			style={{
				flexDirection: "row",
				justifyContent: isNonMobileScreen ? "flex-end" : "space-around",
				width: "100%",
			}}
		>
			{!routesWithoutHelpOption.includes(routeName) && (
				<QuestionMark onPress={setShowOverlay} textColor={textColor} />
			)}
			{!routesWithoutLogoutOption.includes(routeName) && (
				<Pressable onPress={onHeaderRightPress} style={{ flexDirection: "row" }}>
					<Icon
						name="log-out"
						type="feather"
						size={20}
						color={textColor || Colors[theme]["textHighlight"]}
						style={{ marginHorizontal: 10 }}
					/>
					{isNonMobileScreen && (
						<MetroText highlight testID="logoutButton" style={{ marginRight: 10 }}>
							{I18n.t("Navigation.LogOut")}
						</MetroText>
					)}
				</Pressable>
			)}
		</View>
	) : (
		<View
			style={{
				flexDirection: "row",
				justifyContent: isNonMobileScreen ? "flex-end" : "space-around",
				width: "100%",
			}}
		>
			{!routesWithoutHelpOption.includes(routeName) && (
				<QuestionMark onPress={setShowOverlay} textColor={textColor} />
			)}
		</View>
	)
}
