import Colors from "../constants/Colors"
import React, { FunctionComponent } from "react"
import useColorScheme from "../hooks/useColorScheme"
import { Icon, Overlay as RNEOverlay, OverlayProps as RNEOverlayProps } from "react-native-elements"
import { Modal } from "react-native"
import { useCalculatedFrameDimensions } from "../hooks/useCalculatedFrameDimensions"

export type OverlayProps = {
	showClose?: boolean
	overlayName?: string
	setShowOverlay(show?: boolean): void
} & RNEOverlayProps
export const Overlay: FunctionComponent<OverlayProps> = ({
	children,
	showClose,
	setShowOverlay,
	overlayName,
	style,
	...rest
}) => {
	const theme = useColorScheme()
	const { width } = useCalculatedFrameDimensions()

	return (
		<RNEOverlay
			ModalComponent={Modal}
			overlayStyle={[
				{
					backgroundColor: Colors[theme]["background"],
					borderRadius: 6,
					alignItems: "center",
					paddingVertical: 40,
					minWidth: 327,
					marginHorizontal: 24,
					maxWidth: width - 30,
				},
				style,
			]}
			{...rest}
		>
			{showClose && (
				<Icon
					name="close"
					size={20}
					color={Colors[theme]["text"]}
					containerStyle={{
						position: "absolute",
						top: 23,
						right: 23,
					}}
					onPress={() => setShowOverlay(false)}
					testID={overlayName ? `${overlayName}OverlayClose` : "overlayClose"}
				/>
			)}
			{children}
		</RNEOverlay>
	)
}
