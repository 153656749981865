import { SET_ONBOARDING_STATE } from "../actionTypes"

type OnboardingAction = {
	type: typeof SET_ONBOARDING_STATE
	payload: any
}
type OnboardingState = {
	src?: string | null
	businessId?: string | null
	employeeId?: string | null
	widgetId?: string | null
	inSdk?: boolean | null
	id: number | null
	invitationCode: string | null
	firstName: string | null
	lastName: string | null
	email: string | null
	dateOfBirth: string | null
	addressLine1: string | null
	addressLine2: string | null
	shippingAddressLine1: string | null
	shippingAddressLine2: string | null
	shippingCity: string | null
	shippingState: string | null
	shippingPostalCode: string | null
	city: string | null
	state: string | null
	postalCode: string | null
	industry: string | null
	annualSalaryRange: string | null
	password: string | null
	confirmPassword: string | null
	ssn: string | null
	ssnVerify: string | null
	baseUrl?: string | null
	isPhoneVerified?: boolean
}

export const onboardingReducer = (state: OnboardingState, action: OnboardingAction) => {
	let newState = state
	switch (action.type) {
		case SET_ONBOARDING_STATE:
			newState = {
				...state,
				...action.payload,
			}
			break
		default:
			newState = state
	}
	return newState
}
