import * as Device from "expo-device"
import { QueryParamContext } from "../context/queryParam/QueryParamContext"
import { Stage } from "../constants/stages"
import { useContext } from "react"
import { useUserContext } from "../context/user/UserContext"

export default function useDefaultHeaderStyle() {
	const { inSdk } = useContext(QueryParamContext)
	const { stage } = useUserContext()

	const stageTitleMapping: { [key in Stage]: string } = {
		DIRECT_DEPOSIT: "Direct deposit setup",
		WAGE_ADVANCE: "On-demand pay",
		CARD_ACTIVATION: "Card activation",
		READY_TO_GET_PAID_EARLY: "On-demand pay",
		DOWNLOAD_APP: "",
	}
	const stageTitle = stage ? stageTitleMapping[stage] : "Account creation"
	const defaultHeaderStyle = {
		borderBottomWidth: 0,
		height: !inSdk ? 71 : Device.osName === "Android" ? 100 : 84,
	}
	return { defaultHeaderStyle, stageTitle }
}
