import Colors from "../constants/Colors"
import React, { FunctionComponent } from "react"
import useColorScheme from "../hooks/useColorScheme"
import { blendColors } from "../utils/blendColors"
import { LinearGradient } from "expo-linear-gradient"
import { View, ViewProps } from "react-native"

type ProgressIndicatorProps = {
	activeIndex: number
	activeProgress: number
} & ViewProps
export const ProgressIndicator: FunctionComponent<ProgressIndicatorProps> = ({
	activeIndex,
	activeProgress,
	style,
	...rest
}) => {
	const theme = useColorScheme()
	const percentProgress = activeIndex === 0 ? activeProgress / 24 : (activeProgress + 11) / 24
	return (
		<View style={[{ flexDirection: "row", alignItems: "center", flex: 1 }, style]} {...rest}>
			<Circle color={Colors[theme]["progressBarStart"]} width={4} />
			<Bar progressPercent={Math.min(percentProgress * 2, 1)} startsAt={0} />
			<Circle
				color={Colors[theme][activeIndex > 0 ? "progressBarEnd" : "progressBarBackground"]}
				width={6}
			/>
			<Bar
				progressPercent={percentProgress > 0.5 ? (percentProgress - 0.5) * 2 : 0}
				startsAt={0.5}
			/>
			<Circle
				color={Colors[theme][percentProgress >= 1 ? "progressBarEnd" : "progressBarBackground"]}
				width={8}
			/>
		</View>
	)
}

type CircleProps = {
	color: string
	width: number
}
const Circle = ({ color, width }: CircleProps) => (
	<View
		style={{
			backgroundColor: color,
			width,
			height: width,
			borderRadius: width / 2,
			marginHorizontal: 4,
		}}
	/>
)

type BarProps = {
	progressPercent: number
	startsAt: number
}
const Bar = ({ progressPercent, startsAt }: BarProps) => {
	const theme = useColorScheme()
	return (
		<View
			style={{
				flex: 1,
				backgroundColor: Colors[theme]["progressBarBackground"],
				borderRadius: 100,
				height: 2,
			}}
		>
			<LinearGradient
				colors={[
					startsAt === 0
						? Colors[theme]["progressBarStart"]
						: blendColors(
								Colors[theme]["progressBarStart"],
								Colors[theme]["progressBarEnd"],
								progressPercent
						  ),
					startsAt === 0
						? blendColors(
								Colors[theme]["progressBarStart"],
								Colors[theme]["progressBarEnd"],
								progressPercent < 1 ? 1 : 1 - progressPercent / 2
						  )
						: Colors[theme]["progressBarEnd"],
				]}
				start={[0, 0]}
				end={[1, 0]}
				style={{
					borderRadius: 100,
					height: 2,
					width: `${Math.min(progressPercent * 100, 100)}%`,
				}}
			/>
		</View>
	)
}
