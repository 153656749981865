import _ from "lodash"
import I18n from "i18n-js"
import React from "react"
import { FeatureImage } from "../../components/FeatureImage"
import { FlexView } from "../../components/FlexView"
import { Headline, MetroText } from "../../components/StyledText"
import { OnboardingStackParamList } from "../../types"
import { ScreenWrapper } from "../../components/ScreenWrapper"
import { StackScreenProps } from "@react-navigation/stack"

export default function PaydayWalletDisabledBeforeKYCPass({}: StackScreenProps<
	OnboardingStackParamList,
	"PaydayWalletDisabledBeforeKYCPass"
>) {
	return (
		<ScreenWrapper center>
			<FeatureImage
				medium
				style={{ marginBottom: 50, width: 200 }}
				source={require("../../assets/images/kyc_disabled.png")}
			/>

			<Headline center style={{ marginBottom: 28 }}>
				{I18n.t("PDWDisabledPreKYCPass.PageHeader1")}
			</Headline>
			<MetroText h4 center style={{ marginBottom: 29 }}>
				{I18n.t("PDWDisabledPreKYCPass.Body")}
			</MetroText>
			<FlexView />
		</ScreenWrapper>
	)
}
