import React from "react"
import { Icon } from "react-native-elements"
import { PressableProps, TouchableOpacity, View } from "react-native"

type PlayButtonProps = PressableProps & {
	onPlayPress: (shouldPlay: boolean) => void
}
export const PlayButton = ({ onPlayPress, ...rest }: PlayButtonProps) => {
	const opacity = 0.76

	return (
		<TouchableOpacity
			style={{
				position: "absolute",
				top: "25%",
				flexDirection: "row",
				alignSelf: "center",
				alignItems: "center",
				justifyContent: "center",
				height: "50%",
				width: "90%",
			}}
			onPress={() => {
				onPlayPress()
			}}
		>
			<View
				style={{
					backgroundColor: "white",
					opacity,
					width: 60,
					height: 60,
					borderRadius: 60 / 2,
					alignItems: "center",
					justifyContent: "center",
				}}
				{...rest}
			>
				<Icon
					name="play"
					type="ionicon"
					color="black"
					size={32}
					style={{
						opacity,
					}}
				/>
			</View>
		</TouchableOpacity>
	)
}
