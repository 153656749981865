import React from "react"
import { StyleProp, TextStyle } from "react-native"
import { TextUrl } from "../../../components/TextUrl"
import { urls } from "../../../constants/urls"

type DirectDepositFAQsLinkProps = {
	style?: StyleProp<TextStyle>
	url?: string
	eventName?: string
}
export const FAQsLink = ({
	style,
	url = urls.clairSignupFAQUrl,
	eventName,
}: DirectDepositFAQsLinkProps) => {
	return (
		<TextUrl
			h4
			highlight={true}
			textPath={"FAQs"}
			url={url}
			style={[
				{
					marginVertical: 10,
					fontFamily: "metropolis-semi",
					fontSize: 13,
					lineHeight: 13,
					alignSelf: "center",
				},
				style,
			]}
			eventName={eventName}
			internalUrl
			testID="FAQsLink"
		/>
	)
}
