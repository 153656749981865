import AsyncStorage from "@react-native-async-storage/async-storage"
import { Business, DistributionType } from "../../types"
import { INITIAL_USER_STATE } from "./UserProvider"
import { SET_USER_STATE } from "../actionTypes"
export type UserState = {
	accessToken?: string | null
	email: string
	firstName: string
	lastName: string
	business?: Business
	stage?:
		| "DIRECT_DEPOSIT"
		| "WAGE_ADVANCE"
		| "CARD_ACTIVATION"
		| "READY_TO_GET_PAID_EARLY"
		| "DOWNLOAD_APP"
		| ""
	rememberUser?: boolean
	seenWageAdvanceTour?: boolean
	showWageAdvanceTour?: boolean
	distributionType?: DistributionType | ""
	distributionValue?: string
	usedOTPLogin: boolean
}
export type UserAction = {
	type: typeof SET_USER_STATE
	payload: Partial<UserState>
}
export const userReducer = (state: UserState, action: UserAction) => {
	let newState = state
	switch (action.type) {
		case SET_USER_STATE:
			newState = { ...state, ...action.payload }
			break
		default:
			break
	}

	if (newState?.rememberUser) AsyncStorage.setItem("@user", JSON.stringify(newState))
	else AsyncStorage.setItem("@user", JSON.stringify(INITIAL_USER_STATE))

	return newState
}
