import { wageAdvanceAPI } from "."

// https://swagger.getclair.com/widget/#/Wage%20Advance/get_wage_advance_v1_business__businessId__employee__employeeId__overview
export const getWageAdvanceOverview = async () => {
	const { data } = await wageAdvanceAPI.get("overview")
	return data
}
// https://swagger.getclair.com/widget/#/Wage%20Advance/get_wage_advance_v1_business__businessId__employee__employeeId__agreement
export const getWageAdvancePDFAgreement = async (
	timestamp: string,
	amount: string,
	url: boolean = true
) =>
	wageAdvanceAPI.get("agreement", {
		params: { timestamp, amount, url },
	})

// https://swagger.getclair.com/widget/#/Wage%20Advance/patch_wage_advance_v1_business__businessId__employee__employeeId__skip
export const skipWageAdvanceStage = async () => wageAdvanceAPI.patch("skip")

// https://swagger.getclair.com/widget/#/Wage%20Advance/post_wage_advance_v1_business__businessId__employee__employeeId_
export const postWageAdvance = async (amount: number, timestamp: string, tosAccepted: boolean) =>
	wageAdvanceAPI.post("", { amount, timestamp, tosAccepted })
