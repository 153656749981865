import Colors from "../constants/Colors"
import React from "react"
import useColorScheme from "../hooks/useColorScheme"
import { Button as RNEButton, ButtonProps, Icon } from "react-native-elements"
import { FunctionComponent } from "react"
import { OnboardingStackParamList } from "../types"
import { StackNavigationProp } from "@react-navigation/stack"
import { useLogout } from "../hooks/useLogout"
import { useNavigation } from "@react-navigation/native"
export const buttonTextStyle = { fontFamily: "metropolis-semi", fontSize: 14, lineHeight: 24 }
export const Button: FunctionComponent<ButtonProps> = ({
	buttonStyle,
	titleStyle,
	containerStyle,
	loading,
	...rest
}) => {
	const theme = useColorScheme()
	const backgroundColor = Colors[theme]["backgroundButtonDefault"]
	const titleColor = Colors[theme]["textButtonDefault"]
	const isIOSUIWebView = /(iPhone|iPod|iPad).*AppleWebKit(?!.*Safari)/i.test(navigator.userAgent)

	return (
		<RNEButton
			buttonStyle={[
				{
					backgroundColor: backgroundColor,
					minWidth: "100%",
					paddingVertical: 12,
					borderRadius: 1,
				},
				buttonStyle,
			]}
			containerStyle={[{ alignSelf: "center", marginVertical: 10, width: "100%" }, containerStyle]}
			titleStyle={[{ color: titleColor }, buttonTextStyle, titleStyle]}
			loading={loading}
			disabled={loading}
			{...rest}
			// IOS webview does does not trigger onPress on first interaction with page
			// Call rest.onPress onPressOut if isIOSUIWebView is detected.
			// TODO - Implement in storybook
			onPressOut={(e) => (isIOSUIWebView ? rest.onPress && rest.onPress(e) : null)}
			onPress={(e) => (isIOSUIWebView ? null : rest.onPress && rest.onPress(e))}
		/>
	)
}

export const SecondaryButton: FunctionComponent<ButtonProps> = ({
	buttonStyle,
	titleStyle,
	containerStyle,
	loading,
	...rest
}) => {
	const theme = useColorScheme()
	const titleColor = Colors[theme]["textHighlight"]

	return (
		<Button
			buttonStyle={[{ backgroundColor: "transparent" }, buttonStyle]}
			containerStyle={[
				{
					alignSelf: "center",
					borderColor: titleColor,
					borderWidth: 1,
					marginTop: 6,
				},
				containerStyle,
			]}
			titleStyle={[{ color: titleColor }, titleStyle]}
			loading={loading}
			disabled={loading}
			{...rest}
		/>
	)
}

type SecondaryListButtonProps = {
	showChevron?: boolean
} & ButtonProps
export const SecondaryListButton: FunctionComponent<SecondaryListButtonProps> = ({
	buttonStyle,
	titleStyle,
	containerStyle,
	loading,
	showChevron,
	...rest
}) => {
	const theme = useColorScheme()
	const titleColor = Colors[theme]["textHighlight"]
	const chevron = showChevron
		? {
				icon: (
					<Icon
						name="chevron-right"
						type="feather"
						size={16}
						color={Colors[theme]["textHighlight"]}
						containerStyle={{ marginLeft: 8 }}
					/>
				),
				iconPosition: "right",
		  }
		: {}

	return (
		// @ts-ignore
		<Button
			buttonStyle={[
				{
					backgroundColor: "transparent",
					justifyContent: showChevron ? "space-between" : "flex-start",
				},
				buttonStyle,
			]}
			containerStyle={[
				{
					alignSelf: "center",
					borderColor: Colors[theme]["borderLight"],
					borderWidth: 1,
					marginTop: 6,
					paddingLeft: 19,
					borderRadius: 6,
				},
				containerStyle,
			]}
			titleStyle={[{ color: titleColor, marginLeft: 18 }, titleStyle]}
			loading={loading}
			disabled={loading}
			{...chevron}
			{...rest}
		/>
	)
}

export const TertiaryButton: FunctionComponent<ButtonProps> = ({
	buttonStyle,
	titleStyle,
	containerStyle,
	loading,
	...rest
}) => {
	const theme = useColorScheme()
	const backgroundColor = Colors[theme]["background"]
	const titleColor = Colors[theme]["textHighlight"]

	return (
		<Button
			buttonStyle={[
				{
					backgroundColor: backgroundColor,
					paddingVertical: 4,
				},
				buttonStyle,
			]}
			containerStyle={[{ alignSelf: "center", marginTop: 0 }, containerStyle]}
			titleStyle={[
				{
					color: titleColor,
					fontSize: 14,
					fontWeight: "normal",
					fontFamily: "metropolis-medium",
					lineHeight: 20,
				},
				titleStyle,
			]}
			loading={loading}
			{...rest}
		/>
	)
}

export const LogoutButton: FunctionComponent<ButtonProps> = (props) => {
	const logout = useLogout()
	const navigation = useNavigation<StackNavigationProp<OnboardingStackParamList>>()
	return <SecondaryButton title="LOGOUT" onPress={() => logout(navigation)} {...props} />
}
